import axios from 'axios';
import {
    makeAuthUrlWithHeaders,
    acquireToken,
} from "../../utils";

const fetchCustomPanelTemplate = async (isAuthenticated, msalContext, site) => {
  let url;
  let headers = {};
  /* TODO: replace url with /boa/api/v1/configs/custom-panel-templates when server migration is done
   *  and local url should be the one defined in api.json config file
   */
  if (isAuthenticated) { [url, headers] = await makeAuthUrlWithHeaders(`/boa/api/v1/customTemplate`, headers, acquireToken(msalContext)); }
  else { url = makeLocalUrl("/local/customPanelTemplates.json")}

  try {
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (error) {
    console.error("Error retrieving custom panels: ", error);
    return {};
  }
};

const makeLocalUrl = (url) => {
  if (url.includes("http")) { return url; } 
  else { return process.env.PUBLIC_URL + url}
}

const addEndpointsToCustomPanelTemplates = (templates, endpoints) => {
  Object.keys(templates).forEach((key) => {
    templates[key].sources.series.forEach((serie) => {
      serie.url = endpoints.history;
    });
  });
  return templates;
};

export { fetchCustomPanelTemplate, addEndpointsToCustomPanelTemplates };