import React, { useState, useEffect, useCallback, useContext, createContext } from "react";
import { MsalContext } from "@azure/msal-react";
import { UseAuthenticationContext, SiteContext } from ".";
import { fetchDataHelper } from "../utils";

/**
 * Context containing panel alerts for current site.
 * Note: as panel alert context uses other contexts
 * in its logic, it is critical that whenever context
 * is used through providers, UseAuthenticationProvider
 * and SiteProvider contain PanelAlertProvider.
 */
const PanelAlertContext = createContext();

/**
 * Context provider for panel alerts
 * @param {any} props props
 * @returns context provider for panel alerts
 */
function PanelAlertProvider(props) {
  const [panelAlerts, setPanelAlerts] = useState({});
  const msalContext = useContext(MsalContext);
  const email = msalContext?.accounts?.[0]?.username;
  const { useAuthentication } = useContext(UseAuthenticationContext);
  const { site } = useContext(SiteContext);

  /** Retrieve panel alerts for current user and site */
  const getPanelAlertsForUser = useCallback(async () => {
    fetchDataHelper({
      url: `/boa/api/v1/${site}/panel-alerts`,
      params: { email, site },
      method: "GET",
      useAuthentication,
      authContext: msalContext,
      onSuccess: panelAlerts => { setPanelAlerts(panelAlerts.data) }
    });
  }, [site, email, msalContext, useAuthentication]);

  /** Retrieves panel alerts for user once after login and site select */
  useEffect(() => {
    if (email && site) {
      getPanelAlertsForUser();
    }
  }, [email, site, getPanelAlertsForUser]);

  return (
    <PanelAlertContext.Provider
      value={{
        panelAlerts,
        setPanelAlerts
      }}
    >
      {props.children}
    </PanelAlertContext.Provider>
  );
}

export { PanelAlertContext, PanelAlertProvider };
