import React, { useRef } from "react";
import { ResizableBox } from "react-resizable";
import "../../node_modules/react-resizable/css/styles.css";

/**
 * ResizePanel component for resizing a panel.
 * Uses react-resizable library.
 * documentation: https://github.com/react-grid-layout/react-resizable
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.Ref} props.panelRef - The reference to the panel element.
 * @param {number} props.panelWidth - The width of the panel.
 * @param {Function} props.updateWidth - The function to update the width of the panel.
 * @param {number} props.resizeMinWidth - The minimum width that the panel can be resized to.
 * @param {string} props.itemStorageCode - The code used to store the panel width in local storage.
 * @param {string} props.ruuriId - The ID of the panel.
 * @returns {JSX.Element} The rendered ResizePanel component.
 */

export default function ResizePanel(props) {

    const {
        panelRef,
        panelWidth, 
        updateWidth,
        resizeMinWidth,
        itemStorageCode,
        ruuriId
    } = props;
    const resizeRef = useRef();

    /**
     * Handles the logic when the box resize is active
     * adds a dashed border to the resizeRef and sets the background to white.
     * sets the panel to overflow visible and sets the z-index of the draggable items to 9999.
     * This is done to make sure that the draggable items are visible and not hidden behind other panels while it is being resized.
     */
    const onBoxResize = () => {
        resizeRef.current.style.border = "5px dashed #bfbfbf";
        resizeRef.current.style.background = "white";
        panelRef.current.style.overflow = "visible";
        const draggableItems = document.getElementsByClassName("ruuri-draggable-item");
        for (let i = 0; i < draggableItems.length; i++) {
            if (draggableItems[i].dataset.ruuriId === ruuriId) { draggableItems[i].style.zIndex = 9999; }
        }
    }

    /**
     * Handles the logic when the box resize is stopped.
     * Resets the border and background of the resizeRef and z-index of the draggable item.
     * Sets the panel to overflow hidden, updates the width of the panel and stores the width to local storage.
     */
    const onBoxResizeStop = () => {
        resizeRef.current.style.border = "none";
        resizeRef.current.style.background = "transparent";
        panelRef.current.style.overflow = "hidden";
        const draggableItems = document.getElementsByClassName("ruuri-draggable-item");
        for (let i = 0; i < draggableItems.length; i++) {
            if (draggableItems[i].dataset.ruuriId === ruuriId) { draggableItems[i].style.zIndex = 1; }
        }
        const width = resizeRef.current.clientWidth;
        updateWidth(width); 
        localStorage.setItem(itemStorageCode,JSON.stringify({ width }));
    }

    return (
        <div
          ref={resizeRef}
          style={{
            position: "absolute",
            top: 0,
            pointerEvents: "none",
            opacity: 0.8
          }}
        >
          <ResizableBox 
            width={parseInt(panelWidth)}
            height={panelRef?.current?.clientHeight || 0}
            resizeHandles={['e']}
            minConstraints={[resizeMinWidth, Infinity]}
            axis="x"
            onResize={onBoxResize}
            onResizeStop={onBoxResizeStop}
          />
        </div>
    );
}
