import React, { useContext } from "react";
import { translate } from "../languages";
import { Panel, PanelGroup } from 'rsuite';
import {secondsToString} from "../utils";
import { SiteContext } from "../context/siteContext";

export default function ChartTable(props) {
  const {
    clickedPoints,
    tableOptions,
    resetPoints,
    hiddenSeries
  } = props;

  const { timeZone } = useContext(SiteContext);

  const calculateDifferences = (array1, array2) => {
    return array1.map((obj, index) => {
      if (array2[index] && typeof(array2[index].y) === "number") {
        return {color: obj.color, value: obj.y - array2[index].y, previousValue: array2[index].previousValue || array1[index].previousValue, name: array2[index].name, id: obj.id };
      } else { return {color: obj.color, value: "-", name: obj.name, id: obj.id }}
    });
  };

  const calculateTimeDiff = (array1, array2) => {
    return array1.map((obj, index) => {
      if (array2[index]) { return (obj.x - array2[index].x) / 1000;} 
      else { return "-" }
    });
  };

  const createRulerTable = () => {
    /* decimals */
    const decimals = tableOptions?.decimals || 2;
    return(
      <table className="table-sm table-striped table mb-3">

        <thead>
          <tr>
            <td style={{color: "#2196f3"}}>
              #
            </td>
            <td style={{color: "#2196f3"}}>
              {translate("Time")}
            </td>
            {clickedPoints[0].filter(point => { if (point.id) { return !hiddenSeries.includes(point.id)} else { return !hiddenSeries.includes(point.name)}}).map((point, index) => { 
              return(
                <td 
                  key={index + "title" + point.name} 
                  style={{color: point?.color ? point.color : "#434348", textAlign: "center", maxWidth: "150px"}}
                >
                  {point?.series?.name ? point.series.name : point.name}
                </td>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {clickedPoints.map((point, index) => {
            return(
              <tr key={index + "head"}>
                <td style={{color: "#2196f3"}}>
                  {index+1}
                </td>
                <td style={{color: "#2196f3"}}>
                {new Date(point[0].x).toLocaleString('en-US', { timeZone: timeZone, hour12: false })}
                </td>
                {point.filter(point => { if (point.id) { return !hiddenSeries.includes(point.id)} else { return !hiddenSeries.includes(point.name)}}).map((p1, index1) => { 
                  return(
                  <td 
                    key={index1 + "cell" + index} 
                    style={{color: p1?.color ? p1.color : "#434348", textAlign: "center"}}
                  >
                    {p1?.y && p1.y.toFixed(decimals)}
                    {p1.previousValue === true && <span>*</span>}
                  </td>
                  )
                })}
              </tr>
            )
          })}

          {clickedPoints.length > 1 &&
            <>
              <tr>
                <td 
                  colSpan={clickedPoints[0].length + 2} 
                  style={{color: "#2196f3"}}
                >
                  {translate("Differences")}
                </td>
              </tr>
              {clickedPoints.map((point, index, ogArr) => {
                if (index > 0) {
                  return(
                    <tr key={index + "head2"}>
                      <td style={{color: "#2196f3"}}>
                        {`${index+1}-${index}`}
                      </td>
                      {ogArr[index-1] && ogArr[index-1][0] &&
                        <td style={{color: "#2196f3"}}>
                          {secondsToString(calculateTimeDiff(ogArr[index], ogArr[index-1])[0])}
                        </td>
                      }
                      {ogArr[index-1] &&
                        calculateDifferences(ogArr[index], ogArr[index-1]).map(result => {
                          if ((result.id && !hiddenSeries.includes(result.id)) || (!result.id &&!hiddenSeries.includes(result.name))){ 
                            return(
                              <td 
                                key={result.name + "diff" + index} 
                                id={result.name + "diff" + index} 
                                style={{color: result.color, textAlign: "center"}}
                              >
                                {typeof(result.value) === "number" ? result.value.toFixed(decimals) : result.value}
                                {result.previousValue === true && <span>*</span>}
                              </td>
                            );
                          } else {
                            return null;
                          }
                        })
                      }
                  </tr>
                  )
                } else { return null; }
              })}
            </>
          }
        </tbody>
        
      </table>
    );


  }

  return(
    <PanelGroup className="rulerTable" accordion onSelect={() => resetPoints()}>
      <Panel header={<div style={{padding: "10px"}}></div>} defaultExpanded>
        <div className="table-responsive">
          {createRulerTable()}
        </div>
      </Panel>
    </PanelGroup>
  );
}
