import React from "react";

import { Whisper, Tooltip } from "rsuite";

import { translate } from "../languages";

const TOOLTIP_DELAY = 0;

export function getQuillToolbar() {
  return(
    <>
      <Whisper
        speaker={<Tooltip>{translate("Bold")}</Tooltip>}
        trigger="hover"
        placement="top"
        delayOpen={TOOLTIP_DELAY}
      >
        <button className="ql-bold"/>
      </Whisper>

      <Whisper
        speaker={<Tooltip>{translate("Italic")}</Tooltip>}
        trigger="hover"
        placement="top"
        delayOpen={TOOLTIP_DELAY}
      >
        <button className="ql-italic" />
      </Whisper>

      <Whisper
        speaker={<Tooltip>{translate("Underline")}</Tooltip>}
        trigger="hover"
        placement="top"
        delayOpen={TOOLTIP_DELAY}
      >
        <button className="ql-underline"/>
      </Whisper>

      <Whisper
        speaker={<Tooltip>{translate("Strike")}</Tooltip>}
        trigger="hover"
        placement="top"
        delayOpen={TOOLTIP_DELAY}
      >
        <button className="ql-strike"/>
      </Whisper>

      <Whisper
        speaker={<Tooltip>{translate("Block quote")}</Tooltip>}
        trigger="hover"
        placement="top"
        delayOpen={TOOLTIP_DELAY}
      >
        <button className="ql-blockquote"/>
      </Whisper>

      <Whisper
        speaker={<Tooltip>{translate("Code block")}</Tooltip>}
        trigger="hover"
        placement="top"
        delayOpen={TOOLTIP_DELAY}
      >
        <button className="ql-code-block" />
      </Whisper>

      <Whisper
        speaker={<Tooltip>{translate("Link")}</Tooltip>}
        trigger="hover"
        placement="top"
        delayOpen={TOOLTIP_DELAY}
      >
        <button className="ql-link" />
      </Whisper>

      <Whisper
        speaker={<Tooltip>{translate("Numbered list")}</Tooltip>}
        trigger="hover"
        placement="top"
        delayOpen={TOOLTIP_DELAY}
      >
        <button className="ql-list" value="ordered"/>
      </Whisper>

      <Whisper
        speaker={<Tooltip>{translate("List")}</Tooltip>}
        trigger="hover"
        placement="top"
        delayOpen={TOOLTIP_DELAY}
      >
        <button className="ql-list" value="bullet"/>
      </Whisper>

      <Whisper
        speaker={<Tooltip>{translate("Unindent")}</Tooltip>}
        trigger="hover"
        placement="top"
        delayOpen={TOOLTIP_DELAY}
      >
        <button className="ql-indent" value="-1"/>
      </Whisper>

      <Whisper
        speaker={<Tooltip>{translate("Indent")}</Tooltip>}
        trigger="hover"
        placement="top"
        delayOpen={TOOLTIP_DELAY}
      >
        <button className="ql-indent" value="+1"/>
      </Whisper>
      
      <Whisper
        speaker={<Tooltip>{translate("Text style")}</Tooltip>}
        trigger="hover"
        placement="top"
        delayOpen={TOOLTIP_DELAY}
      >
        <select
          className="ql-header"
          defaultValue={""}
          onChange={e => e.persist()}
        >
          <option value="1" />
          <option value="2" />
          <option value="3" />
          <option value="4" />
          <option value="" />
        </select>
      </Whisper>

      <Whisper
        speaker={<Tooltip>{translate("Font")}</Tooltip>}
        trigger="hover"
        placement="top"
        delayOpen={TOOLTIP_DELAY}
      >
        <select className="ql-font" />
      </Whisper>

      <Whisper
        speaker={<Tooltip>{translate("Align")}</Tooltip>}
        trigger="hover"
        placement="top"
        delayOpen={TOOLTIP_DELAY}
      >
        <select className="ql-align" />
      </Whisper>

      <Whisper
        speaker={<Tooltip>{translate("Color")}</Tooltip>}
        trigger="hover"
        placement="top"
        delayOpen={TOOLTIP_DELAY}
      >
        <select className="ql-color" />
      </Whisper>
    </>
  );
}
