import { create, all } from "mathjs";
import { MathUtils, sumArrayValues } from "../utils";
import moment from 'moment';
import 'moment-timezone';
window.moment = moment;

export function Equations(seriesData, cell, singleDataSeries, time, timeZone) {
  /* cell has either calculate or choose property */
  if (cell.calculate) { return calculateFormula(seriesData, cell, singleDataSeries, time, timeZone)} 
  else if (cell.choose) { return calculateResult(seriesData, cell.choose, timeZone)}
}

function calculateFormula(seriesData, cell, singleDataSeries, time, timeZone) {
  const math = create(all);
  let variables = {};
  if (cell.tags) {
    for (let tagVariable of Object.keys(cell.tags)) { 
      let dataIndex = Object.keys(cell.tags).indexOf(tagVariable); //data array index
      let dataSet = singleDataSeries ? seriesData : seriesData[dataIndex];
      if (dataSet && Array.isArray(dataSet)) { //value is an array
        if (typeof(dataSet[0]) === "number" && typeof(dataSet[1]) === "undefined") { // array with only one number value, e.g. [30]
          variables[tagVariable] = dataSet[0]; // use that number
        } else if (Array.isArray(dataSet[0]) && dataSet[0][1] === undefined) { // [[100]]
          variables[tagVariable] = dataSet[0][0];
        } else if (Array.isArray(dataSet[0]) && typeof(dataSet[0][1]) === "number") { // value is an array of arrays e.g. [[1634027243, 34.2],[1634030843, 25.1]]
          let tagEquation = cell.tags[tagVariable].choose ? cell.tags[tagVariable].choose : "last"; //equation method, default method is "last"
          variables[tagVariable] = calculateResult(dataSet, tagEquation, timeZone) //calculates tag value
        } else if (!Array.isArray(dataSet[0])) {  //array that only has timestamp and value e.g. [1634030843, 10]
          variables[tagVariable] = dataSet[1]; //use value
        }
      } else if (typeof(dataSet) === "number") { //value is a single number
        variables[tagVariable] = dataSet; //use that number
      }
    }
    /* timeButton value that can be used in formula */
    let hours;
    if (time) {
      hours = time/60/60;
    } else {
      hours = 86400/60/60;
    }
    variables["timeButton"] = hours;

    const formula = cell.calculate;
    try {
      return math.evaluate(
        formula,
        variables
      );
    } catch (error) {
      console.log(error.message)
      return "-";
    }
  } else { // no tags => loop through every value and calculate a new value
    return seriesData.map(timeSeries => [timeSeries[0], math.evaluate(cell.calculate, { value: timeSeries[1] })]);
  }
}

function calculateResult(seriesData, equation, timeZone) {
  let calculation;
  if (typeof(equation) === "string"){ calculation = equation }
  else if (typeof(equation.index) === "number") { calculation = "chooseIndex" }
  let result;
  let values = [];
  switch (calculation) {
    case "sum":
      if (Array.isArray(seriesData)) {
        result = sumArrayValues(seriesData.flat(Infinity));
      }
      break;

    case "sumValues":
      if (Array.isArray(seriesData)) {
        result = MathUtils.calcSumValues(seriesData);
      }
      break;

    case "avg":
    case "mean":
      result = MathUtils.calcMean(seriesData);
      break;

    case "aucMean":
      result = MathUtils.aucFunctions(seriesData);
      break;
    
    case "aucVar":
      result = MathUtils.aucFunctions(seriesData, "var");
      break;

    case "aucStdev":
      result = MathUtils.aucFunctions(seriesData, "stdev");
      break;
      
    case "absavg":
      result = Math.abs(MathUtils.calcAbsMean(seriesData));
      break;

    case "max":
      result = MathUtils.getMax(seriesData);
      break;
      
    case "min":
      result = MathUtils.getMin(seriesData);
      break;

    case "stdev":
      let list = [];
      for (let data in seriesData) {
        if (typeof(seriesData[data][1])  === "number") {
          list.push(seriesData[data][1])
        }
      }
      if (list.length > 0) {
        result = Math.sqrt(list.map(x => Math.pow(x-(list.reduce((a,b) => a+b)/list.length), 2)).reduce((a, b) => a + b)/list.length);
      }
      break;

    case "last":
    case "lastVal":
    if (seriesData?.length > 0) {
        result = seriesData[seriesData.length-1][1];
      } else if (typeof(seriesData) === "number") {
        result = seriesData
      } else {
        result = "-";
      }
      break;

      case "abslast":
      if (seriesData?.length > 0) {
        result = Math.abs(seriesData[seriesData.length-1][1]);
      } else if (seriesData) {
        result = Math.abs(seriesData);
      } else {
        result = "-";
      }
      break;

    case "chooseIndex":
      if (seriesData.length > 0 && typeof(equation.index) === "number" && parseInt(equation.index) < seriesData.length) {
        if (equation.timestamp === "utc") { //utc timestamp
          let timestamp = moment(new Date(parseInt(seriesData[equation.index][0])).toISOString());
          result =  timestamp.format('HH:mm:ss (DD.MM.)');
        } else if (equation.timestamp === "date") {
          let timestamp = moment(new Date(parseInt(seriesData[equation.index][0])).toISOString());
          result =  timestamp.format('DD.MM.');
        } else if (equation.timestamp) { // local time format
          let timestamp = moment(new Date(parseInt(seriesData[equation.index][0])).toISOString());
          if (typeof(equation.timestamp) === "string") {
            try {
              result = timestamp.tz(timeZone).format(equation.timestamp);
            } catch (e) {
              result = timestamp.tz(timeZone).format('HH:mm:ss (DD.MM.)');
              console.log("timestamp format is invalid");
            }
          } else {
            result = timestamp.tz(timeZone).format('HH:mm:ss (DD.MM.)');
          }
        } else { result = seriesData[equation.index][1]; } // value
      } else { result = "-"; }
      break;
    
    case "count":
      if (Array.isArray(seriesData)) {
        result = seriesData.length;
      } else {
        result = "-";
      }
        break;

    case "countPos":
      if (Array.isArray(seriesData)) {
        result = seriesData.reduce(function(total, val, i) {
          let lastVal = 0; // init last value with zero to prevent -1 index crash
          if (seriesData[i-1]) {
            lastVal = seriesData[i-1][1]; // compare result to last
          }
        
          if (val[1] !== lastVal) {
            return total + (val[1] > 0);  // add 1
          } else {
            return total; // add 0
          }
        }, 0);
      }
      break;

    case "countChanges":
      if (Array.isArray(seriesData)) {
        result = seriesData.reduce(function(total, val, i) {
          let lastVal = 0; // init last value with zero to prevent -1 index crash
          if (seriesData[i-1]) {
            lastVal = seriesData[i-1][1]; // compare result to last
          }
        
          if (val[1] !== lastVal) {
            return total + 1;  // add 1
          } else {
            return total; // add 0
          }
        }, 0);
      }
      break;
      
    case "OEE":
    case "oee":
      for (let data in seriesData) {
        values.push(seriesData[data]);
      }
      result = (values[0]) * (values[1]) * (values[2]) * 100;
      break;

    case "timestamp":
      if (Array.isArray(seriesData) && seriesData.length > 0 && seriesData[seriesData.length-1][0]) {
        let timestamp = moment(new Date(parseInt(seriesData[seriesData.length-1][0])).toISOString());
        result =  timestamp.tz(timeZone).format('HH:mm:ss (DD.MM.)');
      } else {
        result = "-";
      }
      break;  

    case "listDates":
      result = "";
      for (let i in seriesData) {
        let timestamp = moment(new Date(parseInt(seriesData[i][0])).toISOString());
        timestamp = timestamp.tz(timeZone).format('DD/MM/YYYY HH:mm:ss');     
        result += timestamp + "\n";
      }
      break;

    case "tofahrenheit":
      if (seriesData.length > 0) {
        result = seriesData[seriesData.length-1][1]  * 1.8 + 32;
      } else if (seriesData) {
        result = seriesData  * 1.8 + 32;
      } else {
        result = "-";
      }
      break;
    
    default:
      result = "equation missing";
  }
  return result;
}