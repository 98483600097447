import React, { useContext, useState, useEffect } from "react";
import { DatePicker, DateRangePicker } from "rsuite";
import { LanguageContext } from "../context";
import { datePickerLocale, getTimeparams } from "../utils";
import { calculateTimeWindow, calculateExtraTimeAndSkipCount } from ".";

export default function DatePickerComponent(props) {
  const {
    pickerType = "range",
    defaultDate = getTimeparams(new Date()),
    timeWindow = 86400,
    passTimeValue
  } = props;

  const { afterToday } = DateRangePicker;
  const { translations, selectedLanguage } = useContext(LanguageContext);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768); 

  useEffect(() => { 
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    const debounceResize = debounce(handleResize, 100); 

    window.addEventListener('resize', debounceResize);
    return () => {
      window.removeEventListener('resize', debounceResize);
    };
  }, []);

  function debounce(func, wait) {
    let timeout;
    return function(...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  /**
   * Handles the date range change event.
   *
   * @param {string} sdt - The start date and time.
   * @param {string} edt - The end date and time.
   */
  const handleRangeChange = (sdt, edt) => {
    let newSdt = new Date(sdt);
    let newEdt = new Date(edt);
    newSdt.setSeconds(0, 0);
    newEdt.setSeconds(0, 0);
    const newTimeWindow = calculateTimeWindow(newSdt, newEdt);
    const [newSkipTimeCount, newExtraTime ] = calculateExtraTimeAndSkipCount(newTimeWindow, newEdt);

    passTimeValue({
      sdt: newSdt,
      edt: newEdt,
      newTimeWindow: newTimeWindow / 1000,
      newSkipTimeCount: newSkipTimeCount,
      newExtraTime: newExtraTime
    });
  
  }

  /**
   * Handles the date change event.
   * @param {Object} date - The selected date.
   * @param {string} date.sdt - The selected start date.
   * @param {string} date.edt - The selected end date.
   */
  const handleDateChange = ({sdt, edt}) => {
    const currentTime = new Date().getTime();
    const selectedSdtTime = new Date(sdt).getTime();
    const selectedEdtTime = new Date(edt).getTime();
    const newTimeWindow = (selectedEdtTime - selectedSdtTime) / 1000;
    const newSkipTimeCount = parseInt((currentTime - selectedSdtTime) / 1000 / newTimeWindow);
    passTimeValue({
      sdt: sdt,
      edt: edt,
      newTimeWindow: newTimeWindow,
      newSkipTimeCount: newSkipTimeCount,
      newExtraTime: 0
    });
  };

  /**
   * Handles the month change event.
   * @param {Date} month - month is new Date() object e.g. Date Thu Jan 25 2024 09:45:33 GMT+0200 (Eastern European Standard Time)
   * Does this work also on 30th and 31st of the month if I select February?
   */
  const handleMonthChange = (month) => {
    const selectedMonth = month.getMonth() + 1;
    const selectedYear = month.getYear();
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getYear();
    /* Calculation:
     * Today is March 1st 2024, selected month is February 2024, currentMonth > selectedMonth
     * 3 - 2 + (2024-2024) * 12
     * = 1 + 0 * 12 = 1
     * Today is March 1st 2024, selected month is August 2023
     * 12 - 8 + 3 + (2024 - 2023 - 1) * 12 
     * = 4 + 3 + 0 * 12 = 7
     */
    const calculateNewSkipTimeCount = () => {
      if (currentMonth > selectedMonth) { return currentMonth - selectedMonth + (currentYear - selectedYear) * 12; } 
      else { return 12 - selectedMonth + currentMonth + (currentYear - selectedYear - 1) * 12; }
    }
    passTimeValue({ 
      sdt: month, 
      edt: month,
      newTimeWindow: pickerType, 
      newSkipTimeCount: calculateNewSkipTimeCount(),
      newExtraTime: 0
    });
  };
  

  // Save the selected dates to session storage
  // so they can be restored when the page is reloaded

  /**
   * Creates an end date based on the given start date.
   *
   * @param {string} sdt - The start date in string format.
   * timeWindow - The time window that panel is using in seconds.
   * @returns {Object} An object containing the start date and the calculated end date.
   */
  const createTimeParams = (sdt) => {
    const todayEpoch = new Date().getTime();
    const sdtEpoch = new Date(sdt).getTime();
    let edtSeconds =
      sdtEpoch + timeWindow * 1000 > todayEpoch
        ? todayEpoch
        : sdtEpoch + timeWindow * 1000;
    let edt = new Date(edtSeconds).toISOString();
    return { sdt, edt };
  };

  /**
   * Returns a DatePicker or DateRangePicker component based on the pickerType.
   * pickerTypes: month, select, selector, range
   *
   * @returns {JSX.Element} The DatePicker or DateRangePicker component.
   */
  const getDatePicker = () => {
    switch (pickerType) {
      case "month":
        return (
          <DatePicker
            className="custom-date-picker"
            key={defaultDate.sdt}
            style={{ width: "100%" }}
            format="MM / yyyy"
            placement="auto"
            ranges={[]}
            cleanable={false}
            shouldDisableDate={afterToday()}
            oneTap
            onSelect={(month) => handleMonthChange(month)}
            value={new Date(defaultDate.sdt)}
            preventOverflow
            locale={datePickerLocale(translations, selectedLanguage)}
          />
        );
      case "select":
      case "dayselect":
      case "selector":
        return (
          <DatePicker
            className="custom-date-picker"
            key={defaultDate.sdt}
            style={{ width: "100%" }}
            format="dd.MM.yyyy HH:mm"
            placement="auto"
            ranges={[]}
            cleanable={false}
            shouldDisableDate={afterToday()}
            onOk={(date) => handleDateChange(createTimeParams(date))}
            value={new Date(defaultDate.sdt)}
            preventOverflow
            isoWeek
            locale={datePickerLocale(translations, selectedLanguage)}
          />
        );
      default:
        return (
          <DateRangePicker
            className="custom-date-picker"
            key={defaultDate.sdt}
            style={{ width: "100%" }}
            format="dd.MM.yyyy HH:mm"
            placement="auto"
            ranges={[]}
            cleanable={false}
            shouldDisableDate={afterToday()}
            character=" - "
            isoWeek
            value={[
              new Date(defaultDate.sdt),
              new Date(defaultDate.edt)
            ]}
            locale={datePickerLocale(translations, selectedLanguage)}
            defaultValue={[
              new Date(defaultDate.sdt),
              new Date(defaultDate.edt)
            ]}
            onOk={(value) => {
              const startDate = value[0].toISOString();
              const endDate = value[1].toISOString();
              handleRangeChange(startDate, endDate);
            }}
            showOneCalendar={isSmallScreen} 
          />
        );
    }
  };

  return getDatePicker();
}
