import React, { useContext, useState, useEffect, useCallback } from "react";

import axios from "axios";
import { Modal, Checkbox } from 'rsuite';

import { translate } from "../languages";
import {
  makeAuthUrlWithHeaders,
  logErrorIfDevelopmentMode,
  acquireToken
} from "../utils";
import { isFunction } from "lodash";
import { UseAuthenticationContext } from "../context";
import { MsalContext } from "@azure/msal-react";

export default function ManageModal(props) {
  const {
    currentFolder,
    targetItem,
    endpoint,
    closeModal
  } = props;

  const { useAuthentication }                   = useContext(UseAuthenticationContext);
  const msalContext                             = useContext(MsalContext);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmed, setConfirmed]               = useState(false);
  const [itemCount, setItemCount]               = useState("-");
  const [itemName, setItemName]                 = useState("");

  const handleClose = useCallback(() => { // closes modal and resets default states
    if (isFunction(closeModal)) {
      closeModal();
      setShowConfirmation(false);
      setConfirmed(false);
      setItemCount("-");
      setItemName("");
    }
  },[closeModal])
  
  useEffect(() => { // counts files inside a selected folder
    async function countFiles() {  
      try {
        let url = endpoint; 
        let headers = {};
        const data = { countItems: `${currentFolder}/${targetItem.name}` }
        if (useAuthentication) {
          [url, headers] = await makeAuthUrlWithHeaders(
            url, 
            headers, 
            acquireToken(msalContext)
          )
        }
        await axios({
          method: "post",
          url: url,
          data: data,
          headers: headers
        }).then((response) => {
          if (response.status === 200) {
            setItemCount(response.data.count);    
          }
        });
      } catch (e) {
        logErrorIfDevelopmentMode(e);
      }
    }
    if (targetItem && targetItem !== "new" && targetItem.type === "directory") { // only when folder exists
      countFiles();
    }
  },[targetItem, currentFolder, endpoint, msalContext, useAuthentication])

  /* Function handles folder creation, renaming, and deletion */
  const manageItems = useCallback(async(action) => {
    let data;
    switch(action) { //creates correct data object based on action type
      case "create":
        data = { createFolder: `${currentFolder}/${itemName}` };
        break;
      case "rename":
        const newName = targetItem.extension ? `${currentFolder}/${itemName}.${targetItem.extension}` : `${currentFolder}/${itemName}`;
        data = { renameItem: `${currentFolder}/${targetItem.name}`, rename: newName };
        break;
      case "deleteFolder":
        data = { deleteFolder: `${currentFolder}/${targetItem.name}`};
        break;
      case "deleteFile":
        data = { rmItem: `${currentFolder}/${targetItem.name}`};
        break;
      default:
    } 

    try {
      let url = endpoint; 
      let headers = {};
      if (useAuthentication) {
        [url, headers] = await makeAuthUrlWithHeaders(
          url, 
          headers, 
          acquireToken(msalContext)
        )
      }
      await axios({
        method: "post",
        url: url,
        data: data,
        headers: headers
      }).then((response) => {
        if (response.status === 200) {
          handleClose();
        }
      });
    } catch (e) {
      logErrorIfDevelopmentMode(e);
    }
  },[currentFolder, itemName, endpoint, msalContext, targetItem, useAuthentication, handleClose])

  const checkIfValidName = () => {
    const allowedChars = /^([a-zA-Z0-9\s._-]+)$/;
    if (allowedChars.test(itemName) ) {
        return false;
      }
      return true;
  }

  const getItemNameInput = () => { // creates input field that is used in folder creation and renaming
    let labelText = translate(`Rename ${targetItem.type}`);
    let buttonText = translate("Rename");
    if (targetItem === "new") {
      labelText = translate("Folder name");
      buttonText = translate("Create");
    }
    return(
      <div className="mb-4">
        <span className="row d-block">
          <span>{labelText}:</span>
        </span>
        <div className="row">
          <div className="col-7">
            <div className="row">
              <input 
                className="col"
                type="text" 
                onChange={ (e) => setItemName(e.target.value) } 
              /> 
              {targetItem.extension &&
                <span className="col-3 input-group-addon">.{targetItem.extension}</span>
              }
            </div>
          </div>
          <button 
            type="button"
            className="col-4 btn btn-primary" 
            disabled={checkIfValidName()}
            onClick={ ()=> targetItem === "new" ? manageItems("create") : manageItems("rename")}
          >
            {buttonText}
          </button>
        </div>
        {checkIfValidName() && itemName.length > 0 &&
          <span className="d-block">
            <span style={{color: "red"}}>
              {translate(`Invalid ${targetItem.type} name`)}
            </span>
          </span>
        }
      </div>
    );
  } 

  const getDeleteContent = () => {
    const buttonText = targetItem.type === "file" ? "Delete File" : "Delete Folder";
    const checkBoxText =  targetItem.type === "file" ? "I want to delete this file" : "I want to delete this folder and all of its content";
    const deleteType = targetItem.type === "file" ? "deleteFile" : "deleteFolder";

    if (showConfirmation) { //creates confirmation text, checkbox, and delete button
      return(
        <div>
          {targetItem.type === "directory" &&
            <div>
              {Number.isInteger(itemCount) && itemCount === 0 ? (
                <div className="mt-4 mb-3">
                  <p>{translate("This folder is empty")}</p>
                </div>
              ) : (
                <div className="mb-3">
                  <p>
                    {translate("This folder has ")} 
                    <span style={{fontWeight:"bold", color: "red"}}>
                      {itemCount}
                    </span> 
                    {translate(" items.")}
                  </p>
                  <p>
                    {translate("If you delete this folder also the files will be deleted!")}
                  </p>
                </div>
              )}
            </div>
          }
          <div className="row">
            <div className="col-7">
              <Checkbox onChange={ () => setConfirmed(!confirmed) }>
                {translate(checkBoxText)}
              </Checkbox>
            </div>
            <button 
              type="button"
              className="col-4 btn btn-danger"
              disabled={!confirmed}
              onClick={ () => manageItems(deleteType) }
            >
              {translate(buttonText)}
            </button>
          </div>
        </div>
      );
    } else { // delete button that opens confirmation
      return(
        <div className="row">
          <button 
            type="button"
            className="offset-7 col-4 btn btn-danger"
            onClick={ () => setShowConfirmation(true) }
          >
            {translate(buttonText)}
          </button>
        </div>
      );
    }
  } 

  /* Render modal */
  return (
    <Modal 
      size = "xs"
      open = {Boolean(targetItem)} 
      onClose = {handleClose}
    >
      <Modal.Header>
        {targetItem === "new" &&
          translate("Create Folder")
        }
        {targetItem && targetItem !== "new" && 
          <>
            <span>{translate("Manage")} {translate(targetItem.type)}:</span>
            <span className="ms-1" style={{fontWeight:"bold", fontSize:"1rem"}}>{targetItem.name}</span>
          </>
        }
      </Modal.Header>

      <Modal.Body classPrefix="modal-body filemanManage container">
        {targetItem && getItemNameInput()}
        {targetItem && targetItem !== "new" && getDeleteContent()}
      </Modal.Body>
    </Modal>
  );
}
