import axios from "axios";
import { makeAuthUrlWithHeaders, logErrorIfDevelopmentMode, acquireToken } from "../utils";

// Returns a list of tags from the backend
export default async function getTagList(useAuthentication, msalContext, selectedLanguage, url) {
    let headers = {};
    if (useAuthentication && msalContext.accounts.length > 0) {
        [url, headers] = await makeAuthUrlWithHeaders(url, headers, acquireToken(msalContext));
    }
    try {
        const params = { like: "", language: selectedLanguage };
        const response = await axios.get(url, { params, headers });
        return response.data.map(listItem => {
            return Object.fromEntries(
                Object.entries(listItem).map(([k, v]) => [k.toLowerCase(), v])
            );
        });
    } catch (e) {
        logErrorIfDevelopmentMode(e);
        return null;
    }
}