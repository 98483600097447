import React, { useRef, useState, useContext } from "react";
import { Modal, Notification, Loader, toaster } from "rsuite";
import { fetchDataHelper } from "../utils";
import { translate } from "../languages";
import { SiteContext, UseAuthenticationContext } from "../context";
import { MsalContext } from "@azure/msal-react";
import { useIntl } from "react-intl";
import { Editor } from '@tinymce/tinymce-react';

export default function DataCommentingModal(props) {
  const {show, details, onHide } = props;

  const { useAuthentication }     = useContext(UseAuthenticationContext);
  const msalContext               = useContext(MsalContext);
  const { site }                  = useContext(SiteContext);
  const intl                      = useIntl();

  const [loading, setLoading] = useState(false);

  const editorRef = useRef();

  const feedbackSendSuccess = () => {
    toaster.push(
      <Notification
        type="success"
        header={intl.formatMessage({ id:("Comment sent successfully")})}
        closable
      />,
      { placement: "topEnd" }
    );
    onHide("updateData");
  };

  const feedbackSendError = () => {
    toaster.push(
      <Notification
        type="error"
        header={intl.formatMessage({ id:("Something went wrong")})}
        closable
      >
        {intl.formatMessage({ id:("notification_something_wrong_with_comment_send") })}
      </Notification>,
      { placement: "topEnd" }
    );
  };

  const handleSend = (sendMail) => {
    const content = editorRef.current.getContent();
    const data = {
      panel: details.panel,
      chart: details.chart,
      site: site,
      value: details.value,
      start: details.start,
      end: details.end,
      message: content,
      sendMail: sendMail,
    };
    fetchDataHelper({
      url: `/boa/api/v1/${site}/eventReporter`,
      headers: { "content-type": "application/json" },
      method: "POST",
      data: data,
      useAuthentication: useAuthentication,
      authContext: msalContext,
      onFetchStart: setLoading(true),
      onFetchEnd: setLoading(false),
      onSuccess: feedbackSendSuccess,
      onError: feedbackSendError,
    });
  };

  return (
    <Modal
      open={show}
      size="lg"
      onClose={onHide}
      backdrop="static"
    >
      <Modal.Header>
        <h3>{translate("Report Moment")}</h3>
      </Modal.Header>

      <Modal.Body>
        <div className="row">
          <div className="col-1 p-1">{translate("chart id")}:</div>
          <div className="col-11 p-1">{details.panel} {details.chart}</div>
          <div className="col-1 p-1">{translate("start")}:</div>
          <div className="col-11 p-1">{new Date(details.start).toISOString().split(".")[0] + "Z"}</div>
          <div className="col-1 p-1">{translate("end")}:</div>
          <div className="col-11 p-1">{new Date(details.end).toISOString().split(".")[0] + "Z"}</div>
          <div className="col-1 p-1">{translate("message")}:</div>
        </div>

        <Editor
          tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
          onInit={(evt, editor) => editorRef.current = editor}
          initialValue={""}
          init={{
            height: "200px",
            menubar: false,
            plugins: ['lists'],
            toolbar: 'undo redo | bold italic forecolor removeformat | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent'
          }}
        />

        {loading && <Loader size="lg" center />}

      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={()=> handleSend(true)}
          disabled={loading}
        >
          {translate("Save & Report by email")}
        </button>

        <button
          type="button"
          className="btn btn-success"
          onClick={() => handleSend(false)}
          disabled={loading}
        >
          {translate("Save")}
        </button>

        <button type="button" className="btn btn-danger" onClick={onHide}>
          {translate("Cancel")}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
