import axios from 'axios';
import {
    makeAuthUrlWithHeaders,
    acquireToken,
    popNotification
} from "../../utils";

// Get global and user created panels from the backend
// If user is not authenticated, use the local storage
// If no panels are found, return demo panels
const fetchCustomPanels = async (msalContext, isAuthenticated, site, activeMenu) => {
  let customPanels = [];
  let url = "";
  let headers = {};

  if (isAuthenticated) {
    url = `/boa/api/v1/${site}/custompanels/all?user=${msalContext.accounts[0].username}&menu=${activeMenu}`;
    [url, headers] = await makeAuthUrlWithHeaders(url, headers, acquireToken(msalContext));

    try {
      const response = await axios.get(url, { headers });
      customPanels = response.data || [];
    } catch (e) {
      console.error(e);
      popNotification({type: "error", text: "Failed to load custom panels. Please try again later."});
      return [];
    }

  } else {
      customPanels = JSON.parse(localStorage.getItem(`${site}-customPanels`)) || [];
  }

  // Filter customPanels based on the active menu
  if (activeMenu === "Favorites") {
    const favoritePanels = JSON.parse(localStorage.getItem(`${site}-favorites`)) || [];
    customPanels = customPanels.filter(panel => favoritePanels.includes(panel.id));
  } else {
    customPanels = customPanels.filter(panel => panel.menu === activeMenu);
  }

  // If no custom panels are found, load demo panels (only in local version)
  // In production the backend will send the demo panels
  if (customPanels.length === 0 && activeMenu === "custom_panels" && !isAuthenticated) {
    let url = makeLocalUrl("/local/demoPanels.json");
    try {
      const demoPanels = await axios.get(url, { headers });
      return demoPanels.data;
    } catch (error) {
      console.error("Error retrieving custom panels: ", error);
      return {};
    }
  }

  return customPanels;
};

const makeLocalUrl = (url) => {
  if (url.includes("http")) { return url; } 
  else { return process.env.PUBLIC_URL + url}
}

export default fetchCustomPanels;