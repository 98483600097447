import React, { useEffect, useRef, useState, useContext } from "react";
import { Tooltip, Whisper } from "rsuite";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import Heatmap from "highcharts/modules/heatmap.js";
import Histogram from "highcharts/modules/histogram-bellcurve.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import annotations from "highcharts/modules/annotations.js";
import bullet from "highcharts/modules/bullet.js";
import xrange from "highcharts/modules/xrange.js";
import boost from "highcharts/modules/boost.js";
import moment from "moment";
import "moment-timezone";
import { cloneDeep } from "lodash";
import { updateAxis, cuttingSelections, ChartTable, fetchNonSeriesData, addSeries, yParser } from ".";
import { HighchartOptions, getColors } from "../utils";
import { DataCommentingModal } from "../modals";
import { translateOptions, translate } from "../languages";
import { SiteContext, LanguageContext, ThemeContext, UseAuthenticationContext } from "../context";
import { MsalContext } from "@azure/msal-react";
import { useIntl } from 'react-intl';

Heatmap(Highcharts);
Histogram(Highcharts);
HighchartsMore(Highcharts);
solidGauge(Highcharts);
xrange(Highcharts);
bullet(Highcharts);
annotations(Highcharts);
boost(Highcharts);

export default function ChartItem(props) {
  
  const {
    title,
    module,
    updateData,
    time,
    chart,
    fetchedData,
    socket_values,
    socket,
    cuttingRange,
    cuttingMode,
    cuttingToolsEnabled,
    zoomRange,
    width,
    commentingMode,
    setAutoUpdate,
    nonSeriesData,
    queryParams,
    resetZoomValues,
    chartObjName,
    panelName,
    zoomingEnabled
  } = props;

  const [commentingDetails, setCommentingDetails]   = useState();
  const [clickedPoints, setClickedPoints]           = useState([]);
  const [insert, setInsert]                         = useState(false);
  const [chartData, setChartData]                   = useState([]);
  const [hiddenSeries, setHiddenSeries]             = useState([]);
  const [hcOptions, setChartOptions]                = useState();
  const [panningInfo, showPanningInfo]              = useState(false);
  const intl                                        = useIntl();
  const { timeZone, site }                          = useContext(SiteContext);
  const { theme }                                   = useContext(ThemeContext);
  const { translations, selectedLanguage }          = useContext(LanguageContext);

  const { useAuthentication }     = useContext(UseAuthenticationContext);
  const msalContext               = useContext(MsalContext);

  const resetButtonClick = useRef(true);
  const chartRef = useRef();

  HighchartOptions(
    Highcharts,
    selectedLanguage,
    timeZone,
    translations
  );
  window.Highcharts = Highcharts;  
  /* Handle "reset zoom"- click when time is changed outside of chart's selection function 
   * resetZoomValues is set to null when time is changed outside of chart's selection function
   * when zoom is used resetZoomValues has time window, sdt & edt values before zoom
   * resetButtonClick is set to false to prevent chart's selection event when zoomOut is called
   */
  useEffect(() => {
    if (chartRef.current && chartRef.current.chart && !resetZoomValues) {
      resetButtonClick.current = false;
      chartRef.current.chart.zoomOut();
      resetButtonClick.current = true;
    }
  }, [resetZoomValues]);

  useEffect(() => { // Handles width when component is resized
    if (chartRef.current) {
      chartRef.current.container.current.style.width = "100%";
      chartRef.current.chart.reflow();
    }
  }, [width]);

  useEffect(() => { // Handles chartData
    setChartData(addSeries(chart, fetchedData, theme, queryParams, time, timeZone));
  }, [fetchedData, chart, time, queryParams, theme, timeZone]);

  useEffect(() => { //add plotlines when clickedPoints changes 
    if (chartRef.current && chartRef.current.chart) {
      let array = [];
      for (let click in clickedPoints) {
        array.push({
            color: "#2196f3", 
            width: 2, 
            value: clickedPoints[click][0].x,
            label: {
              text: Number(click)+1,
              align: 'center',
              verticalAlign: 'top',
              rotation: 0,
              x: 0,
              y: -10
            }
        })
      }
      if (clickedPoints.length > 0 ) { 
        chartRef.current.chart.update({ xAxis:{ plotLines: array } })
        chartRef.current.chart.update({ chart:{ marginTop: 30 } })
      } else if (chart.rulerTable) { chartRef.current.chart.update({ xAxis:{ plotLines: [] } }) }
    }
  },[clickedPoints, chart]);

  useEffect(() => { //update chart based on selected cutting tool */
    if (chartRef.current && cuttingToolsEnabled) { chartRef.current.chart.update(updateAxis(cuttingMode)); } 
  }, [cuttingMode, cuttingToolsEnabled, hcOptions]);

  useEffect(() => { //fetch nonSeriesData
    const getData = async(i) => {
      const config = await fetchNonSeriesData({
        series: nonSeriesData[i],
        useAuthentication: useAuthentication,
        msal: msalContext,
        state: {}
      });
      const path = nonSeriesData[i].path;
      let updatedObject;
      if (typeof(path)==="string") { updatedObject = { [path] : config} }
      else { 
        let obj = JSON.stringify(path);
        updatedObject = JSON.parse(obj.replace("null", JSON.stringify(config)));
      }
      if (updatedObject) { 
        setInsert({path: path, config: config})
      }
    }
    if(nonSeriesData){ for (let i=0; i < nonSeriesData.length; i++){ getData(i) }}
  },[nonSeriesData, fetchedData, msalContext, useAuthentication]);

  useEffect(() => { // create highchart options  

    const handleLegendItemClick = (event) => {
      if (event.target.visible) { //visible series is being hidden
        setHiddenSeries([...hiddenSeries, event.target.name]); // add clicked item to hiddenSeries array
      } else { // hidden series is being shown
        setHiddenSeries(hiddenSeries.filter(item => item !== event.target.name)); // remove clicked item from hiddenSeries array
      }
    }
  
    let chartOptions = cloneDeep(chart);

    if (typeof chartOptions.chart === "undefined" ) { // creates an empty chart object if chart object is not existing in chartOptions */
      chartOptions = Object.assign(chartOptions, { chart: {} });
    }

    const clonedChartData = cloneDeep(chartData);
    chartOptions.series = clonedChartData // add series
    chartOptions = translateOptions(chartOptions, intl); // translate texts in chart options

    /* colors */
    const colors = getColors(theme);
    chartOptions = Object.assign(chartOptions, { colors }); // add colors
    chartOptions = {...chartOptions, ...updateAxis(cuttingMode).chart}

    /* handle y-axis if chart has yParser object */ 
    if (chart.yParser) { chartOptions = yParser(chartOptions, fetchedData) }
      
    /* events */
    chartOptions.chart = Object.assign(
      chartOptions.chart,
      {
        events: {
          load: function () {
            if (socket_values?.current && socket?.api) {
              socket_values.current.on(socket.api, (data) => {
                let dataObject = typeof data === "string" ? JSON.parse(data) : data;
                if (chartRef.current?.chart?.series) { // add points when time window is not "custom"
                  for (let serie of chartRef.current.chart.series) {
                    if (serie.name ===  dataObject.name) {
                      serie.addPoint( {x: dataObject.data[0], y: dataObject.data[1], color: "green"},  true, true, true);
                    }
                  }
                }
              });
            }
          },
          selection: function(e) {
            if (commentingMode) {
                e.preventDefault();  
                setCommentingDetails({
                  panel: panelName,
                  chart: chartObjName,
                  value: e.target.yAxis[0].dataMin,
                  start: e.xAxis[0].min,
                  end: e.xAxis[0].max
                });
            } else if (cuttingToolsEnabled && cuttingMode !== "off") { //cutting tool selections
              return cuttingSelections(cuttingMode, fetchedData, e, cuttingRange);    
            } else if (cuttingMode === "off" && zoomingEnabled && resetButtonClick.current) {
              if (e.xAxis && e.xAxis[0]) { //zoom
                return zoomRange({
                  sdt: new Date(moment.utc(new Date(e.xAxis[0].min)).valueOf()).toISOString(),
                  edt: new Date(moment.utc(new Date(e.xAxis[0].max)).valueOf()).toISOString(),
                  zoomUsed: true
                });
              } else { return zoomRange({ zoomUsed: false }); }
            } else { // normal zoom / selection event
              /* If panning feature is enabled, info message should be visible */
              if (e.resetSelection){ showPanningInfo(false); }
              else if (chart.chart.panning) { showPanningInfo(true); }
            }
          }
        }
      });

    const getPreviousValue = (data, targetTimestamp) => {
      let closestTimestamp = null;
      for (const dataPoint of data) {
          const timestamp = dataPoint[0];
          if (timestamp <= targetTimestamp) {
              if (closestTimestamp === null || timestamp > closestTimestamp) {
                  closestTimestamp = timestamp;
              }
          }
      }
      const closestDataPoint = data.find(dataPoint => dataPoint[0] === closestTimestamp);
      return closestDataPoint ? closestDataPoint[1] : null;
    }

    const setDataPoint = (points, series, eventX) => { // add previous value if point doesnt have a value & sort points
      let flattenedArray = points.reduce((acc, curr) => acc.concat(curr), []);
      flattenedArray = flattenedArray.map((item, index) => {
        if (item && item.x) { return Object.assign(cloneDeep(item), { name: series[(index) % chartData.length].name});} 
        else {
          return {
            x: eventX, 
            y: getPreviousValue(chartData[ (index) % chartData.length].data, eventX), 
            color: series[(index) % chartData.length].color, 
            name: series[(index) % chartData.length].name, 
            previousValue:  !chartData[ (index) % chartData.length].data.some(timeseries => timeseries[0] === eventX)
          }
        }
      })
      flattenedArray = flattenedArray.sort((a, b) => a.x - b.x);
      let sortedArrayOfArrays = [];
      let currentTimestamp = null;
      flattenedArray.forEach( (item) => {
        if (item && item.x !== currentTimestamp) {
          sortedArrayOfArrays.push([]);
          currentTimestamp = item.x;
        }
        if (item?.x) {
          sortedArrayOfArrays[sortedArrayOfArrays.length - 1].push(item);
        }
      });
  
      setClickedPoints([...sortedArrayOfArrays]);
      setAutoUpdate(false);
    }

    /* rulerTable */
    if (chartOptions.rulerTable) {
      chartOptions.plotOptions = {
        series: {
          allowPointSelect: true,
          events: {
            click: function(event) {
                let clicks = cloneDeep(clickedPoints);
                if (clicks === undefined || clicks.findIndex(item => item[0].x === event.point.x) < 0) { //timestamp not found, add series
                  let serArr = [];
                  let chartObj = cloneDeep(chartRef.current.chart.series);
                  for (let series in chartObj) {
                    if (chartObj[series].name === "All") { continue; } // skip "All" series
                    let value = chartObj[series].data.find(point => point.x === event.point.x)   
                    let newObject = null;
                    if (value) { newObject = {x: value.x, y: value.y, color : value.color}}
                    serArr.push(newObject);
                  }
                  clicks.push(serArr);
                  serArr = []
                  setDataPoint([...new Set(clicks)], clonedChartData, event.point.x);
                } else if (clicks) { //timestamp found, remove series
                  clicks = clicks.filter(subArray => !subArray.some(obj => obj.x === event.point.x))
                  setDataPoint([...clicks], clonedChartData, event.point.x);
                } 
              //}
            }
          }
        }
      }
    }

    if (chartOptions.rulerTable && chartRef.current) {
      /* Create plotOptions.series.events if it doesnt't exist */
      if (!chartOptions.plotOptions) { chartOptions.plotOptions = {} }
      if (!chartOptions.plotOptions.series) { chartOptions.plotOptions.series = {} }
      if (!chartOptions.plotOptions.series.events) { chartOptions.plotOptions.series.events = {} }
      /* handleLegendItemClick sets hidden tags when series is being hidden/shown */
      chartOptions.plotOptions.series.events.legendItemClick = handleLegendItemClick;
    }

    /* Add toggle series if rulerTable is enabled and there are more than 4 series 
     * also we need to wait until chartData has data or first series is 
     */
    if (chartOptions.rulerTable && fetchedData.length > 4 && chartData.length > 0) {
      const toggleSeries = {
        name: 'All',
        color: 'black',
        data: [],
        showInLegend: true,
        visible: hiddenSeries.length > 0 ? false : true,
        events: {
          legendItemClick: function() {
            const chart = this.chart;
            const allSeries = chart.series.filter(series => series !== this);
            const allVisible = allSeries.every(series => series.visible);
            if (allVisible) { //hide all series
              setHiddenSeries(allSeries.map(item => item.name)); 
            } else { //show all series
              setHiddenSeries([]);
            }
            
            allSeries.forEach(series => {
              if (allVisible) { series.hide(); } 
              else { series.show(); }
            });
          }
        }
      };
      chartOptions.series = [ toggleSeries, ...clonedChartData ];
    }

    /* translations */ 
    for (let serieNumber in chartOptions.series) {

      /** name assertion this way instead of using '??' or '||'
       * to ensure an empty name "" does not cause an error. ""
       * is falsy so intl.formatMessage throws an error if it
       * receives such a value as id.
       */
      let name = chartOptions.series[serieNumber].name;
      if (!name) {
        name = "unnamed";
      }

      chartOptions.series[serieNumber].name = intl.formatMessage({ id: name });
    }
    if (insert) {
      const setNestedProperty = (obj, path, value) => {
        const keys = path.split(/\.|\[|\]/).filter(Boolean);
        let current = obj;
      
        for (let i = 0; i < keys.length - 1; i++) {
          current = current[keys[i]];
        }
      
        current[keys[keys.length - 1]] = value;
      }
      
      setNestedProperty(chartOptions, insert.path, insert.config);

      chartOptions[insert.path] = insert.config;
    }
    setChartOptions(chartOptions);
  }, [
      chart, 
      chartData, 
      clickedPoints, 
      intl, 
      theme, 
      fetchedData, 
      cuttingMode,
      commentingMode, 
      socket_values, 
      socket, 
      insert, 
      cuttingRange, 
      setAutoUpdate, 
      zoomRange,
      chartObjName,
      panelName,
      cuttingToolsEnabled,
      zoomingEnabled,
      hiddenSeries
    ]);

  /**
   * Closes data commenting and updates the commenting details.
   * @param {boolean} forceUpdate - Indicates whether to force an update of the data.
   * updateData is a prop function that updates the data in item component.
   */
  const closeDataCommenting = (forceUpdate) => {
    if (forceUpdate) { updateData(); }
    setCommentingDetails();
  }

  const checkIfReady = () => { //check if panel is ready to be shown
    if (nonSeriesData && insert && hcOptions) { return true;}
    else if (!nonSeriesData && hcOptions) { return true; }
    else { return false}
  }

  return (
    <React.Fragment key={`${title}-${module}`}>
      <div style={{ width: "100%", height: "100%", marginTop: "20px", marginBottom: "5px" }}>
        {panningInfo && 
          <div style={{ 
            position: "absolute", 
            marginTop: "-10px",
            zIndex: 2,
            textAlign: "center",
            width: "100%",
            }}
          >
            <Whisper
              trigger="hover"
              placement="bottom"
              speaker={
                <Tooltip>
                  <p>
                    <i className="fas fa-info-circle me-1"></i>
                    {translate("This chart supports panning feature when zoom is used")}
                  </p>
                  <p>1. {translate("Hold down shift key")}</p>
                  <p>2. {translate("Click and drag to pan")}</p>
                </Tooltip>
              }
            >
              <div style={{ width: "80px", margin: "auto" }}>
                <i className="fas fa-info-circle me-1"></i>
                <span>{translate("Panning")}</span>
              </div>
            </Whisper>
          </div>
        }
        {checkIfReady() && 
          <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            options={hcOptions}
          />
        }

      </div>
      {commentingDetails &&
        <DataCommentingModal
          show={commentingDetails !== undefined }
          title={"title"}
          site={site}
          onHide={closeDataCommenting}
          details={commentingDetails}
        />
      }
      {clickedPoints.length > 0 &&
        <ChartTable 
          tableOptions={chart.rulerTable}
          clickedPoints={clickedPoints}
          hiddenSeries={hiddenSeries}
          resetPoints = { () => { setClickedPoints([]); setAutoUpdate(true) }}
        />
      }
    </React.Fragment>
  );
}
