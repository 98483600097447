import React, { useState } from "react";
import { translate } from "../languages";
import { Panel, Button } from "rsuite";
import { SkipTime } from ".";
import { secondsToString, UtilityModal } from "../utils";
import { FilterButtons } from ".";

const GlobalTimeFilter = (props) => {
  const {
    buttons,
    handleChange,
    handleResChange,
    resButtons,
    reset,
    updateGlobalTime,
    panelTimeValues,
    globalResolution
  } = props;

  const [showUtilityModal, toggleUtilityModal] = useState(false);

  return (
    <Panel
      header={
        <div>
          {panelTimeValues?.timeWindow && <div className="d-inline-block me-3"><i className="far fa-clock me-1"></i> {secondsToString(panelTimeValues?.timeWindow)}</div>}
          {globalResolution && <div className="d-inline-block"><i className="fas fa-wave-square me-1"></i> {secondsToString(globalResolution)}</div>}
          {!(globalResolution || panelTimeValues?.timeWindow) && <div>{translate("show_global_time_buttons")}</div>}
        </div>
      }
      className={"global-time-filter"}
      collapsible
      bordered
    >
      <div className="mt-3" >
        <SkipTime 
          passDate = { updateGlobalTime } 
          panelTimeValues = { panelTimeValues } 
        />
        <FilterButtons buttons={buttons} handleChange={handleChange} panelValues={panelTimeValues} icon="far fa-clock me-1" placeholderText="Time"/>
        {resButtons.length > 0 && 
        <FilterButtons buttons={resButtons} handleChange={handleResChange} panelValues={globalResolution} icon="fas fa-wave-square" placeholderText="Resolution"/>
        }
        <div className="reset-panels-container">
          <Button
            appearance="default"
            style={{ width: 224 }}
            onClick={() => toggleUtilityModal(!showUtilityModal)}
          >
            {translate("Reset panels")}
          </Button>
        </div>
        <UtilityModal
          show={showUtilityModal}
          bodyText={"Reset_panels_confirm"}
          confirmCallback={() => reset()}
          displayCallback={() => toggleUtilityModal(!showUtilityModal)}
        />
      </div>
    </Panel>
  );
}

export default GlobalTimeFilter;