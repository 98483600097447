import React, { useState, useEffect, useRef } from 'react';
import { RadioGroup, Radio, SelectPicker } from 'rsuite';
import { secondsToString } from "../utils";
import { translate } from "../languages";

// Filter buttons is used for tag search, global selector and panel specific selectors for choosing time and resolution
export default function FilterButtons(props) {
  const { buttons, handleChange, icon, placeholderText, defaultSelected, triggerOnFirstRender } = props;

  // Parse button values and the default selected value
  const parsedButtons = buttons.map(button => parseInt(button, 10));
  const parsedDefaultSelected = parseInt(defaultSelected, 10);

  // State for the selected value
  const [selectedValue, setSelectedValue] = useState(parsedDefaultSelected);

  const initialRenderRef = useRef(true);

  useEffect(() => {
    if (initialRenderRef.current && triggerOnFirstRender) {
      handleChange(selectedValue);
      initialRenderRef.current = false;
    }
  }, [handleChange, selectedValue, triggerOnFirstRender]);

  const timeOptions = parsedButtons
    .filter(value => Number.isInteger(value))
    .map(value => ({
      label: value === -1 ? "auto" : secondsToString(value),
      value,
    }));

  const buttonStyle = {
    display: 'flex',
    verticalAlign: 'middle',
    justifyContent: 'center',
    alignItems: 'center',
  };

  // Return null if no buttons are provided
  if (parsedButtons.length === 0) return null;

  return (
    <>
      {/* Horizontal Time Picker for larger screens */}
      <div className="global_buttons global_time time-picker-horizontal">
        <RadioGroup
          name="timeGroup"
          inline
          appearance="picker"
          className="footerBtnGroup row"
          value={selectedValue}
          onChange={(newValue) => { 
            setSelectedValue(newValue);
            handleChange(newValue);
          }}
        >
          <span
            style={{ ...buttonStyle, width: "120px" }}
          >
            <i className={`${icon} me-2`}></i>
            <div>{translate(placeholderText)}</div>
          </span>
          {parsedButtons
            .filter(value => Number.isInteger(value))
            .map(value => (
              <Radio
                className={(value === selectedValue) ? "col selectedElement" : "col"}
                value={value}
                key={`t${value}`}
                style={{ textAlign: "center" }}
              >
                {value === -1 ? "auto" : secondsToString(value)}
              </Radio>
            ))}
        </RadioGroup>
      </div>

      {/* Vertical Time Picker for smaller screens */}
      <div className="select-picker-wrapper time-picker-vertical">
        <SelectPicker
          data={timeOptions}
          style={{ width: 224 }}
          placeholder={translate(placeholderText)}
          searchable={false}
          cleanable={false}
          value={selectedValue || ""}
          onChange={(newValue) => {
            setSelectedValue(newValue);
            handleChange(newValue);
          }}
        />
      </div>
    </>
  );
}
