import React, { createContext, useState, useContext } from 'react';

export const HeaderContext = createContext();

// Header context can be used to update it, when panels need to be reloaded
export const HeaderProvider = ({ children }) => {
  const [header, setHeader] = useState(1);

  const updateHeader = (update) => {
    setHeader(update);
  };

  return (
    <HeaderContext.Provider value={{ header, updateHeader }}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = () => {
  const context = useContext(HeaderContext);
  if (context === undefined) {
    throw new Error('Header must be used within a HeaderProvider');
  }
  return context;
};