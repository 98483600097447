import React from "react";
import { createRoot } from "react-dom/client";
import axios from "axios";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { IntlProvider } from "react-intl";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import '../node_modules/rsuite/dist/rsuite.min.css';

import App from "./App";
import { AppContextProvider } from "./context";
import { isAuthenticationUsed, getBoaURL } from "./utils";
import "./css/styles.css";
import "./css/darkTheme.css";

const useAuthentication = isAuthenticationUsed();
const boaUrl = getBoaURL();
const container = createRoot(document.getElementById("root"));

function Root({ pca }) {
  return (
    <IntlProvider locale={process.env.REACT_APP_DEFAULT_LANGUAGE}>
        {pca ? (
          <MsalProvider instance={pca}>
            <AppContextProvider>
              <App />
            </AppContextProvider>
          </MsalProvider>
        ) : (
          <AppContextProvider>
            <App />
          </AppContextProvider>
        )}
    </IntlProvider>
  );
}

if (useAuthentication) { // Web scenario with authentication
  axios.get(`${boaUrl}/boa/msal`).then(response => {
    response.data.auth.redirectUri = window.location.origin + response.data.auth.redirectUri;
    response.data.auth.postLogoutRedirectUri = window.location.origin + response.data.auth.postLogoutRedirectUri;
    const pca = new PublicClientApplication(response.data);

    container.render(<Root pca={pca} />);
  });
} else { // Local scenario without authentication
  container.render(<Root />);
}