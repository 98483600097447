/* built-in packages */
import React, { Component } from "react";

/* 3rd-party packages */
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

/* self-provided packages */
import { HighchartOptions, getColors } from "../../utils";
import { withAllContext } from "../../context";

class AreaChart extends Component {

  afterChartCreated = (chart) => {
    this.internalChart = chart;
  }
  
  componentDidMount() {
    HighchartOptions(
      Highcharts,
      this.props.selectedLanguage,
      this.props.timeZone
    );
    this.setChartSize();
  }

  componentDidUpdate() {
    this.setChartSize();
  }

  setChartSize() {
    /* Handles width when component is resized */
    if (this.internalChart && this.props.panel.current) {
      const height = (
        window.innerWidth < 768
        ? this.props.panel.current.offsetHeight * 0.5
        : this.props.panel.current.offsetHeight * 0.8
      );
      const width = this.props.panel.current.offsetWidth * 0.98;
      this.internalChart.setSize(width, height);
    }
  }

  stripAxisKeys(data) {
    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; ++i) {
        delete data[i].yAxis;
      }
    }
    return data;
  }

  getOptions() {
    const colors = getColors(this.props.theme);

    let seriesData = this.stripAxisKeys(this.props.chartData);

    const options = {
      colors: colors,
      chart: {
        type: "area",
        zoomType: "x",
        className:"chart"
      },
      rangeSelector: {
        enabled: false
      },
      navigator: {
        enabled: true
      },
      xAxis: {
        crosshair: {
          width: 1,
          color: "red"
        },
        type: "datetime"
      },
      title: {
        text: null
      },
      tooltip: {
        shared: true,
        useHTML: true,
        headerFormat: "<small>{point.key}</small><table>",
        pointFormat:
          '<tr><td style="color: {series.color}">{series.name}: </td>' +
          '<td style="text-align: right"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        xDateFormat: "%A, %b %e, %H:%M:%S",
        valueDecimals: 2
      },
      plotOptions: {
        series: {
          showInNavigator: true
        },
        area: {
          stacking: "percent",
          lineColor: "#ffffff",
          lineWidth: 1,
          marker: {
            lineWidth: 1,
            lineColor: "#ffffff"
          },
          accessibility: {
            pointDescriptionFormatter: function (point) {
              function round(x) {
                return Math.round(x * 100) / 100;
              }
              return (
                point.index +
                1 +
                ", " +
                point.category +
                ", " +
                point.y +
                " millions, " +
                round(point.percentage) +
                "%, " +
                point.series.name
              );
            }
          }
        }
      },
      series: seriesData
    }

    // options.series = Object.assign(options.series, this.props.chartData);

    return options;
  }

  render() {
    const options = this.getOptions();
    
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        callback={this.afterChartCreated}
      />
    );
  }
}

export default withAllContext(AreaChart);
