import React, { useEffect, useState, useRef } from "react";
import { Button } from "rsuite";
import { DatePickerComponent } from ".";
import { getTimeparams } from "../utils";

/**
 * A component for skipping time.
 * 
 * @param {Function} props.passDate - useCallback function required to prevent infinite loop. 
 * passDate function passes object like this { sdt, edt, timeWindow, extraTime, skipTimeCounter } to the parent component.
 * 
 * @param {number} props.defaultValue - The default value for the timeWindow state
 * timeWindow updates after new date is selected with DatePickerComponent.
 * 
 * @param {number} props.panelTimeValues - updates the timeWindow, extraTime, skipTimeCounter and selectedRange states when they are changed
  panelTimeValues = { sdt, edt, timeWindow, extraTime, skipTimeCounter } 
 */

export default function SkipTime(props) {
  const {
    loading,
    buttonStyle,
    defaultValue = 86400,
    style,
    passDate,
    panelTimeValues,
    pickerType,
    contentClass = "row mb-3 skipTimeRow"
  } = props;

  const [skipTimeCounter, setSkipTimeCounter] = useState();
  const [selectedRange, setSelectedRange]     = useState();
  const [timeWindow, setTimeWindow]           = useState();
  const [extraTime, setExtraTime]             = useState();

  const timeoutIdRef = useRef(null);
  /* When component is rendered use panelTimeValues if they exist, otherwise use defaultValue 
   * panelTimeValues are updated when user selects new date with DatePickerComponent or chenges time window with time buttons
   * if panelTimeValues doesn't exist, use defaultValue to get the initial time values e.g. component's first render
   * if panelTimeValues.timeWindow is not a number it is "month"
   */
  useEffect(() => {
    if (panelTimeValues) {
      if (typeof(panelTimeValues.timeWindow) === "number") { setTimeWindow(panelTimeValues.timeWindow); }
      else { setTimeWindow(pickerType); }
      setSelectedRange({ sdt: new Date(panelTimeValues.sdt), edt: new Date(panelTimeValues.edt) });
      setExtraTime(panelTimeValues.extraTime);
      setSkipTimeCounter(panelTimeValues.skipTimeCounter);
    } else {
      const dates = getTimeparams(defaultValue);
      setSelectedRange({sdt: new Date(dates.sdt), edt: new Date(dates.edt)});
      setTimeWindow(defaultValue);
      setExtraTime(0);
      setSkipTimeCounter(0);
    }
  },[defaultValue, panelTimeValues, pickerType]);

  /* When skipbutton is clicked new selected range is set, this range will be visible in date picker
   * setTimeout is used to prevent multiple calls to passDate function
   * clearTimeout is used to clear the previous timeout if user clicks skip button multiple times
   * After user has stopped pressing the button, passDate function is called with the new time values
   * This values updates hte parent component's time values and after that new panel time values are set also on this component
   * timeWindow can also be "month" if month picker is used
   */
  const handleClick = (newValue) => {
    if (newValue === 0) {
      if (pickerType === "month") {
        setSelectedRange({ 
          sdt: new Date(new Date().getFullYear(), new Date().getMonth(), 1), 
          edt: null
        });
      } else {
        setSelectedRange({
          sdt: new Date(new Date().getTime() - timeWindow * 1000 - extraTime),
          edt: new Date(new Date().getTime() - extraTime)
        });
      }
    } else {
      if (pickerType === "month") {
        setSelectedRange({ 
          sdt: new Date(new Date().getFullYear(), new Date().getMonth() - newValue, 1), 
          edt: null
        });
      } else {
        setSelectedRange({ 
          sdt: new Date(new Date().getTime() - (timeWindow * 1000 * (newValue + 1)) - extraTime), 
          edt: new Date(new Date().getTime() - (timeWindow * 1000 * (newValue)) - extraTime) 
        });
      }
    }

    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(() => {
      passDate({
        sdt: pickerType === "month" ? new Date(new Date().getFullYear(), new Date().getMonth() - newValue, 1) : new Date(new Date().getTime() - (timeWindow * 1000 * (newValue + 1)) - extraTime).toISOString(),
        edt: pickerType === "month" ? null : new Date(new Date().getTime() - (timeWindow * 1000 * (newValue)) - extraTime).toISOString(),
        timeWindow: timeWindow,
        extraTime: extraTime,
        skipTimeCounter: newValue
      });
    }, 1000); // 1000 milliseconds = 1 second
  }

  /**
   * Function to pass the selected time value when date picker is used.
   *
   * @param {Object} params - The parameters for the time value.
   * @param {Date} params.sdt - The start date and time.
   * @param {Date} params.edt - The end date and time.
   * @param {number} params.newTimeWindow - The new time window value. Can be "month" if month picker is used.
   * @param {number} params.newSkipTimeCount - The new skip time counter value.
   * @param {number} params.newExtraTime - The extra time value.
   */
  const passTimeValue = ({
    sdt,
    edt,
    newTimeWindow,
    newSkipTimeCount,
    newExtraTime
  }) => {
    const newSdt = typeof(newTimeWindow) === "number" ? new Date(sdt).getTime() : sdt;
    const newEdt = typeof(newTimeWindow) === "number" ? new Date(edt).getTime() : edt;
    passDate({
      sdt: new Date(newSdt).toISOString(),
      edt: new Date(newEdt).toISOString(),
      timeWindow: newTimeWindow,
      extraTime: newExtraTime,
      skipTimeCounter: newSkipTimeCount,
      autoUpdate: false
    });
  };

  return (
    <div 
      className={contentClass} 
      style={{
        ...style,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        alignSelf: 'stretch'
      }}
    >
      <Button
        disabled={loading}
        className="skipTimeButton"
        style={{
          ...buttonStyle,
          flexShrink: 3,
          borderTopRightRadius: '0px',
          borderBottomRightRadius: '0px'
        }}
        onClick={() => { 
          const newValue = skipTimeCounter + 1;
          setSkipTimeCounter(newValue); 
          handleClick(newValue);
        }}
      >
        <i className="fas fa-backward" />
      </Button>
  
      {selectedRange && 
        <div
          key={selectedRange.sdt} 
          style={{
            flexShrink: 1,
            height: "100%"
          }}
        >
          <DatePickerComponent
            passTimeValue={passTimeValue}
            defaultDate={selectedRange}
            pickerType={pickerType}
          />
        </div>
      }
  
      <Button
        disabled={skipTimeCounter < 1 || loading}
        className="skipTimeButton"
        style={{
          ...buttonStyle,
          flexShrink: 3,
          borderRadius: '0px',
          borderBottomRightRadius: '0px'
        }}
        onClick={() =>  {
          const newValue = skipTimeCounter - 1;
          setSkipTimeCounter(newValue); 
          handleClick(newValue);
        }}
      >
        <i className="fas fa-forward" />
      </Button>
  
      <Button
        disabled={skipTimeCounter < 1 || loading}
        className="skipTimeButton"
        style={{
          ...buttonStyle,
          flexShrink: 3,
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px'
        }}
        onClick={() => {
          setSkipTimeCounter(0); 
          handleClick(0)
        }}
      >
        <i className="fas fa-fast-forward" />
      </Button>
    </div>
  );
}
