import React, { useState, useContext, useEffect, useCallback } from 'react';
import { makeAuthUrlWithHeaders, acquireToken, useIsAuthenticated } from "../utils";
import { SiteContext, HeaderContext, LanguageContext, UseAuthenticationContext } from '../context';
import { MsalContext } from "@azure/msal-react";
import { Modal, Button } from 'rsuite';
import { translate } from "../languages";
import { popNotification } from "../utils";

import LineChartOptions from './lineChartOptions';
import GaugeChartOptions from './gaugeChartOptions';
import BarChartOptions from './barChartOptions';
import SharedOptions from './sharedOptions';

import getTagList from '../tags/getTagList';

import axios from "axios";

// This component is used to create or edit custom panels
// It acts as parent container and receives options from its children components, which are used to update chartProperties
// Endpoints is defined in header.js, using the endpoints found in tag search sitenav
// Extra settings are used in tag search to pass the selected tags, time window and resolution
const CustomPanelSettingsMenu = (props) => {
    const { 
        isOpen, 
        setIsOpen,
        editMode, 
        panelID, 
        extraSettings, 
        settings, 
        activeMenu 
    } = props;

    const { site } = useContext(SiteContext);
    const { updateHeader } = useContext(HeaderContext);

    const msalContext = useContext(MsalContext);
    const { useAuthentication } = useContext(UseAuthenticationContext);
    const { selectedLanguage } = useContext(LanguageContext);

    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [modalSize, setModalSize] = useState('90%');
    const [chartProperties, setChartProperties] = useState();
    const [tags, setTags] = useState([]);

    const isAuthenticated = useIsAuthenticated();

    /* Fetch tag lists when menu is opened 
     *  The tag lists are used to populate tag pickers in the custom panel settings menu
     *  They are also needed to create modified list of tags which is saved in the panel properties
     */

    useEffect(() => {
        if (settings) {
          const fetchAllTags = async () => {
            const tagPromises = settings.map(item => 
              getTagList(useAuthentication, msalContext, selectedLanguage, item.endpoints.tags)
                .then(tagList => ({ endpoint: item.endpoints.tags, tagList }))
            );
      
            const allTags = await Promise.all(tagPromises);
      
            const updatedTags = allTags.reduce((acc, { endpoint, tagList }) => {
              acc[endpoint] = tagList;
              return acc;
            }, {});
      
            setTags(updatedTags);
          };
      
          fetchAllTags();
        }
      }, [useAuthentication, msalContext, selectedLanguage, settings]);

    /* set the default values of sharedOptions 
     *  If editing a panel, fetch the panel's properties from the server/local storage
     *  If creating a new panel, set the properties to default values
     *  If creating a new panel from tag search, set the properties to the selected tag search options
     */
    useEffect(() => {
        const convertOldCustomPanels = (panel) => { //convert v.4.2.1 custom panels to v.4.2.2
            if (Array.isArray(panel.properties.sharedOptions.tags)) {
                const tagEndpoint = settings.find(obj => obj.endpoints.history === panel.properties.sharedOptions.endpoint).endpoints.tags;
                panel.properties.sharedOptions.tags = { [tagEndpoint] : panel.properties.sharedOptions.tags };
            }
            return panel;
        }

        const loadLocalPanelSettings = () => {
            const customPanels = JSON.parse(localStorage.getItem(`${site}-customPanels`));
            const panel = customPanels?.find(panel => panel.id === panelID);
            if (panel) { setChartProperties(convertOldCustomPanels(panel)); }
        }

        const fetchCustomPanelSettings = async () => {
            let url = `/boa/api/v1/${site}/custompanels/panelByID/${panelID}`;
            let headers = {};
            [url, headers] = await makeAuthUrlWithHeaders(url, headers, acquireToken(msalContext));
            axios.get(url, { headers })
                .then(response => { setChartProperties(convertOldCustomPanels(response.data)); })
                .catch(error => {
                    popNotification({
                        type: "error", 
                        text: translate("Error fetching custom panels") + `: ${error.message}`
                    })
                });
        }

        const addTags = () => {
            if (tags.length === 0) return [];
            // Get the array of tags, regardless of the endpoint key
            const tagsArray = tags[extraSettings.tagsEndpoint];
            // Filter the tags based on the tagIds in extraSettings
            const selectedTags = tagsArray
                .filter(tag => extraSettings.tagIds.includes(tag.id))
                .map(tag => [tag.tagname, tag.description, tag.id, tag.unit]);

            selectedTags.forEach(tag => {
                tag.push(undefined);
                tag.push(undefined);
            });
            return { [extraSettings.tagsEndpoint]: selectedTags };
        }
        
        if (editMode) { // if editing a panel, set the properties to the panel's properties
            isAuthenticated ? fetchCustomPanelSettings() : loadLocalPanelSettings();
        } else { // if creating a new panel, set the properties to default values
            setChartProperties({
                properties: {
                    sharedOptions: {
                        isPublic: false,
                        type: extraSettings ? "Line" : "", // currently when creating a panel from tag search, the default type is line since other types are not supported yet
                        tags: extraSettings ? addTags() : [],
                        name: ""
                    }, 
                    lineOptions: {
                        timeWindow: extraSettings ? extraSettings.timeWindow : undefined
                    },
                    barOptions: {
                        timeWindow: extraSettings ? extraSettings.timeWindow : undefined
                    },
                }
            });
        }
    }, [extraSettings, tags, editMode, isAuthenticated, msalContext, panelID, site, settings]);

    /*  Handle resize of window to adjust the size of the modal */
    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            const newSize = screenWidth < 768 ? '90%' : `${Math.min(screenWidth * 0.8, 1000)}px`;
            setModalSize(newSize);
            setScreenSize(screenWidth);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    /* Save the panel to the server or local storage */
    const handleSave = async () => {
        const { sharedOptions } = chartProperties.properties;
        if (!sharedOptions.name) sharedOptions.name = sharedOptions.type + " chart";

        const isTypeMissing = !sharedOptions.type;
        const areTagsEmpty = Object.keys(sharedOptions.tags).length === 0;
        
        if (isTypeMissing || areTagsEmpty) {
            popNotification({ type: "warning", text: "Required fields are empty." });
            return;
        }

        let id = chartProperties.id || (isAuthenticated ? `${Date.now()}-${msalContext.accounts[0].username.split("@")[0]}` : Date.now().toString());
        chartProperties.id = id;

        delete sharedOptions.endpoint; //this is not needed anymore in v.4.2.2

        if (isAuthenticated) { savePanelToServer(id);} 
        else { savePanelToLocal(id); }

        setIsOpen();
    };

    // If custom panel is created in favorites menu, add the panel to custom panels menu and set it as favorite
    const updateFavoritesInLocalStorage = (id) => {
      let favorites = JSON.parse(localStorage.getItem(`${site}-favorites`)) || [];
      if (!favorites.includes(id)) {
          favorites.push(id);
      }
      localStorage.setItem(`${site}-favorites`, JSON.stringify(favorites));
  };

    /* Save the panel to the server */
    const savePanelToServer = async (id) => {
        let url = editMode ? `/boa/api/v1/${site}/custompanels/edit` : `/boa/api/v1/${site}/custompanels/save`;
        let headers = {};
        const username = msalContext.accounts[0].username;
        [url, headers] = await makeAuthUrlWithHeaders(url, headers, acquireToken(msalContext));

        if (activeMenu === "Favorites") {
          chartProperties.menu = "custom_panels";
          updateFavoritesInLocalStorage(id);
        } else {
            chartProperties.menu = activeMenu;
        }

        axios.post(
            url,
            { 
                id, 
                username, 
                isPublic: chartProperties.properties.sharedOptions.isPublic, 
                menu: chartProperties.menu, 
                properties: chartProperties.properties 
            }, 
            { headers }
        ).then (response => { 
            if (!extraSettings) { updateHeader(new Date().getTime()); } // Refresh header to show the new panel if not created from tag search
            popNotification({type: "success", text: `Panel saved`});
        }).catch(error => { 
            popNotification({type: "error", text: `Error: ${error.message}`});
        });
    };

    /* Save the panel to local storage */
    const savePanelToLocal = (id) => {
        try {
            const existingCustomPanels = JSON.parse(localStorage.getItem(`${site}-customPanels`)) || [];
            chartProperties.isPublic = chartProperties.properties.sharedOptions.isPublic;
            if (activeMenu === "Favorites") {
              chartProperties.menu = "custom_panels";
              updateFavoritesInLocalStorage(id)
            } else {
              chartProperties.menu = activeMenu;
            }
     
            const newCustomPanels = editMode
                ? existingCustomPanels.map(panel => panel.id === id ? chartProperties : panel)
                : [...existingCustomPanels, chartProperties];

            localStorage.setItem(`${site}-customPanels`, JSON.stringify(newCustomPanels));
            
            popNotification({
                type: "success",
                text: extraSettings ? `Panel saved to custom panels menu` : `Panel saved`
            });
            if (!extraSettings) { updateHeader(new Date().getTime()); } // Refresh header to show the new panel if not created from tag search
        } catch (error) {
            popNotification({type: "error", text: `Error: ${error.message}`});
        }
    };

    const updateChartProperties = useCallback((optionsType, key, optionsObj) => {
    
        const handleNewObject = (existingTags) => {
            if (key === "tagPicker") { // optionsObj should be like this: { "http://localhost:3001/tags": [] }
                const tagEndPoint = Object.keys(optionsObj)[0];
                const newTags = Object.values(optionsObj)[0];
                // Ensure existingTags is an object
                const mergedTags = { ...existingTags };
        
                if (newTags.length === 0) { // Remove the tagEndPoint key if newTags is an empty array               
                    delete mergedTags[tagEndPoint];
                } else { // Otherwise, update the tagEndPoint with newTags                 
                    mergedTags[tagEndPoint] = newTags;
                }

                return { tags: mergedTags };
            } else {
                return optionsObj;
            }
        };

        
        
        setChartProperties(prevState => {
            const existingTags = prevState.properties[optionsType]?.tags || {}; 
            const properties = prevState.properties[optionsType]
                ? {
                    ...prevState.properties,
                    [optionsType]: {
                        ...prevState.properties[optionsType],
                        ...handleNewObject(existingTags)
                    }
                }
                : {
                    sharedOptions: prevState.properties.sharedOptions,
                    [optionsType]: optionsObj
                };
            return {
                ...prevState,
                properties
            };
        });
    }, [setChartProperties]);
    
    return (
        <Modal open={isOpen} onClose={() => setIsOpen()} size={modalSize} backdrop="static">
            <Modal.Header>
                <Modal.Title>{translate(editMode ? "Edit custom panel" : "Create custom panel")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {chartProperties &&
                    <>
                        <SharedOptions
                            endpoints={settings}
                            defaultValues={chartProperties.properties.sharedOptions}
                            onChange={(key, optionsObj) => updateChartProperties('sharedOptions', key, optionsObj)}
                            screenSize={screenSize}
                            tags={tags}
                        />
                        {chartProperties.properties.sharedOptions.type === "Gauge" && (
                            <GaugeChartOptions
                                defaultValues={chartProperties.properties.gaugeOptions}
                                onChange={(key, optionsObj) => updateChartProperties('gaugeOptions', key, optionsObj)}
                            />
                        )}
                        {chartProperties.properties.sharedOptions.type === "Line" && (
                            <LineChartOptions
                                defaultValues={chartProperties.properties.lineOptions}
                                onChange={(key, optionsObj) => updateChartProperties('lineOptions', key, optionsObj)}
                            />
                        )}
                        {chartProperties.properties.sharedOptions.type === "Bar" && (
                            <BarChartOptions
                                defaultValues={chartProperties.properties.barOptions}
                                onChange={(key, optionsObj) => updateChartProperties('barOptions', key, optionsObj)}
                            />
                        )}
                    </>
                }
            </Modal.Body>

            <Modal.Footer>
                <Button
                    onClick={() => handleSave()} 
                    appearance="primary" 
                    color="green" 
                    style={{ minWidth: 70, marginRight: 5 }}
                >
                    {translate(editMode ? "Save" : "Create")}
                </Button>
                <Button 
                    onClick={() => setIsOpen()} 
                    appearance="primary" 
                    color="red" 
                    style={{ minWidth: 70 }}
                >
                    {translate("Cancel")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomPanelSettingsMenu;
