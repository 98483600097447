import React, { Component } from "react";
import { AndritzLogoPng } from "../img";

export default class AndritzLogo extends Component {
  render() {
    const version = process.env.REACT_APP_VERSION;
    const year = new Date().getFullYear();
    return (
      <div className="andritz-logo-container">
        <img className="andritz-logo" src={AndritzLogoPng} alt="ANDRITZ" />
        <div className="background-logo-text" id="version">
          METRISBOA v.{version}
          <br />
          © ANDRITZ {year}
        </div>
      </div>
    );
  }
}
