import React, { useState } from 'react';
import { Button } from 'rsuite';
import { translate } from "../languages";
import CustomPanelSettingsMenu from './customPanelSettingsMenu';

// Button which opens the custom panel settings menu
export default function CustomPanelButton({activeApp, endpoints}) {
    let [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Button onClick={() => setIsOpen(true)} className={"custom-panel-button"}>
                {translate("Create custom panel")}
            </Button>

            {isOpen && (
                <CustomPanelSettingsMenu
                    isOpen={isOpen}
                    setIsOpen={() => setIsOpen(false)}
                    settings={endpoints}
                    activeMenu={activeApp}
                />
            )}
        </>
    );
};