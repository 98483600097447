/* built-in packages */
import React, { useRef } from "react";
import Dropzone from "react-dropzone";

/* 3rd-party packages */
import { Modal, Button, Grid, Row, Col } from 'rsuite';

import { translate } from "../languages";
import {
    getFileExtension,
    popNotification
  } from "../utils";

export default function ImportExport(props) {
  const {
    site,
    onClose
  } = props;

  const dropzoneArea = useRef();

  const acceptedFileTypes = [
    "json"
  ];

  const checkCorrectFileType = (filename) => {
    const fileExtension = getFileExtension(filename);
    if (dropzoneArea.current){ dropzoneArea.current.style.backgroundColor = "transparent"; }
    for (let i = 0; i < acceptedFileTypes.length; ++i) {
      const acceptedFileType = acceptedFileTypes[i];
      if (
        fileExtension === acceptedFileType
        || fileExtension?.toLowerCase?.() === acceptedFileType
      ) {
        return true;
      }
    }
    return false;
  }
  
  const getExportButton = () => {
    return(
        <Button 
            appearance="primary"
            color="green"
            style={{minWidth: "175px", margin:"5px"}}
            disabled={isThereFavorites()}
            onClick={ ()=> {
                let objectUrl = window.URL.createObjectURL(new Blob([localStorage.getItem(`${site}-favorites`)], { type: "json" }));
                let downloadLink = document.createElement("a");
                downloadLink.href = objectUrl;
                downloadLink.download = `${site}-favorites.json`;

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }}
        >
            {translate("Click here to export")}
        </Button>
    );
  }

   const getImportArea = () => {
    return(
        <Dropzone
            onDragOver={(e) => e.target.parentNode.style.backgroundColor = "#c3e6cb"}
            onDragLeave={(e) => e.target.parentNode.style.backgroundColor = "transparent" }
            onDrop={(acceptedFiles) => {
                if (window.confirm(`This will overwrite ${site} favorites. Continue?`)) {
                    acceptedFiles.forEach((file) => {
                        if (checkCorrectFileType(file.name)) {
                            var reader = new FileReader();
                            reader.readAsText(file);
                            reader.onload = function(e) {
                                var contents = e.target.result;
                                if (Array.isArray(JSON.parse(contents))) {
                                    localStorage.setItem(`${site}-favorites`, contents);
                                    popNotification({
                                        type: "info",
                                        text: `${site} favorites updated`
                                    });
                                } else {
                                    popNotification({
                                        type: "error",
                                        text: `Json should be an array.`
                                    });
                                }
                            };
                            
                        } else {
                            popNotification({
                                type: "error",
                                text: `Wrong filetype! Accepted file types are ${acceptedFileTypes.map(item => `.${item}`).join(",")}.`
                            });
                        }
                    });
                } else {
                    dropzoneArea.current.style.backgroundColor = "transparent";
                }
            }}
        >
            {({ getRootProps, getInputProps }) => (
                <div className="dropzone-file-item">
                    <div className="file-item" style={{ backgroundColor: "transparent" }}>
                    <section id="dzcontent" className="content">
                        <div
                        {
                            ...getRootProps({
                            className: "dropzone d-flex align-items-center position-relative",
                            })
                        }
                        >
                            <input {...getInputProps()} />
                            <div
                                ref={dropzoneArea}
                                className="w-100 position-absolute"
                                style={{ height: "100%" }}
                            >
                                <em
                                    className="fas fa-plus"
                                    style={{ marginTop: "60px" }}
                                />
                                <p
                                    className="position-absolute w-100"
                                    style={{
                                        top: "0",
                                        left: "0",
                                        height: "100%",
                                        lineHeight: "180px",
                                    }}
                                >
                                    {translate("Drop here to import")}
                                </p>
                            </div>
                        </div>
                    </section>
                    </div>
                </div>
            )}
        </Dropzone>
    );
  }

  const isThereFavorites = () => {
    if (Array.isArray(JSON.parse(localStorage.getItem(`${site}-favorites`))) && JSON.parse(localStorage.getItem(`${site}-favorites`)).length > 0){ return false; }
    else { return true; }
  }

  const getExcelExportModal = () => {
    return(
        <React.Fragment>
            <Modal.Header>
                <Modal.Title>{site} - {translate("favorites")} {translate("import / export tools")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Grid fluid>
                    <Row className="show-grid mt-3">
                        <Col xs={6} style={{height: "170px", lineHeight: "170px"}}>
                            {translate("Import favorites")}
                        </Col>
                        <Col xs={18}>
                            {getImportArea()}
                        </Col>
                    </Row>
                    <Row className="show-grid mt-5">
                        <Col xs={6} style={{height: "36px", lineHeight: "36px"}}>
                            {translate("Export favorites")}
                        </Col>
                        <Col xs={18}>
                            {getExportButton()}
                        </Col>
                    </Row>
                </Grid>

            </Modal.Body>

            <Modal.Footer>
                <Button 
                    onClick={ () => onClose()}
                    appearance="primary" 
                    color="red"
                    data-testid="about-close-button"
                    > {translate("Close")}
                </Button>
            </Modal.Footer>
        </React.Fragment>
    );
  }

  return getExcelExportModal();
}
