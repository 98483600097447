import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import axios from "axios";
import { Loader } from "rsuite";
import { HeaderNav } from ".";
import { SelectedGrid } from "../grid";
import {
  makeAuthUrlWithHeaders,
  acquireToken
} from "../utils";
import { translate } from "../languages";
import { MsalContext } from "@azure/msal-react";
import { 
  SiteContext, 
  UseAuthenticationContext,
  HeaderContext
} from "../context";

export default function Header(props) {
  const {
    siteList,
    onSiteChange,
    isAuthenticated,
    customTemplates
  } = props;

  const [sitenavConfig, setSitenavConfig]             = useState();
  const [panelConfig, setPanelConfig]                 = useState();
  const [siteChangelog, setSiteChangelog]             = useState();
  const [endpoints, setEndpoints]                     = useState();
  const [resolutions, setResolutions]                 = useState();

  const msalContext                             = useContext(MsalContext);
  const { useAuthentication }                   = useContext(UseAuthenticationContext);
  const { site }                                = useContext(SiteContext);
  const { header }                              = useContext(HeaderContext);

  // Store all tag search endpoints in state, filter_indexing is also needed
  // Also save the resolution values, that are used for custom panels
  useEffect(() => {
    if (sitenavConfig) {

      const tagSearchNames = Object.keys(sitenavConfig.apps).filter(app => sitenavConfig.apps[app].content?.tagSearch);
      const tagSearchEndpoints = [];

      tagSearchNames.forEach(tagSearchName => {
        tagSearchEndpoints.push({ 
          endpoints: sitenavConfig.apps[tagSearchName].content.tagSearch.endpoints, 
          filter_indexing: sitenavConfig.apps[tagSearchName].content.tagSearch.filter_indexing 
        });
      });

      const tagSearchResolutions = [];

      tagSearchNames.forEach(tagSearchName => {
        if (sitenavConfig.apps[tagSearchName].content.tagSearch.resolutions) {
          tagSearchResolutions.push(
            ...sitenavConfig.apps[tagSearchName].content.tagSearch.resolutions
          );
        }
      });

      setResolutions(tagSearchResolutions);
      setEndpoints(tagSearchEndpoints);

    } else { setEndpoints({error: "No tagSearch found in sitenavConfig"}); }
  }, [sitenavConfig]);

  useEffect(() => {
    async function getMenuAndDashboard() {
      if (useAuthentication) {
        try {
          const configTypes = siteList.find(apiSite => apiSite.api === site).configTypes;
          let fetchedSitenav, fetchedPanels, fetchedChangelog;
          
          const fetchData = async (configType) => {
            let url;
            let headers = {};
            const allowedEndPoints = ["sitenav", "panels", "changelog"];
            const endPoint = configType.toLowerCase();
            if (allowedEndPoints.includes(endPoint)) { // check if configType is allowed
              url = `/boa/api/v1/${site}/layoutsApi/${endPoint}`;
              [url, headers] = await makeAuthUrlWithHeaders(
                url,
                headers,
                acquireToken(msalContext)
              );
              const response = await axios.get(url, { headers });
              const data = response.data;
              switch (endPoint) {
                case "sitenav":
                  fetchedSitenav = data;
                  break;
                case "panels":
                  fetchedPanels = data;
                  break;
                case "changelog":
                  fetchedChangelog = data;
                  break;
                default:
                  break;
              }
            }
          };
          
          const fetchAllData = async () => {
            for (let configType of configTypes) {
              await fetchData(configType);
            }
            setSitenavConfig(fetchedSitenav);
            setPanelConfig(fetchedPanels);
            setSiteChangelog(fetchedChangelog);
          };
          
          fetchAllData();

        } catch (e) {
          console.error(e.message);
        }
      } else { //local files
        try {
          const panelsUrl = siteList[0].layoutsApi["panel-templates"] ? "/boa/api/v1/local/panel-templates" : makeLocalUrl(siteList[0].layoutsApi.panels);
          await axios.all([
            axios.get(makeLocalUrl(siteList[0].layoutsApi.sitenav)),
            axios.get(panelsUrl)
          ])
          .then(axios.spread((sitenav, panels) => {
            setSitenavConfig(sitenav.data);
            setPanelConfig(panels.data);
          }));
  
          if (siteList[0].layoutsApi.changelog) {
            axios.get(makeLocalUrl(siteList[0].layoutsApi.changelog))
              .then(response => setSiteChangelog(response.data) )
              .catch(() => setSiteChangelog("error" ))
          }
        } catch (e) {
          console.error(e.message);
        }
      }
    }

    if (site) { getMenuAndDashboard(); }

  },[site, useAuthentication, msalContext, siteList]);

  const makeLocalUrl = (url) => {
    if (url.includes("http")) { return url; } 
    else { return process.env.PUBLIC_URL + url}
  }

  const getRouterContent = () => {
    if (siteList.some(siteObj => siteObj.api === site)) {
      const site_info = siteList.find(siteObj => siteObj.api === site);
      const path = [
        `/${site}/:item/:child_item/:child_child_item`,
        `/${site}/:item/:child_item`,
        `/${site}/:item`,
        "/"
      ];

      return(
        <Router
          basename={process.env.REACT_APP_PREFIX}
          key={document.location.href}
        >
          <HeaderNav
            siteList = {siteList}
            onSiteChange = { (newSite) => { onSiteChange(newSite); setSitenavConfig(); }}
            isAuthenticated = {isAuthenticated}
            site={site}
            showMap={ () => { onSiteChange(); setSitenavConfig(); }}
            site_info={site_info}          
          />
          {sitenavConfig ? (
            <Switch>
              <Route
                path={path}
                isAuthenticated={isAuthenticated}
                component={(props) => (
                  sitenavConfig.disabled ? (
                    <div className="card disabledSiteInfo">
                      <span>{translate("disabled_site_info")}</span>
                    </div>
                  ) : (
                    <SelectedGrid
                      key={header}
                      site={site}
                      menuItems={sitenavConfig}
                      layouts={panelConfig}
                      siteChangelog={siteChangelog}
                      dbapi_write={site_info.dbapi_write ? site_info.dbapi_write : null}
                      timeZone={site_info.timeZone}
                      site_info={site_info}
                      endpoints={endpoints}
                      resolutions={resolutions}
                      customTemplates={customTemplates}
                      {...props}
                    />
                  )
                )}
              />
            </Switch>
          ) : (
            <Loader size="lg" center />
          )}
        </Router>
      );
    }
  }

  return endpoints ? getRouterContent() : <Loader size="lg" center />;
}
