import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { StatusBlock, SiteChangeLog } from "./";
import { AndritzLogo } from "../logo";
import { Message } from 'rsuite';
import {
  LangProvider,
  locales,
  translate
} from "../languages";
import { 
  SiteContext,  
  NarrowScreenContext,
  UseAuthenticationContext,
  TrackingContext,
  LanguageContext
} from "../context";
import {
  MsalContext
} from "@azure/msal-react";

export default function GridSelection(props){
  const {
    menuItems,
    siteChangelog,
    endpointsExists
  } = props;
  
  const { site }                            = useContext(SiteContext);
  const { track }                           = useContext(TrackingContext);
  const { isNarrowScreen }                  = useContext(NarrowScreenContext);
  const { useAuthentication }               = useContext(UseAuthenticationContext);
  const msalContext                         = useContext(MsalContext);
  const { translations, selectedLanguage }  = useContext(LanguageContext);

  useEffect(() => { document.title = "MetrisBOA" }, [] );

  const createLink = (appContent, appLink) => {
    const hideFromHome = appContent?.homeBox?.hide ? true : false;
    const showNewBanner = appContent?.homeBox?.new ? true : false;
    if (!hideFromHome) {
      return(
        <Link
          key={`Grid-${appLink}`}
          to={{ pathname: `/${site}/${appLink}` }}
          onClick={() => {
            document.title=`${appContent.text} | MetrisBOA`;
            track(
              {
                type: "page_change",
                action: "click",
                source: "grid_selection",
                target: appLink,
                site: site
              },
              useAuthentication,
              msalContext
            );
          }}
          data-testid="grid-selection-item"
        >
          <div
            className="file-item"
            style={{ color: appContent?.homeBox?.colors?.color, backgroundColor: appContent?.homeBox?.colors?.background }}
          >
            {showNewBanner && <div className="new-banner">{translate("New")}</div>}
            {translate(appContent.text)}
            <em className={`fas ${appContent.icon} file-icon`} style={{ color: appContent?.homeBox?.colors?.color }} data-testid="grid-selection-icon" />
          </div>
        </Link>
      )
    }
  }

  const parseItems = () => {
    const linkItems = [];
    let apps = Object.entries(menuItems.apps);

    if (endpointsExists){
      apps.unshift([
        "custom_panels",
        {
          "text": "Custom panels",
          "icon": "fas fa-cogs",
          "content": [
            {
              "panels": {
                "loadPanels": "custom_panels"
              }
            }
          ]
        }
      ]);
    }

    if (localStorage.getItem(`${site}-favorites`)) {
      let savedFavorites = JSON.parse(localStorage.getItem(`${site}-favorites`));
      if (savedFavorites.length > 0 ) {
        apps.unshift([
          "favorites",
          {
            "text": "Favorites",
            "icon": "fas fa-star",
            "content": [
              {
                "panels": {
                  "loadPanels": savedFavorites
                }
              }
            ]
          }
        ]);
      }
    }

    for (let app of apps) {
      let appLink = app[0];
      let appContent = app[1];
      if (appContent?.homeBox?.rowChange) { //add a new row
        linkItems.push(
          <div
            data-testid="grid-selection-item"
            key={`newRow__${appLink}`}
            style={{ display: "block" }}
          />
        );
      }
      if (appContent.children) {
        let children = Object.entries(appContent.children);
        for (let child of children) {
          appLink = `${app[0]}/${child[0]}`;
          appContent = child[1];
          if (appContent.children) {
            let subChildren = Object.entries(appContent.children);
            for (let subChild of subChildren) {
              appLink = `${app[0]}/${child[0]}/${subChild[0]}`;
              appContent = subChild[1];
              linkItems.push(createLink(appContent, appLink));
            }
          } else { // child item
            linkItems.push(createLink(appContent, appLink));
          }
        }
      } else { // no children
        linkItems.push(createLink(appContent, appLink));
      }
    }
    return linkItems;
  }

  const gridStyles = isNarrowScreen ? { textAlign: "center", marginTop: "10px" } : { marginTop: "10px" }
  let changelog;
  let changelogError = translate("changelog endpoint is missing");
  if (siteChangelog?.list) { changelog = siteChangelog.list }
  else if (siteChangelog) { changelogError = translate("Error in changelog endpoint")}
  
  return (
    <LangProvider
      translations={translations}
      locale={locales[selectedLanguage]}
    >   
      <div className="gridSelection" style={{ paddingTop: isNarrowScreen ? "20px" : "0px" }}> 
        {menuItems?.home?.statusMonitoring &&  
          <StatusBlock 
            style={gridStyles}
            statusMonitoring={menuItems.home.statusMonitoring}
          /> 
        }
        {menuItems?.home?.changelog &&
          <>
            {changelog ? (
              <SiteChangeLog 
                style={gridStyles}
                changelog={changelog}
              />
            ) : ( 
              <Message className="m-2" showIcon type="warning" header={changelogError} />
            )}
          </>
        }
        <div
          style={gridStyles}
          data-testid="grid-selection"
        >
          {parseItems()}
        </div>
      </div> 
      <AndritzLogo />
    </LangProvider>
  );
}
