/* built-in packages */
import React, { useState, useEffect, useContext, useRef } from "react";
/* 3rd-party modules */
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Loader } from "rsuite";
import { isFunction } from "lodash";
import moment from "moment";
import "moment-timezone";
/* self-provided packages */
import {
  HighchartOptions,
  isNumber
} from "../../utils";
import { translate } from "../../languages";
import { updateAxis, cuttingSelections, ChartTable} from "../../grid";
import { SiteContext, LanguageContext } from "../../context";

export default function TimeserieChart(props) {
  const {
    chartData,
    loading,
    cuttingMode,
    cuttingRange,
    handleZoom,
    hiddenSeries,
    selectedTags
  } = props;

  const [singleYAxis, yAxisToggle]          = useState(true);
  const [clickedPoints, setClickedPoints]   = useState([]);

  const { timeZone, site }                  = useContext(SiteContext);
  const { translations, selectedLanguage }  = useContext(LanguageContext);

  const chartRef = useRef();
  HighchartOptions(
    Highcharts,
    selectedLanguage,
    timeZone,
    translations
  );

  useEffect(() => { // update axis crosshairs, tooltip and zooming type
    if(chartRef?.current?.chart) { chartRef?.current?.chart?.update(updateAxis(cuttingMode)); }
  }, [cuttingMode]);

  useEffect(() => { // zoom out when data changes, zoom is only used to get timestamps and needs to be reset after that  
    if (chartRef?.current?.chart) { chartRef?.current?.chart?.zoomOut(); }
  }, [chartData]);

  useEffect(() => { // handle clicked points
    if (sessionStorage.getItem("autosave_ruler")) {
      let rulers = JSON.parse(sessionStorage.getItem("autosave_ruler"));
      if ( // load previous points when tags havent changed
        selectedTags.every(tag => rulers.rulers[0].map(point => point.id).includes(tag)) &&
        rulers.rulers[0].map(point => point.id).every(tag => selectedTags.includes(tag))
      ) {
        if (rulers.site === site) { setClickedPoints(rulers.rulers); }
      } else { // reset clicked points when tags have changed
        setClickedPoints([]); 
      }
    }
  }, [selectedTags, site]);

  useEffect(() => { // handles chart size when chart is resized
    const resizeObserver = new ResizeObserver((entries) => {
      if (chartRef?.current?.chart) { chartRef.current.chart.reflow(); }
    });
    resizeObserver.observe(document.getElementById("tagsearch"));
    return () => { resizeObserver.disconnect(); }
  },[]);

  useEffect(() => { // update plotlines when clickedPoints changes
    if (chartRef.current && chartRef.current.chart) {
      let array = [];
      for (let click in clickedPoints){
        array.push({
            color: "#2196f3", 
            width: 2, 
            value: clickedPoints[click][0].x,
            label: {
              text: Number(click)+1,
              align: 'center',
              rotation: 0,
              x: 0,
              y:-10
            }
        })
      }
      chartRef.current.chart.update({ xAxis:{ plotLines: array } })
    }
  },[clickedPoints]);

  const getOptions = () => {

    const zoomData = (time) => {
      if (isFunction(handleZoom)) {
        handleZoom(
          { 
          sdt: time.value.sdt,
          edt: time.value.edt, 
          },
          true  
        );
      }
    }
  
    const getYAxisRange = (oneChartData) => {
      let yAxisMin, yAxisMax;
      let yAxisStartOnTick = true;
  
      if ("min" in oneChartData && isNumber(oneChartData.min)) {
        yAxisMin = oneChartData.min;
        yAxisStartOnTick = false;
      }
  
      if ("max" in oneChartData && isNumber(oneChartData.max)) {
        yAxisMax = oneChartData.max;
        yAxisStartOnTick = false;
      }
  
      return [yAxisMin, yAxisMax, yAxisStartOnTick];
    }
  
    const allocateYAxesToSeries = (data) => {
      if (Array.isArray(data)) {
        for (let i = 0; i < data.length; ++i) {
          data[i].yAxis = singleYAxis ? 0 : i;
        }
      }
      return data;
    }
  
    const getYaxis = (data) => {
      let yAxis = [];
  
      if (Array.isArray(data) && data.length > 0) {
        for (let i = 0; i < data.length; ++i) {
          const [yAxisMin, yAxisMax, yAxisStartOnTick] = getYAxisRange(data[i]);
  
          const currentYAxis = {
            title: {
              text: null
            },
            opposite: false,
            index: i,
            min: yAxisMin,
            max: yAxisMax,
            startOnTick: yAxisStartOnTick
          };
          
          currentYAxis.labels = {
            format: data[i].unit ? `{value} ${data[i].unit}` : `{value}`,
            style: {
              color: data[i].color
            },
            y: -2
          };
  
          if (i > 0) {
            currentYAxis.gridLineWidth = 0;
          }
  
          yAxis.push(currentYAxis);
  
        }
      }
  
      if (yAxis.length === 0) {
        yAxis = { opposite: false };
      }
  
      return yAxis;
    }

    let seriesData = allocateYAxesToSeries(chartData);
    const yAxis = (
      singleYAxis
      ? [
        {
          title: null,
          labels: {
            format: null,
            style: {
              color: "#434348"
            }
          }
        }
      ]
      : getYaxis(seriesData)
    );

    const options = {
      accessibility:{
        enabled: false
      },
      chart: {
        type: "line",
        className: "chart",
        events:    {
          selection: function(e) {
            if (cuttingMode !== "off") { //cutting tool selections
              cuttingSelections(cuttingMode, chartData, e, cuttingRange);       
            } 
            else if (cuttingMode === "off") { // zoom
              if (e.xAxis) { //zoom
                return zoomData({
                  value: {
                    sdt: new Date(moment.utc(new Date(e.xAxis[0].min)).valueOf()).toISOString(),
                    edt: new Date(moment.utc(new Date(e.xAxis[0].max)).valueOf()).toISOString()
                  },
                  time: "custom"
                });
              }
            }       
          }
        }
      },
      rangeSelector: {
        enabled: false
      },
      legend: {
        enabled: false,
        floating: true,
        align: "right",
        verticalAlign: "top",
        layout: "vertical",
        backgroundColor: "#FFFFFF80",
        borderWidth: 1,
        borderRadius: 3
      },
      xAxis: {
        type: "datetime"
      },
      yAxis: yAxis,
      title: {
        text: null
      },
      tooltip: {
        shared: true,
        useHTML: true,
        headerFormat: "<small>{point.key}</small><table>",
        pointFormat:
          '<tr><td style="color: {series.color}">{series.name}: </td>' +
          '<td style="text-align: right"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        xDateFormat: "%A, %b %e, %H:%M:%S",
        valueDecimals: 2
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          }
        },
        series: {
          allowPointSelect: true,
          connectNulls: false,
          events: {
            click: function(event) {
              let clicks = clickedPoints;
              if (clicks === undefined || clickedPoints.findIndex(item => item[0].x === event.point.x) < 0) { //timestamp not found, add series
                let serArr = [];
                let chartObj = chartRef.current.chart.series;
                for (let series in chartObj) {               
                  let value = chartObj[series].data.find(point => point.x === event.point.x);
                  let objId = chartData[series].id;
                  let objName = chartData[series].name
                  let newObject = { id: objId, name: objName };
                  if (value) { newObject = { x: value.x, y: value.y, color: value.color, id: objId, name: objName } }
                  serArr.push(newObject);
                }
                clicks.push(serArr);
                serArr = []
                setDataPoint([...new Set(clicks)], event.point.x);
              }  else { //timestamp found, remove series
                clicks = clicks.filter(subArray => !subArray.some(obj => obj.x === event.point.x))
                setDataPoint([...clicks], event.point.x);
              }   
            }
          }
        }
      },
      series: seriesData
    };
    return(options);

  }

  const getPreviousValue = (data, targetTimestamp) => {
    let closestTimestamp = null;
    for (const dataPoint of data) {
        const timestamp = dataPoint[0];
        if (timestamp <= targetTimestamp) {
            if (closestTimestamp === null || timestamp > closestTimestamp) {
                closestTimestamp = timestamp;
            }
        }
    }
    const closestDataPoint = data.find(dataPoint => dataPoint[0] === closestTimestamp);
    return closestDataPoint ? closestDataPoint[1] : null;
}

  const setDataPoint = (points, eventX) => { // add previous value if point doesnt have a value & sort points
    let flattenedArray = points.reduce((acc, curr) => acc.concat(curr), []);
    flattenedArray = flattenedArray.map((item, index) => {
      if (item && item.x) { return Object.assign(item, { name: chartRef.current.chart.series[(index) % chartData.length].name});} 
      else {
        return {
          x: eventX, 
          y: getPreviousValue(chartData[ (index) % chartData.length].data, eventX), 
          color: chartRef.current.chart.series[(index) % chartData.length].color, 
          name: chartRef.current.chart.series[(index) % chartData.length].name, 
          previousValue:  !chartData[ (index) % chartData.length].data.some(timeseries => timeseries[0] === eventX),
          id: item.id
        }
      }
    })
    flattenedArray = flattenedArray.sort((a, b) => a.x - b.x);
    let sortedArrayOfArrays = [];
    let currentTimestamp = null;
    flattenedArray.forEach( (item, index) => {
      if (item && item.x !== currentTimestamp) {
          sortedArrayOfArrays.push([]);
          currentTimestamp = item.x;
      }
      if (item?.x) {
        sortedArrayOfArrays[sortedArrayOfArrays.length - 1].push(item);
      }
    });

    setClickedPoints([...sortedArrayOfArrays]);
    sessionStorage.setItem("autosave_ruler", JSON.stringify({ site: site, rulers: sortedArrayOfArrays}));
  }

  const removeClickPoint = (index) => {
    let clicks = clickedPoints;
    clicks.splice(index, 1);
    setClickedPoints([...clicks])
  }

  const getLoadingBlur = () => {
    return(
      <div style={{ backgroundColor: "rgba(242, 242, 242, 0.7)", position: "absolute", height: "100%", width: "100%", zIndex: "10" }}>
        <div className="d-flex align-items-center justify-content-center h-100">
          <div style={{ display: "inline-block" }}>
            <Loader size="lg" />
          </div>
          <div className="d-inline-block ms-4" style={{ fontSize:"3rem" }}>{translate("Loading data")}</div>
        </div>
      </div>
    );
  }

  const getGripIconClasses = () => {
    if (singleYAxis) { return "fas fa-grip-vertical"; } 
    else { return "fas fa-grip-horizontal"; }
  }

  return (
    <div style={{ position: "relative" }}>
      {loading && getLoadingBlur()}
      <button  
        type="button" 
        className="btn btn-light y-axis-toggle-button mb-2 border"
        onClick={ () => yAxisToggle(!singleYAxis) }
      >
        <i className={getGripIconClasses()} />
      </button>
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        options={getOptions()}
      />
      {clickedPoints.length > 0 && 
        <ChartTable 
          clickedPoints={clickedPoints}
          hiddenSeries={hiddenSeries}
          resetPoints = { () => { setClickedPoints([]); sessionStorage.removeItem("autosave_ruler") }}
          removeClickPoint= { (index) => removeClickPoint(index) }
        />
      }
    </div>
  );
}

