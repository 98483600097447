import React, { useContext, useState, useRef } from "react";
import axios from "axios";

import { Whisper, Popover, Dropdown, Modal } from "rsuite";
import { MsalContext, useMsal } from "@azure/msal-react";
import { ChangeLogModal, changeLog } from "../changelog";
import { AboutModal, SystemInfoModal, ImportExport } from "../modals";
import { sharedSettings } from ".";
import { NarrowScreenContext, ThemeContext, LanguageContext, SiteContext, UseAuthenticationContext } from "../context";
import { 
  isUserFromAndritz,
  toggleFullScreen,
  UtilityModal,
  acquireToken,
  makeAuthUrlWithHeaders
} from "../utils";
import {
  HeaderDropdownItem,
} from "../header";
import {
  LanguageSelect,
  translate
} from "../languages";

/**
 * Button for general settings and other user-facing
 * utilities. The button hides behind it a number of
 * elements to reduce icon bloating of header.
 * @param {*} props authentication metadata
 * @returns {JSX.Element} settings button which opens dropdown menu
 */
export default function SettingsButton(props) {
  const {
    isAuthenticated,
    languages,
    systemInfo,
    grafana,
    homeClick
  } = props;

  const popoverRef = useRef();

  const [modalContent, setModal] = useState(false);
  const { sharedClasses = "" } = sharedSettings;

  const { instance } = useMsal();
  const { useAuthentication } = useContext(UseAuthenticationContext);
  const authcontext = useContext(MsalContext);
  const { isNarrowScreen } = useContext(NarrowScreenContext);
  const { theme, changeTheme } = useContext(ThemeContext);
  const { availableLanguages } = useContext(LanguageContext);
  const { site } = useContext(SiteContext);
  
  const latestViewedChangelogVersion = localStorage.getItem("latestViewedChangelogVersion");
  const latestChangelogVersion = changeLog?.[0]?.version;

  const setModalContent = (content) => {
    popoverRef.current.close();
    setModal(content);
  }

  const openGrafana = async() => {
    try {
      let url = `/boa/api/v1/${site}/grafana`;
      let headers = {};
      [url, headers] = await makeAuthUrlWithHeaders(url, headers, acquireToken(authcontext));
      await axios.get(url, { headers }).then(response => { window.open(response.data, "_blank"); });
    } catch (e) { console.log(e); }
  }

  /**
   * Creates dropdown menu of useful items
   * @returns {JSX.Element} dropdown menu
   */
  function getDropdown() {
    return (
      <Popover style={{ minWidth: "300px" }}>
        <Dropdown.Menu data-testid="header-dropdown">

        {isAuthenticated &&
          <>
            <HeaderGroup text="Get Help" />
            <Dropdown.Separator />

            <HeaderDropdownItem
                text = "BOA Service Desk"
                icon = "fa-question" 
                iconStyle = "fa"
                onClick = {() => window.open("https://andritz.atlassian.net/servicedesk/customer/portal/13")}
              />
          </>
        }

          <HeaderGroup text="Layout" />
          <Dropdown.Separator />

          <HeaderDropdownItem
            text = "Change Theme"
            icon = {theme === "light" ? "fa-moon" : "fa-sun"}
            onClick = {() => changeTheme()}
            testId="header-dropdown-item-theme"
          />

          {languages && availableLanguages.length > 1 &&
            <HeaderDropdownItem
              text = "Change Language"
              icon = "fa-language"
              onClick={() => setModalContent("language")}
              testId="header-dropdown-item-language"
            />
          }

          {site &&
            <HeaderDropdownItem
              text = "Favorites (Import/Export)"
              icon = "fa-star"
              onClick={() => setModalContent("importExport")}
              testId="header-dropdown-item-importExport"
            />
          }

          {!isNarrowScreen &&
            <HeaderDropdownItem
              text = "Fullscreen"
              icon = "fa-expand-arrows-alt"
              onClick={() => toggleFullScreen(document.documentElement)}
              testId="header-dropdown-item-fullscreen"
            />
          }

          <HeaderGroup text="Version" />
          <Dropdown.Separator />

          {isAuthenticated &&
            <HeaderDropdownItem
              text = "Changelog"
              icon = "fa-list-alt"
              onClick = {() => setModalContent("changelog") }
              showBadge = {latestChangelogVersion !== latestViewedChangelogVersion}
              testId="header-dropdown-item-changelog"
            />
          }

          <HeaderDropdownItem
            text = "About"
            icon = "fa-info-circle" 
            onClick = {() => setModalContent("about")}
            testId="header-dropdown-item-about"
          />

          {isUserFromAndritz(authcontext?.accounts?.[0]?.username) &&
            <React.Fragment>
              <HeaderGroup text="Tools" />
              <Dropdown.Separator />
            </React.Fragment>
          }

          {systemInfo && 
            <HeaderDropdownItem
              text = "System Status"
              icon = "fa-info-circle" 
              onClick = {() => setModalContent("system")}
            />
          }
  
          {isUserFromAndritz(authcontext?.accounts?.[0]?.username) &&
            <HeaderDropdownItem
              text = "Configurator"
              icon = "fa-tools" 
              onClick = {() => window.open(`${process.env.REACT_APP_ROOT_URI}/configurator`)}
            />
          }

          {grafana && 
            <HeaderDropdownItem
              text = "Grafana"
              icon = "fa-poll"
              onClick = {() => openGrafana()}
              testId = "header-openGrafana"
            />
          }

          {isUserFromAndritz(authcontext?.accounts?.[0]?.username) &&
            <React.Fragment>
              <HeaderGroup text="Documentation" />
              <Dropdown.Separator />

              <HeaderDropdownItem
                text = "Confluence"
                icon = "fa-confluence" 
                iconStyle = "fab"
                onClick = {() => window.open("https://andritz.atlassian.net/wiki/spaces/MB/overview")}
              />

              <HeaderDropdownItem
                text = "Jira"
                icon = "fa-jira" 
                iconStyle = "fab"
                onClick = {() => window.open("https://andritz.atlassian.net/jira/software/c/projects/MBOA/issues")}
              />
              
              <HeaderDropdownItem
                text = "GitHub"
                icon = "fa-github"
                iconStyle = "fab"
                onClick = {() => window.open("https://github.com/andritzot/boa-frontend-v.-2.0")}
              />
            </React.Fragment>
          }

          {useAuthentication && isAuthenticated &&
            <React.Fragment>
              <Dropdown.Separator />
              <HeaderDropdownItem
                text = "Sign out"
                icon = "fa-sign-out-alt"
                onClick = { () => setModalContent("logout") }
                testId = "header-dropdown-item-loginout"
              />
            </React.Fragment>
          }
        </Dropdown.Menu>
      </Popover>
    );
  }

  const HeaderGroup = ({text}) => {
    return (
      <div className="header-dropdown-group">
        {translate(text)}
      </div>
    );
  }

  const GetModal = () => {
    return(
      <Modal 
        backdrop="static"
        size={isNarrowScreen ? "xs" : "md"}
        open={modalContent !== false} 
        onClose={()=> setModal(false)}
        onExit={()=> setModal(false)}
        overflow={modalContent === "language" ? false : true}
      >
        {modalContent === "changelog" &&
          <ChangeLogModal
            onClose={() => setModal(false)}
          />
        }

        {modalContent === "language" &&
          <LanguageSelect  
            onClose={() => setModal(false)} 
            languages={languages}
          />
        }

        {modalContent === "system" &&
          <SystemInfoModal
            onClose={() => setModal(false)}
            systemInfo={systemInfo}
          />
        }

        {modalContent === "about" &&
          <AboutModal
            onClose={() => setModal(false)}
          />
        }

        {modalContent === "importExport" &&
          <ImportExport
            site={site}
            onClose={ ()=> {setModal(false); homeClick(); }}
          />
        }

        {modalContent === "logout" &&
          <UtilityModal
            // show={showLogoutPrompt}
            titleText = "Sign out"
            bodyText = "Are you sure you wish to sign out?"
            confirmButtonText = "Sign out"
            cancelButtonText = "Cancel"
            confirmCallback={() => instance.logout()}
            displayCallback={() => setModal(false)}
          />
        }
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <Whisper
        speaker={getDropdown()}
        ref={popoverRef}
        trigger="click"
        placement="bottomEnd"
      >
        <i className={`${sharedClasses} fa-cog order-2`} data-testid="settings-button" />
      </Whisper>  
      
      <GetModal />

    </React.Fragment>
  );
}
