import React, { useContext, useRef } from "react";
import { useIntl } from 'react-intl';
import axios from "axios";
import { toaster, Notification } from "rsuite";

import {
  makeAuthUrlWithHeaders,
  acquireToken
} from "../utils";

import { UseAuthenticationContext } from "../context";
import { MsalContext } from "@azure/msal-react";
import { Editor } from '@tinymce/tinymce-react';

export default function TinyMCE(props) {
  const { content, saveUrl } = props;

  const { useAuthentication }     = useContext(UseAuthenticationContext);
  const msalContext               = useContext(MsalContext);

  const editorRef = useRef(null);
  const intl = useIntl();

  const saveText = async() => {
    /*Function Saves the edited text */
    const editorContent = editorRef.current.getContent();
    const blob = new Blob([editorContent], { type: "text/html" })
    const formData = new FormData();
    formData.append('htmlFile', blob);
    let headers = {  'Content-Type': 'multipart/form-data' };
    let url = saveUrl;
    /* Modify request URL in case authentication is used */
    if (useAuthentication) {
      [url, headers] = await makeAuthUrlWithHeaders(
        url, 
        headers, 
        acquireToken(msalContext)
      )
    }
    try {
      await axios({
        method: "post",
        url: url,
        data: formData,
        headers: headers
      })
      .then(() => {
        toaster.push(
          <Notification
            type="success"
            header={intl.formatMessage({ id: "Changes saved successfully"})}
          />
        );
      })
    } catch(e) {
      toaster.push(
        <Notification
          type="error"
          header={intl.formatMessage({ id: "Saving failed" })}
        />
      );
      if (process.env.NODE_ENV === "development") {
        console.warn(e);
      }
    }
  }

  return (
    <React.Fragment>
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={content}
        init={{
          height: "90%",
          menubar: false,
          plugins: [ 'lists', 'code', 'table', 'fullpage' ],
          toolbar: 'undo redo | bold italic forecolor removeformat | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'table | code'
        }}
      />
      <button className="btn btn-primary mt-3" onClick={() => saveText()}>Save</button>
    </React.Fragment>
  );
}