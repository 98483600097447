/* built-in packages */
import React, { useState, useEffect, useContext } from "react";

/* 3rd-party packages */
import { Slider, DateRangePicker, DatePicker } from "rsuite";
import { capitalize } from "lodash";

/* self-provided packages */
import { translate } from "../languages";
import { LanguageContext } from "../context";
import {
  secondsToString,
  getTimeparams,
  todaysTimeParams,
  datePickerLocale
} from "../utils";

export default function TimeButtons(props) {
  const {
    layouts = { timeButtons: [600, 1800, 3600, 28800, "86400", 604800]},
    useGlobalTimeFilterTimeValue = false,
    globalTimeFilterTimeValue,
    id = 0,
    onTimeChange,
    panel,
    placement = "rightStart",
    timeZone,
    timeWindow
  } = props;

  const { translations, selectedLanguage } = useContext(LanguageContext);

  const [startDate, setStartDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState();
  const [width, setWidth] = useState("auto");

  const { afterToday } = DateRangePicker;
  const timeButtons = layouts.timeButtons;


  /* Set selectedTime on mount, selectedTime is compared to buttons time to determinate its checked state */
  useEffect( () => {
    if (timeButtons && !timeWindow) {
      for (let btn of timeButtons) {
        if (typeof(btn) === "string") {
          if (parseInt(btn)) {
            setSelectedTime(parseInt(btn));
            return; //escape loop after
          } else if (btn === "selector") {
            setWidth("90%");
          }
        }
      }
    } else {
      setSelectedTime(timeWindow);
    }
  },[timeButtons, timeWindow])

  /* Update selectedTime when globalTimeFilter is used */
  useEffect( () => {
    if (useGlobalTimeFilterTimeValue) {
      setSelectedTime(globalTimeFilterTimeValue)
    }
  }, [useGlobalTimeFilterTimeValue, globalTimeFilterTimeValue])

  const CreateButtons = () => {
    const buttons = [];
    for (let button in timeButtons) {
      let code = `button_${button}_panel_${id}_${panel}`
      let value = timeButtons[button];
      switch (value) {
        case "custom":
          buttons.push(dateRangeButton(code));
          break;
        case "select":
        case "dayselect":
          buttons.push(selectDateButton(code));
          break;
        case "months":
          buttons.push(selectMonthButton(code));
          break;
        case "selector":
          buttons.push(selectorButton(code));
          break;
        case "today":
          buttons.push(timeButton(value, code));
          break;
        default:
          buttons.push(timeButton(parseInt(value), code));
      }
    }
    return buttons;
  }

  const timeButton = (value, code) => {
    return(
      <button
        key = {code}
        type = "button"
        className = {value === selectedTime ? "timeButton selected" : "timeButton"}
        onClick = {() => timeChange(value)}
      >
        {typeof(value) === "number" ? secondsToString(value) : capitalize(value) }
      </button>
    );
  }

  const dateRangeButton = (code) => {
    let lastMonth = new Date();
    lastMonth.setDate(1);
    lastMonth.setMonth(lastMonth.getMonth()-1);
    return(
      <div key={code} className="datePickerSelect">
        <label className={addClassName()}>
          <i className="fas fa-search" />
          <DateRangePicker
            shouldDisableDate={afterToday()}
            format="yyyy-MM-dd HH:mm"
            onOk={(dates) => timeChange(dates)}
            ranges={[]}
            preventOverflow
            defaultCalendarValue={[lastMonth, new Date()]}
            placement={placement}
            placeholder={translate("Select Date Range")}
            isoWeek
            locale={datePickerLocale(translations, selectedLanguage)}
          />
        </label>
      </div>
    );
  }

  const selectDateButton = (code) => {
    return(
      <div key={code} className="datePickerSelect">
        <label className={addClassName()}>
          <i className="fas fa-search" />
            <DatePicker
              ranges={[]}
              onOk={(date) => timeChange(date)}
              shouldDisableDate={afterToday()}
              value={startDate}
              preventOverflow
              isoWeek
              locale={datePickerLocale(translations, selectedLanguage)}
            />
        </label>
      </div>
    );
  }

  const selectMonthButton = (code) => {
    return(
      <DatePicker
        key={code}
        style={{ width: 125 }}
        format = "yyyy-MM"
        ranges = {[]}
        cleanable={false}
        oneTap
        onSelect={(month) => monthChange(month)}
        shouldDisableDate = {afterToday()}
        value = {startDate}
        placement = "topStart"
        preventOverflow
        locale={datePickerLocale(translations, selectedLanguage)}
      />
    );
  }

  const selectorButton = (code) => {
    let localNow = new Date();
    let defaultValue = new Date(startDate.getTime());
    const width = "80%";
    const marginLeft = "15%";
    return(
      <div key = {code} className = "timeButton-slider">
        <div
          id={code}
          className = "slider-text"
          style={{ width: width, marginLeft: marginLeft }}
        >
          <span>
            {`${defaultValue.toLocaleString("en-US", {timeZone: timeZone})}`}
          </span>
        </div>
        <Slider
          style = {{ width: width, marginLeft: marginLeft }}
          min = {localNow.getTime() - 86400 * 1000 * 2}
          max = {localNow.getTime()}
          step = {900000}
          defaultValue = {defaultValue.getTime()}
          tooltip = {false}
          progress
          graduated
          onChange={(e) => {
            document.getElementById(code).textContent = `${new Date(parseInt(e)).toLocaleString("en-US", {timeZone: timeZone})}`;
          }}
          onMouseUp={() => { timeChange(document.getElementById(code).textContent) }}
          renderMark={mark => {
            if (mark === localNow.getTime() - 86400 * 1000 * 2) { return sliderMark("-2d"); }
            else if (mark === localNow.getTime() - 86400 * 1000) { return sliderMark("-1d"); } 
            else if (mark === localNow.getTime()) { return sliderMark("now"); }
          }}
        />
      </div>
    );
  }
  
  const sliderMark = (text) => {
    return (
      <span style = {{ fontWeight: 800 }}> 
        {translate(text)}
      </span>
    );
  }

  const timeChange = (time) => {
    let value = null;
    /* timebutton (86400) */
    if (Number.isInteger(time)) {
      value = getTimeparams(time);
      time = parseInt(time);
    }
    /* timebutton (today) */
    else if (time === "today") {
      value = getTimeparams(todaysTimeParams(new Date()));
    }
    /* selectDateButton (Date Tue Mar 08 2022 10:03:27) */
    else if (time instanceof Date) {
      setStartDate(time)
      value = selectDateChange(time);
      time = "dayselect";
    } 
     /* dateRangeButton (Array [ Date Thu Feb 24 2022 10:03:27, Date Sat Feb 26 2022 10:03:27]) */
    else if (Array.isArray(time)) {
      value = { sdt: time[0].toISOString(), edt: time[1].toISOString()};
      time = "custom";
    }
    /* selectorButton */
    else {
      let date = new Date(time);
      let sdt = new Date(date.getTime() - 120000).toISOString();
      let edt = date.toISOString();
      value = { sdt, edt };
      setStartDate(date)
    }
    setSelectedTime(time);
    const auto = false;
    return onTimeChange({value, auto, time, update: Date.parse(new Date())});
  }

  const monthChange = (value) => {
    const auto = false;
    const time = "month";
    setStartDate(value);
    return onTimeChange({ value, auto, time });
  }

  const selectDateChange = (date) => {
    const today = new Date();
    let sdt = date;
    let edt = new Date(sdt);
    let edtSeconds;
    //adds 24hours to end date
    //unless end date is today
    if (edt.getTime() + 86400 * 1000 > today.getTime()) {
      edtSeconds = today.getTime();
    } else {
      edtSeconds = edt.getTime() + 86400 * 1000;
    }
    edt = new Date(edtSeconds).toISOString();
    sdt = sdt.toISOString();
    return { sdt, edt };
  }

  //Make 'custom'-button look like a checked radio button if clicked
  const addClassName = () => {
    if (selectedTime === "custom" || selectedTime === "dayselect") {
      return "customInputField selected";
    } else {
      return "customInputField";
    }
  }

  return(
    <form className = "timebuttons" style={{width: width, display: "inline-block"}}>
      {timeButtons && <CreateButtons/>}
      {timeWindow && !timeButtons.map(value => Number(value)).includes(timeWindow) && <span className="timeWindow">{timeButton(timeWindow)}</span>}
    </form>
  );
}
