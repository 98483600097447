import React from "react";
import { Modal } from "rsuite";
import { isFunction } from "lodash";

import { TagSearchTool } from "../tags";

export default function TagSearchModal(props) {
  const { 
    show, 
    onHide, 
    showData, 
    fetchedData,
    queryParams
  } = props;

  const handleClose = () => {
    if (isFunction(onHide)) {
      onHide();
    }
  }

  return(
    <Modal size="95%" open={show} dialogClassName="tagSearchModal" overflow={false} onClose={handleClose}>
      <Modal.Body>
        <TagSearchTool 
          showData={showData} 
          chartData={fetchedData}
          queryParams={queryParams}
          settings={showData} 
        />
      </Modal.Body>
    </Modal>
  );
}
