import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default function translate(id, value={}) {
  if (typeof id !== "undefined") {
    id = id.length > 0 ? id : " ";
    return <FormattedMessage id={id} values={{...value}} />;
  }
};

// Returns translation in string format. Used for placeholders for example.
export function useTranslateToString(id, values = {}) {
  const intl = useIntl();
  return intl.formatMessage({ id }, values);
}