export function areArraysSame(arr1, arr2) {
  let areArraysSame = true;
  if (Array.isArray(arr1) && Array.isArray(arr2)) {
    if (arr1.length === arr2.length) {
      for (let i = 0; i < arr1.length; ++i) {
        if (arr1[i] !== arr2[i]) {
          areArraysSame = false;
          break;
        }
      }
    } else {
      areArraysSame = false;
    }
  } else {
    areArraysSame = false;
  }
  return areArraysSame;
}

/**
 * Check if arrays are of equal length
 * @param {array} arrays array of arrays
 * @returns {boolean} *true* if each array in **arrays** parameter has the same length, otherwise *false*
 */
export function arraysAreOfEqualLength(arrays) {
  if (
    Array.isArray(arrays)
    && arrays.length > 0
  ) {
    const baseLength = arrays[0].length;
    if (!Array.isArray(arrays[0]) || baseLength === undefined) return false;
    if (arrays.length === 1) return true;

    for (let i = 1; i < arrays.length; ++i) {
      if (!Array.isArray(arrays[i]) || arrays[i].length !== baseLength) return false;
    }
    return true;
  }
  return false;
}

/**
 * Check if objects have arrays of equal length behind 'key' parameter.
 * Useful for merging arrays along time axis, for first checking if those
 * axes contain the same amount of data.
 * @param {array} dataObjects basic data objects
 * @param {string} key key in data object behind which data exists
 * @returns {boolean} true if all data arrays (behind key) are of equal length in each object
 */
export function arraysInObjectAreOfEqualLength(dataObjects, key) {
  if (
    Array.isArray(dataObjects)
    && dataObjects.length > 0
    && typeof key === "string"
  ) {
    const baseLength = dataObjects?.[0]?.[key]?.length;
    if (baseLength === undefined) return false;
    if (dataObjects.length === 1) return true;

    for (let i = 1; i < dataObjects.length; ++i) {
      if (dataObjects?.[i]?.[key]?.length !== baseLength) return false;
    }
    return true;
  }
  return false;
}

/**
 * Check if input is of data array shape:
 * [
 *   [ *timestamp*, *value* ],
 *   [ *timestamp*, *value* ],
 *   ...
 * ]
 * @param {array} array array to test
 * @param {number} minLength minimum length of the array, if less than minimum length -> return *false*
 * @returns {boolean} true if array id of data array shape
 */
export function arrayHasTSValArraysOnly(array, minLength=3) {
  if (
    Array.isArray(array)
    && array.length >= minLength
    && array.every(item => {
      if (
        Array.isArray(item)
        && item.length === 2
        && typeof item[0] === "number"
        && typeof item[1] === "number"
      ) {
        return true;
      } else {
        return false;
      }
    })
  ) {
    return true;
  } else {
    return false;
  }
}

/**
 * Check if an array of data contains only epoch timestamps in a given axis
 * @param {array} array data array
 * @param {number} axis timestamp axis
 * @returns {boolean} true if data array contains only epoch timestamps in given axis
 */
export function dataArrayAxisContainsEpochTimestamps(array, axis=0) {
  if (
    Array.isArray(array)
    && array.length > 1
    && array.every(item => {
      if (
        Array.isArray(item)
        && axis <= item.length - 1 
        && typeof item[axis] === "number"
        && item[axis] > 946684800000 // later than at the start of year 2000
      ) {
        return true;
      } else {
        return false;
      }
    })
  ) {
    return true;
  } else {
    return false;
  }
}
