import CryptoJS from "crypto-js";

/**
 * Function encrypts a message with a key environment variable.
 * Advanced encryption standard (AES) is the de facto encryption
 * method used at the time of writing.
 * @param {any} message message to encrypt
 * @returns {string} encrypted message
 */
export function encrypt(message) {
  return CryptoJS.AES.encrypt(JSON.stringify(message), process.env.REACT_APP_ENCRYPTION_KEY).toString();
}

/**
 * Hashes a message using the MD5 hashing algorithm
 * @param {any} message message to hash
 * @returns {string} hashed message
 */
export function hash(message) {
  return CryptoJS.MD5(message).toString(CryptoJS.enc.Base64);
}
