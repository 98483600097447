/* built-in packages */
import React, { useState } from "react";

/* 3rd-party packages */
import { Modal, DatePicker, Button } from "rsuite";
import { isFunction } from "lodash";

/* self-provided packages */
import { translate } from "../languages";

export default function UtilityModal(props) {
  const {
  /* Basic props to component, with defaults. */
    show = null,
    size = "xs",
    titleText = "Confirmation",
    bodyText = "Are you sure?",
    confirmButtonText = "Yes",
    cancelButtonText = "Cancel",
    confirmCallback = null,
    displayCallback = null,
    
    /* Time picker props which alter the Modal's contents. */
    timePicker = null,
    timePickerText = null
  } = props;

  const [pickedDatetime, setPickedDatetime] = useState(new Date("1970-1-1 00:00:00"));

  /* Return value, is suspect to changing on function execution. */
  // let returnValue = true;
  const [returnValue, setReturnValue] = useState(true);

  function extractTime(datetime) {
    let ret = null;
    
    /* Check if parameter is a Date object. */
    if (isFunction(datetime.getHours)) {
      const hours = datetime.getHours();
      const minutes = datetime.getMinutes();
      const seconds = datetime.getSeconds();
      ret = 3600 * hours + 60 * minutes + seconds;
    }

    return ret;
  }

  function getDatePicker() {
    let timePickerDiv = null;
    
    if (timePicker) {
      timePickerDiv = (
        <div className="text-and-input-container">

          <div>
            <DatePicker
              format="HH:mm:ss"
              hideSeconds={second => second % 5 !== 0}
              onSelect={datetime => {
                setPickedDatetime(datetime);
                const extractedSeconds = extractTime(datetime);
                if (typeof extractedSeconds === "number") {
                  setReturnValue(extractedSeconds);
                } 
              }}
              defaultValue={pickedDatetime}
              ranges={[]}
            />
          </div>

          <div className="helper-text-inline-container">
            <p className="helper-text-inline">
              {translate(timePickerText)}
            </p>
          </div>

        </div>
      );
    }
    
    return timePickerDiv;
  }

  const modalContent = () =>{
    return(
      <React.Fragment>
        <Modal.Header closeButton={false} />

        <Modal.Title>
          {translate(titleText)}
        </Modal.Title>

        <Modal.Body>
          <div data-testid="utility-modal-text-container">

            <p className="helper-text-above">
              {translate(bodyText)}
            </p>

            {getDatePicker()}

          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="button"
            appearance="primary" 
            color="green"
            onClick={() => {
              if (isFunction(confirmCallback)) confirmCallback(returnValue);
              if (isFunction(displayCallback)) displayCallback(false);
            }}
            data-testid="utility-modal-confirm-button"
          >
            {translate(confirmButtonText)}
          </Button>

          <Button
            type="button"
            appearance="primary" 
            color="red"
            onClick={() => {
              if (isFunction(displayCallback)) displayCallback(false);
            }}
            data-testid="utility-modal-cancel-button"
          >
            {translate(cancelButtonText)}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
  return (
    typeof show === "boolean" ? ( 
      <Modal
        backdrop="static"
        open={show}
        size={size}
      >
        {modalContent()}
      </Modal>
    ) : (
      modalContent()
    )
  );
}
