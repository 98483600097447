import React from 'react';
import { toaster, Notification } from "rsuite";
import { capitalize } from "lodash";

export function popNotification(options) {
  const {
    type = "info",
    text = "",
    placement = "topEnd"
  } = options;

  toaster.push(
    <Notification
      type={type}
      header={capitalize(type)}
      closable
    >
      {text}
    </Notification>,
    {
      placement
    }
  );
}
