import { translate } from "../languages";

export function datePickerLocale(translations, selectedLanguage) {
  const translated = translations.find(translation => translation.code === selectedLanguage).translations;
  const defaultWeekdays = [ "Su", "Mo", "Tu", "We", "Th", "Fr", "Sa" ];
  const weekdays = translated.weekdays_short || defaultWeekdays;

  return({
    sunday: weekdays[0],
    monday: weekdays[1],
    tuesday: weekdays[2],
    wednesday: weekdays[3],
    thursday: weekdays[4],
    friday: weekdays[5],
    saturday: weekdays[6],
    ok: translate("ok"),
    today: translate("Today"),
    yesterday: translate("Yesterday"),
    hours: translate("Hours"),
    minutes: translate("Minutes"),
    seconds: translate("Seconds"),
    last7Days: translate("Last 7 days"),
    formattedMonthPattern: 'MM yyyy',
    formattedDayPattern: 'dd.MM.yyyy',
  });
}
