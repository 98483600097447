/* built-in packages */
import React, {useContext } from "react";
/* 3rd-party modules */
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import Histogram from "highcharts/modules/histogram-bellcurve.js";
import { Loader } from "rsuite";
/* self-provided packages */
import {
  HighchartOptions,
  removeSeriesTimestamps
} from "../../utils";
import { translate } from "../../languages";
import { SiteContext, LanguageContext } from "../../context";

Histogram(Highcharts);
HighchartsMore(Highcharts);

export default function HistogramChart(props) {
  const {
    chartData,
    loading,
    hiddenSeries
  } = props;

  const { timeZone }                        = useContext(SiteContext);
  const { translations, selectedLanguage }  = useContext(LanguageContext);

  HighchartOptions(
    Highcharts,
    selectedLanguage,
    timeZone,
    translations
  );


  const getSeries = () => {
    const visibleData = chartData.filter(series => !hiddenSeries.includes(series.id));
    const histogramData = [];
    visibleData.forEach(series => histogramData.push(removeSeriesTimestamps(series.data)));
    const data = visibleData.length > 0 ? [{
      name: 'Histogram',
      type: 'histogram',
      xAxis: 1,
      yAxis: 1,
      baseSeries: "all",
      zIndex: -1,
      color: "#7cb5ec"
    }] : []
    visibleData.forEach((dataSeries, index) => data.push({
      name: dataSeries.description,
      type: 'scatter',
      showInLegend: false,
      color: dataSeries.color,
      data: removeSeriesTimestamps(dataSeries.data),
      id: `series${index}`,
      marker: { radius: 2 }
    }));
    if (visibleData.length > 0 ) {
      data.push({
        name: "All",
        type: 'scatter',
        data: histogramData.flat(),
        id: `all`,
        showInLegend: false,
        visible: false
      });
    }
    return data;
  }

  const getOptions = () => {

    const options = {
      title: {
          text: ''
      },
      xAxis: [
        {
          title: { text: 'Data' },
          alignTicks: false
        }, 
        {
          title: { text: 'Distribution' },
          alignTicks: false,
          opposite: true
        }
      ],
      yAxis: [
        {
          title: { text: 'Data' }
        }, 
        {
          title: { text: 'Occurences of Data' },
          opposite: true
        }
      ],
      plotOptions: {
        histogram: {
          accessibility: {
            point: {
              valueDescriptionFormat: '{index}. {point.x:.3f} to {point.x2:.3f}, {point.y}.'
            }
          }
        }
      },
      series: getSeries()
    };
    return(options);

  }


  const getLoadingBlur = () => {
    return(
      <div style={{ backgroundColor: "rgba(242, 242, 242, 0.7)", position: "absolute", height: "100%", width: "100%", zIndex:"10" }}>
        <div className="d-flex align-items-center justify-content-center h-100">
          <div style={{ display: "inline-block" }}>
            <Loader size="lg" />
          </div>
          <div className="d-inline-block ms-4" style={{ fontSize:"3rem" }}>{translate("Loading data")}</div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ position: "relative" }}>
      {loading && getLoadingBlur()}
      <HighchartsReact
        highcharts={Highcharts}
        options={getOptions()}
      />
    </div>
  );
}

