import React, { useState, useEffect, useContext } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Link } from "react-router-dom";
import { OverviewItem } from ".";
import TagSearchModal from "../modals/tagSearchModal";
import { UseAuthenticationContext, SiteContext } from "../context";
import { Equations } from "../grid";

export default function Overview(props) {
  const {
    overview,
    sources,
    layouts,
    toggleOptions,
    fetchedData,
    queryParams,
    socket_values,
    socket,
    time
  } = props;

  const { useAuthentication } = useContext(UseAuthenticationContext);
  const { timeZone, site } = useContext(SiteContext);

  const [show, setShow] = useState(false);
  const [showData, setShowData] = useState(null);
  const [webSocketData, setWebSocketData] = useState(fetchedData);
  
  const currentData = socket_values.current ? webSocketData : fetchedData;
  const overview_items = overview.items ?? [];
  const items = Array.isArray(overview_items) ? overview_items : Object.values(overview_items);

  const constructAlignmentData = (options) => {
    let ticks = options.tickPositions;
    let tags = options.tags;
    const data = [];
    for (let i = 0; i < ticks.length; i++) {
      let tagId = tags[i];
      let tag = null;
      let tagValue = null;
      if (tagId !== null) { // when tagId is null value should be 0, which is the starting and ending point
        tag = currentData.find(data =>  data.id === tagId);
        if (typeof tag === "undefined") {
          tagValue = "-";
        } else if (tag.data[0]) {
          tagValue = tag.data[0][1];
        } else {
          tagValue = "-";
        }
      } else {
        tagValue = 0;
      }
      if (tagValue !== 0 && options.divide) { tagValue = tagValue / options.divide }
      data.push( [ticks[i], tagValue ] );
    }
    return data;
  }

  useEffect(()=> {
    if (socket_values?.current && socket?.api) {
      let newData = fetchedData;
      socket_values.current.on(socket.api, (data) => {
        let dataObject = typeof data === "string" ? JSON.parse(data) : data;
        let index = newData.findIndex(data => data.name === dataObject.name);
        if (index >= 0) {
          newData[index].data = [dataObject.data];
          setWebSocketData([...newData])
        }
      });
    }
  }, [socket_values, fetchedData, socket?.api ]);

  let imageUrl = window.location.origin;
  if (useAuthentication) {
    /* Replace 'localhost' with the correct dev URI */
    if (
      window.location.hostname === "localhost"
      || window.location.hostname === "127.0.0.1"
    ) {
      imageUrl = process.env.REACT_APP_DEV_URI;
    }
  } else {
    imageUrl = process.env.PUBLIC_URL;
  }
  let backgroundImageUrl = `${imageUrl}${overview?.background?.image}`;

  const filterFetchedData = (tags) => {
    /* tags are either inside an array or inside an object */
    let tagNames = []; // filtering requires the tag names in array
    if (Array.isArray(tags)) { tagNames = tags; } // ["tag_1", "tag_2"]
    else { tagNames = Object.values(tags).map(variable => variable.tag); } // {"x": {"tag": "tag_1", "choose": "last"}}
    /* After the tag names array is ready, correct series can be added to cell's series array */
    let series = [];
    for (let tag of tagNames) {
      if (fetchedData.some(data => data.seriesName === tag)) {
        series.push(fetchedData.find(data => data.seriesName === tag));
      } else if (fetchedData.some(data => data.id === tag)) {
        series.push(fetchedData.find(data => data.id === tag));
      } else if (fetchedData.some(data => data.name === tag)) {
        series.push(fetchedData.find(data => data.name === tag));
      }
    }
    /* return series object if only 1 tag, otherwise return series array */
    return tagNames.length === 1 && series.length > 0 ? series[0] : series;
  }

  const getStatusColor = (value, limits) => {
    if (limits) {
      for (let limit of Object.entries(limits)) {
        let colorCode = limit[0];

        /* Nicer color codes */
        if (colorCode.includes("green")) { colorCode = "#28a745" }
        if (colorCode.includes("yellow")) { colorCode = "#ffc107" }
        if (colorCode.includes("red")) { colorCode = "#dc3545" }

        let colorLimits = limit[1];
        if (value >= colorLimits.min && value <= colorLimits.max) {
          return { code: colorCode, name: limit[0] };
        }
      }
    }
    /* transparent if value is out of limits range */
    return { code: "transparent", name: "transparent" };
  }

  const objects =  currentData.length > 0 &&
    items.map((item, index) => {
      /** Define current data value of the current item. */
      let currentValue;
      if (sources.series) { //get value
        let itemDataSeries = [];
        if (item.tags) {itemDataSeries = filterFetchedData(item.tags) }
        else if (item.tag) { itemDataSeries = filterFetchedData([item.tag]) }
        const singleDataSeries = Array.isArray(itemDataSeries) ? false : true;
        const dataSet = Array.isArray(itemDataSeries) ? itemDataSeries.map(series => series.data) : itemDataSeries.data;
        if (item.calculate || item.choose) { currentValue = Equations(dataSet, item, singleDataSeries, time, timeZone); }
        else { currentValue = Equations(dataSet, {choose: "last"}, singleDataSeries, time, timeZone); }
        if (typeof(currentValue) !== "string") { 
          let decimals = item.decimals || 0; 
          currentValue = parseFloat(currentValue).toFixed(decimals); 
        }
      } 
      let currentColor; // Color based on the values and thresholds.
      let limits = item.limits;
      /** Get correct color for status ball, basd on type of the item. */
      if (currentValue === undefined) {
        currentColor = {code: "transparent", name: "transparent"};
      } else {
        currentColor = getStatusColor(currentValue, limits);
      }
      /** Wrap the component inside the link if needed. */
      const component = (
        <OverviewItem
          item={item}
          color={currentColor}
          value={currentValue}
          layouts={layouts}
          imageUrl={imageUrl}
          toggles={toggleOptions}
          socket_values={socket_values}
          socket={socket}
        />
      );
      return (
        <React.Fragment key={index}>
          {item.alignment_line ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={    
                { 
                  chart: {
                    width: item.alignment_line.width,
                    marginTop: item.alignment_line.marginTop ?? 0,
                    backgroundColor: 'rgba(0,0,0,0)',
                    type: 'spline',
                    style: item?.alignment_line?.css
                  },

                  title: {
                    text: null
                  },
                  tooltip: {
                    enabled: false
                  },
                  xAxis: {
                    tickPositions: item.alignment_line.tickPositions,
                    visible: false,
                    labels: {
                      enabled:false
                    }
                  },
                  yAxis: {
                    min: item.alignment_line.yMin,
                    max: item.alignment_line.yMax,
                    title: null,
                    offset: item.alignment_line.offset,
                    gridLineWidth: 0,
                    labels: {
                      enabled:false
                    }
                  },
                  series: [
                    {
                      data: constructAlignmentData(item.alignment_line),
                      color: 'red',
                      dashStyle: 'shortdash',
                      showInLegend: false
                    }
                  ]

                }
              }
            />
          ) : (
            <React.Fragment>
              {typeof currentColor === "object" && !item.link && !item.showData && component}
              {typeof currentColor === "object" && item.link && !item.showData && (
                <Link to={item.link}>{component}</Link>
              )}
              {typeof currentColor === "object" && !item.link && item.showData && 
                <div className="pointer showData" onClick={()=> {setShowData(item.showData); setShow(true)} } >
                  {component}
                </div>
              }
            </React.Fragment>
          )}
        </React.Fragment>
      );
    });

  let css = {
    ...{
      width: "100%",
      height: "100%",
      position: "relative",
      backgroundImage: `url(${backgroundImageUrl})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: overview?.background?.position ?? "left center",
      backgroundSize: overview?.background?.size ?? "contain"
    },
    ...overview.css
  }

  return (
    <div className="m-0 overview" style={css}>
      {objects}
      <TagSearchModal 
        show={show} 
        onHide={()=>setShow(false)} 
        site={site}
        showData={showData}
        fetchedData={fetchedData}
        queryParams={queryParams}
      />
    </div>
  );
}
