import React from 'react';
import { Button, Modal } from 'rsuite';
import { translate } from "../languages";

const NotificationPopup = ({ show, onClose, notification }) => {
  
  const getIcon = (type) => {
    switch (type) {
      case 'info':
        return <i className="fas fa-info-circle me-2" style={{ color: '#2196f3' }} />;
      case 'warning':
        return <i className="fas fa-exclamation-triangle me-2" style={{ color: '#ffb300' }} />;
      case 'error':
        return <i className="fas fa-exclamation-circle me-2" style={{ color: '#f44336' }} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      backdrop={true}
      open={show}
      onClose={onClose}
    >
      {notification.header &&
        <Modal.Header>
          <Modal.Title> 
            {notification.header.type && getIcon(notification.header.type)}
            {notification.header.text && <span>{notification.header.text}</span>}
          </Modal.Title>
        </Modal.Header>
      }

      <Modal.Body style={{ flex: 1, padding: '0 20px' }}>
        {notification?.content?.map((content, index) => (
          <div key={index}>
            {content.title && <h5 style={{ marginBottom: '10px' }}>{content.title}</h5>}
            {content.sections &&
              content.sections.map((section, index) => (
                <div key={index}>
                  <h6
                    style={{ 
                      marginBottom: "10px",
                      marginLeft: "10px"
                    }}
                  >
                    {section.title}
                  </h6>
                  <p 
                    style={{ 
                      marginBottom: "20px",
                      marginLeft: "20px"
                    }}
                  >
                    {section.text}
                    {section.link && (
                      <a href={section.link.url} target="_blank" rel="noopener noreferrer">
                        {section.link.text}
                      </a>
                    )}
                  </p>
                </div>
              ))
            }
          </div>
        ))}
      </Modal.Body>

      <Modal.Footer style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 10px' }}>
        {notification.readMore &&
          <Button 
            onClick={() => window.open(notification.readMore, '_blank') } 
            appearance="primary" 
            style={{ marginRight: '10px' }}
          >
            {translate("Find out more")}
          </Button>
        }
        <Button 
          onClick={() => onClose()} 
          appearance="ghost" 
          style={{ border: '1px solid #ccc' }}
        >
          {translate("Got it")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NotificationPopup;