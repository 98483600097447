import React, { useContext } from 'react';
import { Input } from 'rsuite';
import { useTranslateToString } from "../languages";
import { UseAuthenticationContext } from '../context';

import ToggleVisibilityButton from './toggleVisibilityButton';
import ChartList from './chartList';
import TagList from './tagList';
import TagCustomizer from './tagCustomizer';

const SharedOptions = (props) => {
    const { 
        endpoints, 
        defaultValues, 
        screenSize, 
        onChange,
        tags
    } = props;

    const { useAuthentication } = useContext(UseAuthenticationContext);

    const handleChange = (key, value) => {
      if (key === 'tagPicker' || key === 'customizeTags') {
        onChange(key, value);
      } else {
        onChange(key, { ...defaultValues, [key]: value });
      }
    };

    const placeholderChartName = useTranslateToString('Chart name');

    const bigScreen = screenSize > 750;

    return (
      <>
        <div className="flex-container">
          <div className={"flex-item"}>
            <Input
              className="input"
              placeholder={placeholderChartName}
              defaultValue={defaultValues?.name}
              onChange={(value) => handleChange("name", value)}
              style={{ minWidth: 150, flex: 1 }}
            />
            <ChartList
              className="chart-list"
              defaultValue={defaultValues?.type}
              onChange={(value) => handleChange("type", value)}
              style={{ minWidth: 150, flex: 1 }}
            />
            {useAuthentication && 
              <ToggleVisibilityButton
                className="toggle-visibility-button"
                defaultValue={defaultValues?.isPublic}
                onClick={(value) => handleChange("isPublic", value)}
                style={bigScreen ? { width: 130 } : { flex: 1} }
              />
            }
            <TagCustomizer
              style={bigScreen ? { width: 130 } : { flex: 1} }
              screenSize={screenSize}
              tags={defaultValues?.tags}
              endpoint={defaultValues?.endpoint}
              onChange={(value) => handleChange("customizeTags", value)}
              chartType={defaultValues?.type}
            />
          </div>
        </div>
  
          {endpoints && endpoints.map(obj => 
            <TagList
              key={obj.endpoints.tags}
              className="tag-list"
              defaultTags={defaultValues?.tags?.[obj.endpoints.tags] || []}
              endpoint={obj.endpoints.tags}
              tags={tags || []}
              onChange={(value) => handleChange("tagPicker", { [obj.endpoints.tags] : value })}
            />
          )}
      </>
    );
};

export default SharedOptions;