import React, { useRef, useEffect, useContext } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { SiteContext, LanguageContext } from "../../context";
import { HighchartOptions } from "../../utils";

export default function SingleMultiline(props) {
  const {
    trendsInCharts,
    data,
    handleZoom
  } = props;

  const { timeZone }                        = useContext(SiteContext);
  const { translations, selectedLanguage }  = useContext(LanguageContext);

  const chartRef = useRef(null);

  HighchartOptions(
    Highcharts,
    selectedLanguage,
    timeZone,
    translations
  );

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current.chart;
      const yAxisLabels = chart.yAxis.map(axis => axis?.labelGroup?.element);

      chart.container.addEventListener('wheel', handleMouseWheel, { passive: false });

      function handleMouseWheel(e) {
        const cursorX = e.clientX;
        const cursorY = e.clientY;

        const yAxisIndex = getYAxisIndex(cursorX, cursorY, yAxisLabels);
        if (yAxisIndex >= 0) {
          e.preventDefault();
          const axis = chart.yAxis[yAxisIndex];
          const currentMax = axis.max;
          const scrollAmount = e.deltaY > 0 ? 1 : -1; // Increase or decrease the max value based on scroll direction, negative = up, positive = down

          const newMax = scrollAmount > 0 ? currentMax - axis.tickInterval : currentMax + axis.tickInterval;
          if (newMax) { axis.setExtremes(null, newMax); }
        }
      }

      function getYAxisIndex(cursorX, cursorY, yAxisLabels) {
        for (let i = 0; i < yAxisLabels.length; i++) {
          const yAxisLabelBounds = yAxisLabels[i].getBoundingClientRect();

          if (isCursorWithinElementBounds(cursorX, cursorY, yAxisLabelBounds)) { return i; }
        }
    
        return -1;
      }

      function isCursorWithinElementBounds(cursorX, cursorY, elementBounds) {
        const { left, top, width, height } = elementBounds;
        const right = left + width;
        const bottom = top + height;
        return cursorX >= left && cursorX <= right && cursorY >= top && cursorY <= bottom;
      }
    }
  }, [trendsInCharts, data]);

  const getYAxisRange = (oneChartData) => {
    let yAxisMin, yAxisMax;
    let yAxisStartOnTick = true;

    if (
      "min" in oneChartData
      && Number.isInteger(oneChartData.min)
    ) {
      yAxisMin = oneChartData.min;
      yAxisStartOnTick = false;
    }

    if (
      "max" in oneChartData
      && Number.isInteger(oneChartData.max)  
    ) {
      yAxisMax = oneChartData.max;
      yAxisStartOnTick = false;
    }

    return [yAxisMin, yAxisMax, yAxisStartOnTick];
  }

  const getYaxis = () => {
    let yAxis = [];
    if (Array.isArray(data) && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const [yAxisMin, yAxisMax, yAxisStartOnTick] = getYAxisRange(data[i]);
          const currentYAxis = {
            title: {
              text: null,
            },
            opposite: i === 1 ? true : false,
            index: i,
            min: yAxisMin,
            max: yAxisMax,
            startOnTick: yAxisStartOnTick
          };
          currentYAxis.labels = {
            formatter: function() {
              let labelText;
              if (Number.isInteger(this.value)) { labelText = this.value }
              else { labelText = this.value.toFixed(2) }
              if (data[i].unit) { labelText = `${labelText} ${data[i].unit}` }
              return labelText;
            },
            style: {
              color: data[i].color
            }
          };
  
          if (i > 0) {
            currentYAxis.gridLineWidth = 0;
          }
  
          yAxis.push(currentYAxis);
  
        }
      }
  
      if (yAxis.length === 0) {
        yAxis = [{ opposite: true }];
      }
  
      return yAxis;
  
  }

  const zoomData = async (event) => {
    if (event.xAxis) {   
      handleZoom(
        {
          sdt: new Date(event.xAxis[0].min).toISOString(),
          edt: new Date(event.xAxis[0].max).toISOString()
        }, 
        true
      );
    }
  }

  const getOptions = () => {
    const yAxis = getYaxis();
    const options = {
      chart: {
        height: "250px",
        zoomType: "x",
        type: "line",
        className: "chart",
        marginLeft: trendsInCharts === 3 ? 120 : 100,
        marginRight: trendsInCharts === 1 ? undefined : 75,
        events: {
          selection: (e) => zoomData(e)
        }
      },
      title: {
        text: ""
      },
      xAxis: {
        crosshair: {
          width: 1,
          color: "red"
        },
        type: "datetime",
        events: {}
      },
      yAxis: yAxis,
      tooltip: {
        shared: true,
        valueDecimals: 2,
        backgroundColor: "#f7f7f7",
        className: "multilineTooltip",
        positioner: function(labelWidth, labelHeight, point) {
          let tooltipX, tooltipY;
          if (point.plotX > this.chart.chartWidth - labelWidth ) { tooltipX = this.chart.chartWidth - labelWidth }
          else { tooltipX = point.plotX; }
          tooltipY = this.chart.chartHeight - this.chart.xAxis[0].bottom;
          return {
            x: tooltipX,
            y: tooltipY
          };
        },
        useHTML: true,
        headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
        pointFormat: '<tr><td style="color: {series.color}">{series.name} </td>' +
            '<td style="text-align: right"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
      },
      legend:{
        layout: "vertical"
      },
      series: data
    }
    return options;
  }

  return(
    <HighchartsReact
      key={trendsInCharts}
      highcharts={Highcharts} 
      options={getOptions()}
      ref={chartRef} 
    />
  );
};
