import React, { useEffect, useContext, useState } from "react";

import {
  LangProvider,
  locales,
  translate
} from "../languages";

import { Badge, Button, List, Container, Sidebar, Header, Content, Message } from "rsuite";
import { 
  SiteContext,  
  LanguageContext
} from "../context";

export default function SiteChangeLog(props) {
  const { 
    changelog,
    style 
  } = props;
  
  const [showChangeLog, setShowChangeLog]       = useState(false);
  const [higlightedItems, setHighlightedItems]  = useState(false);
  const [showError, setError]  = useState();
  
  const { site }                            = useContext(SiteContext);
  const { translations, selectedLanguage }  = useContext(LanguageContext);

  const siteChangeLogCode = site + "_siteChangeLog";

  useEffect(() => {
    try {
      if (localStorage.getItem(siteChangeLogCode)) { setHighlightedItems(changelog.filter(list => new Date(list.date).getTime() > new Date(localStorage.getItem(siteChangeLogCode)).getTime()).length); } 
      else { setHighlightedItems(changelog.length); }
    } catch (error) { setError("error in changelog"); }
  }, [siteChangeLogCode, changelog] );

  const toggleShowChangeLog = () => {
    try {
      let siteChangeLogCode = site + "_siteChangeLog";
      localStorage.setItem(siteChangeLogCode, changelog[0].date);
      if (showChangeLog) { setHighlightedItems(false); }
    } catch (error) { setError("error in changelog"); }
    setShowChangeLog(!showChangeLog);
  }

  const ToggleButton = () => {
    return(
      <Button
        onClick={() => toggleShowChangeLog()}
        className = "statusBlockButton"
      >
        {translate("Site Changelog")}
        <i className = {showChangeLog ? "fas fa-angle-up ms-2" : "fas fa-angle-down ms-2" }></i>
      </Button>
    );
  }

  /**
   * Wraps toggle button inside badge component.
   * Badge has a red dot when one of the tags have "red" status.
   */
  const ToggleButtonWrapper = () => {
    return(
      <Badge color="blue" content = {higlightedItems > 5 ? "5+" : higlightedItems}>
        <ToggleButton />
      </Badge>
    );
  }

  /* StatusList shows all the tags and their statuses: "green"/"red". */
  const StatusList = () => {
    return(
      <div className="statusBlockList" style={{ padding: 0 }}>
        {showError ? (
          <Message showIcon type="error">{showError}</Message>
        ) : (
          <List>
            {changelog.slice(0,5).map((item, index) => {
              let rowClass = index < higlightedItems ? "siteChangelogHighlightedRow" : "siteChangelogRow";
              return (
                <List.Item key={"siteChanglogItem" + index} className={rowClass}>
                  <Container>
                    <Sidebar style={{flex:"none", width:"40%", maxWidth: "260px"}}>
                      <div className="text-center">
                        {new Date(item.date).toLocaleDateString()}
                      </div>
                    </Sidebar>
                    <Container>
                      <Header>{item.panel}</Header>
                      <Content>{item.content}</Content>
                    </Container>
                  </Container>
                </List.Item>
              );
            })}
          </List>
        )}
      </div>
    );
  }

  /**
   * Render ToggleButton without badge component when statusArray is not fetched yet
   * Render StatusList when showStatusList is true
   */
  return (
    <LangProvider
      translations={translations}
      locale={locales[selectedLanguage]}
    >   
      <div key={higlightedItems} style={style}>
        {higlightedItems ? <ToggleButtonWrapper/> : <ToggleButton/>}
        {showChangeLog && <StatusList/>}
      </div>
    </LangProvider>
  );
}
