import React from "react";

import { useMsal } from "@azure/msal-react";
import { Button } from "react-bootstrap";

import {
  LangProvider,
  locales,
  translate
} from "../languages";

export default function LoginButton(props) {
  const { instance} = useMsal();

  function login() {
    instance.loginRedirect();
  }

  function getButtonContent() {
    return(
      <Button
          className="btn-margin"
          id="btn-login"
          variant="primary"
          onClick={login}
        >
          <i
            className="fab fa-windows me-2"
            onClick={login}
          />
          {translate("Log in with Azure AD")}
        </Button>
      );
  }

  return (
    <LangProvider
      translations={props.translations}
      locale={locales[props.selectedLanguage]}
    >
      {getButtonContent()}
    </LangProvider>
  );
}
