import React, { createContext, useState } from "react";

/**
 * Context containing information about
 * the current site.
 */
const SiteContext = createContext();

/**
 * Context provider for setting and retrieving site
 * @param {any} props props
 * @returns context provider
 */
function SiteProvider(props) {
  const [site, setSite] = useState(null);
  const [timeZone, setTimeZone] = useState(null);

  return (
    <SiteContext.Provider
      value={{
        site,
        setSite,
        timeZone,
        setTimeZone
      }}
    >
      {props.children}
    </SiteContext.Provider>
  );
}

export { SiteContext, SiteProvider };