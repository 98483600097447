// built-in modules
import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

// self-provided modules
import {
  makeAuthUrlWithHeaders,
  logErrorIfDevelopmentMode,
  acquireToken
} from "../utils";
import { withAllContext } from "../context";

class TrafficLight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null
    };
  }

  async componentDidMount(){
    let headers = {};
    let url = this.props.options.source;
    /* Modify request URL in case authentication is used */
    if (this.props.useAuthentication) {
      [url, headers] = await makeAuthUrlWithHeaders(
        url, 
        headers, 
        acquireToken(this.props.msalContext)
      )
    }
    try {
      await axios({
        method: "get",
        url: url,
        headers: headers
      })
      .then(response => {
        this.setState({ data: response });
      })
    } catch(e) {
      logErrorIfDevelopmentMode(e);
    }
  }

  render() {
    if (this.state.data) {
      let lightColor;
      for (let color in this.props.options.colors) {
        if (
          this.state.data.data[0] && this.state.data.data[0].data[0] && (this.state.data.data[0].data[0][1] <= this.props.options.colors[color].max)
          &&   this.state.data.data[0] && this.state.data.data[0].data[0] && (this.state.data.data[0].data[0][1] >= this.props.options.colors[color].min)
        ) {
          lightColor = color;
        }
      }
      return (
        <div className="z-index-1" style={this.props.options.css}>
          <Link to={this.props.options.event}>
            <h4>{this.props.options.title}</h4>
          </Link>
          <img
            style={{
              width: "40px",
              margin: "auto",
              textAlign: "center",
              display: "block"
            }}
            src={`/media/trafficlight/${lightColor}.png`}
            alt="traffic light"
          />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default withAllContext(TrafficLight);
