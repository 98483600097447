import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  MsalContext
} from "@azure/msal-react";

import { SiteSelect } from ".";
import { SettingsButton, HeaderButton } from "../iconButtons";
import NotificationPopup from "../modals/UpdateNotificationPopup"; // Import the NotificationPopup component

import { MetrisLogo1 } from "../img";
import {
  NarrowScreenContext,
  UseAuthenticationContext,
  TrackingContext
} from "../context";
import { fetchDataHelper } from "../utils";

const HeaderNav = (props) => {
  const {
    siteList = [],
    onSiteChange = null,
    isAuthenticated = false,
    site = null,
    showMap,
    site_info = null
  } = props;

  const [notification, setNotification] = useState();
  const [showNotification, setShowNotification] = useState(false);

  const { isNarrowScreen }    = useContext(NarrowScreenContext);
  const { useAuthentication } = useContext(UseAuthenticationContext);
  const { track }             = useContext(TrackingContext);
  const msalContext           = useContext(MsalContext);

  const history = useHistory();

  const setNotificationHeader = (notification) => {
    setNotification(notification.data)
  }

  /* Show notification only during the time range defined in the notification config
   * when user closes the notification, it will not be shown again until end time changes
   */
  useEffect(() => {
    const checkTimerange = () => {
      if (notification.visible) { // time range exists
        /* If notification end time is stored in localStorage (notification that has been closed previously)
         * and it is the same as the current notification end time, do not show the notification 
         */
        if (Number(localStorage.getItem("notification")) === notification.visible.end) { return false; }
        /* If notification start time is less than current time 
         * and end time is greater than current time, show the notification 
         */
        const currentTime = new Date().getTime();
        if (notification.visible.start < currentTime && notification.visible.end > currentTime) { return true; }
        else { return false; }
      } else { return false; }
    }

    if (notification) { // notification is fetched
      setShowNotification(checkTimerange());
    }

  }, [notification] )

  /* Fetch notification */
  useEffect(() => {
    if (useAuthentication && msalContext.accounts.length > 0) {
      fetchDataHelper({
        url: `/boa/api/v1/notifications`,
        method: "GET",
        useAuthentication,
        authContext: msalContext,
        onSuccess: setNotificationHeader,
      });
    }
  }, [useAuthentication, msalContext] )

  const getIconButtons = () => {
    if (site_info) { // site is selected
      return (
        <div className="d-flex">
          <div className="navbar-icons" style={{ position:"absolute", right:"10px", top:"10px" }}>
            {isAuthenticated &&
              <div className="iconBackground me-1">
                <HeaderButton 
                  tooltip = "Map view"
                  icon = "fa-globe-americas"
                  onClick = {showMap}
                />
              </div>
            }
            <div className="iconBackground">
              <SettingsButton
                isAuthenticated={isAuthenticated}
                useAuthentication={useAuthentication}
                languages={site_info?.languages}
                systemInfo={site_info?.systemInfo}
                grafana={site_info?.grafana}
                homeClick={ () => history.push(history.location.pathname) }
              />
            </div>
          </div>
        </div>
      );
    } else { // map and log in pages
      return(
        <div className="d-flex">
          <div className="navbar-icons" style={{ position:"absolute", right:"10px" }}>
            <div className="iconBackground">
              <SettingsButton
                isAuthenticated={isAuthenticated}
                useAuthentication={useAuthentication}
                languages={site_info?.languages}
                systemInfo={site_info?.systemInfo}
                grafana={site_info?.grafana}
              />
            </div>
          </div>
        </div>
      );
    }
  }

  const navbarBrandClass = "navbar-brand";
  const MetrisLink = () => {
    return (
      <div>
        <img className="metris-logo" src={MetrisLogo1} alt="metris-logo" />
        <span>
          <b>Metris</b>
        </span>
        <label id="site-details">
          {site_info ? site_info.product : "BOA"} 
        </label>
      </div>
    );
  }

  return (
    <nav
      className="navbar"
      role="navigation"
      data-testid="navbar-main-nav"
    >
    <div className={`navbar-header d-flex`}>
      {site ? (
        <Link
          className={`${navbarBrandClass}`}
          to={`/${site}/home`}
          onClick={() => {
            track(
              {
                type: "page_change",
                action: "click",
                source: "site_name",
                target: "grid_selection",
                site: site
              },
              useAuthentication,
              msalContext
            )
          }}
          data-testid="navbar-metris-logo"
        >
          <MetrisLink />
        </Link>
      ) : (
        <a
          className={`${navbarBrandClass}`}
          href="/"
          data-testid="navbar-brand"
        >
          <MetrisLink />
        </a>
      )}

      {siteList.length > 1 && !isNarrowScreen &&
        <div className="d-flex">
          <div id="select-site" className="select-site ms-2">
            <SiteSelect
              id="sites-dropdown"
              className="form-control sites-dropdown"
              site={site}
              onSiteChange={onSiteChange}
              siteList={siteList}
              isAuthenticated={isAuthenticated}
            />
          </div>
        </div>
      }

      {showNotification && site &&
        <NotificationPopup
          show={showNotification}
          notification={notification}
          onClose={() => {
            setShowNotification(false);
            localStorage.setItem("notification", JSON.stringify(notification.visible.end));
          }}
      />
      }
      
        {getIconButtons()}

    </div>
  </nav>
  )
}

export default HeaderNav;
