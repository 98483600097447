import React, { Fragment } from "react";
import { IntlProvider } from "react-intl";

import { locales } from ".";
//import messages from "./messages";

export default function Provider({
  children,
  locale=locales.en,
  translations
}) {
  const messages = translations && translations.some(translation => translation.language === locale) ? translations.find(translation => translation.language === locale).translations : [];
  return ( 
    <IntlProvider
      locale={locale}
      textComponent={Fragment}
      messages={messages}
      onError={() => null}
    >
      {children}
    </IntlProvider>
  );
}
