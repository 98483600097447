import React, { useEffect, useState } from "react";

import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { translate } from "../languages";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default function SiteSelectMap(props) {
  const {
    siteList,
    onSiteChange
  } = props

  const [position, setPosition] = useState();
  const [resetCenter, setResetCenter] = useState();
  const [zoom, setZoom] = useState({ level: 3, date: new Date() });

  useEffect(() => { // handles center position when resized
    const resizeObserver = new ResizeObserver((entries) => {
      if (window.innerWidth >= 1400) {
        setPosition([30,0]);
        setResetCenter([30,0]);
      } else {
        setPosition([55,0]);
        setResetCenter([55,0]);
      }
    });
    resizeObserver.observe(document.getElementById("map-container"));
    return () => { resizeObserver.disconnect(); }
  },[]);

  const siteChange = (selectedSite, coordinates) => {
    setZoom({ level: 15, date: new Date() });
    setPosition(coordinates);
    if (siteList.some((site_) => site_.api === selectedSite)) {
      window.history.pushState("", "", `${process.env.REACT_APP_PREFIX}/${selectedSite}`);
      return onSiteChange(selectedSite);
    }
  }

  const zoomMap = (e) => {
    setZoom({ level: 13, date: new Date() });
    setPosition([e.latlng.lat, e.latlng.lng]);
  }

  const setMarkers = (sites) => {
    const arr = [];
    let coordinatesList = {};
    for (let i = 0; i < sites.length; ++i) {
      if (sites[i].coordinates) {
        if (Object.keys(coordinatesList).includes(sites[i].coordinates.toString())) {
          coordinatesList[sites[i].coordinates.toString()].sites.push({ text: sites[i].text, api: sites[i].api })
        } else {
          coordinatesList[sites[i].coordinates.toString()] = { sites: [{ text: sites[i].text, api: sites[i].api }] };
        }
      }
    }
    for (let coordinates of Object.keys(coordinatesList)) {
      if (coordinatesList[coordinates].sites.length > 1) { //if more than 1 site make a dropdown menu
        let dropdown = [];
        for (let i in coordinatesList[coordinates].sites) {
          const item = (
            <option 
              key={"option" + coordinatesList[coordinates].sites[i].api}
              value={coordinatesList[coordinates].sites[i].api}
            >
              {coordinatesList[coordinates].sites[i].text}
            </option>
          );
          dropdown.push(item);
          arr.push(
            <Marker
              key={"dropdown_marker" + coordinatesList[coordinates].sites[i].api}
              position={JSON.parse("[" + coordinates + "]")}
              eventHandlers={{
                click: (e) => { zoomMap(e) }
              }}
            >
              <Popup>
                <select 
                  onChange={(e) => siteChange(e.target.value, JSON.parse("[" + coordinates + "]") )}
                  style={{ backgroundColor: "#606060", border: "none" }}
                  className="map-popup-select"
                >
                  <option
                    value={coordinatesList[coordinates].sites[0].api}
                    style={{ display: "none" }}
                  >
                    {coordinatesList[coordinates].sites[0].text}
                  </option>
                  {dropdown}
                </select>
              </Popup>
            </Marker>
          );
        }   

      } else { // only one site
        arr.push(
          <Marker
            key={"marker" + coordinatesList[coordinates].sites[0].api}
            position={JSON.parse("[" + coordinates + "]")}
            eventHandlers={{
              click: (e) => { zoomMap(e) }
            }}
          >
            <Popup>
              <div
                className="pointer"
                onClick={() => siteChange(coordinatesList[coordinates].sites[0].api, JSON.parse("[" + coordinates + "]"))}
              >
                {coordinatesList[coordinates].sites[0].text}
              </div>
            </Popup>
          </Marker>
        );
      }
    }
    return arr;
  }

  const getMapBounds = () => {
    const southWest = L.latLng(90, -180);
    const northEast = L.latLng(-90, 180);
    const bounds = L.latLngBounds(southWest, northEast);
    return bounds;
  }

  function ChangeMapView({ coords }) {
    const map = useMap();
    map.setView(coords, map.getZoom());
  
    return null;
  }

  function ZoomMap() {
    const map = useMap()
    useEffect(() => {
      map.setView(position, zoom.level)
    }, [map])
    return null
  }

  return(
    <div id="map-container" className="map-container">
      
      {position &&
        <>
          <MapContainer
            center={position}
            zoom={zoom.level}
            doubleClickZoom={false}
            closePopupOnClick={false}
            zoomSnap={false}
            zoomDelta={false}
            trackResize={false}
            touchZoom={false}
            scrollWheelZoom={true}
            maxBounds={getMapBounds()}
            maxBoundsViscosity={0.65}
            minZoom={2}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              noWrap
            />
            {setMarkers(siteList)}
            <ChangeMapView coords={position} />
            <ZoomMap />
          </MapContainer>
          <button
            className="btn btn-secondary map-reset-button"
            onClick={() => { setZoom({ level: 3, date: new Date() }); setPosition(resetCenter) }}
          >
            {translate("map_reset")}
          </button>
        </>
      }
    </div>
  )
}
