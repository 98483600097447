/* built-in packages */
import React, { useState } from "react";

/* 3rd-party packages */
import axios from "axios";
import { Modal, Form, ButtonToolbar, Button, Message, Toggle, toaster, InputPicker, RadioGroup, Radio } from 'rsuite';
import { isFunction, cloneDeep } from "lodash";

/* self-provided packages */
import { getConciseTime, makeAuthUrlWithHeaders, acquireToken, logErrorIfDevelopmentMode } from "../utils";
import { translate } from "../languages";
import { withAllContext } from "../context";

const ManageTagListsModal = (props) => {
  const {
    show,
    selectedTags,
    customTagLists,
    selectedTagList,
    manageType,
    site,
    onHide,
    user,
    sdt,
    edt,
    useAuthentication,
    msalContext,
    intl,
    tagListPickerValues = [],
    _testOverride,
    isNarrowScreen,
  } = props;

  const [formData, setFormData] = useState();
  const [modalOpened, setModalOpened] = useState(new Date().toISOString());
  const siteCode = site + "_savedTagLists";

  const setDefaultValues = () => {
    /* Function sets the default values of the form when this modal is opened
     * selectedTagList props looks like this: ville.aunola@andritz.com?lists?list233 
     * on cloud BOA the first value is user email or "public", on local BOA value is local_storage_lists 
     * when a new list is saved targetFolder is "create_a_new_folder" and private value is set based on currently selected list 
     * when list is updated values are set to match selected list, however on cloud BOA targetFolder is "create_a_new_folder" when selected tag list is "local_storage_lists" 
     * local_storage_lists contains the older lists that are saved on browser's local storage which is now deprecated on cloud version 
    */
    if (manageType === "Update") {
      let valueArray = selectedTagList.split("?");
      let userValue = valueArray[0];
      let folderValue = valueArray[1];
      let listValue = valueArray[2];
      setFormData({ 
        tagListName: listValue, 
        targetFolder: userValue === "local_storage_lists" && user !== "local_storage_lists" ? "create_a_new_folder" : folderValue,
        tagListFolder: "",
        private: userValue === "public" ? false : true,
        saveTimestamp: customTagLists.find(user => user.value === userValue).children.find(folder => folder.value === folderValue).children.find(list => list.value === listValue).staticTimestamp
      });
    } else {
      setFormData({ 
        tagListName: "", 
        targetFolder: "create_a_new_folder",
        tagListFolder: "",
        private: selectedTagList && selectedTagList.split("?")[0] === "public" ? false : true,
        saveTimestamp: false
      });
    }
    setModalOpened(new Date().toISOString()); // modalOpened is used as a key that refreshes the modal after values are set
  }

  const handleClose = (key = selectedTagList) => { 
    /* onHide function changes the selected list when modal is closed */
    if (isFunction(onHide)) {
      onHide(key);
    }
  }

  const isNameInUse = (type = "folder") => {
    /* Function checks is another list or folder already using the same name that user typed  */
    let folders = listUserFolders(); // filter lists based on "public" and "private" values.
    /* check if folder name is already in use  */
    if (type === "folder") { return folders.some(folder => folder.value === formData.tagListFolder) }
    /* check if tag list name is already in use in selected folder */
    else {
      /* check that target folder is not "create_a_new_folder" because then the list name will be automatically available
       * check that user is not local_storage_lists when Cloud BOA is used so old local storage lists can be saved to a new location
       * when manageType is update tagListName and selectedTagList can have a same value
       * but if target folder changes or manageType is Add and targetFolder is "create_a_new_folder" availability of the name should be checked
      */
      if ( 
        (formData.targetFolder !== "create_a_new_folder" && selectedTagList && selectedTagList.split("?")[0] === "local_storage_lists" && user !== "local_storage_lists") ||
        (formData.targetFolder !== "create_a_new_folder" && (manageType === "Update" && selectedTagList && (selectedTagList.split("?")[2] !== formData.tagListName || manageType === "Add"))) ||
        (formData.targetFolder !== "create_a_new_folder" && (manageType === "Update" && selectedTagList && selectedTagList.split("?")[2] === formData.tagListName && selectedTagList.split("?")[1] !== formData.targetFolder))
       ) {
        if (folders.some(folder => folder.value === formData.targetFolder)) { //when target folder is selected and private state changes folder.find is undefined
          return folders.find(folder => folder.value === formData.targetFolder).children.some(list => list.value === formData.tagListName) // find if the tagListName is already in use in targetFolder
        } else { return false }
      }
    }
  }

  const listUserFolders = () => {
    /* Function filter lists based on private value, either all user's (private) lists  or all public lists that are visible for everybody  */
    if (formData.private && tagListPickerValues.some(lists => lists.value === user)) { return tagListPickerValues.filter(value => value.value === user).map(c => c.children)[0] } 
    else if (tagListPickerValues.some(value => value.value === "public")) { return tagListPickerValues.filter(value => value.value === "public").map(c => c.children)[0] }
    else { return [] }
  }

  const showSavedMsg = (key) => {
    toaster.push(
      <Message showIcon type={"success"} >
        {intl.formatMessage({id: ("Tag list saved successfully")})}!
      </Message>
    );
    handleClose(key);
  }

  const saveServerLists = async(lists, oldListName, key) => {
    let url = `/boa/api/v1/${site}/tagLists`;
    let headers = {};
    if (useAuthentication) {[url, headers] = await makeAuthUrlWithHeaders(url, headers, acquireToken(msalContext));}
    try {
      axios({
        method: 'post',
        headers: headers,
        url: url,
        data: lists
      }).then(()=> { 
        if (localStorage.getItem(siteCode)) {
          let localList = localStorage.getItem(siteCode);
          let newLocalList = JSON.parse(localList).filter(list => list.value !== oldListName);
          localStorage.setItem(siteCode, JSON.stringify(newLocalList));
        }
        showSavedMsg(key) 
      });
    } catch (e) {
      logErrorIfDevelopmentMode(e);
    }
  }
   
  const saveList = () => {
    let allTagList = cloneDeep(customTagLists);
    let oldListName; //is needed later in saveServerLists function
    if (manageType === "Update") {
      oldListName = selectedTagList.split("?")[2]; 
      let oldFolder = selectedTagList.split("?")[1];
      let oldPath = selectedTagList.split("?")[0];
      let oldLists = allTagList.filter(lists => lists.value === oldPath)[0];
      let oldListsFolders = oldLists.children.filter(folder => folder.value === oldFolder)[0];
      let index = oldListsFolders.children.findIndex(item => item.value === oldListName);
      oldListsFolders.children.splice(index, 1);
      if (oldListsFolders.children.length < 1) {
        let emptyFolder = oldLists.children.findIndex(folder => folder.value === oldListsFolders.value);
        oldLists.children.splice(emptyFolder, 1);
      }
    }
    let folderPath = formData.private ? user : "public";
    let folderText = formData.private ? "Private" : "Public";
    if (user === "local_storage_lists") { folderText = "Lists"; }
    //if user haven't saved any taglists yet, create a new user level object
    if (!allTagList.some(user => user.value === folderPath)) { allTagList.push({label: folderText, value: folderPath, valueKey: folderPath, children: [] }) }
    let userLists = allTagList.filter(folder => folder.value === folderPath)[0]; //modify only user's lists
    let selectedFolder = formData.targetFolder !== "create_a_new_folder" ? formData.targetFolder : formData.tagListFolder;
    const newListItem = {
      value: _testOverride ? "_test" : formData.tagListName,
      label: _testOverride ? "_test" : formData.tagListName,
      valueKey: `${folderPath}?${selectedFolder}?${formData.tagListName}`,
      tags: selectedTags,
      staticTimestamp: false,
      sdt: null,
      edt: null
    };

    if (formData.saveTimestamp && sdt && edt) {
      newListItem.staticTimestamp = true;
      newListItem.sdt = sdt;
      newListItem.edt = edt;
    }

    //create new folder and insert new list object there
    if (formData.targetFolder === "create_a_new_folder") {userLists.children.push({label: formData.tagListFolder, value: formData.tagListFolder, valueKey:`${folderPath}?${formData.tagListFolder}`, children: [] })} 
    //when tag list is updated and it is the only tag list in its folder, create a new folder because the original is deleted
    else if (formData.targetFolder !== "create_a_new_folder" && !userLists.children.some(folder => folder.value === formData.targetFolder)) {
      userLists.children.push({label: formData.targetFolder, value: formData.targetFolder, valueKey:`${folderPath}?${formData.targetFolder}`, children: [] })
    } 
    let addListHere = userLists.children.find(item => item.value === selectedFolder);
    addListHere.children.push(newListItem);
    // localStorage.setItem(siteCode, JSON.stringify(allTagList));
    if (useAuthentication) { saveServerLists(allTagList, oldListName, `${folderPath}?${selectedFolder}?${formData.tagListName}`) }
    else { localStorage.setItem(siteCode, JSON.stringify(allTagList)); showSavedMsg( `${folderPath}?${selectedFolder}?${formData.tagListName}`); }
  }

  /**
   * Check if inputValue contains only allowed characters/symbols and is
   * not empty. Allowed characters are
   * * letters from a-z and A-Z
   * * numbers 0-9
   * * special characters **_** **.** **-**
   * @returns true if inputValue is not empty and contains only allowed characters
   */
  const checkIfDisabled = () => {
    if (_testOverride) return false;
    if (formData.targetFolder !== "create_a_new_folder" && listUserFolders().some(folder => folder.value === formData.targetFolder)) {
      return (
        formData.tagListName.length > 0
        && isValidCharacters(formData.tagListName)
        && !isNameInUse("input")
        ? false
        : true
      );
    } else {
      return (
        formData.tagListName.length > 0
        && isValidCharacters(formData.tagListName)
        && formData.tagListFolder.length > 0
        && isValidCharacters(formData.tagListFolder)
        && !isNameInUse("folder")
        ? false
        : true
      );

    }
  }

  const isValidCharacters = (text) => {
    if (text && text.length > 0 ) { return text.match(/^[ a-zA-Z0-9&#%_.-]*$/g) && Array.from(text)[0].match(/^[a-zA-Z0-9_#.-]*$/g) }
    else { return true } //default value when text is empty string
  }

  const getBodyContent = () => {
    let defaultTargetFolder = "create_a_new_folder";
    if (manageType === "Update") {
      let valueArray = selectedTagList.split("?");
      let userValue = valueArray[0];
      let folderValue = valueArray[1];
      if (userValue === "local_storage_lists" && user !== "local_storage_lists") { defaultTargetFolder = "create_a_new_folder" }
      else { defaultTargetFolder = folderValue }
    }
    return(
      <Form key={modalOpened} formDefaultValue={formData} onChange={ formValue =>  setFormData(formValue) }>

        <Form.Group>
          <Form.ControlLabel>{ manageType === "Update" ? translate("Update tag list") : translate("Save a new tag list")}:</Form.ControlLabel>
          {manageType === "Update" ? ( 
            <Form.ControlLabel>{selectedTagList.split("?")[2]}</Form.ControlLabel>
          ) : ( 
            <Form.ControlLabel>{translate("You have selected")} {selectedTags.length} {translate("tags")}</Form.ControlLabel>
          )}
        </Form.Group>

        {user !== "local_storage_lists" && manageType === "Add" &&
          <Form.Group>
            <Form.Control name="private" accepter={RadioGroup} inline appearance="picker" defaultValue={selectedTagList && selectedTagList.split("?")[0] === "public" ? false : true}>
              <span className="radio-group-title"> {translate("Save list to:")} </span>
              <Radio value={true}> {translate("Myself")} </Radio>
              <Radio value={false}> {translate("Everyone")} </Radio>
            </Form.Control>
          </Form.Group>
        }

        <Form.Group>
          <Form.ControlLabel>{manageType === "Update" ? translate("Change Folder") : translate("Save List in Folder")}</Form.ControlLabel>
          {Array.isArray(tagListPickerValues) &&
            <Form.Control 
              name="targetFolder"  
              accepter={InputPicker} 
              data={[...listUserFolders(), ...[{"label": translate("create a new folder"), "value": "create_a_new_folder"}]]}
              defaultValue={defaultTargetFolder}
              cleanable={false}
              renderMenuItem={(label, item) => { 
                if (item.value === "create_a_new_folder") { return <div><i className="fas fa-plus me-2"></i>{label}</div> } 
                else { return <div>{label}</div> }
              }}
            />
          }
        </Form.Group>

        {formData.targetFolder === "create_a_new_folder" &&
          <Form.Group controlId="tagListFolder">
            <Form.ControlLabel>Folder name:</Form.ControlLabel>
            <Form.Control name="tagListFolder"/>
            {isNameInUse() && <Form.HelpText>{translate("This folder already exists")}</Form.HelpText> }
            {!isValidCharacters(formData.tagListFolder) && <Form.HelpText>{translate("Invalid folder name")}</Form.HelpText>}
          </Form.Group>
        }

        <Form.Group>
          <Form.ControlLabel>Tag List Name</Form.ControlLabel>
          <Form.Control name="tagListName"/>
          {isNameInUse("list") && <Form.HelpText>{translate("This list already exists in selected folder")}</Form.HelpText> }
          {!isValidCharacters(formData.tagListName) && <Form.HelpText>{translate("Invalid tag list name")}</Form.HelpText>}
        </Form.Group>

        <Form.Group>
          <Form.Control name="saveTimestamp" accepter={Toggle} checked={formData.saveTimestamp} unCheckedChildren={translate("Save default time range")} checkedChildren={translate("Save selected time range")}  /> 
          {formData.saveTimestamp ? <div className="mt-2">{` (${getConciseTime(sdt)} - ${getConciseTime(edt)})`}</div> : null}
        </Form.Group>

        <Form.Group>
          <ButtonToolbar>
            <Button color="green" appearance="primary" onClick={() => saveList()} disabled={checkIfDisabled()}>
              {manageType === "Update" ? translate("Update list") : translate("Save list")}
            </Button>
            <Button color="red" appearance="primary" onClick={() => handleClose()}> 
              {translate("Cancel")} 
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    );
  }

    return(
      <Modal
        size={isNarrowScreen ? "xs" : "sm"}
        open={show}
        overflow={false}
        onEnter={()=> setDefaultValues()}
        onClose={()=> handleClose()}
        data-testid="manage-taglists-modal"
      >
        <Modal.Header>
          <Modal.Title>{translate("Manage Tag Lists")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formData && getBodyContent()}
        </Modal.Body>
      </Modal>
    );
}

export default withAllContext(ManageTagListsModal);
