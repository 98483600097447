// built-in modules
import React from "react";
import { Radio, RadioGroup } from "rsuite";
import { cloneDeep } from "lodash";
import { translate } from "../languages";

export const cuttingSelections = (cuttingMode, fetchedData, e, cuttingRange) => {
  let cuttingData = cloneDeep(fetchedData)
  if (cuttingMode ==="xAxis" && e.xAxis) {
    e.preventDefault();
    for (let serie in cuttingData) {
      for (let i = cuttingData[serie].data.length - 1; i >= 0; i--) {
        if (
          cuttingData[serie].data[i][0] < e.xAxis[0].max
          && cuttingData[serie].data[i][0] > e.xAxis[0].min
        ) {
          cuttingData[serie].data.splice(i,1);
        }
      }
    }
    return cuttingRange(cuttingData);
  } 
  
  else if (cuttingMode ==="yAxis" && e.yAxis) {
    e.preventDefault();
    for (let serie in cuttingData) {
      for (let i = cuttingData[serie].data.length - 1; i >= 0; i--) {
        let yAxis = cuttingData[serie].yAxis ?? 0;
        if (e.yAxis[yAxis]) {
          if (
            cuttingData[serie].data[i][1] < e.yAxis[yAxis].max
            && cuttingData[serie].data[i][1] > e.yAxis[yAxis].min
          ) {
            cuttingData[serie].data.splice(i,1);
          }
        }
      }
    }
    return cuttingRange(cuttingData)
  }

}

export const updateAxis = (cuttingMode) => {
  if (cuttingMode === "yAxis") {
    return {
      yAxis: [{
        crosshair: {
          snap: false,
          color: 'red'
        }                       
      }],
      xAxis: {
        crosshair: false
      },                                          
      tooltip: {
        enabled: false
      },                                  
      chart: {
        zooming: {
          type: "y"
        }
      }
    };
  } else if (cuttingMode === "xAxis"){
    return {
      xAxis: {
        crosshair: {
          snap: false,
          color: 'red'
        }                       
      },
      yAxis: [{
        crosshair: false
      }],
      tooltip: {
        enabled: false
      },
      chart: {
        zooming: {
          type: "x"
        }
      }
    }
  } else if ("off") { // cuttingMode === "off"
    return {
      xAxis: {
        crosshair: {
          snap: true,
          color: 'red'
        }                       
      },
      yAxis: [{
        crosshair: false
      }],
      tooltip: {
        enabled: true
      },
      chart: {
        zooming: {
          type: "x"
        }
      }
    }
  }
}

export default function CuttingTools(props) { //used in tag search
  const { 
    onClick, 
    titleClass = "row",
    buttonStyle = {
      verticalAlign: 'middle',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    selectedMode
  } = props;

  const getClassName = (value) => {
    let className = "col";
    if (selectedMode) {
      if (selectedMode === value) {
        className += " selectedElement";
      } else {
        className += " unselectedElement";
      }
    }
    return className;
  }

  return (
    <RadioGroup 
      name = "radioList" 
      inline 
      appearance = "picker" 
      className = "cuttingTools footerBtnGroup" 
      defaultValue = "off"
      onChange={(value) => {
        onClick(value);
      }}
    >
      <span 
        className={titleClass}
        style={buttonStyle}
      >
        <i className="fas fa-cut me-2"></i>{translate("CUT")}
      </span>
      <Radio className={getClassName("off")} style={{textAlign: "center"}} value="off" >{translate("Off")}</Radio>
      <Radio className={getClassName("xAxis")} style={{textAlign: "center"}} value="xAxis">{translate("X-Axis")}</Radio>
      <Radio className={getClassName("yAxis")} style={{textAlign: "center"}} value="yAxis">{translate("Y-Axis")}</Radio>
    </RadioGroup>
  );
}