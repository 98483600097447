import React, { Component, createContext } from "react";

const ThemeContext = createContext();

class ThemeProvider extends Component {
  state = {
    theme: "light"
  };

  componentDidMount() {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      if (storedTheme === "light") {
        document.body.classList.remove("darkTheme");
      } else if (storedTheme === "dark") {
        document.body.classList.add("darkTheme");
      }
      this.setState({ theme: storedTheme })
    }
  }

  changeTheme = () => {
    let theme;
    
    switch(this.state.theme) {
      case "light":
        theme = "dark";
        document.body.classList.add("darkTheme");
        break;
      case "dark":
        theme = "light";
        document.body.classList.remove("darkTheme");
        break;
      default:
        theme = "light";
        document.body.classList.remove("darkTheme");
        break;
    }

    localStorage.setItem("theme", theme);
    this.setState({ theme });
  }

  render() {
    return(
      <ThemeContext.Provider
        value={{
          theme: this.state.theme,
          changeTheme: this.changeTheme
        }}
      >
        {this.props.children}
      </ThemeContext.Provider>
    );
  }
}

export { ThemeContext, ThemeProvider };
