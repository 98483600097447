import { capitalize } from "lodash";
import { logErrorIfDevelopmentMode } from ".";
import { useContext } from 'react';
import { UseAuthenticationContext } from '../context';
import { MsalContext } from "@azure/msal-react";

/**
 * Function takes a string as emailAccount parameter and determines whether
 * or not it belongs to an Andritz user.
 * @param {String} emailAccount   email account, against which to check
 * @return {boolean} true if user is determined to be Andritz user, otherwise false
 */
export function isUserFromAndritz(emailAccount) {
  let userIsFromAndritz = false;

  if (typeof emailAccount === "string") {
    const splitAccount = emailAccount.split("@");
    if (
      splitAccount.length === 2
      && splitAccount[1] === "andritz.com"  
    ) {
      userIsFromAndritz = true;
    }
  }

  return userIsFromAndritz;
}

/** Function forms an authenticated version of URL with appropriate
 * headers for authenticated access to resources
 * 
 * @param {String} url                    URL for API call
 * @param {Object} headers                headers for request, can be empty
 * @param {Object} accessToken            Either a promise or an object
 * 
 * @return {Array}  Array containing the modified URL and
 *                  modified headers for authenticated request
 */
export async function makeAuthUrlWithHeaders(url, headers, accessToken) {
  let boaurl = window.location.origin;
  /* Replace 'localhost' with the correct dev URI */
  if (
    window.location.hostname === "localhost"
    || window.location.hostname === "127.0.0.1"
  ) {
    boaurl = process.env.REACT_APP_DEV_URI;
  }
  url = `${boaurl}${url}`;
  
  /** accessToken can be either a Promise object or a
   * dictionary-like object. If it is a dictionary-like
   * object, it is ready as is, and in case it is a Promise,
   * awaiting yields the object needed. awaiting does not
   * do any harm if the accessToken is already in desired form. */
  accessToken = await accessToken;
  headers.Authorization = `Bearer ${accessToken.idToken}`;
  return [url, headers];
}

export function isAuthenticationUsed() {
  let authenticationIsUsed;

  if (process.env.REACT_APP_USE_AUTHENTICATION === "true") {
    authenticationIsUsed = true;
  } else {
    authenticationIsUsed = false;
  }

  return authenticationIsUsed;
}

/** Function receives props of component as
 * argument and attempts to extract email
 * address from a nested data structure,
 * which depends on used authentication
 * provider. If no email exists on the
 * given path, null is returned instead of
 * undefined.
 * @param {Object} componentProps props of component calling this function
 * @return {string | null} email if exists, otherwise null
 */
export function getUserEmailFromProps(componentProps) {
  let email = componentProps?.msalContext?.accounts?.[0]?.username;
  if (!email) email = null;
  return email;
}

/** Function receives user's email as argument
 * and extracts all names (separated by '.')
 * from the first half (preceding '@') and
 * joins them together with white-space,
 * capitalizing each word. Capitalization does
 * not include the latter parts of dashed names
 * like 'Juha-Pekka', which will end up like
 * 'Juha-pekka'.
 * @param {string} emailAccount email account of the user
 * @return {string} names extracted from the email account
 */
export function getUserNameFromEmail(emailAccount) {
  const firstHalf = emailAccount.split("@")[0].split(".");

  let fullName = "";
  for (let i = 0; i < firstHalf.length; ++i) {
    fullName += capitalize(firstHalf[i]);
    if (i !== firstHalf.length - 1) {
      fullName += " ";
    }
  }

  return fullName;
}

export async function acquireToken(authenticationContext, forceRefresh=false) {
  if ("instance" in authenticationContext && "accounts" in authenticationContext) {
    try {
      return await authenticationContext.instance.acquireTokenSilent({
        scopes: ["User.Read"],
        account: authenticationContext.accounts[0],
        forceRefresh
      });
    } catch(error) {
      logErrorIfDevelopmentMode(error);
      return null;
    }
  }
}
// Usage:
// import { useIsAuthenticated } from '../utils';
// Inside the component:
// const isAuthenticated = useIsAuthenticated(); 
export function useIsAuthenticated() {
  const useAuthentication = useContext(UseAuthenticationContext);
  const msalContext = useContext(MsalContext);

  return useAuthentication && msalContext.accounts.length > 0;
}