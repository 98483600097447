import moment from 'moment';
import 'moment-timezone';


function setHighchartsOptions(Highcharts, lang, timeZone, translations) {
  window.moment = moment;
  /* Defaults if translations are missing */
  const defaultShortMonth = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
  const defaultMonths = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
  const defaultWeekdays = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ];
  /* Find the correct translations */
  let translated = translations.find(translation => translation.code === lang).translations;
  let shortMonths = translated.highcharts_short_months || defaultShortMonth;
  let months = translated.highcharts_months || defaultMonths;
  let weekdays = translated.highcharts_weekdays || defaultWeekdays;
  /* Global highcharts options including language and timezone */
  Highcharts.setOptions({
    accessibility:{
      enabled: false
    },
    title: { 
      text: undefined 
    },
    chart: {
      style: {
        fontFamily: 'Poppins-Regular'
      }
    },
    lang: {
      shortMonths: shortMonths,
      months: months,
      weekdays: weekdays
    },
    time: {
      timezone: timeZone
    }
  });
}

export default setHighchartsOptions;