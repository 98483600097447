import React, { useEffect, useState, useCallback } from 'react';
import { useTranslateToString, translate } from '../languages';
import { SelectPicker, Divider } from 'rsuite';
import {secondsToString} from "../utils";

const initialTimeWindow = [
  { label: "10min", value: 600 },
  { label: "30min", value: 1800 },
  { label: "1h", value: 3600 },
  { label: "8h", value: 28800 },
  { label: "1d", value: 86400 },
  { label: "7d", value: 604800 }
];

const LineChartOptions = (props) => {
  const { defaultValues, onChange } = props;
  const [selectedTime, setSelectedTime] = useState();
  const [timeWindow, setTimeWindow] = useState(initialTimeWindow);

  useEffect(() => {
    if (defaultValues?.timeWindow && !initialTimeWindow.some(item => item.value === defaultValues.timeWindow)) {
      const updatedTimeWindow = [
        ...initialTimeWindow, 
        { 
          label: secondsToString(defaultValues.timeWindow), 
          value: defaultValues.timeWindow 
        }
      ];

      updatedTimeWindow.sort((a, b) => a.value - b.value);
      setTimeWindow(updatedTimeWindow);  
    }
  }, [defaultValues]);

  const handleChange = useCallback((key, value) => {
    onChange(key, { ...defaultValues, [key]: value });
  }, [onChange, defaultValues]);

  // Update custom panel settings time window when line chart is chosen
  useEffect(() => {
    if (selectedTime && selectedTime !== defaultValues?.timeWindow) { handleChange("timeWindow", selectedTime);} 
    else if (!selectedTime) { setSelectedTime(defaultValues?.timeWindow || 86400); }
  }, [selectedTime, defaultValues, handleChange]);

  return (
    <>
      <Divider>{translate("Line chart options")}</Divider>
      <div className="flex-container">
        <SelectPicker
          data={timeWindow}
          searchable={false}
          label={useTranslateToString("Time window")}
          value={timeWindow.find(time => time.value === selectedTime) ? selectedTime : 86400}
          onChange={(value) => setSelectedTime(value)}
        />
      </div>
      <Divider />
    </>
  );
};

export default LineChartOptions;