import { cloneDeep, isFunction } from "lodash";
import XLSX from "xlsx";

import {
  arraysAreOfEqualLength,
  arrayHasTSValArraysOnly,
  dataArrayAxisContainsEpochTimestamps
} from ".";

export function logErrorIfDevelopmentMode(error) {
  if (process.env.NODE_ENV === "development") {
    const logMsg = error.message || error;
    console.error(`[DEV] ${logMsg}`);
  }
}

/**
 * Function checks for a small screen.
 * @return {Boolean} true if screen is narrow, else false 
 */
export function isDeviceMobile() {
  let deviceIsMobile = false;
  if (window.screen.width < 760) deviceIsMobile = true;
  return deviceIsMobile;
}

/**
 * Function adds a leading zero to a
 * numeric value to display two digits.
 * @param {Number} i Number to manipulate
 * @return {Number | String} Value where leadign zero might be added
 */
export function checkTime(i) {
  if (i < 10) {
    i = `0${i}`;
  }
  return i;
}

export function epoch2timestamp(epoch, utc_offset) {
	let utcTime = new Date(epoch);
	let utcHours = utcTime.getHours();
	let locHours = utcHours + ((utc_offset * 60 - new Date().getTimezoneOffset() + new Date().getTimezoneOffset()) / 60);
	let locTime = utcTime.setHours(locHours);	
	return new Date(locTime).toISOString();
}

export function localTime() {
  const today = new Date(new Date().getTime());
  const h = checkTime(today.getHours());
  const m = checkTime(today.getMinutes());
  const s = checkTime(today.getSeconds());
  return h + ":" + m + ":" + s;
}

/**
 * Convert time to concise human-readable format
 * @param {Date} timestamp timestamp to convert
 * @returns {string} timestamp in concise format
 */
export function getConciseTime(timestamp) {
  const dateObject = new Date(timestamp);
  
  // return parameter if not provided a Date object
  if (isNaN(dateObject.getMonth())) return timestamp;

  const year    = dateObject.getFullYear();
  const month   = dateObject.getMonth() + 1;
  const day     = dateObject.getDate();
  const hours   = checkTime(dateObject.getHours());
  const minutes = checkTime(dateObject.getMinutes());
  const seconds = checkTime(dateObject.getSeconds());
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

const TimeLevels = {
  scale: [12, 30, 24, 60, 60, 1],
  units: ["y ", "m ", "d ", "h ", "min ", "s "],
};

export function secondsToString(interval) {
  return intervalToLevels(interval, TimeLevels);
}

export function intervalToLevels(interval, levels) {
  const cbFun = (d, c) => {
    let bb = d[1] % c[0],
      aa = (d[1] - bb) / c[0];
    aa = aa > 0 ? aa + c[1] : "";

    return [d[0] + aa, bb];
  };

  let rslt = levels.scale
    .map((d, i, a) => a.slice(i).reduce((d, c) => d * c))
    .map((d, i) => [d, levels.units[i]])
    .reduce(cbFun, ["", interval]);
  return rslt[0];
}

export function getTimeparams(differ, endDate) {
  /* Get time difference in seconds. */
  let sdt = new Date();
  let edt = new Date();
  let extraSecs = endDate ? endDate : 0; 
  if (endDate === "useTimestamp"){extraSecs = 0; }
  try {
    if (typeof(differ?.sdt) === "number" && typeof(differ?.edt) === "number") { 
      sdt.setSeconds(sdt.getSeconds() - parseInt(differ.sdt) - extraSecs);
      edt.setSeconds(edt.getSeconds() - parseInt(differ.edt) - extraSecs);
    } else { sdt.setSeconds(sdt.getSeconds() - parseInt(differ)); }

    /* Get either epoch timestamp
    * or string type datetimes. */
    if (endDate === "useTimestamp") {
      sdt = +sdt;
      edt = +edt;
    } else {
      sdt = sdt.toISOString();
      edt = edt.toISOString();
    }
    return { sdt, edt };
  } catch (e) {
    return {sdt: new Date(), edt: new Date()}
  }
}

export function todaysTimeParams(date) {
  return date.getHours() * 60 * 60 + date.getMinutes() * 60 + date.getSeconds();
}

export function checkDateRange(start, end) {
  const today = new Date();
  //In case of invalid endDate we use today's data and set endDate to match startDate
  if (start.getTime() > end.getTime()) {
    // this.setState({ endDate: today });
    return getTimeparams(todaysTimeParams(today));
  }
  //Date range is valid
  else {
    const sdt = start.toISOString();
    let edtSeconds;
    //adds 24hours to end date so date range contains the dates which were selected
    //unless end date is today
    if (end.getTime() + 86400 * 1000 > today.getTime()) {
      edtSeconds = today.getTime();
    } else {
      edtSeconds = end.getTime() + 86400 * 1000;
    }
    //converts it back to ISO format
    const edt = new Date(edtSeconds).toISOString();
    return { sdt, edt };
  }
}

/*-----TagTable-----*/

export function checkedTags(checkedItems) {
  let value = checkedItems.values();
  let keys = checkedItems.keys();
  let checked = false;
  let notChecked = [];
  let tags = [];
  //checks if there are checked checkboxes
  for (let i = 0; i < checkedItems.size; i++) {
    if (value.next().value === true) {
      tags.push(keys.next().value);
      checked = true;
    } else {
      notChecked.push(keys.next().value);
    }
  }
  return [tags, checked];
}

export function getColors(theme) {
  let colors;
  const lightColors = [
    "#7cb5ec",
    "#434348",
    "#abcc73",
    "#f7a35c",
    "#1446A0",
    "#f15c80",
    "#2b908f",
    "#e4d354",
    "#9477cb",
    "#dda0dd",
    "#2caffe",
    "#8b4513",
    "#90ed7d",
    "#fe6a35",
    "#0000ff",
    "#c42525",
    "#2ee0ca",
    "#ffd700",
    "#6E2594",
    "#f032e6"
  ];
  const darkColors = [
    "#7cb5ec",
    "#90ee7e",
    "#f45b5b",
    "#bdb76b",
    "#aaeeee",
    "#9477cb",
    "#dda0dd",
    "#FFA500",
    "#ff1493",
    "#00fa9a",
    "#1e90ff",
    "#55BF3B",
    "#ff0066",
    "#808000",
    "#d3d3d3",
    "#8b008b",
    "#f032e6",
    "#8b4513",
    "#ffff00",
    "#147551"
  ];

  switch(theme) {
    case "light":
      colors = lightColors;
      break;
    case "dark":
      colors = darkColors;
      break;
    default:
      colors = lightColors;
      break;
  }

  return colors;
}

export function getColor(theme, index) {
  const colors = getColors(theme);
  const colorIndex = index >= colors.length ? index % colors.length : index;
  return colors[colorIndex];
}

export function getBoaURL() {
  let boaurl;

  if (
    window.location.hostname === "localhost"
    || window.location.hostname === "127.0.0.1"
  ) {
    boaurl = process.env.REACT_APP_DEV_URI;
  } else {
    boaurl = window.location.origin;
  }

  return boaurl;
}


/**
 * Function takes layouts object and menu object
 * as parameters and determines the order in which
 * layout display names (found only in 'layouts'
 * in arbitrary order) appear in the menu. The
 * filtering utilizes shared information, i.e. layout
 * ids. The ordering includes nested menus i.e.
 * submenus in such a way that main level menu item
 * and its submenu items appear before the next
 * main level menu item.
 * @param {Object} layouts Layouts object for creating the layouts of BOA
 * @param {Object} menu Menu object, which depicts order and submenu relations
 * @return {Array} Array in which the menu item titles are set in order of appearance
 */
export function getDeepMenuOrder(layouts, menu) {
  const menuOrder = [];
  const menuKeysMainLevel = Object.keys(menu);
  for (let i = 0; i < menuKeysMainLevel.length; ++i) {
    
    // parse main level menu
    const menuTitleMainLevel = layouts?.[menuKeysMainLevel[i]]?.text;
    if (menuKeysMainLevel) {
      menuOrder.push(menuTitleMainLevel)
    }

    // parse submenu if exists
    if (
      menu[menuKeysMainLevel[i]]?.submodules
      && Array.isArray(menu[menuKeysMainLevel[i]].submodules)
      && menu[menuKeysMainLevel[i]].submodules.length > 0
    ) {
      const menuKeysSubLevel = menu[menuKeysMainLevel[i]].submodules;
      for (let j = 0; j < menuKeysSubLevel.length; ++j) {
        const menuTitleSubLevel = layouts?.[menuKeysSubLevel[j]]?.text;
        if (menuTitleSubLevel) {
          menuOrder.push(menuTitleSubLevel);
        } 
      }
    }
  }

  return menuOrder;
}

/**
 * Function compares two string values
 * and sorts them alphabetically. The parameter
 * values are converted to lower case comparison
 * step so that 'Zebra' would not appear before
 * 'apple' in alphabetical ordering.
 * @param {String} a first value
 * @param {String} b second value
 * @return {Number} negative if first value should be placed before the second value
 *                  positive of first value should be placed after the second value
 *                  zero if values are equal
 */
export function alphabeticalSort(a, b) {
  let ret = 0;
  
  if (
    typeof a === "string"
    && typeof b === "string"  
  ) {
    const aValue = a.toLowerCase();
    const bValue = b.toLowerCase();
    if (aValue < bValue) {
      ret = -1;
    } else if (aValue > bValue) {
      ret = 1;
    } else {
      ret = 0;
    }
  }

  return ret;
}

/**
 * Function checks whether the view is
 * currently in full screen or not, and
 * toggles the other mode active. Different
 * browsers require different function calls
 * so the function checks which one is
 * available.
 * @param {Element} element Element given to function
 */
export function toggleFullScreen(element) {
  if (!document.fullscreenElement) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) { // Mozilla Firefox only
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) { // Webkit-based browsers, of which there are many
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) { // Microsoft's browsers
      element.msRequestFullscreen();
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
}

/**
 * Function checks if a given string
 * contains only digits, or something
 * else as well.
 * @param {String} str input string
 * @return {Boolean} true input has only digits, otherwise false
 */
export function stringOnlyHasDigits(str) {
  const hasDigitsOnly = /^\d+$/.test(str);
  return hasDigitsOnly;
}

/**
 * Function checks if a value is truly
 * a number, not only if its type is number.
 * @param {any} val value to check
 * @return {boolean} true if val is number, otherwise false
 */
export function isNumber(val) {
  let ret;

  if (
    typeof val === "number"
    && !isNaN(val)
  ) {
    ret = true;
  } else {
    ret = false;
  }

  return ret;
}

/**
 * Function extracts sitename from current URL.
 * The function would work properly also without
 * a parameter by using window.location directly
 * but that would disable memoization, so the
 * function takes window.location as a parameter.
 * @param {string} currentURL URL of currently open window
 * @return {string} site name from current URL
 */
export function getSiteFromWindowURL(currentURL) {
  let site;

  const split1 = currentURL.split("boa/");
  if (split1.length === 2) {
    site = split1[1].split("/")[0];
  } else {
    logErrorIfDevelopmentMode("Unable to extract site from URL.");
    site = null;
  }

  return site;
}

/**
 * Function takes an array of tags (full tag strings)
 * as parameter and creates a comma-separated string
 * containing tag names that have been extracted from
 * each full tag string in tagList parameter.
 * @param {Array} tagList array of full tag strings
 * @return {string} comma-separated tag names
 */
export function extractAllTagNames(tagList) {
  if (!Array.isArray(tagList)) return null;
  const tagNamesList = [];
  for (let i = 0; i < tagList.length; ++i) {
    tagNamesList.push(extractTagName(tagList[i]));
  }
  return tagNamesList.join(",");
}

/**
 * Function extracts a tag's name from
 * tag string containing more information
 * about the tag. Full tag string should
 * be in the form of:
 * id?tagname?description?unit
 * and the regex matches the occurrence
 * between the first and second '?'.
 * @param {string} tagString full tag string
 * @return {string} tag's name extracted from tag string
 */
export function extractTagName(tagString) {
  if (!(typeof tagString === "string")) return "";
  return tagString.match(/\?(.*?)\?/)[1];
}

/**
 * Generate GUID (globally unique identifier)
 * @returns {string} probably random guid
 */
export function generateGuid() {
  const S4 = function() {
      return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
  };
  return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

/**
 * Check if a string contains only spaces and newlines
 * @param {string} str any string
 * @returns {boolean} true if string has only spaces and newlines, otherwise false
 */
export function hasOnlySpacesAndNewlines(str) {
  if (str === "&nbsp;") return true;
  let withoutNewlines = str.replace(/(\r\n|\r|\n)/g, "");
  if (!withoutNewlines.replace(/\s/g, "").length) return true;
  return false;
}

/**
 * Merge arrays that have one pseudo-matching axis
 * @param {array} arrays array of data arrays
 * @param {number} dataAxis axis of data to add to combined data structure
 * @returns {array} merged data
 */
export function mergeArraysAlongAxis(arrays, dataAxis=1) {
  const arrayCount = arrays.length;
  const mergedData = cloneDeep(arrays[0]);
  const dataPointCountOnFirst = arrays[0].length;

  for (let i = 1; i < arrayCount; ++i) {
    if (arrays[i].length !== dataPointCountOnFirst) continue;

    for (let j = 0; j < dataPointCountOnFirst; ++j) {
      mergedData[j].push(arrays[i][j][dataAxis]);
    }
  }

  return mergedData;
}

/**
 * Merge data arrays of unequal lengths. The first item in each
 * data array data point should be the timestamp, and the second
 * one should be the data value. I.e. **arrays** parameter looks
 * as follows:
 * [
 *   [
 *     [ts1, val1],
 *     [ts2, val2],
 *     ...
 *   ],
 *   ...
 * ]
 * The timestamps are matched for each data point and nulls are
 * placed on each data point where the dataset does not have a
 * data value.
 * @param {array} arrays data arrays
 * @return {array} merged data arrays
 */
export function mergeArraysOfUnequalLength1(arrays) {
  // see if arrays parameter and each array in it are arrays
  if (Array.isArray(arrays)) {
    // if one of the items in arrays is not an array, return
    for (let i = 0; i < arrays.length; ++i) {
      if (!Array.isArray(arrays[i])) return null;
    }

    // map first array of data arrays into array of data objects
    const baseArray = arrays[0].map(item => ({ timestamp: item[0], data: [item[1]] }));
    for (let i = 1; i < arrays.length; ++i) {
      const array = arrays[i];
      for (let j = 0; j < array.length; ++j) {

        // extract timestamp and data value
        const timestamp = array[j][0];
        const data = array[j][1];

        // get timestamp index in existing array
        const matchingTimestampIndexInBaseArray = baseArray.findIndex(item => item.timestamp === timestamp);

        // if timestamp exists in existing array, append data value to its data
        if (matchingTimestampIndexInBaseArray !== -1) {
          while (baseArray[matchingTimestampIndexInBaseArray].data.length < i) {
            baseArray[matchingTimestampIndexInBaseArray].data.push(null);
          }
          baseArray[matchingTimestampIndexInBaseArray].data.push(data);
        }
        
        // if timestamp does not exist in existing array, append a new entry at the end
        else {
          const newTimestamp = { timestamp, data: [] };
          while (newTimestamp.data.length < i) {
            newTimestamp.data.push(null);
          }
          newTimestamp.data.push(data);
          baseArray.push(newTimestamp);
        }
      }
    }

    // fill remaining nulls at the end of each data array
    const dataPointCount = arrays.length;
    for (let i = 0; i < baseArray.length; ++i) {
      while (baseArray[i].data.length < dataPointCount) {
        baseArray[i].data.push(null);
      }
    }

    // sort into chronological order
    baseArray.sort((a, b) => {
      if (a.timestamp < b.timestamp) return -1;
      if (a.timestamp > b.timestamp) return 1;
      return 0;
    });

    // finally construct new data array of sorted data
    const newDataArray = baseArray.map(item => {
      const timestamp = item.timestamp;
      const data = item.data;
      const finalDataArrayForTimestamp = [timestamp].concat(data);
      return finalDataArrayForTimestamp;
    });

    return newDataArray;
  }
  return null;
}

/**
 * Merge data arrays if unequal length. Similar in operation
 * to *mergeArraysOfUnequalLength1*, which is self-made. Asked
 * a question on StackOverflow for an efficient function at
 * doing this exact thing and received this answer.
 * @see https://stackoverflow.com/a/70112925/12023402
 * @param  {...any} arrs array parameters
 * @returns {array} merged arrays
 */
export function mergeArraysOfUnequalLength2(...arrs) {
  if (arrs.length < 1) return null;
  for (let i = 0; i < arrs.length; ++i) {
    if (!Array.isArray(arrs[i])) return null;
  }

  const result = {};
  for (let i = 0; i < arrs.length; ++i) {
    for (let [ts, val] of arrs[i]) {
      result[ts] = result[ts] || new Array(arrs.length + 1).fill(null);
      result[ts][0] = ts;
      result[ts][i + 1] = val;
    }     
  }
  return Object.values(result).sort((a, b) => a[0] - b[0]);
}

/**
 * Transform epoch timestamp to YYYY-MM-DD hh-mm-ss.mmm
 * string. The millisecond part is omitted if 'milliseconds'
 * parameter is false.
 * @param {boolean} milliseconds include milliseconds if true
 * @returns {string} timestamp without any timezone fiddling
 */
export function epoch2timestampNoTimezone(timestamp, milliseconds=true) {
  const dateObject = new Date(timestamp);
  // return parameter if not provided a Date object
  if (isNaN(dateObject.getMonth())) return timestamp;

  const year    = dateObject.getUTCFullYear();
  let month     = dateObject.getUTCMonth() + 1;
  let day       = dateObject.getUTCDate();
  const hours   = checkTime(dateObject.getUTCHours());
  const minutes = checkTime(dateObject.getUTCMinutes());
  const seconds = checkTime(dateObject.getUTCSeconds());

  if (day < 10) { day = "0" + day }
  if (month < 10) { month = "0" + month }

  if (!milliseconds) return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  
  // add milliseconds to time string
  let milliSeconds = dateObject.getMilliseconds().toString();
  while (milliSeconds.length < 3) {
    milliSeconds += "0";
  }

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliSeconds}`;
}

/**
 * Transform all timestamps along one axis. If data is not an
 * array or timestamps are not valid, do not transform.
 * @param {array} data data
 * @param {number} axis axis that contains timestamps
 * @returns {array} first item is data array, second item is boolean on whether axis timestamps were transformed
 */
export function transformAxisTimestamps(data, axis=0) {
  let timestampsTransformed = false;
  if (!Array.isArray(data)) return [data, timestampsTransformed];
  if (!dataArrayAxisContainsEpochTimestamps(data, axis)) return [data, timestampsTransformed];

  for (let i = 0; i < data.length; ++i) {
    data[i][axis] = epoch2timestampNoTimezone(data[i][axis]);
  }
  timestampsTransformed = true;

  return [data, timestampsTransformed];
}

/**
 * Function exports timeseries data to excel format
 * @param {array} data array of data objects
 * @param {object} options options for function
 */
export function data2excel(data, timeZone, options = {}) {

  /**
   * Create custom header row for for sheet
   * @param {object} ws XLSX sheet
   * @param {array} data data
   * @param {boolean} timestampsTransformed timestamp has been transformed prior
   * @returns {object} sheet with modified header row
   */
  function makeCustomHeaderRow(ws, data, timestampsTransformed) {
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const address = XLSX.utils.encode_col(C) + "1";
      if (!ws[address]) continue;
      if (C === 0) {
        if (timestampsTransformed) {
          ws[address].v = "Datetime (UTC+0)";
        } else {
          ws[address].v = "X-axis";
        }
      } else if (C === 1) {
        ws[address].v = `Datetime (${timeZone})`;
      } else {
        ws[address].v = data[C - 2].tag || data[C - 2].name;
      }
    }
    return ws;
  }

  /**
   * Create filename for .xlsx file from data attributes
   * @param {array} data array of data objects
   * @returns {string} filename
   */
  function getExcelFileName(data) {
    const datePart = new Date(data[0].data[0][0]).toISOString().split("T")[0].split("-").join("");
    let tagPart;
    if (data.length > 2) {
      tagPart = `${data.length}_tags`;
    } else {
      tagPart = data.reduce((acc, item, index) => {
        if (item.tag) return acc + `_${item.tag}`;
        if (item.name) return acc + `_${item.name}`;
        return acc + `_tag-${index + 1}`;
      }, "");
    }
    return `${datePart}_${tagPart}.xlsx`;
  }

  const {
    onStart = null,
    onFinish = null,
    onSuccess = null,
    onError = null,
    _test = false
  } = options;

  if (isFunction(onStart)) onStart();

  try {
    // gather data arrays into one array
    const dataArrays = [];
    for (let i = 0; i < data.length; ++i) {
      dataArrays.push(data[i].data);
    }

    let mergedData;
    if (arraysAreOfEqualLength(dataArrays)) {
      mergedData = mergeArraysAlongAxis(dataArrays);
    } else {
      mergedData = mergeArraysOfUnequalLength2(...dataArrays);
    }
    const [transformedData, timestampsTransformed] = transformAxisTimestamps(mergedData);

    // Add local time column
    const transformedDataWithLocalTime = transformedData.map(row => {
      const utcTime = row[0]; // First column is the timestamp 2024-11-12 09:00:00.000 (UTC+0)
      //create new date object with UTC+0 time
      const [datePart, timePart] = utcTime.split(' ');
      const [year, month, day] = datePart.split('-').map(Number);
      const [hours, minutes, seconds] = timePart.split(':').map(Number);
      const milliseconds = Number(timePart.split('.')[1]);
      const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds, milliseconds));
      // convert UTC+0 time to local time
      const localTime = new Date(utcDate.toLocaleString('en-US', { timeZone }));
      const localYear = localTime.getFullYear();
      const localMonth = String(localTime.getMonth() + 1).padStart(2, '0');
      const localDay = String(localTime.getDate()).padStart(2, '0');
      const localHours = String(localTime.getHours()).padStart(2, '0');
      const localMinutes = String(localTime.getMinutes()).padStart(2, '0');
      const localSeconds = String(localTime.getSeconds()).padStart(2, '0');
      const localMilliseconds = String(localTime.getMilliseconds()).padStart(3, '0');
      const formattedLocalTime = `${localYear}-${localMonth}-${localDay} ${localHours}:${localMinutes}:${localSeconds}.${localMilliseconds}`;
      return [row[0], formattedLocalTime, ...row.slice(1)];
    });

    let ws = XLSX.utils.json_to_sheet(transformedDataWithLocalTime);
    ws = makeCustomHeaderRow(ws, data, timestampsTransformed);

    // Set column widths
    ws['!cols'] = [
      { wch: 25 }, // Width for the first column
      { wch: 25 }, // Width for the second column
      ...Array(transformedDataWithLocalTime[0].length - 2).fill({ wch: 10 }) // Width for the remaining columns
    ];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const fileName = getExcelFileName(data);

    if (!_test) XLSX.writeFile(wb, fileName);

    if (isFunction(onSuccess)) onSuccess();
  } catch (error) {
    if (isFunction(onError)) onError();
  }

  if (isFunction(onFinish)) onFinish();
}

/**
 * Check if data object has data for quick export function
 * @param {object} dataSet data object containing keys
 * @returns {boolean} true if data object has quickly exportable data
 */
export function dataSetHasQuicklyExportableData(dataSet) {
  if (
    "data" in dataSet
    && ("tag" in dataSet || "name" in dataSet)
    && arrayHasTSValArraysOnly(dataSet.data)
  ) return true;
  return false;
}

/**
 * Extract file extension from string
 * @param {string} filename name of file
 * @returns {string} file extension
 */
export function getFileExtension(filename) {
  if (typeof filename === "string" && filename.includes(".")) {
    const parts = filename.split(".");
    const partCount = parts.length;
    const extension = parts[partCount - 1];
    return extension;
  }
  return null;
}
