import React, { useContext, useState, useEffect, useCallback } from "react";

import { Modal, RadioGroup, Radio, Loader, Progress, Button } from 'rsuite';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

import { translate } from "../languages";
import { NarrowScreenContext } from "../context";

const PdfExportModal = (props) => {
  const {
    pdfName,
    showPDFModal,
    onClick
  } = props;

  const { isNarrowScreen }    = useContext(NarrowScreenContext);

  const [orientation, setOrientation] = useState("portrait");
  //const [content, setContent] = useState("single");
  const content = "single";
  const [percent, setPercent] = useState(0);
  const [disabledButton, toggleButton] = useState(false);

  const changeRow = (type) => {
    let rows = Array.from(document.getElementsByClassName('rs-table-row'));
    if (type === "pdf") {
      rows.forEach((row) => {
        let style = row.getAttribute('style');
        let top = row.style.transform.split(",")[1]?.split(")")[0];
        if (style && style.indexOf('translate') !== -1) {
          row.style.position = 'absolute';
          row.style.top = top;
          row.style.left = "0px";
          row.style.transform = "";
        }
      });
    } else {
      rows.forEach((row) => {
        let top = row.style.top;
        row.style.position = '';
        row.style.top = "";
        row.style.left = "";
        row.style.transform = `translate(0px, ${top})`;
      })
    }
  }
  const exportPDF = useCallback( () => {
    changeRow("pdf");
    const cards = Array.from(document.getElementsByClassName("muuri-item"));
    const tableLoaders = document.querySelectorAll(".rs-table-loader-wrapper");
    tableLoaders.forEach((loader) => {
      loader.remove();
    });
    const canvases = [];
    const promises = [];
    const pdf = new jsPDF(orientation, 'px', "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    for (let card in cards) {
      promises.push(
        html2canvas(cards[card],
          {
            logging: false,
            useCORS: true,
            allowTaint: true,
            scale: 1
          }
        ).then(canvas => {
          canvases.push(canvas);
          setPercent( parseInt(canvases.length / cards.length * 100) );
        }).catch((error)=> { console.log(error);})
      );
    }

    Promise.all(promises).then(() => { // Save PDF after all elements are added to it.
      let widthLeft = pdfWidth;
      let heightLeft = pdfHeight;
      let xPos = 0;
      let yPos = 0;
      let canvasHeights = [];
      const addedMargin = 1;
      for (let canvas of canvases.sort((a,b) => (a.height > b.height) ? 1 : (b.height > a.height) ? -1 : 0)) { //smallest canvases first
        /* Calculate width and height of the canvas */
        const imgData = canvas.toDataURL('image/png'); 
        const imgProps = pdf.getImageProperties(imgData);
        let canvasWidth = content === "single" ? pdfWidth : canvas.width / 4;
        let canvasHeight = (imgProps.height * canvasWidth) / imgProps.width;
        /* Calculate how much free space there is left if canvas is added to pdf */
        widthLeft = widthLeft - canvasWidth - addedMargin;
        
        /* If single content is selected, each canvas is added in a new page */
        if (content === "single") {
          /* Keep aspect ratio if pdf height is smaller than canvas height */
          if (canvasHeight > pdfHeight) {
            canvasWidth =  pdfHeight * imgProps.width / imgProps.height;
            canvasHeight = pdfHeight;
          }
          pdf.addImage(imgData, 'PNG', xPos, yPos, canvasWidth, canvasHeight);
          /* Add new page if there is still canvases left */
          if (pdf.internal.getNumberOfPages() < canvases.length) {
            pdf.addPage();
          }
        } 

        /* content === "multiple" */
        else {
          /* if canvas can be added, add it, set new xPos, and store canvas height */
          if (widthLeft >= 0 && canvasHeight < heightLeft) {
            pdf.addImage(imgData, 'PNG', xPos, yPos, canvasWidth, canvasHeight);
            xPos = pdfWidth - widthLeft; //starting point of the next canvas
            canvasHeights.push(canvasHeight);
          } 
          /* Start new line when there's no more width left */
          else {
            heightLeft = heightLeft - Math.max( ...canvasHeights ) - addedMargin; //calculate height left
            canvasHeights = []; //reset heights
            widthLeft = pdfWidth; //reset widthLeft
            xPos = 0; //reset xPos
            /* if canvas can be added, set correct yPos, and add canvas */
            if (canvasHeight < heightLeft) {
              yPos =  pdfHeight - heightLeft;
              pdf.addImage(imgData, 'PNG', xPos, yPos, canvasWidth, canvasHeight);
              widthLeft = widthLeft - canvasWidth - addedMargin;
              xPos = canvasWidth + addedMargin; //starting point of the next canvas
            }
            /* if there's no enough height left, add a new page and add canvas there */
            else {
              pdf.addPage(); //add new page
              heightLeft = pdfHeight; //reset heightLeft
              yPos = 0; //reset yPos
              pdf.addImage(imgData, 'PNG', xPos, yPos, canvasWidth, canvasHeight);
              widthLeft = widthLeft - canvasWidth - addedMargin;
              xPos = canvasWidth + addedMargin; //starting point of the next canvas
            }
          }
        }
      }
      pdf.save(`${pdfName}.pdf`);
      toggleButton(false);
    }).then(()=> { changeRow("html"); })
  },[content, orientation, pdfName])

  useEffect(() => { // run exportPDF after button is disabled
    if (disabledButton) {
      exportPDF();
    }
  },[disabledButton, exportPDF])

  const GetRadioButton = ({buttonValue, buttonText, icon}) => {
    return(
      <Radio 
        value={buttonValue}
        style={{
          width:"50%", 
          textAlign:"center"
        }}
      >
        <div className="p-2">
          <div className="d-block">
            <i className = {`fas ${icon} fa-3x`} />
          </div>
          <div className="mt-2">
            {translate(buttonText)}
          </div>
        </div>
      </Radio>
    );
  }

  const loaderText = translate("Cloning document...");

  return (
    <Modal 
      size={isNarrowScreen ? "xs" : "md"} 
      open={showPDFModal} 
      onClose={()=> { onClick(); setPercent(0) }}
    >
      <Modal.Header>
        <Modal.Title>
          {translate("Export PDF")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="d-block mb-3">
          {translate("Orientation")}:
        </div>
      
        <RadioGroup 
          inline
          name="orientation" 
          appearance="picker" 
          style={{width: "100%"}}
          value={orientation}
          onChange={(value) => {
            setOrientation(value);
          }}
        >
          <GetRadioButton
            buttonValue = "portrait"
            buttonText = "Portrait"
            icon = "fa-grip-vertical"
          />

          <GetRadioButton
            buttonValue = "landscape"
            buttonText = "Landscape"
            icon = "fa-grip-horizontal"
          />

        </RadioGroup>

        {/* <div className="d-block mb-3 mt-3">
          {translate("Content per page")}:
        </div>

        <RadioGroup 
          inline
          name="content" 
          appearance="picker" 
          style={{width: "100%"}}
          value={content}
          onChange={(value) => {
            setContent(value);
          }}
        >

          <GetRadioButton 
            buttonValue = "single"
            buttonText = "single"
            icon = "fa-square"
          />

          <GetRadioButton
            buttonValue = "multiple"
            buttonText = "multiple"
            icon = "fa-th-large"
          />

        </RadioGroup> */}

      </Modal.Body>

      <Modal.Footer>
        <div>
          {percent > 0 &&
            <div className="d-inline-block me-3">
              {percent !== 100 && 
                <div className="d-inline-block me-3">{translate("Processing PDF")}:</div>
              }
              <div style={{ width: 50, display: "inline-block" }}>
                <Progress.Circle 
                  percent = {percent} 
                  strokeColor = { percent === 100 ? "#52c41a" : "#3385ff" } 
                  status = {percent === 100 ? "success" : null} 
                />
              </div>
            </div> 
          }

          {disabledButton && percent === 0 &&
            <div className="me-3 d-inline-block">
              <Loader speed="slow" content={loaderText} />
            </div>
          }
          <div className="d-inline-block">
            <Button
                onClick={()=>{
                  setPercent(0);
                  toggleButton(true);
                }}
                disabled={disabledButton} 
                appearance="primary" 
                color="green"
              > {translate("Export PDF")}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default PdfExportModal;
