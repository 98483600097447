import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import { RadioGroup, Radio } from 'rsuite';
import { translate } from "../../languages";
import { Loader } from "rsuite";
import { SingleMultiline } from "./";

export default function MultilineChart(props) {

  const { 
    chartData, 
    tagsInfo, 
    movingAverageData,
    trendsInCharts, 
    setTrends, 
    handleZoom,
    loading
  } = props;

  const [multilineData, setMultilineData] = useState();
  const [linesReady, setReady] = useState();

  useEffect(() => { // handles chart size when chart is resized
    const resizeObserver = new ResizeObserver((entries) => {
      for (let i = 0; i < Highcharts.charts.length; i++) {
        if (Highcharts.charts[i] !== undefined) {
          Highcharts.charts[i].reflow();
        }
      }
    });
    resizeObserver.observe(document.getElementById("tagsearch"));
    return () => { resizeObserver.disconnect(); }
  },[]);

  useEffect(()=> {
    if (!linesReady) { setReady(true); }

    const showSynchronizedTooltips = (e) => {
      let chart, points, i, event;
      let filteredCharts = Highcharts.charts.filter(n => n !== undefined ).filter(n => n.container.offsetParent.id === "multilines");
      for (i = 0; i < filteredCharts.length; i++) {
        chart = filteredCharts[i];
        event = chart.pointer.normalize(e); // Find coordinates within the chart
        if (trendsInCharts === 1){ points = [chart.series[0].searchPoint(event, true) ]}
        else if (trendsInCharts === 2) { 
          if (chart.series.length === 2) {
            points = [chart.series[0].searchPoint(event, true),  chart.series[1].searchPoint(e, true)];
          } else {
            points = [chart.series[0].searchPoint(event, true)];
          }
        }
        else {
          if (chart.series.length === 3) {
            points = [chart?.series[0].searchPoint(event, true),  chart?.series[1].searchPoint(e, true), chart?.series[2].searchPoint(e, true)];
          } else if (chart.series.length === 2) {
            points = [chart?.series[0].searchPoint(event, true),  chart?.series[1].searchPoint(e, true)];
          } else {
            points = [chart?.series[0].searchPoint(event, true)];
          }
        }
        if (points[0] && points[0]?.series?.visible) { points[0].onMouseOver(); }
        if (points[1] && points[1]?.series?.visible) { points[1].onMouseOver(); }
        if (points[2] && points[2]?.series?.visible) { points[2].onMouseOver(); }
        if (points[0] && points[0]?.series?.visible) {
          chart.tooltip.refresh(points.filter(x => x !== undefined));
          chart.xAxis[0].drawCrosshair(e, points[0]);
        }
      }
    }

    /* charts in multiline view */
    let charts =  Array.from(document.querySelectorAll("#tagsearch .highcharts-container"));

    for (let c = 0; c < charts.length; c++) { // add mousemove, touchmove and touchstart events in each multiline chart
      ['mousemove', 'touchmove', 'touchstart'].forEach((event) => 
        charts[c].addEventListener(event, showSynchronizedTooltips)
      )
    }

    const originalReset = Highcharts.Pointer.prototype.reset;

    Highcharts.Pointer.prototype.reset = function () { //crosshair stays visible in every chart
      return undefined
    }

    return () => { // return the original reset when component is closed
      Highcharts.Pointer.prototype.reset = originalReset;
    };

  },[trendsInCharts, linesReady]);

  useEffect(()=> {
    if (chartData.length > 0 ) {
      setMultilineData(chartData.map(data => { 
        if (tagsInfo && tagsInfo.some(tag => tag.id === data.id)) {
          let movingAvgValue = null;
          let lineText = null;
          if (movingAverageData && movingAverageData.some(tag => tag.id === data.id)) {
            movingAvgValue = parseFloat(movingAverageData.find(tag => tag.id === data.id).data[0]).toFixed(2);
          }
          let tagObj = tagsInfo.find(tag => tag.id === data.id);
          if (tagObj.description){lineText = tagObj.description}
          else {lineText = tagObj.tagname}
          return {...data, name: movingAvgValue ? `${lineText} (MA: ${movingAvgValue})` : lineText, yAxis: 0} 
        } else { return data }
      } ));
    }

    /* Zoom out when chart data changes*/
    for (let i = 0; i < Highcharts.charts.length; i++) {
      if (Highcharts.charts[i] !== undefined) {
        Highcharts?.charts[i]?.zoomOut();
      }
    }

  },[chartData, tagsInfo, movingAverageData]);

  const createOptions = () => {
    return(
      <div>
        <table>
          <tbody>
            <tr>
              <td><span>Trends in chart:</span></td>
              <td>
                <RadioGroup defaultValue={trendsInCharts} name="trends_in_chart" inline onChange={value => setTrends(parseInt(value))}>
                  <Radio value={1}>1</Radio>
                  <Radio value={2}>2</Radio>
                  <Radio value={3}>3</Radio>
                </RadioGroup>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  const createChart = () => {
    const charts = [];
    if (trendsInCharts === 1 ) {
      for (let i=0; i < multilineData.length; i++) {
        charts.push(  
          <SingleMultiline
            key={trendsInCharts + "multiline_" + i}
            trends = {1}
            data = {[multilineData[i]]}
            handleZoom={handleZoom}
            trendsInCharts={trendsInCharts}
          />
        );
      }
    } else if (trendsInCharts === 2) {
      for (let i=0; i < multilineData.length;) {
        let filteredData = multilineData.filter((_, index) => {return index === i || index === i+1} ).map((data, index) => { return {...data, yAxis: index}});
        charts.push(  
          <SingleMultiline
            key={trendsInCharts + "multiline_" + i}
            trends = {2}
            data = {filteredData}
            handleZoom={handleZoom}
            trendsInCharts={trendsInCharts}
          />
        );
        i = i + 2;
      }
    } else if (trendsInCharts === 3) {
      for (let i=0; i < multilineData.length;) {
        let filteredData = multilineData.filter((_, index) => {return index === i || index === i+1 || index === i+2} ).map((data, index) => { return {...data, yAxis: index}});
        charts.push(  
          <SingleMultiline
            key={trendsInCharts + "multiline_" + i}
            trends = {2}
            data = {filteredData}
            handleZoom={handleZoom}
            trendsInCharts={trendsInCharts}
          />
        );
        i = i + 3;
      }
    }
    return charts;
  }

  const getLoadingBlur = () => {
    return(
      <div style={{ backgroundColor: "rgba(242, 242, 242, 0.7)", position: "absolute", height: "100%", width: "100%", zIndex:"10" }}>
        <div className="d-flex align-items-center justify-content-center h-100">
          <div style={{ display: "inline-block" }}>
            <Loader size="lg" />
          </div>
          <div className="d-inline-block ms-4" style={{ fontSize:"3rem" }}>{translate("Loading data")}</div>
        </div>
      </div>
    );
  }

  return(
    <div id="multilines" style={{position: "relative"}} key={trendsInCharts}>
      {loading && getLoadingBlur()}
      {createOptions()}
      {multilineData && createChart()}
    </div>
  );
}