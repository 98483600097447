import React, { useState, useContext } from "react";

import { Whisper, Tooltip, Loader } from "rsuite";
import { useIntl } from "react-intl";
import { MsalContext } from "@azure/msal-react";

import { sharedSettings } from ".";
import {
  fetchDataHelper,
  popNotification
} from "../utils";
import {
  TrackingContext,
  PanelAlertContext,
  UseAuthenticationContext
} from "../context";
import { translate } from "../languages";

/**
 * Panel ellipsis menu alerting component
 * @param {any} props props
 * @returns {JSX.Element} panel alerting button component
 */
export default function PanelAlertButton(props) {
  const {
    panelId,
    site,
    alertEnabled
  } = props;

  const {
    trigger,
    delayShow,
    placementInMoreIcon
  } = sharedSettings;

  const [loading, setLoading] = useState(false);

  const { track } = useContext(TrackingContext);
  const { setPanelAlerts } = useContext(PanelAlertContext);
  const { useAuthentication } = useContext(UseAuthenticationContext);
  const msalContext = useContext(MsalContext);
  const email = msalContext?.accounts?.[0]?.username;

  const intl = useIntl();

  function toggleLoaderOn() {
    setLoading(true);
  }

  function handleErrorResponse() {
    setLoading(false);
    popNotification({
      type: "error",
      text: intl.formatMessage({ id: "Error updating panel alert" })
    });
  }

  /**
   * Set new panel alert config to context
   * @param {object} newPanelAlerts post request response
   */
  function setNewPanelAlerts(newPanelAlerts) {
    const newConfig = newPanelAlerts?.data?.config;
    const message = alertEnabled ? "Panel alert unsubscribed" : "Panel alert subscribed"; 
    if (newConfig) {
      setPanelAlerts(newConfig);
      popNotification({
        type: "success",
        text: intl.formatMessage({ id: message })
      });
    } else {
      popNotification({
        type: "error",
        text: intl.formatMessage({ id: message })
      });
    }
    setLoading(false);
  }

  function handleClick() {
    // send to subscription/unsubscription to tracking
    track({
      type: "panel-alert-toggle",
      action: "click",
      source: panelId,
      target: alertEnabled ? "unsubscribe" : "subscribe",
      site
    });

    // change panel alert status in backend
    fetchDataHelper({
      url: `/boa/api/v1/${site}/panel-alerts`,
      method: "POST",
      data: {
        site,
        panel: panelId,
        email,
        type: alertEnabled ? "unsubscribe" : "subscribe"
      },
      useAuthentication,
      authContext: msalContext,
      onFetchStart: toggleLoaderOn,
      onSuccess: setNewPanelAlerts,
      onError: handleErrorResponse
    });
  }

  function getIcon() {
    if (loading) return <Loader />;
    if (alertEnabled) return <i className="far fa-bell" data-testid="panel-alert-button-icon" />;
    return <i className="far fa-bell-slash" data-testid="panel-alert-button-icon" />;
  }

  function getText() {
    if (loading) return "Handling alert status change";
    if (alertEnabled) return "Panel alerting is on";
    return "Panel alerting is off";
  }

  return(
    <Whisper
      speaker={
        <Tooltip>
          {translate("Toggle panel alerting")}
        </Tooltip>
      }
      trigger={trigger}
      placement={placementInMoreIcon}
      delayShow={delayShow}
    >
      <div
        className="more-item-container pointer"
        onClick={() => handleClick()}
        data-testid="panel-alert-button-container"
      >
  
        <div className="more-item-icon-container">
          {getIcon()}
        </div>

        <div className="more-item-text-container">
          <p className="more-text">
            {translate(getText())}
          </p>
        </div>

      </div>
    </Whisper>
  );
}
