import React, { useState, useEffect } from 'react';
import { Button, Drawer, IconButton, Input, InputGroup } from 'rsuite';
import { translate } from "../languages";
import { getColors } from "../utils";
import { cloneDeep } from "lodash";

import EditIcon from '@rsuite/icons/Edit';
import ColorPicker from './colorPicker';
import YAxisHeightSelector from './yAxisHeightSelector';

// Tag format example:[
// [
//     "653-ka-478", (name)
//     "Operation Time", (description)
//     1, (index)
//     "-", (unit)
//     "#0000ff" (color)
//     {min: null, max: null} (min/max height)
// ]

const TagCustomizer = (props) => {
  const { style, screenSize, tags, onChange, chartType } = props;
  const [open, setOpen] = useState(false);
  const [editedTags, setEditedTags] = useState({});
  const [defaultValues, setDefaultValues] = useState({});

  useEffect(() => {
    setEditedTags(tags);
    setDefaultValues(cloneDeep(tags));
  }, [tags]);

  const colorPalette = getColors().slice(0, 13);

  const updateTags = (key, value, endpoint, tagIndex) => {
    setEditedTags((prevTags) => {
        const updatedTags = { ...prevTags };
        if (updatedTags[endpoint] && Array.isArray(updatedTags[endpoint])) {
          const tagsArray = [...updatedTags[endpoint]];
          if (key === "color") {tagsArray[tagIndex][4] = value; } 
          else if (key === "description") {tagsArray[tagIndex][1] = value; } 
          else if (key === "unit") {tagsArray[tagIndex][3] = value; } 
          else if (key === "yAxisHeight") {tagsArray[tagIndex][5] = value; }
          updatedTags[endpoint] = tagsArray;
        }
        return updatedTags;
    });
  };

  const saveChanges = () => {
    onChange(editedTags);
    setDefaultValues(cloneDeep(editedTags));
    setOpen(false);
  };

  const createInputs = () => {
    let currentTagIndex = 0; 
    const addTagCount = (tagCount) => { currentTagIndex += tagCount; };
    const calculateIndex = (index) => { return currentTagIndex + index; };

    const inputs = [];
    for (let endpoint of Object.keys(editedTags)) {
      inputs.push(
        editedTags[endpoint].map((tag, index) => {
          return (
            <div key={`${endpoint}-${index}`} className="flex-container">
              <InputGroup>
                <InputGroup.Addon>{translate('Description')}</InputGroup.Addon>
                <Input
                  value={tag[1]}
                  onChange={(value) => updateTags("description", value, endpoint, index)}
                />
              </InputGroup>

              <InputGroup style={{flex: "0 1 120px"}}>
                <InputGroup.Addon>{translate('Unit')}</InputGroup.Addon>
                <Input
                  value={tag[3]}
                  onChange={(value) => updateTags("unit", value, endpoint, index)}
                />
              </InputGroup>

              {(chartType === "Line" || chartType === "Bar") && (
                <YAxisHeightSelector
                  screenSize={screenSize}
                  defaultValues={tag[5]}
                  onChange={(value) => updateTags("yAxisHeight", value, endpoint, index)}
                />
              )}

              {(chartType !== "Gauge") && (
                <div className="flex-item">
                  <ColorPicker 
                    key={editedTags}
                    colors={colorPalette}
                    defaultColor={ tag[4] || colorPalette[calculateIndex(index)] }
                    onChange={(color) => updateTags("color", color, endpoint, index)} 
                  />
                </div>
              )}
            </div>
          );
        })
      );
      addTagCount(editedTags[endpoint].length);
    }

    return inputs;
  };

  return (
    <>
      <IconButton
        icon={<EditIcon className="white-icon" />}
        style={style}
        onClick={() => setOpen(true)}
      >
        {translate('Edit tags')}
      </IconButton>

      <Drawer
        open={open}
        size={screenSize < 585 ? 'full' : 'sm'}
        placement='bottom'
        onClose={() => setOpen(false)}
      >
        <Drawer.Header>
          <Drawer.Title>{translate('Tag editor')}</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => saveChanges()} appearance="primary" color="green">
              {translate('Save')}
            </Button>
            <Button onClick={() => { setOpen(false);  setEditedTags(cloneDeep(defaultValues)); }} appearance="primary" color="red">
              {translate('Cancel')}
            </Button>
          </Drawer.Actions>
        </Drawer.Header>

        <Drawer.Body className='tag-editor-body'>
          {editedTags ? (
            <div key={editedTags}>
              {createInputs()}
            </div>
          ) : (
            <div>{translate("No tags selected")}</div>
          )}
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default TagCustomizer;