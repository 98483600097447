import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withAllContext } from "../context";
import { translate } from "../languages";

class SubMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubmenu: false,
    };
  }

  handleClick = () => {
    // this.setState((prevState) => ({ showSubmenu: !prevState.showSubmenu }));
    this.setState((prevState) => ({ showSubmenu: !prevState.showSubmenu }));
  };

  render() {
    // const path = this.props.path;
    // const site = this.props.site;
    // const order = this.props.order;
    // const menuItems = this.props.menuItems;
    const arr = [];

    //loops submodule links
    for (let link in this.props.order) {
      //loads layout items
      for (let layout in this.props.menuItems.layouts) {
        const menuItem = this.props.menuItems.layouts[layout];
        //pick right icon and text for submodule link
        if (layout === this.props.order[link]) {
          const icon = menuItem.icon;
          const text = menuItem.text;
          arr[link] = (
            <li
              key={"submenulink" + this.props.path + link}
              className={"parent"}
              style={{ backgroundColor: "#2a2a2a" }}
            >
              <Link 
                to={{ pathname: `/${this.props.site}/${layout}` }}  
                onClick={() => {
                  if (this.props.isNarrowScreen) {
                    this.props.onClick();
                  }
                  this.props.track(
                    {
                      type: "page_change",
                      action: "click",
                      source: "side_menu",
                      target: layout,
                      site: this.props.site
                    },
                    this.props.useAuthentication,
                    this.props.msalContext
                  );
                }}
              >
                <i className={"me-1 fas " + icon} />
                {translate(text)}
              </Link>
            </li>
          );
        }
      }
    }
    return (
      <>
        {
          this.state.showSubmenu
          ? (
            <Link to="#" onClick={this.handleClick} style={{ float: "right" }}>
              <i className={"fas fa-minus"} />
            </Link>
          )
          : (
            <Link to="#" onClick={this.handleClick} style={{ float: "right" }}>
              <i className={"fas fa-plus"} />
            </Link>
          )
        }

        <ul style={{ padding: "0px" }}>
          {this.state.showSubmenu ? arr.map((item) => item) : null}
        </ul>
      </>
    );
  }
}

export default withAllContext(SubMenu);
