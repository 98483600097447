const sharedSettings = {
  trigger: "hover",
  placementInHeader: "left",
  placementInMoreIcon: "left",
  placementInAnalysisDrawer: "top",
  analysisDrawerIconSize: "2x",
  delayShow: 1000,
  sharedClasses: "header-button fas hvr-grow pointer fsb"
};

export default sharedSettings;
