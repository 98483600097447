/* built-in modules */
import React from "react";
/* 3rd-party modules */
import { Link } from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
/* self-provided modules */
import {
  LangProvider,
  translate,
  locales
} from "../languages";
import { withAllContext } from "../context";

const SiteNav = (props) => {
  const {
    menuItems,
    translations,
    selectedLanguage,
    site,
    collapsed,
    appActive,
    activeSubMenu = "",
    topButton,
    endpointsExists
  } = props;

  const width = "270px";  

  const getIconClass = (icon) => { return `fa-lg fas ${icon}`; }

  const parseTopButton = () => {
    return (
      <MenuItem 
        key="Top" 
        icon={<i className={getIconClass("fa-arrow-up")} /> }
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} 
      >
        <button 
          style={{ 
            background: 'none', 
            border: 'none', 
            color: 'inherit', 
            cursor: 'pointer', 
            padding: 0 
          }}
        >
          {translate("TOP")}
        </button>
      </MenuItem>
    )
  }

  const createMenuItem = (appContent, appLink) => {
    return (
      <MenuItem 
        key={appLink} 
        active={appLink === appActive} 
        icon={<i className={getIconClass(appContent.icon)} />}
      >
        {translate(appContent.text)}
        <Link to={{ pathname: `/${site}/${appLink}` }} />
      </MenuItem>
    )
  }

  const parseMenu = () => {
    let apps = Object.entries(menuItems.apps);
    if (endpointsExists) {
      apps.unshift([
        "custom_panels",
        {
          "text": "Custom Panels",
          "icon": "fas fa-cogs"
        }
      ]);
    }
    if (localStorage.getItem(`${site}-favorites`)) {
      let savedFavorites = JSON.parse(localStorage.getItem(`${site}-favorites`));
      if (savedFavorites.length > 0 ) {
        apps.unshift([
          "favorites",
          {
            "text": "Favorites",
            "icon": "fas fa-star"
          }
        ]);
      }
    }
    let sideNav = apps.map(app => {
      let appLink = app[0];
      let appContent = app[1];
      return appContent.children ? (
        <SubMenu
          key={appLink}
          title={translate(appContent.text)}
          defaultOpen={appLink === activeSubMenu.split("/")[0]}
          icon={<i className={getIconClass(appContent.icon)} />}
        >
          {Object.entries(appContent.children).map((children) => {
            appLink = `${app[0]}/${children[0]}`;
            appContent = children[1];
            return appContent.children ? (
              <SubMenu
                key={appLink}
                title={translate(appContent.text)}
                defaultOpen={appLink === activeSubMenu}
                icon={<i className={getIconClass(appContent.icon)} />}
              >
                {Object.entries(appContent.children).map((subChildren) => {
                  appLink = `${app[0]}/${children[0]}/${subChildren[0]}`;
                  appContent = subChildren[1];
                  return createMenuItem(appContent, appLink);
                })}
              </SubMenu>
            ) : (
              createMenuItem(appContent, appLink)
            )
          })}
        </SubMenu>
     ) : (
      createMenuItem(appContent, appLink)
     )
    })
    return sideNav;
  }
  return (
    <LangProvider
      translations={translations}
      locale={locales[selectedLanguage]}
    >   
      <ProSidebar  
        collapsed={collapsed}
        style={{maxWidth:width}}
        collapsedWidth={75}
      >
        <Menu iconShape="round">
          {topButton
            ? parseTopButton()
            : parseMenu()
          }
        </Menu>
      </ProSidebar>
    </LangProvider>
  );
}

export default withAllContext(SiteNav);
