import React, { useState, useEffect, useContext, useRef } from "react";
import { InputNumber, DatePicker, DateRangePicker, Message, useToaster } from "rsuite";
import { addDays } from 'date-fns';
import { Link } from "react-router-dom";
import { isFunction, cloneDeep } from "lodash";
import { Equations } from ".";
import { useIntl } from 'react-intl';
import { translate, checkAndTranslate } from "../languages";
import { ThemeContext, SiteContext } from "../context";
import TagSearchModal from "../modals/tagSearchModal";

export default function ReportItem(props) {
  const {
    fetchedData,
    queryParams,
    table,
    code,
    time,
    setStateOfInputs,
    submitForm,
    socket,
    socket_values
  } = props;

  const { timeZone, site } = useContext(SiteContext);
  const { theme } = useContext(ThemeContext);
  const intl = useIntl();

  const [selectedTab, setSelectedTab] = useState();
  const [paginationButtons, setPaginationButtons] = useState();
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationLimits, setPaginationLimits] = useState();
  const [inputs, setInputs] = useState();
  const [sortStatus, setSortStatus] = useState({});
  const [tagData, setTagData] = useState();
  const [showTagSearchModal, openTagSearchModal] = useState(false);
  const [pasteTime, setPasteTime] = useState(new Date());

  const toaster = useToaster();
  const { afterToday } = DateRangePicker;

  const tableRef = useRef();
  const tableBodyRef = useRef();
  const tabs = table.tabs ? table.tabs : table;

  useEffect(() => { // set default tab when table is loaded
    if (table.tabs) {
      setSelectedTab(Object.keys(table.tabs)[0]);
    } else { setSelectedTab(); }
  }, [table, tabs])

  /* handle default input values */
  useEffect(()=> {
    if (!inputs) { //set default input values
      const defaultInputs = [];
      let tab;
      if (selectedTab && selectedTab === Object.keys(table.tabs)[0]) { 
        tab = selectedTab;
      } else if (table.tabs) { 
        tab = Object.keys(table.tabs)[0]; 
      }
      const tbody = table?.tbody ?? table?.[tab]?.tbody ?? [];
      try {
        Object.values(tbody).forEach(row => {
          row.cells.forEach(cell => {
            const input = getDefaultInputValue(cell);
            if (input) {
              defaultInputs.push(input);
            }
          });
        });
      } catch (error) { 
        console.error(error.message); 
      }
      if (defaultInputs.length > 0) {
        setInputs(defaultInputs); 
      }
    }

    function getDefaultInputValue(cell) {
      if (cell.numberInput?.name && typeof cell.numberInput.defaultValue === "number") {
        return { name: cell.numberInput.name, value: cell.numberInput.defaultValue };
      }
      
      if (cell.submitBtn?.name) {
        return { name: "submit", value: false };
      }
      
      if (cell.datePicker?.name) {
        const baseDate = new Date();
        const startDateOffset = cell.datePicker.removeDay ? cell.datePicker.removeDay - 1 : -1;
        const endDateOffset = cell.datePicker.removeDay ? cell.datePicker.removeDay : 0;
        return {
          ...cell.datePicker,
          value: {
            sdt: addDays(baseDate, startDateOffset),
            edt: addDays(baseDate, endDateOffset)
          }
        };
      }
    }
  }, [inputs, selectedTab, table, tabs]);

  /* wait after input change to minimize queries */
  useEffect(() => {
    if (inputs && !inputs.some(input => input.name === "submit")) {
      const timeOutId = setTimeout(() => {
        if (isFunction(setStateOfInputs)) { setStateOfInputs(inputs); }
      }, 1000);
      return () => clearTimeout(timeOutId);
    }
  }, [inputs, setStateOfInputs]);

  useEffect(() => {
    if (socket_values?.current && socket?.api) {
      socket_values.current.on(socket.api, (data) => {
        let dataObject = typeof(data) === "string" ? JSON.parse(data) : data;
        let updateCell = document.getElementById(`${dataObject.name}-last`);
        if (updateCell) { updateCell.innerHTML = dataObject.data[1].toFixed(2); }
      });
    }
  }, [socket_values, socket]);

  const removeStripes = (boolean) => {
    Promise.resolve().then(() => {
      if (boolean) {
        tableRef?.current?.classList.remove('table-striped');
      } else {
        tableRef?.current?.classList.add('table-striped');
      }
    });
  };

  /* create Pagination Buttons and set visible rows limits if they are used in the table */
  useEffect( () => {
    try {
      const report = selectedTab ? tabs[selectedTab] : tabs;
      if (report.maxVisibleRows) {
        const maxVisibleRows = report.maxVisibleRows; //how many rows are shown at once
        const rowCount = Object.keys(report.tbody).length; //how many rows table has
        const remainder = rowCount % maxVisibleRows; //defines if an extra button is needed to show the remaining rows
        const buttonsNeeded = remainder ? parseInt(rowCount/maxVisibleRows) + 1 : parseInt(rowCount/maxVisibleRows);
        const startIndex = maxVisibleRows * paginationPage - maxVisibleRows; // (10 * 2) - 10 = 10
        const maxValue = maxVisibleRows * paginationPage; // (10 * 2) = 20
        const buttons = [];
        for (let index = 1; index <= buttonsNeeded; ++index) {
          buttons.push( 
            <input 
              key={"paginationBtn"+index}
              type="button"
              className={paginationPage === index ? "selected-pagination pagination" : "pagination"} 
              value={index} 
              onClick={() => { setPaginationPage(index); setPaginationLimits( { start: startIndex, end: maxValue } ); }}
            /> 
          );
        }
        setPaginationLimits( { start: startIndex, end: maxValue } );
        setPaginationButtons(
          <ul className="paginationButtons">  
            {buttons.map(button => button)}
          </ul>
        );
      } else {
        setPaginationLimits();
        setPaginationButtons();
        setPaginationPage(1);
      }
    } catch (error) { console.error(error.message); }
  }, [paginationPage, selectedTab, tabs]);

  const showErrorMessage = (error) => {
    let errorMessage;
    switch (error) {
      case "tabs is undefined" : errorMessage = translate("tabs is undefined"); break;
      case "tabs.map is not a function" : errorMessage = translate("tabs.map is not a function"); break;
      case "tabs[selectedTab] is undefined": errorMessage = translate("tabs[selectedTab] is undefined"); break;
      case "can't convert undefined to object" : errorMessage = translate("can't convert undefined to object"); break;
      case "tabs[selectedTab].tbody[row].cells is undefined" : errorMessage = translate("tabs[selectedTab].tbody[row].cells is undefined"); break;
      case "cells is not iterable" : errorMessage = translate("cells is not iterable"); break;
      default : errorMessage = error;
    }
    return <div className="p-2">{translate("Table error")} : {errorMessage}</div>;
  }

  const handlePaste = (e) => {
    if (inputs && inputs.some(input => input.value)) { // pasting works only in tables that have input fields
      let clipboardData = e.clipboardData.getData("text").trim('\r\n').split("\t").filter(n => n); // format excel data
      let rsInputs = Array.from(e.target.closest(`table`).querySelectorAll(`.rs-input`)).map(a => a.id); // all input fields in table
      let inputIndex = rsInputs.indexOf(e.target.id); //input field where data was pasted
      const newInputs = cloneDeep(inputs);
      let clipboardIndex = 0;
      if (inputIndex >= 0) {
        for (let input in newInputs) {
          let newIndex = parseInt(input) + parseInt(inputIndex);
          if (newIndex >= newInputs.length){ break; }
          if (!clipboardData[clipboardIndex]){ break; } //break the loop if clipboard data is processed already
          if ( 
            newInputs[newIndex] && 
            typeof(newInputs[newIndex].value) === "number"
          ) {
            newInputs[newIndex].value = parseFloat(clipboardData[clipboardIndex].replace(/\s/g, '').replaceAll(',', '.'));
            clipboardIndex = clipboardIndex + 1; //index + 1 after value is pasted in correct field
          }
        }
        setInputs(newInputs);
        setPasteTime(new Date());
      }
    }
  }

  const createTable = () => {
    try {
      const tableClasses = "table-sm table-striped";
      return(
        <div className="tab table-responsive">
          {selectedTab && createTableTabs()}
          {paginationButtons}
          <table
            id={`${code}table`}
            ref={tableRef}
            className={theme === "dark" ? `${tableClasses} table-dark` : tableClasses} 
            style={{ width: "100%", borderRadius: "2px" }}
            onPaste={handlePaste}
          >
            {createTableHeader()}
            {createTableBody()}
          </table>
        </div>
      );
    } catch (error) { return showErrorMessage(error.message); }
  }

  const createTableTabs = () => {
    const tabClasses = "btn btn-inline btn-secondary";
    return(
      <div>
        {Object.keys(tabs).length > 1 && (
          <div className="tab-buttons">
            {Object.keys(tabs).map((tab, index) => (
              <div
                key={"tabButton" + index + selectedTab}
                className={tab === selectedTab ? `${tabClasses} selected` : tabClasses}
                onClick={() => setSelectedTab(tab)}
              >
                {translate(tab)}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  const createTableHeader = () => {
    const report = selectedTab ? tabs[selectedTab] : tabs;
    const rowCount = Object.keys(report.tbody).length;
    const sortEnabled = report.disableSorting ? false : rowCount > 3;
    return (
      <thead className="tableHeader">
        <tr>
          {report.thead && (
            report.thead.map((head, i) => {
              // Check if head is an object with title and style, otherwise treat it as a string
              const content = typeof head === 'object' ? translate(head.title) : translate(head);
              const style = typeof head === 'object' 
              ? { 
                  ...head.style, 
                  cursor: !report.maxVisibleRows && sortEnabled ? "pointer" : "default"
                } 
              : { 
                  cursor: !report.maxVisibleRows && sortEnabled ? "pointer" : "default"
                };
            
            return (
              <td
                key={`${code}_head${i}`}
                onClick={() => sortEnabled && sortFunction(i)}
                style={style}
              >
                {content}
                {sortEnabled && getSortIcon(i)}
              </td>
            );
            })
          )}
        </tr>
      </thead>
    );
  }

  const getSortIcon = (index) => { //Function returns icon based on sort status (up/down/default)
    const visibleRows = selectedTab ? tabs[selectedTab].maxVisibleRows : tabs.maxVisibleRows
    if (!visibleRows) { //pagination doesn't have sorting
      if (sortStatus?.head !== index) { return <i className="ms-1 fas fa-sort" /> } //default icon
      else if (sortStatus?.head === index && sortStatus?.asc) { return <i className="ms-1 fas fa-sort-up" /> } //asc order icon
      else if (sortStatus?.head === index && !sortStatus?.asc) { return <i className="ms-1 fas fa-sort-down" /> } //desc order icon
    }
  }

  const sortFunction = (index) => {
    const visibleRows = selectedTab ? tabs[selectedTab].maxVisibleRows : tabs.maxVisibleRows
    if (!visibleRows) { //pagination doesn't have sorting
      setSortStatus({ head: index, asc: !sortStatus.asc}); //update which cell was clicked
      const tableBodyRows = Array.from(tableBodyRef.current.children); //array of <tr> elements
      tableBodyRows.sort((a,b) => { //sort rows
        if (Array.from(a.childNodes)[index] && Array.from(b.childNodes)[index]) { //check that cell exists
          let value1 = Array.from(a.childNodes)[index].textContent;
          let value2 = Array.from(b.childNodes)[index].textContent;
          if (value1 !== '' && value2 !== '' && !isNaN(value1) && !isNaN(value2)) { //sort number values
            return sortStatus.asc ? value1 - value2 : value2 - value1;
          } else { //sort string values
            return sortStatus.asc ? value1.toString().localeCompare(value2) : value2.toString().localeCompare(value1);
          }
        } else { return null; }
      }).forEach((tr) => tableBodyRef.current.appendChild(tr)); //display <tr> elements in the correct order
    }
  }

  const createTableBody = () => {
    const tableRows = [];
    const tbody = selectedTab ? tabs[selectedTab].tbody : tabs.tbody;
    const rowKeys = Object.keys(tbody);
    const tbodyCss = selectedTab && tabs[selectedTab]?.table?.tbodyCss ? tabs[selectedTab].table.tbodyCss : tabs?.tbodyCss;
    for (let row of rowKeys) {
      let index = rowKeys.findIndex(key => key === row);
      let cells = tbody[row].cells;
      // Use optional chaining and nullish coalescing operator to provide a fallback empty object
      const rowCss = tbody[row].rowCss ?? {};
      let cellCss = {};
      let tableCell = [];
      if (!paginationLimits || (index >= paginationLimits.start && index < paginationLimits.end)) {
        for (let cell of cells) {
          let cellIndex = cells.indexOf(cell);
          let highlightCss = cell?.highlight?.find(h => h.condition.over > 5)?.highlightCss ?? {};
          cellCss = { ...(cell.cellCss ?? {}), ...(highlightCss ?? {}) };

          tableCell.push(createCell(cell, cellIndex, cellCss));
        }

        removeStripes(rowCss.backgroundColor || cellCss.backgroundColor);

        tableRows.push(<tr key={`row_${row}`} id={`row_${row}`} style={{ ...rowCss }}>{tableCell}</tr>);
      }
    }
    return <tbody ref={tableBodyRef} id={`${code}tbody`} style={tbodyCss}>{tableRows}</tbody>; // Apply tbodyCss to <tbody>
  }

  const handleNumberInputChange = (value, name, min= -Infinity, max = Infinity) => {
    if (value && value <= max && value >= min) {
      const newInputs = cloneDeep(inputs);
      let replace = newInputs.find(n=> n.name === name);
      replace.value = value;
      setInputs(newInputs);
    }
  }

  const handleSubmit = () => {
    const newInputs = cloneDeep(inputs);
    let replace = newInputs.find(n=> n.name === "submit");
    replace.value = true;
    let text = checkAndTranslate("Data sent!", intl);
    toaster.push(<Message type="success">{text}</Message>);
    submitForm(newInputs);
  }

  const handleDateChange = (value, name) => {
    const newInputs = cloneDeep(inputs);
    for (let inputs of newInputs) {
      if (inputs.useInput === name) {
        let sdt = addDays(new Date(value), inputs.removeDay -1);
        let edt = addDays(new Date(value), inputs.removeDay);  
        inputs.value = { 
          sdt: sdt,
          edt: edt
        };
      }
    }
    let replace = newInputs.find(n=> n.name === name);
    let sdt = addDays(new Date(value), -1);
    let edt = value;
    replace.value = { 
      sdt: sdt, 
      edt: edt
    };
    setInputs(newInputs);
  }

  const constructSubmitBtn = ({name, width = "100%" }) => {
    return(
      <button
        type = "submit" 
        className="customInputField"
        onClick={ () => { handleSubmit() } }
        style={{ width: width, minWidth: "75px"}}
      >
        {name}
      </button>
    );
  }

  const constructNumberInput = ({name, defaultValue = null, step = 1, min = -Infinity, max = Infinity, width = "100%", postfix = null, multiplyIfDecimal }, index) => {
    let inputValue;
    if (inputs && inputs.some(n => n.name === name)) { inputValue = inputs.find(n => n.name === name).value }
    else { inputValue = defaultValue; }
    if (!Number.isInteger(inputValue) && multiplyIfDecimal) { inputValue = inputValue * multiplyIfDecimal; } // fix value if thousands separator is used
    return(
      <InputNumber
        key={pasteTime}
        id={`inputNumber_${index}`}
        defaultValue={inputValue}
        step={step}
        min={min}
        max={max}
        scrollable={false}
        postfix={postfix}
        onChange={(value) => handleNumberInputChange(parseFloat(value), name, min, max) }
        style={{width: width, minWidth:"75px"}}
      />
    );
  }

  const constructDatepicker = ({
    name, 
    removeDay = 0, 
    width = "100%", 
    readOnly = false, 
    useInput = null, 
    format = "dd.MM.yyyy", 
    oneTap = true ,
    utcMidnight = false
  }) => {
    let inputValue;
    if (inputs && inputs.some(n => n.name === name)) { inputValue = inputs.find(n => n.name === name).value.edt }
    else if (useInput && inputs && inputs.some(n => n.name === name)) { inputValue = addDays(new Date(inputs.find(n => n.name === name).value.sdt), inputs.find(n => n.name === name).value.removeDay) }
    else { inputValue = addDays(new Date(), removeDay) }
    if (utcMidnight) {inputValue.setUTCHours(0, 0, 0); }
    return(
      <DatePicker
        key={inputValue}
        id={name}
        readOnly={readOnly}
        defaultValue={inputValue}
        format={format}
        appearance={readOnly ? "subtle" : "default"}
        ranges={[]}
        oneTap={oneTap}
        cleanable={false}
        onChange={(value) => handleDateChange(value, name) }
        shouldDisableDate={afterToday()}
        value={inputValue}
        style={{width: width, minWidth:"75px", padding: 0}}
        className={readOnly ? "rs-input noCaret" : "rs-input"}
      />
    );
  }

  const handleCellHighlighting = (rules, value) => {
    let highlightStyle = {}; 
    for (let rule of rules) {
      if (rule.condition) {
        if (typeof(rule.condition.over) === "number" && typeof(rule.condition.under) === "number") {
          if (rule.condition.over < value && rule.condition.under > value) { highlightStyle = rule.highlightCss; }
        } else if (typeof(rule.condition.over) === "number") {
          if (rule.condition.over < value) { highlightStyle = rule.highlightCss; }
        } else if (typeof(rule.condition.under) === "number") {
          if (rule.condition.under > value) { highlightStyle = rule.highlightCss; }
        } else if (typeof(rule.condition.equal) === "number") {
          if (rule.condition.equal === value) { highlightStyle = rule.highlightCss; }
        }
      }
    }  
    return highlightStyle;
  }

  const convertToTime = (value) => {
    let secs = value;
    let hours = Math.floor(secs / 3600);
    secs %= 3600;
    let minutes = Math.floor(secs / 60);
    let seconds = secs % 60;
    if (parseFloat(seconds) >= 30) {
      minutes = parseFloat(minutes) + 1;
    }
    /* add leading zeroes */
    minutes = String(minutes).padStart(2, "0");
    hours = String(hours).padStart(2, "0");
    seconds = String(seconds).padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  const filterFetchedData = (tags) => {
    /* tags are either inside an array or inside an object */
    let tagNames = []; // filtering requires the tag names in array
    if (Array.isArray(tags)) { tagNames = tags; } // ["tag_1", "tag_2"]
    else { tagNames = Object.values(tags).map(variable => variable.tag); } // {"x": {"tag": "tag_1", "choose": "last"}}
    /* After the tag names array is ready, correct series can be added to cell's series array */
    let series = [];
    for (let tag of tagNames) {
      if (fetchedData.some(data => data.seriesName === tag)) {
        series.push(fetchedData.find(data => data.seriesName === tag));
      } else if (fetchedData.some(data => data.id === tag)) {
        series.push(fetchedData.find(data => data.id === tag));
      } else if (fetchedData.some(data => data.name === tag)) {
        series.push(fetchedData.find(data => data.name === tag));
      }
    }
    /* return series object if only 1 tag, otherwise return series array */
    return tagNames.length === 1 && series.length > 0 ? series[0] : series;
  }

  const createCellId = (cell, index) => {
    if (cell.tag && cell.choose === "last") { return `${cell.tag}-${cell.choose}`; }
    else if (cell.tag) { return `${cell.tag}-${index}`; }
    else { return index; }
  }

  const createCell = (cell, index, cellCss) => {
    let cellDataSeries = [];
    if (cell.tags) { cellDataSeries = filterFetchedData(cell.tags) }
    else if (cell.tag) { cellDataSeries = filterFetchedData([cell.tag]) }
    const singleDataSeries = Array.isArray(cellDataSeries) ? false : true;
    const dataSet = Array.isArray(cellDataSeries) ? cellDataSeries.map(series => series.data) : cellDataSeries.data;
    const colSpan = cell.colSpan || 1;
    const cellId = createCellId(cell, index);
    let cellValue;
    let highlightColors;
    let classNames = "tableCell";
    
    if (cell.classNames) { classNames += ` ${cell.classNames}`; }
    if (cell.showData) { classNames += " showData"; }
    if (cell.choose || cell.calculate) { 
        cellValue = Equations(dataSet, cell, singleDataSeries, time, timeZone); 
    }
    else if (cell.title) { 
        cellValue = cell.title; 
    } 
    else if (cell.numberInput) { 
        cellValue = constructNumberInput(cell.numberInput, index); 
    }
    else if (cell.datePicker) { 
        cellValue = constructDatepicker(cell.datePicker); 
    }
    else if (cell.submitBtn) { 
        cellValue = constructSubmitBtn(cell.submitBtn); 
    }
    else if (cell.image) { 
        cellValue = (<img style={{ width: "100%" }} alt={cell.tag} src={`data:image/png;base64,${dataSet}`} />);
    }
    else if (cell.link) { 
        cellValue = (<Link to={`/${site}/${cell.link.url}`}>{cell.link.text}</Link>);
    }
    else if (cell.tag && dataSet) { 
        if (typeof(dataSet[0]) === "string") { 
            cellValue = dataSet[0]; 
        } else if (typeof(dataSet[0]) === "number" || Array.isArray(dataSet[0]) ) { 
            cellValue = parseFloat(dataSet); 
        } else { 
            cellValue = "-"; 
        }
    }
    
    if (Number.isInteger(cell.decimals) && typeof(cellValue !== "string")) { 
        cellValue = parseFloat(cellValue).toFixed(cell.decimals); 
    }
    
    if (cell.highlight) { 
        highlightColors = handleCellHighlighting(cell.highlight, cellValue); 
    }
    
    if (cellDataSeries.css) { 
        highlightColors = cellDataSeries.css; 
    }
    
    if (cell.result) {
        if (cellValue > cell.result.max) { 
            cellValue = cell.result.max; 
        } else if (cellValue < cell.result.min) { 
            cellValue = cell.result.min; 
        }
    }
    
    if (cell.convert === "hhmm") { 
        cellValue = convertToTime(cellValue); 
    }
    
    if (typeof(cellValue) === "boolean") { 
        cellValue = cellValue.toString(); 
    }
    else if (cellValue === undefined || cellValue === "NaN") { 
        cellValue = "-"; 
    }
    
    // Combine highlightColors and cellCss, with cellCss taking precedence
    const combinedStyle = { 
        ...highlightColors, 
        ...cellCss
    };
    
    return (
      <td 
        key={index} 
        id={cellId} 
        colSpan={colSpan} 
        className={classNames} 
        style={combinedStyle} // Apply combined styles here
        onClick={cell.showData ? () => { openTagSearchModal(true); setTagData(cell.showData);} : null }
      > 
        {typeof(cellValue) === "string" ? checkAndTranslate(cellValue, intl) : cellValue}
      </td>
    );
  }

  return(
    <div>
      {fetchedData && createTable()}
      {showTagSearchModal && (
        <TagSearchModal 
          show={showTagSearchModal} 
          onHide={()=> openTagSearchModal(false)} 
          showData={tagData}
          fetchedData={fetchedData}
          queryParams={queryParams}
        />
      )}
    </div>
  );
}