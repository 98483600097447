import React, { useEffect, useContext, useRef, useState, useCallback } from "react";

import {
  LangProvider,
  locales,
  translate
} from "../languages";
import {
  acquireToken,
  logErrorIfDevelopmentMode,
  getBoaURL
} from "../utils";

import { Badge, Button } from "rsuite";
import axios from "axios";

import {
  MsalContext
} from "@azure/msal-react";
import { 
  SiteContext,  
  UseAuthenticationContext,
  LanguageContext
} from "../context";

export default function StatusBlock(props) {
  const { 
    statusMonitoring,
    style 
  } = props;
  
  const [statusArray, setStatusArray]       = useState([]);
  const [showStatusList, setShowStatusList] = useState(false);

  const { site }                            = useContext(SiteContext);
  const { useAuthentication }               = useContext(UseAuthenticationContext);
  const msalContext                         = useContext(MsalContext);
  const { translations, selectedLanguage }  = useContext(LanguageContext);

  let componentMounted                       = useRef(true);

  /**
   * Function fetches the last value of tags that are listed in api json.
   * Status is "red" when the timestamp is older than defined time range.
   */
  const fetchStatus = useCallback(async() => {
    try {
      let headers = {};
      if (useAuthentication && msalContext.accounts[0]) { // modify headers when cloud version is used
        const accessToken = await acquireToken(msalContext);
        headers = {authorization: `Bearer ${accessToken.idToken}`};
      }
      const tagGroupArray = [];
      for (let tagGroup of Object.keys(statusMonitoring)) {
        const endpoint = statusMonitoring[tagGroup].endpoint || `/boa/api/v1/${site}/history`;
        const queryParams = statusMonitoring[tagGroup].queryParams || "func=last";
        const url = useAuthentication ? getBoaURL() + endpoint : endpoint; //add boa url when cloud version is used
        tagGroupArray.push({
          url: `${url}?${queryParams}&tags=${statusMonitoring[tagGroup].followUpTags.map(tagObj => tagObj.tag).join()}`,
          minuteRange: statusMonitoring[tagGroup].minuteRange,
          groupTitle: statusMonitoring[tagGroup].groupTitle,
          group: tagGroup
        });
      }
      axios.all(tagGroupArray.map(group => axios.get(group.url, {headers} )))
      .then(axios.spread((...response) => {
        const statusTagArray = [];
        for (let index in response) {
          for (let tagIndex in response[index].data) {
            if (parseInt(tagIndex) === 0) { statusTagArray.push({group: tagGroupArray[index].groupTitle}); }
            if (
              Array.isArray(response[index].data) &&
              Array.isArray(response[index].data[tagIndex].data) && 
              Array.isArray(response[index].data[tagIndex].data[0]) && 
              typeof(response[index].data[tagIndex].data[0][0]) === "number"
            ) {
              const timeRange = tagGroupArray[index].minuteRange;
              const currentTime = new Date().getTime();
              const minTime = currentTime - (60 * 1000 * timeRange);
              const colorCode = response[index].data[tagIndex].data[0][0] > minTime ? "green" : "red";
              const tagTitle = statusMonitoring[tagGroupArray[index].group].followUpTags[tagIndex].title;
              const tagStatus = {title: tagTitle, status: colorCode};
              statusTagArray.push(tagStatus);
            } else { statusTagArray.push({title: "Error", status: "red"}); }
          }
        }
        if (componentMounted.current) { setStatusArray(statusTagArray); }
      }))
      .catch(error => setStatusArray( [{title: error.message, status: "red"}] ) );
    } catch (error) {
      logErrorIfDevelopmentMode(error);
      setStatusArray( [{title: error.message, status: "red"}] );
    }
    
  },[msalContext, site, statusMonitoring, useAuthentication])

  /**
   * Fetch status info when mounted
   * Update status info every minute
   * Clear interval when unmounted
   */
  useEffect(() => {

    fetchStatus();

    const fetchStatusInterval = setInterval(() => {
      fetchStatus();
    }, 60*1000);

    return () => {
      componentMounted.current = false;
      clearInterval(fetchStatusInterval);
    }

  }, [fetchStatus, componentMounted] );
  
  /**
   * Toggle the visibility of status list.
   * true/false, default: false.
   */
  const toggleShowStatusList = () => {
    setShowStatusList(!showStatusList)
  }

  /**
   * ToggleButton toggles the visibility of status list
   */
  const ToggleButton = () => {
    return(
      <Button
        onClick={() => toggleShowStatusList()}
        className = "statusBlockButton"
      >
        {translate("Status Monitoring")}
        <i className = {showStatusList ? "fas fa-angle-up ms-2" : "fas fa-angle-down ms-2" }></i>
      </Button>
    );
  }

  /**
   * Wraps toggle button inside badge component.
   * Badge has a red dot when one of the tags have "red" status.
   */
  const ToggleButtonWrapper = () => {
    if (statusArray.filter(tag => tag.status === "red").length > 0) {
      return(
        <Badge content = {statusArray.filter(tag => tag.status === "red").length}>
          <ToggleButton />
        </Badge>
      );
    } else {
      return(
        <Badge color = "green">
          <ToggleButton />
        </Badge>
      );
    }
  }

  /* StatusList shows all the tags and their statuses: "green"/"red". */
  const StatusList = () => {
    return(
      <div className="statusBlockList" >
        {statusArray.map((tag, index) => {
          if (tag.group) {
            return (
              <div key = {"tagStats" + index} className = "title d-block border-bottom">
                <h5>{tag.group}</h5>
              </div>
            );
          } else if (tag.status) {
            return (
              <div key={"tagStats" + index} className="d-inline-block mb-1 col-6 col-md-4 col-xxl-3" >
                <div className = "d-inline-block">
                  <div
                    className = "d-inline-block me-2"
                    style = {{ height: "15px", width: "15px", backgroundColor: tag.status, borderRadius: "50%" }}
                  >
                  </div>

                  {translate(tag.title)}

                </div>
              </div>
            );
          } else { return null; }
        })}
      </div>
    );
  }

  /**
   * Render ToggleButton without badge component when statusArray is not fetched yet
   * Render StatusList when showStatusList is true
   */
  return (
    <LangProvider
      translations={translations}
      locale={locales[selectedLanguage]}
    >   
      <div style={style}>
        {statusArray.length > 0 ? <ToggleButtonWrapper/> : <ToggleButton/>}
        {showStatusList && <StatusList/>}
      </div>
    </LangProvider>
  );
}
