import { cloneDeep } from "lodash";
import { getTimeparams, getColor, pairArraymaths } from "../utils";
import { Equations } from ".";


/**
 * Filters the fetched data based on the provided chart configuration.
 *
 * @param {Object} chartConfig - The configuration object for the chart.
 * @param {Array} fetchedData - The data fetched for the chart.
 * @returns {Array} - The filtered data based on the chart configuration.
 */
export function filterData(chartConfig, fetchedData) {
  if (Array.isArray(chartConfig.useTags)) { return fetchedData.filter(data => chartConfig.useTags.includes(data.id)); } 
  else if (Number.isInteger(chartConfig.sourceIndex)) { return fetchedData.filter(data => data.sourceIndex === chartConfig.sourceIndex); } 
  else if (Array.isArray(chartConfig.sourceIndex)) { return fetchedData.filter(data => chartConfig.sourceIndex.includes(data.sourceIndex)); } 
  else { return fetchedData; }
}

/**
 * Converts an array of data into a pie chart series object.
 * @param {Array} series - The array of data to be converted.
 * @param {string} theme - The theme to be used for the chart.
 * @returns {Array} - The pie chart series object.
 */
export function pieChartMaths(series, theme) {
  /* create series that is needed in pie charts */
  const pieSeries = [ { name: "Value", data: [] } ];
  /* populate pieSeries with series data */
  for (let serie of series) {
    const visible = serie.visible === false ? false : true;
    if (visible) {
      if (serie.zones) { pieSeries[0].zones = serie.zones; }
      if (serie.dataLabels) { pieSeries[0].dataLabels = serie.dataLabels; }
      if (serie.data) {
        /* data is already in pie chart format e.g. data: [{"y": 0.5,"name": "value 1"}] */
        if (serie.data[0] && typeof(serie.data[0].y) === "number") {
          for (let pieData of serie.data) {
            pieSeries[0].data.push({
              name: pieData.name ? pieData.name : serie.name,
              color: serie.zones ? null : (serie.color ? serie.color : getColor(theme, serie.data.indexOf(pieData))),
              y: pieData.y
            });
          }
        } 
        /* data is in timeseries format e.g. data: [[1646636400000,204.55673726194982]] */
        else if (serie.data[0]) {
          pieSeries[0].data.push({
            name: serie.name,
            color: serie.zones ? null : (serie.color ? serie.color : getColor(theme, serie.data.indexOf(serie))),
            y: Math.abs(serie.data[0][1])
          })
        }
      }
    }
  }
  /* if series has "visible = true" it should overwrite other series */
  if (series.some(data => data.visible === true)) {
    const dataIndex = series.findIndex(data => data.visible === true); // index of data that should be used
    pieSeries[0].data = [pieSeries[0].data[dataIndex]]; //replace data with the correct one
    const visibleSeries = series.find(data => data.visible === true); //series that should be used
    pieSeries[0] = {...pieSeries[0], ...visibleSeries}; // combine series and pieseries
  }
  return pieSeries;
}

/**
 * Constructs boolean data for a chart.
 * @param {Array} series - The series data.
 * @param {number} y - The y-coordinate value.
 * @param {string} color - The color of the data.
 * @param {string} endDate - The end date of the chart.
 * @param {string} time - The time parameter.
 * @returns {Array} - The constructed boolean data.
 */
export function constructBooleanData (series, y, color, endDate, time) {
  const data = [];
  if (series && series[0]) {
    let startTsInMillis = series[0][0];
    let endTsInMillis = null;
    /* Every value is 1 */
    if (series.every(value => value[1] === 1)) {
      const timerange = getTimeparams(time, "useTimestamp");
      data.push({ x: startTsInMillis, x2: timerange.edt, y, color: color, value: 1 });
    } 
    /* Handle changes */  
    else {
      for (let i = 0; i < series.length; i++) {
        let currentValue = series[i][1];
        let previousValue = i === 0 ? 0 : series[i - 1][1];
        /* If the value changes, add a new data point 
         * endTsInMillis is the next timestamp when value changes 
         * if the value doesn't change anymore endTsInMillis is the last timestamp of the series
         */
        if (currentValue && currentValue !== previousValue) {
          startTsInMillis = series[i][0];
          const nextChange = series.find((data, index) => index > i && data[1] !== series[i][1]);
          endTsInMillis = nextChange ? nextChange[0] : series[series.length - 1][0];
          data.push({
            x: startTsInMillis,
            x2: endTsInMillis,
            y,
            color: color,
            description: currentValue,
            value: currentValue,
          });
        }
      }
      /* If the last value is 1, add a data point to the end date */
      if (series[series.length - 1][1] === 1 && endDate) {
        data.push({
          x: series[series.length - 1][0],
          x2: new Date(endDate).getTime(),
          y,
          color: color,
          description: 1,
          value: 1,
        });
      }
      /* every value is 0 */
      if (data.length === 0) {
        data.push({
          x: startTsInMillis,
          x2: startTsInMillis,
          y,
          color: `${color}33`,
          value: 0,
        });
      }
    }
  } 
  /* If there is no data, add a transparent data point */
  else {
    const timerange = getTimeparams(time, "useTimestamp");
    data.push({ x: timerange.sdt, x2: timerange.edt, y, color: "transparent", value: 0 });
  }
  return data;
};

  export function addSeries(chart, fetchedData, theme, queryParams, time, timeZone) {

    let series = [];
    /* Add series that are defined in chart config */
    if (chart.series?.length > 0) { series = cloneDeep(chart.series); }     
    
    /* Filter fetched data and add them to series array */
    for (let filteredSeries of filterData(chart, fetchedData)) { series.push(filteredSeries); }

    /* parse series to work in pie charts */
    if (chart?.chart?.type === "pie") { series = pieChartMaths(series, theme); }

    if (chart.seriesParser) {  // series parser
      try {
        let parsedSeries = [];
        for (let seriesParser of chart.seriesParser) {
          let seriesData;
          let seriesName = seriesParser.choose;
          let seriesObj = seriesParser;
          let singleDataSeries = true;
          let addArray = true; // highcharts series data requires value to be inside an array
          if (seriesParser.tag) {
            if (series.some(series => series.seriesName === seriesParser.tag)) {
              seriesData = series.find(series => series.seriesName === seriesParser.tag).data;
              seriesName = `${seriesParser.tag} - ${seriesParser.choose}`
            } else {
              seriesData = [];
              seriesName = "-"
            }
          } else if (seriesParser.tags) {
            seriesData = series.filter(series => Object.values(seriesParser.tags).map(tag => tag.tag).includes(series.seriesName)).map(series => series.data);
            seriesName = seriesParser.name || "serie";
            singleDataSeries = false;
          } else {
            seriesData = series[chart.seriesParser.indexOf(seriesParser)].data; //index of seriesParser object
            seriesName = seriesParser.name;
            addArray =  seriesParser.choose ? true : false; //If seriesParser doesn't have tags or choose object, seriesData is already inside an array
          }
          let value = Equations(
            seriesData,
            seriesObj,
            singleDataSeries,
            time,
            timeZone
          );
          let object = seriesParser.props || {};
          let mergedSeries = Object.assign(object, {name: seriesName, data: addArray ? [value] : value});
          parsedSeries.push(mergedSeries);
        }
        series = parsedSeries;
      } catch (error) {
        return [{ name: "error in series parser", data: ["-"] }];
      }
    }

    if (chart?.plotOptions?.xrange) {
      let yAxis = 0;
      let converted_xranges = series.filter(s => s.type === "xrange");  
      for (let xrange of converted_xranges) {
        let index = series.indexOf(xrange);
        let endDate = queryParams.length > 0 ? queryParams[0]?.edt : null; // xrange series index should be 0
        let x = {
          ...xrange,
          data: constructBooleanData(xrange.data, yAxis, xrange.color, endDate, time),            
        };
        yAxis = yAxis + 1;
        series[index] = x;
      }
    }

    return([...series]);
  }

export function yParser(chartOptions, fetchedData) {
  let yParser = chartOptions.yParser;
  let centerValue;
  let plotBands = [];
  let yParserData;
  if (Array.isArray(chartOptions.sourceIndex)) {
    let data = fetchedData.filter(dataId => chartOptions.sourceIndex.includes(dataId.sourceIndex))
    yParserData = data.filter(data => data.sourceIndex === yParser.serieIndex);
  } else {
    yParserData = fetchedData.filter(data => data.sourceIndex === yParser.serieIndex);
  }
  for (let y in yParserData) {
    let correctSeries;
    let yAxisId = 0;
    if (yParser.id) {
      correctSeries = yParserData[y].seriesName === yParser.id ? yParserData[y] : null;
    } else {
      correctSeries = yParserData[y];
      yAxisId = y;
    }
           
    if (correctSeries) {
      if (typeof(yParser.middleValue) === "number") {
        centerValue = yParser.middleValue;
      } else {
        centerValue = pairArraymaths(correctSeries.data, yParser.middleValue);      
      }			  
      if (yParser.middleOf && yParser.middleUntil && centerValue) {
        chartOptions.yAxis[yParser.yIndex].update({
          max: centerValue + yParser.middleOf,
          min: centerValue + yParser.middleUntil
        });
      }				
      for (let x = 0; x < yParser.plotBands.length; x++) {
        let band = {
          from: parseFloat(centerValue) + yParser.plotBands[x].ofMiddleValue,
          to: parseFloat(centerValue) + yParser.plotBands[x].untilOfValue,
          color: correctSeries.color ? correctSeries.color : yParser.plotBands[x].bandColor
        }
        plotBands.push(band);
        if (chartOptions.yAxis[yAxisId]) {
          chartOptions.yAxis[yAxisId] = Object.assign(chartOptions.yAxis[yAxisId],{plotBands: plotBands });  
        } else {
          chartOptions.yAxis.push({ plotBands: plotBands });  
        }
      } 
    }
  }   
  return chartOptions;
}