import React, { Component, createContext } from "react";

const LanguageContext = createContext();

class LanguageProvider extends Component {
  state = {
    selectedLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE,
    availableLanguages: [],
    translations: null
  };

  componentDidMount() {
    const storedSelectedLanguage = localStorage.getItem("selectedLanguage");
    if (storedSelectedLanguage) {
      this.setState(
        { selectedLanguage: storedSelectedLanguage },
        this.checkIfSelectedLanguageAmongAvailableLanguages
      );
    }
  }

  setTranslations = (translations) => {
    this.setState({ translations });
  }

  changeSelectedLanguage = (language) => {
    localStorage.setItem("selectedLanguage", language);
    this.setState({ selectedLanguage: language });
  }

  /**
   * Validate (default) selection of language. If available languages
   * have been retrieved from the backend and set into state here,
   * check if currently selected language exists among available languages
   * for site. If not, set the first occurrence as selected language.
   */
  checkIfSelectedLanguageAmongAvailableLanguages() {
    if (
      this.state.availableLanguages.length > 0
      && !this.state.availableLanguages.includes(this.state.selectedLanguage)
    ) {
      this.changeSelectedLanguage(this.state.availableLanguages[0]);
    }
  }

  setAvailableLanguages = (languages) => {
    this.setState(
      { availableLanguages: languages },
      this.checkIfSelectedLanguageAmongAvailableLanguages
    );
  }

  render() {
    return(
      <LanguageContext.Provider
        value={{
          selectedLanguage: this.state.selectedLanguage,
          changeLanguage: this.changeSelectedLanguage,
          availableLanguages: (
            this.props._testOverride && this.props._testAvailableLanguages
            ? this.props._testAvailableLanguages
            : this.state.availableLanguages
          ),
          setAvailableLanguages: this.setAvailableLanguages,
          translations: this.state.translations,
          setTranslations: this.setTranslations
        }}
      >
        {this.props.children}
      </LanguageContext.Provider>
    );
  }
}

export { LanguageContext, LanguageProvider };
