import React from "react";

import { withMsal } from "@azure/msal-react";
import { injectIntl } from "react-intl";
import { flowRight } from "lodash";

import {
  LanguageContext,
  LanguageProvider,
  CookieContext,
  CookieProvider,
  ThemeContext,
  ThemeProvider,
  UseAuthenticationContext,
  UseAuthenticationProvider,
  TrackingContext,
  TrackingProvider,
  NarrowScreenContext,
  NarrowScreenProvider,
  SiteContext,
  SiteProvider,
  PanelAlertContext,
  PanelAlertProvider,
  HeaderContext,
  HeaderProvider
} from ".";

export const AppContextProvider = ({
  selectedLanguage,
  changeLanguage,
  cookiesAccepted,
  setCookiesAccepted,
  translations,
  setTranslations,
  theme,
  changeTheme,
  useAuthentication,
  track,
  isNarrowScreen,
  site,
  setSite,
  timeZone,
  setTimeZone,
  panelAlerts,
  setPanelAlerts,
  header,
  setHeader,
  children
}) => (
  /** Function creates a combined React Context.Provider
   * to supply context information to children. Note:
   * as panel alert context uses other contexts
   * in its logic, it is critical that whenever context
   * is used through providers, UseAuthenticationProvider
   * and SiteProvider contain PanelAlertProvider.
   * 
   * @param {Object} props  Prop-like object containing
   *                        information from the provided contexts.
   * 
   * @return {React.Component}  React Context.Provider Component
   *                            wrapping child Components.
   */
  <LanguageProvider
    value={{
      selectedLanguage,
      changeLanguage,
      translations,
      setTranslations
    }}
  >
    <CookieProvider value={{ cookiesAccepted, setCookiesAccepted }}>
      <ThemeProvider value={{ theme, changeTheme }}>
        <UseAuthenticationProvider value={useAuthentication}>
          <TrackingProvider value={{ track }}>
            <NarrowScreenProvider value={{ isNarrowScreen }}>
              <SiteProvider value={{ site, setSite, timeZone, setTimeZone }}>
                <PanelAlertProvider value={{ panelAlerts, setPanelAlerts }}>
                  <HeaderProvider value={{ header, setHeader }}>
                    {children}
                  </HeaderProvider>
                </PanelAlertProvider>
              </SiteProvider>
            </NarrowScreenProvider>
          </TrackingProvider>
        </UseAuthenticationProvider>
      </ThemeProvider>
    </CookieProvider>
  </LanguageProvider>
);

export const withAppContext = Component => {
  /** Function acts as a higher order component (HOC)
   * accepting a React Component as parameter and
   * wrapping it multiple React Context Consumers.
   * Each of the Consumers add their own context
   * information as props to the wrapped Component.
   * Note: as panel alert context uses other contexts
   * in its logic, it is critical that whenever context
   * is used through providers, UseAuthenticationProvider
   * and SiteProvider contain PanelAlertProvider.
   * 
   * @param {React.Component} Component Component to wrap
   * 
   * @return {React.Component} Wrapped component
   */
    const WrappedComponent = props => (
      <LanguageContext.Consumer>
        {languageContext => (
          <CookieContext.Consumer>
            {cookieContext => (
              <ThemeContext.Consumer>
                {themeContext => (
                  <UseAuthenticationContext.Consumer>
                    {useAuthenticationContext => (
                      <TrackingContext.Consumer>
                        {trackingContext => (
                          <NarrowScreenContext.Consumer>
                            {narrowScreenContext => (
                              <SiteContext.Consumer>
                                {siteContext => (
                                  <PanelAlertContext.Consumer>
                                    {panelAlertContext => (
                                      <HeaderContext.Consumer>
                                        {headerContext => (
                                          <Component
                                            selectedLanguage={languageContext.selectedLanguage}
                                            changeLanguage={languageContext.changeLanguage}
                                            availableLanguages={languageContext.availableLanguages}
                                            setAvailableLanguages={languageContext.setAvailableLanguages}
                                            translations={languageContext.translations}
                                            setTranslations={languageContext.setTranslations}
                    
                                            cookiesAccepted={cookieContext.cookiesAccepted}
                                            setCookiesAccepted={cookieContext.setCookiesAccepted}
                    
                                            theme={themeContext.theme}
                                            changeTheme={themeContext.changeTheme}
                    
                                            useAuthentication={useAuthenticationContext.useAuthentication}
              
                                            track={trackingContext.track}
              
                                            isNarrowScreen={narrowScreenContext.isNarrowScreen}
                    
                                            site={siteContext.site}
                                            setSite={siteContext.setSite}
                                            timeZone={siteContext.timeZone}
                                            setTimeZone={siteContext.setTimeZone}

                                            panelAlerts={panelAlertContext.panelAlerts}
                                            setPanelAlerts={panelAlertContext.setPanelAlerts}
          
                                            header={headerContext.header}
                                            setHeader={headerContext.setHeader}
      
                                            {...props}
                                          />
                                        )}
                                      </HeaderContext.Consumer>
                                    )}
                                  </PanelAlertContext.Consumer>
                                )}
                              </SiteContext.Consumer>
                            )}
                          </NarrowScreenContext.Consumer>
                        )}
                      </TrackingContext.Consumer>
                    )}
                  </UseAuthenticationContext.Consumer>
                )}
              </ThemeContext.Consumer>
            )}
          </CookieContext.Consumer>
        )}
      </LanguageContext.Consumer>
    );
  WrappedComponent.displayName = "withAppContext";

  return WrappedComponent;
  };

/** Function uses composition from lodash's flowRight
 * function to compose multiple higher-order components
 * (HOCs) into one re-usable HOC. This reduces repetition
 * and increases clarity, but increases component state/prop
 * complexity slightly. The order probably does not matter.
 */
export const withAllContext = flowRight([injectIntl, withMsal, withAppContext]);
