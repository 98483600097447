import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { isFunction } from "lodash";
import { Modal, Button } from "rsuite";
import { IntlContext } from "react-intl";

import { locales, translate } from ".";
import { LanguageContext } from "../context";

function LanguageSelect(props) {
  const { 
    languages, 
    onClose 
  } = props;

  const {
    selectedLanguage,
    changeLanguage
  } = useContext(LanguageContext);
  const intl = useContext(IntlContext);

  const [language, setLanguage] = useState(selectedLanguage);
  const [availableLanguages, setAvailableLangauges] = useState([]);

  /** Loop available languages and construct language selection options */
  useEffect(() => {
    const keys = Object.keys(locales);
    const values = Object.values(locales);
    const arr = [];
    
    // Add only site specific languages that are defined in site config
    if (languages) {
      for (let i = 0; i < keys.length; i+=1) {
        const one_obj = {value: keys[i], label: intl.formatMessage({ id: values[i] }).toUpperCase()};
        if (languages.includes(one_obj.value)) {
          arr.push(one_obj);
        }
      }
    }
    
    // Add all available languages that are shown in site selection screen
    else {
      for (let i = 0; i < keys.length; i+=1) {
        const one_obj = {value: keys[i], label: intl.formatMessage({ id:values[i] }).toUpperCase()};
        arr.push(one_obj);
      }
    }

    setAvailableLangauges(arr);
  }, [languages, intl]);

  /**
   * This function helps in filtering the
   * default selected language to insert
   * into the selection field.
   * @param {string} language selected language
   * @returns default language
   */
  function filterDefault(language) {
    const options = availableLanguages;
    for (let i = 0; i < options.length; ++i) {
      if (options[i].value === language) {
        return options[i];
      }
    }
  }

  function handleClose() {
    if (isFunction(onClose)) {
      onClose();
    }
  }

  function getRenderContent() {
    let content = null;

    if (
      selectedLanguage
      && (!languages || languages.length > 1)
    ) {
      const styles = {
        control: styles => ({ ...styles, width: "120px"}),
        option: styles => ({ ...styles, width: "auto" }),
        menu: styles => ({ ...styles, width: "auto" })
      };
      const defaultOption = filterDefault(selectedLanguage);

      content = (
        <>

          <Modal.Header>
            <h3>
              {translate("Change language")}
            </h3>
          </Modal.Header>

          <Modal.Body>
            <Select
              classNamePrefix="react-select"
              defaultValue={defaultOption}
              onChange={(event) => {setLanguage(event.value)}}
              options={availableLanguages}
              styles={styles}
              menuPosition="bottom"
              isSearchable={false}
              placeholder={translate("Select...")}
            />
            {availableLanguages.map(language => language.value).includes(language) &&
              <div className="mt-3">
                {intl.formatMessage({ id: "Selected language" }) + ": " + intl.formatMessage({ id:locales[language] }) }
              </div>
            }
          </Modal.Body>

          <Modal.Footer>
            
            <Button 
              onClick={() => { changeLanguage(language); handleClose() }}
              appearance="primary" 
              color="green"
              type="button" 
            > {translate("Set language")}
            </Button>

            <Button 
              onClick={() => { handleClose() }}
              appearance="primary" 
              color="red"
              type="button" 
            > {translate("Close")}
            </Button>

          </Modal.Footer>

        </>
      );
    }

    return content;
  }

  return getRenderContent();
}

export default LanguageSelect;
