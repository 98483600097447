import {
  mean,
  min,
  max,
  std,
  variance,
  median,
  sum
} from "mathjs";

function getLastValue(array) {
  if (Array.isArray(array) && array.length > 0) { return array[array.length - 1]; } 
  else { return "-"; }
}
  
function getCount(array) {
  let count = 0;
  if (Array.isArray(array)) {
    count = array.length;
  }
  return count;
}

export function removeSeriesTimestamps(data, axis=1, abs=false) {
  const ret = [];
  if (data) {
    for (let i = 0; i < data.length; ++i) {
      if (data[i] && typeof data[i][axis] === "number") {
        if (!abs) {
          ret.push(data[i][axis]);
        } else {
          ret.push(Math.abs(data[i][axis]));
        }
      }
    }
  } else {
    ret.push(0)
  }
  return ret;
}

function roundValue(value, decimals=2) {
  if (!isNaN(value)) {
    value = value.toFixed(decimals);
  }
  return value;
}

export class MathUtils {

  static calcMean(dataArray, roundTo=2) {
    const data = removeSeriesTimestamps(dataArray);
    try { return roundValue(mean(data), roundTo) }
    catch { return "-" }
  }

  static calcAbsMean(dataArray, roundTo=2) {
    const data = removeSeriesTimestamps(dataArray, 1, true);
    try { return roundValue(mean(data), roundTo) }
    catch { return "-" }
  }

  static getMin(dataArray, roundTo=2) {
    const data = removeSeriesTimestamps(dataArray);
    try { return roundValue(min(data), roundTo); }
    catch { return "-" }
  }

  static getMax(dataArray, roundTo=2) {
    const data = removeSeriesTimestamps(dataArray);
    try { return roundValue(max(data), roundTo) }
    catch { return "-" }
  }

  static getMaxMin(dataArray, roundTo=2) {
    const data = removeSeriesTimestamps(dataArray);
    try { 
      const maxValue = max(data);
      const minValue = min(data);
      return roundValue(maxValue - minValue, roundTo);
    } catch { return "-" }
  }


  static getLast(dataArray, roundTo=2) {
    const data = removeSeriesTimestamps(dataArray);
    try { return roundValue(getLastValue(data), roundTo) }
    catch { return "-" }
  }

  static getLastTimestamp(dataArray, timeZone) {
    const data = removeSeriesTimestamps(dataArray, 0);
    if (data.length > 0) {
      const format = timeZone.split("/")[0] === "America" ? "en-US" : "en-GB";
      return new Date(getLastValue(data)).toLocaleString(format, {timeZone: timeZone});
    } else {
      return "-"
    }
  }

  static calcStd(dataArray, roundTo=2) {
    const data = removeSeriesTimestamps(dataArray);
    try { return roundValue(std(data), roundTo) }
    catch { return "-" }
  }

  static calcVariance(dataArray, roundTo=2) {
    const data = removeSeriesTimestamps(dataArray);
    try { return roundValue(variance(data), roundTo) }
    catch { return "-" }
  }

  static calcMedian(dataArray, roundTo=2) {
    const data = removeSeriesTimestamps(dataArray);
    try { return roundValue(median(data), roundTo) }
    catch { return "-" }
  }

  static getValueCount(dataArray) {
    return getCount(dataArray);
  }

  static calcSumValues(dataArray) {
    const data = removeSeriesTimestamps(dataArray);
    try { return sum(data);}
    catch { return "-" }
  }

  static aucFunctions(dataArray, func = "mean") {
    if (dataArray) {
      let timestamps = dataArray.map(data => data[0]);
      let values = dataArray.map(data => data[1]);
      let valueCount = dataArray.length -1;

      let totalDuration = timestamps[timestamps.length - 1] - timestamps[0]
      let M = areaUnderCurveWithChanges(values, timestamps) / totalDuration;
      let g = values.map(value => (value - M) ** 2);
      let S = (1 / (timestamps[valueCount] - timestamps[0])) * areaUnderCurveWithChanges(g, timestamps);
      if (func === "var") {
        if (isNaN(S)) { return this.calcVariance(dataArray); }
        else { return roundValue(S); }
      } else if (func === "stdev") {
        if (isNaN(S)) { return this.calcStd(dataArray); }
        else { return roundValue(Math.sqrt(S)); }
      } else {
        if (isNaN(S)) { return this.calcMean(dataArray); }
        else { return roundValue(M); }
      }
    } else { return "-";}

    function areaUnderCurveWithChanges(valueArr, timestampArr) {
      let sum = 0;
      let newValues = [valueArr[0]];
      let newTimestamps = [timestampArr[0]];
      // insert one datapoint 1 ms / ns before change    
      for (let i = 1; i < valueArr.length; i++) {
        if (valueArr[i] !== valueArr[i-1]) {
            newValues.push(valueArr[i-1]);
            newTimestamps.push(timestampArr[i] - 1); 
            newValues.push(valueArr[i]);
            newTimestamps.push(timestampArr[i]);
        } else {
          newValues.push(valueArr[i]);
          newTimestamps.push(timestampArr[i]);
        }
      }
      // calculate area under curve using the trapezoidal rule    
      for (let i = 1; i < newValues.length; i++) { 
        sum += (newTimestamps[i] - newTimestamps[i-1]) * (newValues[i] + newValues[i-1]) / 2; 
      }     
      return sum;
    }
  }

}

export function pairArraymaths(data_array, func, roundTo=2) {
  if (data_array.length === 0) { return "<i>no data</i>"; }

  try {
    const values = data_array.map(data => data[1]);
    switch (func) {
      case "mean":
        return mean(values).toFixed(roundTo);
      case "min":
        return min(values).toFixed(roundTo);
      case "max":
        return max(values).toFixed(roundTo);
      case "last":
        return getLastValue(values).toFixed(roundTo);
      case "std":
        return std(values).toFixed(roundTo);
      case "variance":
        return variance(values).toFixed(roundTo);
      case "median":
        return median(values).toFixed(roundTo);
      case "values":
        return getCount(values);
      default:
        return "NaN";
    }
  } catch {
    return "NaN";
  }
}

export function sumArrayValues(array) {
  return array.reduce((a, b) => a + b, 0);
}

export function calcMean(array) {
  let total = 0;
  for (let i = 0; i < array.length; i++) {
    total += array[i];
  }
  return total / array.length;
}

/** Function rounds a number to nearest number
 * divisible by provided nearest parameter.
 * @param {number} num number to round
 * @param {number} nearest divisor of number to round
 * @return {number} parameter number rounded to nearest divisor
 */
export function roundToNearest(num, nearest) {
  return Math.round(num / nearest) * nearest;
}
