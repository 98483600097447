/* built-in packages */
import React, { useState, useEffect, useContext } from "react";
import { Tooltip, Whisper, Table, toaster, Message } from 'rsuite';
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useIntl } from 'react-intl';
import { translate } from "../../languages";

/* self-provided packages */
import { SimpleInfoButton } from "../../documentation";
import {
  MathUtils
} from "../../utils";
import { SiteContext } from "../../context";
import { cloneDeep } from "lodash";

export default function Statistics(props) {
  const { 
    chartData, 
    tags, 
    tagList, 
    updateOrder, 
    toggleVisibility
  } = props;
  const { Column, HeaderCell, Cell } = Table;
  const { timeZone } = useContext(SiteContext);

  const [tableData, setTableData] = useState();
  const intl = useIntl();

  /* create data suitable for react suite */
  useEffect(() => {
    let createTableData = chartData.map(data => {
      return {...data,
        ...{ visible: data.visible }, 
        ...{ id: data.id },
        ...{ color: data.color },
        ...{ description: data.description ? data.description : "-"  },
        ...{ unit: data.unit ? data.unit : "-"  },
        ...{ mean: MathUtils.aucFunctions(data.data, "mean") },
        ...{ stdev: MathUtils.aucFunctions(data.data, "stdev") },
        ...{ var: MathUtils.aucFunctions(data.data, "var") },
        ...{ median: MathUtils.calcMedian(data.data) },
        ...{ max: MathUtils.getMax(data.data) },
        ...{ min: MathUtils.getMin(data.data) },
        ...{ maxMin: MathUtils.getMaxMin(data.data) },
        ...{ last: MathUtils.getLast(data.data) },
        ...{ values: MathUtils.getValueCount(data.data) },
        ...{ lastTimestamp: MathUtils.getLastTimestamp(data.data, timeZone) }
      }
    });
    setTableData(createTableData);
  }, [chartData, tags, tagList, timeZone] );

  /* Basic value columns that can be added in table with array.map() */
  const valueColumns = [
    { id: "id",           name: "Id",                   width: 80,   align: "center" },
    { id: "description",  name: "Tag Description",      width: 250,  align: "left" },
    { id: "name",         name: "Tag",                  width: 250,  align: "left" },
    { id: "unit",         name: "Unit",                 width: 80,   align: "center" },
    { id: "mean",         name: "Mean",                 width: 80,   align: "center",   title: "Mean / average",      infokey: "stat_table_mean" },
    { id: "stdev",        name: "Stdev",                width: 80,   align: "center",   title: "Standard deviation",  infokey: "stat_table_stdev" },
    { id: "var",          name: "Var",                  width: 80,   align: "center",   title: "Variance",            infokey: "stat_table_var" },
    { id: "median",       name: "Median",               width: 80,   align: "center",   title: "Median",              infokey: "stat_table_median" },
    { id: "max",          name: "Max",                  width: 80,   align: "center",   title: "Maximum",             infokey: "stat_table_max" },
    { id: "min",          name: "Min",                  width: 80,   align: "center",   title: "Minimum",             infokey: "stat_table_min" },
    { id: "maxMin",       name: "Max-Min",              width: 80,   align: "center",   title: "Max - Min",           infokey: "stat_table_max-min" },
    { id: "values",       name: "Values",               width: 80,   align: "center",   title: "Amount of values",    infokey: "stat_table_values" }
  ];

  const ItemTypes = {
    COLUMN: "column",
    ROW: "row"
  };

  function Row({ children, onDrag, id, rowData }) {
    const ref = React.useRef(null);
  
    const [{ canDrop, isOver }, drop] = useDrop({
      accept: ItemTypes.ROW,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      }),
      drop(item) {
        onDrag && onDrag(item.id, rowData.id);
      }
    });
  
    const [{ isDragging }, drag] = useDrag({
      item: { id: rowData.id },
      type: ItemTypes.ROW,
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    });
    const isActive = canDrop && isOver;
  
    drag(drop(ref));
  
    const styles = {
      cursor: "grab",
      opacity: isDragging ? 0.5 : 1,
      background: isActive ? "#ddd" : null,
      width: "100%",
      height: "100%",
      borderTop: isActive ? "2px solid #2589f5" : null
    };
  
    return (
      <div ref={ref} style={styles}>
        {children}
      </div>
    );
  }

  const sort = (source, sourceId, targetId) => {
    const test = cloneDeep(source);
    const nextData = test.filter((item) => item.id !== sourceId);
    const dragItem = test.find((item) => item.id === sourceId);
    const index = nextData.findIndex((item) => item.id === targetId);
    nextData.splice(index, 0, dragItem);
    return nextData;
  }

  const handleDragRow = (sourceId, targetId) => {
    let newTableData = sort(tableData, sourceId, targetId);
    updateOrder(newTableData);
  };

  const getHeaderCell = (column) => {
    return(
      <HeaderCell id={column.id}>
        {column.infokey ? 
          <div className="stat-table-icon">
            <SimpleInfoButton
              useText={column.name}
              title={column.title}
              content={column.infokey}
              />
           </div>
          : translate(column.name)
          }
      </HeaderCell>
    );
  }

  const columnVisible = () => {

    const toggleVisibilityAll = () => {
      let visible = Array.isArray(tableData) && tableData.some(data => data.visible === false) ? "fa-eye-slash" : "fa-eye";
      return( 
        <i  
          className={`fas ${visible}`}
          style={{cursor: "pointer", width: "17.5px"}}
          onClick={()=> toggleVisibility(visible)}
        />
      );
    }

    const toggleVisibilityButton = (id, color) => {
      let visible = tableData.some(data => data.id === id) ? tableData.find(data => data.id === id).visible === false ? "fa-eye-slash" : "fa-eye" : "fa-eye";
      let iconColor = tableData.some(data => data.id === id) ? tableData.find(data => data.id === id).visible === false ? "gray" : color : "gray";
      return( 
        <i className={`fas ${visible}`} style={{color: iconColor, cursor: "pointer", width:"17.5px"}} 
        onClick={()=>toggleVisibility(id)} 
        />
      );
    }

    return (
      <Column width={50} align="center">
        <HeaderCell>
          {toggleVisibilityAll()}
        </HeaderCell>
        <Cell>
          {rowData => toggleVisibilityButton(rowData.id, rowData.color)}
        </Cell>
      </Column>
    );
  }

  const columnLast = () => {
    return (
      <Column width={100} align="center">
        {getHeaderCell({ id: "last", name: "Last", width: 100, title: "Latest value", infokey: "stat_table_last" })}
        <Cell>
          {rowData =>{
            return <Whisper 
              placement="left" 
              trigger={rowData.lastTimestamp === "-" ? "none" : "hover"}
              speaker={
                <Tooltip>
                  <div className="p-1">
                    <p>{timeZone}</p>
                    <p>{rowData.lastTimestamp}</p>
                  </div>
                </Tooltip>
              }
            >
              <div style={{ position: "relative" }}>
                <div>
                  {rowData.last}
                </div>
                <div style={{ position: "absolute", top: "7px", width: "100%", left: "12px" }}>
                  {rowData.lastTimestamp !== "-" &&
                    <span>...</span>
                  }
                </div>
              </div>
            </Whisper>
          }
          }
        </Cell>
      </Column>
    )
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="stat-table-container">
        <Table
          wordWrap="break-word"
          autoHeight 
          hover 
          data={tableData}
          rowKey="id"
          renderRow={(children, rowData) => {
            return rowData ? (
              <Row
                key={rowData.id}
                rowData={rowData}
                id={rowData.id}
                onDrag={handleDragRow}
              >
                {children}
              </Row>
            ) : (
              children
            );
          }}
        >

          {columnVisible()}
      
          {valueColumns.map(column => {
            if (column.id === "name") {
              return (
                <Column key={column.id} width={column.width} align={column.align}>
                  {getHeaderCell(column)}
                    <Cell>
                    {rowData =>
                      <div style={{display: "flex", alignItems: "center"}}>
                        <i className="far fa-copy me-1" style={{ cursor: "pointer", width:"17.5px"}} 
                            onClick={() => {
                              navigator.clipboard.writeText(rowData.name);
                              toaster.push(
                                <Message showIcon type="info">
                                {`"${rowData.name}" ${intl.formatMessage({ id: "copied to clipboard" })} `}
                                </Message>, "topStart"
                              );
                            }}
                          /> 
                          {rowData.name}
                          
                      </div>
                    }
                  </Cell>
                </Column>
              );
            } else {
              return(
                <Column key={column.id} width={column.width} align={column.align}>
                  {getHeaderCell(column)}
                  <Cell onClick={(e)=> e.stopPropagation()} style={{cursor: "grab"}} dataKey={column.id} />
                </Column>
              );
            }
          })}
          
          {columnLast()}
  
        </Table>
      </div>
    </DndProvider>
  );
}