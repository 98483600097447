/* 3rd-party packages */
import axios from "axios";
import { isFunction } from "lodash";
import {
  makeAuthUrlWithHeaders,
  stringOnlyHasDigits
} from ".";

/* Function receives a HTTP status code as parameter
 * and checks if its first character is a '2' or a '3'.
 * If so, return value is true, otherwise false. */
export function checkHttpStatusCode(httpStatusCode) {
  if (
    (
      typeof httpStatusCode === "number"
      &&["2", "3"].indexOf(httpStatusCode.toString().charAt(0)) > -1
    )
    ||
    (
      typeof httpStatusCode === "string"
      && stringOnlyHasDigits(httpStatusCode)
      && ["2", "3"].indexOf(httpStatusCode.charAt(0)) > -1
    )
  ) {
    return true;
  }
  return false;
}

export function getTagParams(tags, filterIndexing) {
  let tagParam;
  if (tags.length > 0) {
    let index = 0;
    if (
      typeof filterIndexing === "string"
      && filterIndexing.toLowerCase() === "tagname"
    ) {
      index = 1;
    }

    tagParam = `${tags.map((tag) => tag.split("?")[index])}`;
  }

  else {
    tagParam = null;
  }

  return tagParam;
}

/**
 * Function returns an access token function.
 * Currently works with Microsoft Azure Authentication
 * object structure.
 * @param {Object} authContext  Context information for authenticating with access token.
 * @return {Promise}            AccessTo
 */
export async function getAccessToken(authContext) {
  return(
    await authContext.instance.acquireTokenSilent({
      scopes: ["User.Read"],
      account: authContext.accounts[0]
    })
  );
}

/* Function takes only one Object parameter, which can have multiple
 * key-value pairs. Only 'url'-key is hard-required, the rest are
 * optional. The argsObject is destructured with default values below. */
export default async function fetchDataHelper(argsObject) {
  let {
    url, // required
    headers = {}
  } = argsObject;
  const {
    method = "GET",
    data = null,
    params = {},
    useAuthentication = false,
    authContext = null,
    onFetchStart = null,
    onFetchEnd = null,
    onSuccess = null,
    onError = null,
    responseType = null
  } = argsObject;

  /* Execute logic only if 'url'-key has a non-nullish value. */
  if (url) {
    /* Execute 'onFetchStart' callback if it is a function. */
    if (isFunction(onFetchStart)) onFetchStart();
    
    /* Add authentication add-ons to url and headers in case
     * authentication is used ('useAuthentication' is truthy)
     * and and 'accessTokenFunction' is provided (e.g.
     * 'getAccessTokenSilently' from a function wrapped by withAuth0). */
    if (useAuthentication && authContext) {
      const accessToken = await getAccessToken(authContext);
      [url, headers] = await makeAuthUrlWithHeaders(url, headers, accessToken);
    }

    /* Call function that fetches data. */
    let response;
    try {
      if (method.toUpperCase() === "GET") {
        response = await dataRequestGet(url, params, headers, responseType);
      } else if (method.toUpperCase() === "POST" && data) {
        if (responseType) headers["Content-Type"] = responseType;
        response = await dataRequestPost(url, data, { params, headers });
      }

      /* Check if response status code signals of a successful
      * request. If so, return data and trigger 'onSuccess' and
      * 'onFetchEnd' callbacks if they are functions. */
      if (response && checkHttpStatusCode(response.status)) {
        if (isFunction(onSuccess)) onSuccess(response);
        if (isFunction(onFetchEnd)) onFetchEnd();
        return response.data;
      }

      /* If response status code signals of an erroneous request,
      * trigger 'onError' and 'onFetchEnd' callbacks if they are
      * functions. */
      else {
        if (isFunction(onError)) onError(response);
      }
    } catch (error) {
      if (isFunction(onError)) onError(error);
    }
  }
}

export async function dataRequestGet(url, params, headers = {}, responseType = {}) {
  return await axios.get(url, { params, headers, responseType });
}

export async function dataRequestPost(url, data, config = {}) {
  return await axios.post(url, data, config);
}

/* Function merges an arbitrary amount of
 * objects into one object using spread. */
export function mergeObjects() {
  let returnObject = {};
  for (let i = 0; i < arguments.length; ++i) {
    if (
      typeof arguments[i] === "object"
      && arguments[i] !== null  
    ) {
      returnObject = { ...returnObject, ...arguments[i] };
    }
  }
  return returnObject;
}
