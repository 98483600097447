import React, { useState } from 'react';
import { SelectPicker, InputNumber, InputGroup } from 'rsuite';
import { useTranslateToString, translate } from '../languages';

const YAxisHeightSelector = (props) => {
    const {screenSize, defaultValues } = props;
    const translation = useTranslateToString("Type");

    const [yAxisHeight, setYAxisHeight] = useState({
      min: Array.isArray(defaultValues) ? defaultValues[5]?.min || 0 : 0,
      max: Array.isArray(defaultValues) ? defaultValues[5]?.max || 100 : 100,
  });

    const [heightType, setHeightType] = useState(
        Array.isArray(defaultValues) && (defaultValues[5]?.min === null || !defaultValues[5]) ? "dynamic" : "manual"
    );
    
    const handleChange = (key, value) => {

        if (key === "heightType") {
            setHeightType(value);
            if (value === "dynamic") {
                props.onChange({ min: null, max: null });
            } else {
                props.onChange(yAxisHeight);
            }
            return
        }

        setYAxisHeight((prevHeight) => {
            const updatedHeight = { ...prevHeight, [key]: value };
            props.onChange(updatedHeight);
            return updatedHeight;
        });
    };

    const heightTypeOptions = [
        { label: useTranslateToString('Dynamic'), value: 'dynamic' },
        { label: useTranslateToString('Manual'), value: 'manual' },
    ];

    return (
        <InputGroup>
            {screenSize > 670 && (
                <InputGroup.Addon>
                    {translate('Y-axis height')}
                </InputGroup.Addon>
            )}

            <SelectPicker
                placeholder={translation}
                value={heightType}
                searchable={false}
                data={heightTypeOptions}
                style={{ minWidth: screenSize > 450 ? 120 : 100 }}
                className="no-borders y-axis-height-selector"
                onChange={(value) => handleChange("heightType", value)}
            />

            <InputNumber
                prefix="Min"
                max={yAxisHeight.max}
                value={yAxisHeight.min}
                className="y-axis-height-selector"
                style={{ minWidth: 120 }}
                disabled={heightType === 'dynamic'}
                onChange={(value) => handleChange("min", Number(value))}
            />

            <InputNumber
                prefix="Max"
                min={yAxisHeight.min}
                value={yAxisHeight.max}
                className="y-axis-height-selector"
                style={{ minWidth: 120 }}
                disabled={heightType === 'dynamic'}
                onChange={(value) => handleChange("max", Number(value))}
            />
        </InputGroup>
    );
};

export default YAxisHeightSelector;