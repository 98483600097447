import React from 'react';
import { InputNumber, Divider, Button } from 'rsuite';
import { translate, useTranslateToString } from "../languages";
import GaugeRangeSelector from './gaugeRangeSelector';

const GaugeChartOptions = (props) => {
    const { defaultValues, onChange } = props;

    const options = defaultValues || {
        colorRanges: [{
            id: Date.now(),
            min: 0, 
            max: 50 
        }],
        unit: "",
        decimalAmount: 0
    };

    // Adds a new gauge range selector
    const addRangeRow = () => {
      if (options.colorRanges.length < 6) {
          const lastRange = options.colorRanges[options.colorRanges.length - 1];
          const diff = lastRange.max - lastRange.min; // Calculate the difference between min and max
          
          handleChange('colorRanges', [...options.colorRanges, {
              id: Date.now(),
              min: lastRange.max,           // New min starts from previous max
              max: lastRange.max + diff      // New max maintains the same difference
          }]);
      }
  }

    const removeRangeRow = (id) => {
        if (options.colorRanges.length > 1) {
            handleChange('colorRanges', options.colorRanges.filter(range => range.id !== id));
        }
    };

    const handleChange = (key, value) => {
        const updatedOptions = { ...options, [key]: value };
        onChange(key, updatedOptions);
    };

    return (
        <>
            <Divider>{translate("Gauge chart options")}</Divider>
            <div className="flex-container">
                <InputNumber
                    prefix={useTranslateToString("Decimal numbers")}
                    max={10}
                    min={0}
                    value={options.decimalAmount}
                    onChange={(value) => handleChange("decimalAmount", Number(value))}
                />
                <Button
                    appearance="default" 
                    size="sm" 
                    disabled={options.colorRanges.length >= 6}
                    onClick={() => addRangeRow()}>
                    {translate("Add range")}
                </Button>
            </div>
            <br></br>
            {options.colorRanges.map((range, index) => (
                <GaugeRangeSelector
                    key={range.id}
                    defaultValues={range}
                    selectorIndex={index}
                    rangeCount={options.colorRanges.length}
                    onDelete={() => removeRangeRow(range.id)}
                    onChange={(colorRangeObj) => {
                        handleChange('colorRanges', [
                            ...options.colorRanges.slice(0, index), 
                            colorRangeObj, 
                            ...options.colorRanges.slice(index + 1)
                        ]);
                    }}
                />                  
            ))}
            <Divider />
        </>
    );
};

export default GaugeChartOptions;
