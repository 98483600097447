import React, { useEffect, useContext, useState, useRef } from "react";
import { useIntl } from 'react-intl';
import axios from "axios";
import Dropzone from "react-dropzone";
import FsLightbox from "fslightbox-react";
import { Modal, Loader, Message, toaster, Button } from 'rsuite';
import { translate } from "../languages";
import {
  makeAuthUrlWithHeaders,
  logErrorIfDevelopmentMode,
  acquireToken,
  getFileExtension,
  popNotification
} from "../utils";
import { ManageModal } from ".";
import {
  UseAuthenticationContext,
  SiteContext
} from "../context";
import { MsalContext } from "@azure/msal-react";

export default function Fileman() {

  const [files, setFiles] = useState([]);
  const [toggler, setToggler] = useState(false);
  const [folder, setFolder] = useState();
  const [filePath, setFilePath] = useState();
  const [rootFolder, setRootfolder] = useState();
  const [request, setRequest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshing, refreshContent] = useState(new Date());
  const [selectedFile, setSelectedFile] = useState();
  const [targetItem, setTargetItem] = useState();

  const intl = useIntl();
  const dropzoneArea = useRef();
  const { useAuthentication }     = useContext(UseAuthenticationContext);
  const { site }                  = useContext(SiteContext);
  const msalContext               = useContext(MsalContext);

  const acceptedFileTypes = [
    "jpg",
    "jpeg",
    "png",
    "pdf",
    "mp4",
    "html"
  ];

  const endpoint = `/boa/api/v1/${site}/fileman`;

  useEffect(() => { //set root folder
    if (useAuthentication) {
      const siteFolder = site.split(":")[1];
      setRootfolder(siteFolder);
      setFolder(siteFolder);
    } else { // local BOA
      setRootfolder("root");
      setFolder("root");
    }
  }, [msalContext, useAuthentication, site]);

  useEffect(() => { //fetch files when folder location changes
    async function showFolderContent() {
      try {
        let url = `${endpoint}?folder=${folder}`;
        let headers = {};
        if (useAuthentication) {    
          [url, headers] = await makeAuthUrlWithHeaders(
            url, 
            headers, 
            acquireToken(msalContext)
          )
        }
        await axios({
          method: 'get',
          url: url,
          headers: headers
        }).then(response => {
            let folderContent;
              //empty folder
              if (!response.data.children) {
                folderContent = [];
              }
              //show folders before files
              else {
                folderContent = response.data.children.sort(function (a, b) {
                  var x = a.type.toLowerCase();
                  var y = b.type.toLowerCase();
                  if (x < y) {
                    return -1;
                  }
                  if (x > y) {
                    return 1;
                  }
                  return 0;
                });
              }
          //save folderContent
          setFiles(folderContent);
        });
      } catch (e) {
        logErrorIfDevelopmentMode(e);
      }
    }
    if (folder) {showFolderContent(); }
  }, [folder, refreshing, endpoint, msalContext, useAuthentication])

  useEffect(() => {
    let isCancelled = false;

    async function showFile() {
      let fileURL = null;
      let fileType;
      let extension;
      if (selectedFile.extension) {
        extension = selectedFile.extension.toLowerCase();
      }
      switch (extension) {
        case "html":
          fileType = "text/html";
          break;
        case "jpg":
          fileType = "image/jpeg";
          break;
        case "png":
          fileType = "image/png";
          break;
        case "pdf":
          fileType = "application/pdf";
          break;
        case "mp4":
          fileType = "video/mp4";
          break;
        default:
          fileType = "application/json";
      }

      try {
        let url = endpoint;
        let headers = {};
        let method = "post";
        if (useAuthentication) {
          [url, headers] = await makeAuthUrlWithHeaders(
            url, 
            headers, 
            acquireToken(msalContext)
          )
        }
        if (!isCancelled) {
          await axios({
            method: method,
            url: url,
            responseType: "blob",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            data: selectedFile,
            redirect: "follow",
            referrer: "no-referrer",
            headers: headers,
          }).then((response) => {
            fileURL = window.URL.createObjectURL(
              new Blob([response.data], { type: fileType })
            );
            setLoading(false);
            setFilePath(fileURL);
            setToggler(prevToggle => !prevToggle);
            setSelectedFile();
          });
        }
      } catch (e) {
        logErrorIfDevelopmentMode(e);
      }
    }
    if(selectedFile) { showFile(); }
    return () => { isCancelled = true;};
  }, [selectedFile, endpoint, msalContext, useAuthentication])

  const uploadFile = async (formData) => {
    try {
      let url = `${endpoint}?folder=${folder}&upload=true`;
      let headers = {};
      if (useAuthentication) {
        [url, headers] = await makeAuthUrlWithHeaders(
          url, 
          headers, 
          acquireToken(msalContext)
        )
      }
      await axios({
        method: 'post',
        url: url,
        data: formData,
        headers: headers,
      }).then((response) => {
        if (response.status === 200) {
          setRequest(null);
          refreshContent(new Date());
          toaster.push(
            <Message type="success">
              {intl.formatMessage({id: "file_uploaded_successfully" })}
            </Message>
          );
        }
      });
    } catch (e) {
      logErrorIfDevelopmentMode(e);
    }
  }
  
  const getIcon = (extension) => {
    let ext;
    if (extension) {
      ext = extension.toLowerCase();
    }
    let icon = {
      undefined: "fa-folder",
      "": "fa-file",
      pdf: "fa-file-pdf",
      mp4: "fa-file-video",
      avi: "fa-file-video",
      jpg: "fa-file-image",
      png: "fa-file-image",
      gif: "fa-file-image",
      bmp: "fa-file-image",
      txt: "fa-file-alt",
      html: "fa-file-alt",
    };
    return `fa ${icon[ext]} file-icon`;
  }

  const checkCorrectFileType = (filename) => {
    const fileExtension = getFileExtension(filename);
    if (dropzoneArea.current){ dropzoneArea.current.style.backgroundColor = "transparent"; }
    for (let i = 0; i < acceptedFileTypes.length; ++i) {
      const acceptedFileType = acceptedFileTypes[i];
      if (
        fileExtension === acceptedFileType
        || fileExtension?.toLowerCase?.() === acceptedFileType
      ) {
        return true;
      }
    }
    return false;
  }

  const getDropZoneArea = () => {
    return (
      <Dropzone
        onDragOver={(e) => e.target.parentNode.style.backgroundColor = "#c3e6cb"}
        onDragLeave={(e) => e.target.parentNode.style.backgroundColor = "transparent" }
        onDrop={(acceptedFiles) => {
          acceptedFiles.forEach((file) => {
            if (checkCorrectFileType(file.name)) {
              const filename = `${file.name.split(".")[0]}.${file.name.split(".")[1].toLowerCase()}`;
              const renamedFile = new File([file], filename, { type: file.type });
              setRequest("upload");
              const formData = new FormData();
              formData.append("file", renamedFile);
              uploadFile(formData);
            } else {
              popNotification({
                type: "error",
                text: `Wrong filetype! Accepted file types are ${acceptedFileTypes.map(item => `.${item}`).join(",")}.`
              });
            }
          });
        }}
        noClick
        multiple
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone-file-item">
            <div className="file-item">
              <section id="dzcontent" className="content">
                <div
                  {
                    ...getRootProps({
                      className: "dropzone d-flex align-items-center position-relative",
                    })
                  }
                >
                  <input {...getInputProps()} />
                  <div
                    ref={dropzoneArea}
                    className="w-100 position-absolute"
                    style={{ height: "100%" }}
                  >
                    <em
                      className="fas fa-plus"
                      style={{ marginTop: "60px" }}
                    />
                    <p
                      className="position-absolute w-100"
                      style={{
                        top: "0",
                        left: "0",
                        height: "100%",
                        lineHeight: "180px",
                      }}
                    >
                      {translate("Drop new file(s) here")}
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        )}
      </Dropzone>
    );
  }

  const getHeaderContent = () => {

    const folderBreadcrumb = () => {
      let pathParts = folder.split("/").filter(part => part !== "");
      return pathParts.map(part => (
        <div
          key={part}
          className="folderBC"
          onClick={() => setFolder(folder.split(part)[0] + part)}
        >
          {`/${part}`}
        </div>
      ));
    }
    return(
      <div>
        <div className="m-2 w-100 d-block fileman-breadcrumb">
          <Button 
            className="me-3"
            onClick = { () => setTargetItem("new") }
          >
            {translate("Create folder")}
          </Button>
          <i className="far fa-folder-open me-2"></i>
          {folder && folderBreadcrumb()}
        </div>

        <div style={{ marginLeft: "0.5rem" }}>
          {`Accepted file types are: ${acceptedFileTypes.map(item => `.${item}`).join(",")}.`}
        </div>
      </div>
    );
  }


  const createIconSquares = () => {
    return (
      <div>
        {request &&
          <div>
            <span>
              <em className={"fas fa-spinner me-2"} id="loadingIcon"></em>
              {request === "delete" ? "Deleting file" : "Uploading file"}...
            </span>
          </div>
        }

        {files.map((file) => {
          let icon = getIcon(file.extension);
            return (
              <div
                key={file.name}
                onClick={file.type === "file" ? () => 
                  {
                    setLoading(true);
                    setSelectedFile({
                      item: folder + "/" + file.name,
                      type: file.type,
                      extension: file.extension,
                    })
                  } : () => { setFolder(file.path.substring(file.path.indexOf(rootFolder))) }                
                }
                className="file-item"
                name={file.name}
                type={file.type}
              >
                <span>{file.name}</span>
                <em className={icon + " file-item-icon"}></em>
                <div
                  className="remove-file-icon"
                  onClick={ (e)=> {  e.stopPropagation(); setTargetItem(file) } }
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: "25px",
                    paddingTop: "10px",
                    paddingBottom: "5px"
                  }}
                >
                  <i className="fas fa-ellipsis-v" />
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }
  return (
    <div>
      {folder ? (
        getHeaderContent() 
      ) : (
        <div>
          <em className={"fas fa-spinner me-2"} id="loadingIcon"></em>
          <span>{translate("loading content")}</span>
        </div> 
      )}
      {files && createIconSquares()}
      {getDropZoneArea()}

      <div style={{ width: "100%" }}>
        <FsLightbox
          toggler={toggler}
          sources={[
            <iframe
              key="iframe"
              title={filePath}
              src={filePath}
              style={{ height: `${window.innerHeight - 150}px` }}
              id="fullDiv"
            />
          ]}
        />
        </div>

      {loading &&
        <Modal size="xs" open={loading} onClose={() => { setLoading(false); }} >
          <Modal.Body>
            <Loader size="md" content={translate("file_loading")} />
          </Modal.Body>
        </Modal>
      }

      {folder &&
        <ManageModal 
          endpoint={endpoint}
          currentFolder={folder}
          targetItem={targetItem}
          closeModal={()=> {setTargetItem(); refreshContent(new Date()); }}
        />
      }
    </div>
  );
}
