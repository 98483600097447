import React, { Component, createContext } from "react";
import { isBoolean } from "lodash";

const CookieContext = createContext();

class CookieProvider extends Component {
  state = {
    cookiesAccepted: false
  };

  componentDidMount() {
    const storedCookiesAccepted = JSON.parse(localStorage.getItem("cookiesAccepted"));
    if (isBoolean(storedCookiesAccepted)) {
      this.setState({ cookiesAccepted: storedCookiesAccepted });
    }
  }

  setCookiesAccepted = (cookiesAccepted) => {
    if (isBoolean(cookiesAccepted)) {
      this.setState({ cookiesAccepted });
      localStorage.setItem("cookiesAccepted", true);
    }
  }

  render() {
    return(
      <CookieContext.Provider
        value={{
          cookiesAccepted: this.state.cookiesAccepted,
          setCookiesAccepted: this.setCookiesAccepted
        }}
      >
        {this.props.children}
      </CookieContext.Provider>
    );
  }
}

export { CookieContext, CookieProvider };
