import React, { useState } from 'react';
import { IconButton } from 'rsuite';
import { translate } from '../languages';
import VisibleIcon from '@rsuite/icons/Visible';
import UnvisibleIcon from '@rsuite/icons/Unvisible';

const ToggleVisibilityButton = (props) => {
  let { defaultValue, style } = props;

  const [isPublic, setIsPublic] = useState(defaultValue);

  const toggleVisibility = () => {
    setIsPublic(!isPublic);
    props.onClick(!isPublic);
  };

  return (
    <IconButton
      onClick={toggleVisibility}
      appearance={isPublic ? "primary" : "default"}
      icon={isPublic ? <VisibleIcon /> : <UnvisibleIcon />}
      style={style}
      >
      {isPublic ? translate('Public') : translate('Private')}
    </IconButton>
  );
};

export default ToggleVisibilityButton;