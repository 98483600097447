
/* built-in packages */
import React, { Component } from "react";

/* 3rd-party packages */
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  Loader,
  InputNumber,
  Whisper,
  Tooltip
} from "rsuite";
import { isEqual } from "lodash";

/* self-provided packages */
import { SimpleInfoButton } from "../../documentation";
import { translate } from "../../languages";
import { getColors } from "../../utils";

class Scatter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      x_param: null,
      y_param: null,
      z_param: null,
      x_checked: "scatter-table-radio-x0",
      y_checked: "scatter-table-radio-y1",
      z_checked: "scatter-table-radio-z2",
      raw_scatter_data: null,
      scatter_data: null,
      groups: 5,
      show_table: true
    };
    this.radioChangeTimeout = null;
    this.internalChart = null;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = prevState;

    /* Set maximum of three state parameters (x, y, z), but less
     * in case there are fewer than three tags selected. */
    const state_params = ["x_param", "y_param", "z_param"];
    for (let i = 0; i < Math.min(state_params.length, nextProps.data.length); ++i) {
      const data = nextProps.data[i];
      if (!state[state_params[i]]) {
        state[state_params[i]] = (
          data.id
          || data.tag
          || data.name
          || data.description
          || null
        );
      }
    }

    /* Update state with raw tag data in case
     * new data differs from current data. */
    if (!isEqual(nextProps.data, prevState.raw_scatter_data)) {
      state.raw_scatter_data = nextProps.data;
    }

    return state;
  }

  afterChartCreated = (chart) => {
    this.internalChart = chart;
  }

  componentDidMount() {
    const resizeObserver = new ResizeObserver((entries) => {
      if (this.internalChart) {
        this.internalChart.reflow();
      }
    });

    resizeObserver.observe(document.getElementById("tagsearch"));
  }

  componentWillUnmount() {
    this.internalChart = null;
  }

  getOptions(seriesdata) {
    const colors = getColors(this.props.theme);

    const options = {
      colors: colors,
      chart: {
        type: "scatter",
        className: "chart"
      },
      legend: {
        enabled: true,
        floating: true,
        align: "right",
        verticalAlign: "top",
        layout: "vertical",
        backgroundColor: "#FFFFFF80",
        borderWidth: 1,
        borderRadius: 3
      },
      title: {
        text: ""
      },
      yAxis: {
        title: {
          text: null
        }
      },
      time: {
        timezoneOffset: new Date().getTimezoneOffset()
      },
      plotOptions: {
        scatter: {
          turboThreshold: 50000
        },
        series: {
          marker: {
            radius: 3
          }
        }
      },
      series: seriesdata
    }

    return options;
  }

  getScatterConfigRow() {
    let toggleButtonIcon = "scatter-toggle-button-icon",
      toggleButtonClass = "scatter-toggle-button",
      tableToggleTooltip;

    const groupCountToggleTooltip = "Toggle the amount of groups in scatter";
    const groupCountInfoTooltip = "Show groups explanation";

    if (this.state.show_table) {
      toggleButtonIcon += " fas fa-chevron-up";
      toggleButtonClass += " display";
      tableToggleTooltip = "Hide table";
    } else {
      toggleButtonIcon += " fas fa-chevron-down";
      toggleButtonClass += " no-display";
      tableToggleTooltip = "Show table";
    }

    return (
      <div style={{ margin: "15px 0px" }}>
        <Whisper
          trigger="hover"
          placement="topStart"
          speaker={<Tooltip>{translate(tableToggleTooltip)}</Tooltip>}
          delayShow={1000}
        >
          <div
            className={toggleButtonClass}
            onClick={() => {
              this.setState((prevState) => (
                { show_table: !prevState.show_table }
              ))
            }}
          >
            <i className={toggleButtonIcon}></i>
          </div>
        </Whisper>

        <Whisper
          trigger="hover"
          placement="topStart"
          speaker={<Tooltip>{translate(groupCountToggleTooltip)}</Tooltip>}
          delayShow={1000}
        >
          <div className="scatter-number-input">
            <InputNumber
              defaultValue={5}
              min={1}
              max={10}
              scrollable={false}
              size="xs"
              onChange={(value) => this.handleNumberInputChange(value)}
            />
          </div>
        </Whisper>

        <Whisper
          trigger="hover"
          placement="top"
          speaker={<Tooltip>{translate(groupCountInfoTooltip)}</Tooltip>}
          delayShow={1000}
        >
          <div className="scatter-groups-info">
            <SimpleInfoButton
              title="Scatter information"
              content="scatter_groups_info"
            />
          </div>
        </Whisper>

      </div>
    );
  }

  handleNumberInputChange = (value) => {
    try { clearTimeout(this.radioChangeTimeout); }
    catch {}

    this.radioChangeTimeout = setTimeout(
      this.setState({ groups: parseInt(value) }),
      1000
    );
  }

  matchDataPoints(allTagsData, selectedTagsList) {
    /**
     * matchDataPoints takes data and a list of selected tags as parameters
     * and filters currently used data out of all tags data by comparing ids
     * in selectedTagsList. Data points are subsequently matched by matching
     * their timestamps. A data point not containing a timestamp for each
     * selected tag is skipped.
     * 
     * @param {Array} allTagsData       Array of objects containing data of all selected tags
     * @param {Array} selectedTagsList  Array of integers, which are tag ids
     * 
     * @return {Object} Object containing keys 'names', 'ids', 'tagCount', 'dataPointCount', 'data', 'ZData'.
     * The values of these keys are Arrays containing names and ids in order, tag amount, amount of matched data points, xyz-data and z-data.
     */

    const selectedTagsData = [];
    for (let i = 0; i < selectedTagsList.length; ++i) {
      for (let j = 0; j < allTagsData.length; ++j) {
        if (selectedTagsList[i] === allTagsData[j].id) {
          selectedTagsData.push(allTagsData[j]);
          break;
        }
      }
    }

    const selectedTagsDataLength = selectedTagsData.length;

    const dataObjects = [];
    for (let i = 0; i < selectedTagsDataLength; ++i) {
      const datetime = [];
      const value = [];
      for (let j = 0, count = selectedTagsData[i].data.length; j < count; ++j) {
        datetime.push(selectedTagsData[i].data[j][0]);
        value.push(selectedTagsData[i].data[j][1]);
      }
      dataObjects.push({
        name: selectedTagsData[i].name,
        id: selectedTagsData[i].id,
        data: {
          datetime,
          value,
          count: datetime.length
        }
      });
    }

    const matchingDataValues = [];
    for (
      let i = 0, dataPointCount = dataObjects[0].data.count, dataLen = dataObjects.length;
      i < dataPointCount;
      ++i
    ) {
      const referenceDatetime = dataObjects[0].data.datetime[i];
      const indices = [i];
      for (let j = 1; j < dataLen; ++j) {
        indices.push(dataObjects[j].data.datetime.findIndex((datetime) => datetime === referenceDatetime));
      }
      if (indices.every((value) => value > -1)) {
        const matchingValuesArray = [];
        for (let k = 0; k < indices.length; ++k) {
          matchingValuesArray.push(dataObjects[k].data.value[indices[k]]);
        }
        matchingDataValues.push(matchingValuesArray);
      }
    }

    const XYData = matchingDataValues.map((dataPoint) => [dataPoint[0], dataPoint[1]]);

    /* separate z-data for ease of use */
    const ZData = (
      selectedTagsData.length === 3
      ? matchingDataValues.map((dataPoint) => dataPoint[2])
      : null
    );

    const retObject = {
      names: dataObjects.map((obj) => obj.name),
      ids: dataObjects.map((obj) => obj.id),
      tagCount: dataObjects.length,
      dataPointCount: matchingDataValues.length,
      XYData,
      ZData
    };

    return retObject;

  }

  parseScatterSeries(allTagsData, selectedTagsList) {
    const matchedData = this.matchDataPoints(allTagsData, selectedTagsList);

    const max_groups = Math.min(10, this.state.groups);
    const tagCount = matchedData.tagCount;
    const dataPointCount = matchedData.dataPointCount;

    let sections = [];
    if (tagCount === 3) {
      const sortedZData = matchedData.ZData.sort((a, b) => a - b);

      /* JavaScript engine limits the number of function
       * arguments to e.g. 65536 or some other 5-digit
       * value, depending on engine. If there are more
       * than that amount of points in the scatter, the
       * spread operator (...) is not sufficient and the method for hunting
       * maximum and minimum values from z_numbers array
       * and the parsing has to be changed at least slightly. */
      const max = Math.max(...sortedZData);
      const min = Math.min(...sortedZData); 
      const difference = max - min;
      const gap = difference / max_groups;
      const threshold_indices = [0];
      let current_gap_number = min + gap;
      for (let i = 0; i < dataPointCount; ++i) {
        if (
          sortedZData[i - 1] < current_gap_number
          && sortedZData[i] >= current_gap_number
          && !threshold_indices.includes(i)
        ) {
          threshold_indices.push(i);
          /* Add new gap until the current gap number includes new numbers. */
          while (current_gap_number < sortedZData[i + 1]) current_gap_number += gap;
        }
      }
      threshold_indices.push(dataPointCount - 1);

      /* Add minimum and maximum value from a z-value color group to
       * sections array. The items in the array represent the min and
       * max z-value for that group. */
      for (let i = 0; i < threshold_indices.length - 2; ++i) {
        const lowDataValue = sortedZData[threshold_indices[i]];
        const highDataValue = (
          i === threshold_indices.length - 3
          ? sortedZData[threshold_indices[i + 1]]
          : sortedZData[threshold_indices[i + 1] - 1] 
        );
        sections.push([lowDataValue, highDataValue]);
      }
    }

    const seriesData = [];
    /* 2 tags, no grouping */
    if (tagCount === 2) {
      seriesData.push({
        name: `X - ${matchedData.names[0]}<br>Y - ${matchedData.names[1]}`,
        data: matchedData.XYData
      });
    /* 3 tags, grouping */
    } else if (tagCount === 3) {
      const XYData = matchedData.XYData
      const ZData = matchedData.ZData;
      for (let i = 0; i < sections.length; ++i) {
        seriesData.push({
          name: `${sections[i][0]} - ${sections[i][1]}`,
          data: [],
          zMin: sections[i][0],
          zMax: sections[i][1]
        });
      }

      /* Add each data point to the group it falls into */
      for (let i = 0; i < dataPointCount; ++i) {
        for (let j = 0; j < seriesData.length; ++j) {
          if (
            ZData[i] >= seriesData[j].zMin
            && ZData[i] <= seriesData[j].zMax
          ) {
            seriesData[j].data.push(XYData[i]);
          }
        }
      }
    }

    return seriesData;
  }

  handleRadioChange = (xyz, row_index, data) => {
    /* xyz is either 'x', 'y', or 'z' depending on column. */
    const state_xyz_param_key = `${xyz}_param`; // e.g. x_param
    const state_xyz_param_value = data.id || data.tag || data.name || data.description || null;
    const state_xyz_checked_key = `${xyz}_checked`;
    const state_xyz_checked_value = `scatter-table-radio-${xyz}${row_index}`;
    
    this.setState(
      {
        [state_xyz_param_key]: state_xyz_param_value,
        [state_xyz_checked_key]: state_xyz_checked_value
      }
    );
  }

  formRadioRows() {
    const rows = [];
    const data = this.props.data;
    for (let i = 0; i < data.length; ++i) {
      rows.push(
        <tr key={i}>

          <th className="scatter-table-tag">
            {`${data[i].description} (${data[i].tag})`}
          </th>
          
          <th>
            <input
              id={`scatter-table-radio-x${i}`}
              className="scatter-table-radio"
              type="radio"
              name="x"
              checked={`scatter-table-radio-x${i}` === this.state.x_checked}
              onChange={() => this.handleRadioChange("x", i, data[i])}
            />
          </th>

          <th>
            <input
              id={`scatter-table-radio-y${i}`}
              className="scatter-table-radio"
              type="radio"
              name="y"
              checked={`scatter-table-radio-y${i}` === this.state.y_checked}
              onChange={() => this.handleRadioChange("y", i, data[i])}
            />
          </th>

          <th>
            <input
              id={`scatter-table-radio-z${i}`}
              className="scatter-table-radio"
              type="radio"
              name="z"
              checked={`scatter-table-radio-z${i}` === this.state.z_checked}
              onChange={() => this.handleRadioChange("z", i, data[i])}
            />
          </th>

        </tr>
      );
    }
    return rows;
  }

  getDimensionParams() {
    const states = [this.state.x_param, this.state.y_param, this.state.z_param];
    const ret = [];
    for (let i = 0; i < states.length; ++i) {
      if (states[i]) ret.push(states[i]);
    }
    return ret;
  }

  getScatterData() {
    let data = null;
    if (this.state.raw_scatter_data) {
      const dimension_params = this.getDimensionParams();
      data = this.parseScatterSeries(this.state.raw_scatter_data, dimension_params);
    }
    return data;
  }

  render() {
    const scatter_series_data = this.getScatterData();

    const options = this.getOptions(scatter_series_data);

    const tableContent = (
      this.state.show_table
      ? (
        <div className="scatter-table">
          <table className="scatter-table table-striped">
            <thead className="scatter-table-head">
              <tr className="scatter-table-head-row">
                <th className="scatter-table-head-tag">{translate("Tag")}</th>
                <th className="scatter-table-head-variable">X</th>
                <th className="scatter-table-head-variable">Y</th>
                <th className="scatter-table-head-variable">Z</th>
              </tr>
            </thead>
            <tbody>
              {this.formRadioRows()}
            </tbody>
          </table>
        </div>
      )
      : null
    );

    const scatterChartContent = (
      this.state.loading
      ? <Loader size="lg" center />
      : (
        <div className="scatter-chart">
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            callback={this.afterChartCreated}
          />
        </div>
      )
    );

    return (
      <div className="scatter-div">

        {this.getScatterConfigRow()}

        {tableContent}

        {scatterChartContent}

      </div>
    );
  }
}

export default Scatter;
