import React from 'react';
import { SelectPicker } from 'rsuite';
import { useTranslateToString } from "../languages";

const ChartList = (props) => {
  let { defaultValue, style } = props;

  const data = [
    { label: useTranslateToString('Line'), value: 'Line' },
    { label: useTranslateToString('Pie'), value: 'Pie' },
    { label: useTranslateToString('Gauge'), value: 'Gauge' },
    { label: useTranslateToString('Bar'), value: 'Bar' },
  ].sort((a, b) => a.value.localeCompare(b.value));

  const handleSelect = (value) => {
      props.onChange(value);
  };

  return (
    <SelectPicker
      data={data}
      searchable={false}
      placeholder={useTranslateToString("Chart type")}
      onChange={handleSelect}
      style={style}
      defaultValue={defaultValue}
    />
  );
};

export default ChartList;