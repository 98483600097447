/**
 * Function attempts to translate multiple values
 * of options object given as parameter. The options
 * object is modified on the fly, if some translatable
 * keys are found from the object, and if a translation
 * for the value of that key exists.
 * 
 * @param {Object} options Highcharts options
 * @param {Object} intl translation Object
 * @returns {Object} translated Highcharts options
 */
export const translateOptions = (options, intl) => {
  options = translateAllAxes(options, intl);
  options = translateLegend(options, intl);
  options = translateTitle(options, intl);
  options = translateSeriesNames(options, intl);
  options = translateDataNames(options, intl);
  return options;
}

/**
 * Function attempts to translate axis titles. This is based on
 * Highcharts having a consistent title location for its axes.
 * 
 * @param {Object} options Highcharts options
 * @param {Object} intl translation object
 * @returns {Object} translated Highcharts options
 */
function translateAllAxes(options, intl) {
  options = translateOneAxis("yAxis", options, intl);
  options = translateOneAxis("xAxis", options, intl);
  return options;
}

/**
 * Function translates the title text attributes of one axis.
 * The axis which to translate is given given as parameter.
 * 
 * @param {string} axisName name of axis to translate, "xAxis" or "yAxis"
 * @param {Object} options Highcharts options
 * @param {Object} intl translation object
 * @returns {Object} translated Highcharts options
 */
function translateOneAxis(axisName, options, intl) {
  if (options[axisName]) {
    if (
      typeof options[axisName] === "object"
      && options[axisName] !== null
      && !Array.isArray(options[axisName])
    ) {
      if (typeof options[axisName].title?.text === "string") {
        options[axisName].title.text = checkAndTranslate(options[axisName].title.text, intl);
      }

      if (Array.isArray(options[axisName].categories)) {
        options[axisName].categories = translateCategories(options[axisName].categories, intl);
      }
    }
    
    else if (
      Array.isArray(options[axisName])
      && options[axisName].length > 0
    ) {
      /* yAxis is an array which has items. */
      for (let i = 0; i < options[axisName].length; ++i) {
        if (
          /* Current array item has a title to translate. */
          typeof options[axisName][i].title?.text === "string"
        ) {
          options[axisName][i].title.text = checkAndTranslate(options[axisName][i].title.text, intl);
        }

        if (Array.isArray(options[axisName][i].categories)) {
          options[axisName][i].categories = translateCategories(options[axisName][i].categories, intl);
        }
      }
    }
  }
  return options;
}

/**
 * Function attempts to translate categories of an axis.
 * 
 * @param {Array} categories categories an axis value can have
 * @param {Object} intl translation object
 * @returns {Array} translated categories
 */
function translateCategories(categories, intl) {
  if (
    Array.isArray(categories)
    && categories.length > 0
  ) {
    for (let i = 0; i < categories.length; ++i) {
      if (typeof categories[i] === "string") {
        categories[i] = checkAndTranslate(categories[i], intl);
      }
    }
  }
  return categories;
}

/**
 * Translates Highcharts titles.
 * 
 * @param {Object} options Highcharts options
 * @param {Object} intl translation object
 * @returns {Object} translated Highcharts options
 */
function translateTitle(options, intl) {
  if (options.title) {
    if (
      typeof options.title === "object" &&
      options.title !== null &&
      options.title.text
    ) {
      options.title.text = checkAndTranslate(options.title.text, intl);
    }
  }
  return options;
}

/**
 * Function loops through options series
 * and attempts to translate words under
 * the 'name' key in a series.
 * 
 * @param {Object} options Highchart options
 * @param {Object} intl translation object
 * @returns {Object} translated Highcharts options
 */
const translateLegend = (options, intl) => {
  if (options.series) {

    /* Series should come as an Array,
     * additionally check if it contains
     * any items. */
    if (
      Array.isArray(options.series) &&
      options.series.length
    ) {
      
      /* Loop through each item, modifying the name
       * attribute of that item in case one exists. */
      for (let i = 0; i < options.series.length; ++i) {
        const current_serie = options.series[i];
        if (
          typeof current_serie === "object" &&
          current_serie !== null &&
          current_serie.name
        ) {
          options.series[i].name = checkAndTranslate(options.series[i].name, intl);
        }
      }
    }
  }
  return options;
}

/**
 * Function loops options in case options are in array form
 * and attempts to translate 'name' properties from the array.
 * 
 * @param {Object} options Highcharts options
 * @param {Object} intl translation object
 * @returns {Object} translated options for Highcharts
 */
const translateSeriesNames = (options, intl) => {
  if (
    Array.isArray(options.series)
    && options.series.length > 0
  ) {
    for (let i = 0; i < options.series.length; ++i) {
      if (
        typeof options.series[i] === "object"
        && options.series[i] !== null
        && typeof options.series[i].name === "string"
      ) {
        options.series[i].name = checkAndTranslate(options.series[i].name, intl);
      }
    }
  }
  return options;
}

/**
 * Translates names in data points. Pie charts contain names
 * in data points so their translation happens by looping chart
 * data.
 * 
 * @param {Object} options Highcharts options for a chart
 * @param {Object} intl translation object
 * @returns {Object} translated Highcharts options for a chart
 */
const translateDataNames = (options, intl) => {
  if (
    Array.isArray(options.series)
    && options.series.length > 0
    && Array.isArray(options.series[0].data)
    && options.series[0].data.length > 0
    && typeof options.series[0].data[0] === "object"
    && options.series[0].data[0]?.name
  ) {
    for (let i = 0; i < options.series.length; ++i) {
      for (let j = 0; j < options.series[i].data.length; ++j) {
        options.series[i].data[j].name = checkAndTranslate(options.series[i].data[j].name, intl);
      }
    }
  }
  return options;
}

/**
 * Function splits origin string (which is
 * translations id), with multiple delimiters
 * and determines which is the best fit, then
 * returns the split array with its delimiter.
 * 
 * @param {string} origin string to be split
 * @returns {Array} split string
 */
const getIdsArr = (origin) => {
  let ids_arr, delimiter;

  /* Delimiters used are whitespace and comma-whitespace. */
  const ids_arr1 = typeof(origin) === "string" ? origin.split(" ") : [];
  const ids_arr2 = typeof(origin) === "string" ? origin.split(", ") : [];

  /* If whitespace-split array has more items
   * the comma-whitespace-split array, return it.
   * Otherwise return the comma-whitespace-split
   * array, which takes precedence in a tie. */
  if (ids_arr1.length > ids_arr2.length) {
    ids_arr = ids_arr1;
    delimiter = " ";
  } else {
    ids_arr = ids_arr2;
    delimiter = ", ";
  }

  return [ids_arr, delimiter];
}

/**
 * Function takes a string (parameter id), for which
 * translation existence is checked. If translation for
 * the entire id is not found, the id is split using
 * whitespace character as delimiter and the translation
 * is attempted for each individual word in the id.
 * 
 * @param {string} id translation id
 * @param {Object} intl translation Object
 * @returns {string} translated id
 */
export const checkAndTranslate = (id, intl) => {
  let ret = id;
  
  /* In case translation for the entire id
   * string exists, return the translation. */
  const translation_exists = !!intl.messages[id];
  if (translation_exists) {
    ret = intl.formatMessage({ id });
  }
  
  /* In case translation does not exist for the entire
   * id string, split the string from whitespace and try
   * to translate its individual parts. */
  else {
    let str = "";

    const [ids_arr, delimiter] = getIdsArr(id);

    const translated_ids_arr = [];

    /* In case there were multiple words separated by
     * whitespace, try to translate each one separately. */
    if (ids_arr.length > 1) {
      for (let i = 0; i !== ids_arr.length; ++i) {
        if (!!intl.messages[ids_arr[i]]) {
          translated_ids_arr.push(intl.formatMessage({ id: ids_arr[i] }));
        } else {
          translated_ids_arr.push(ids_arr[i]);
        }
      }
      str = translated_ids_arr.join(delimiter);
    }

    /* In case 'str' variable was modified, return it. */
    if (str) {
      ret = str;
    }
  }

  /* Return the translation,
   * backup is the original
   * id parameter if no
   * transating was done. */
  return ret;
}
