import React, { useState, useContext } from "react";
import Dropzone from "react-dropzone";
import { Input, Button } from "rsuite";
import { MsalContext } from "@azure/msal-react";
import {
  DetectFileType,
  ParseTimeZone,
  ParseTimeoffset
} from ".";
import {
  fetchDataHelper,
  logErrorIfDevelopmentMode,
  popNotification
} from "../utils";
import { UseAuthenticationContext } from "../context";
import { translate } from "../languages";

export default function DataImport(props) {
  const { endpoint } = props;

  const acceptedFileTypes = [
    ".csv",
    ".xlsx"
  ];

  const [selectedFileName, setSelectedFileName]                         = useState(null);
  const [fileWarningMessage, setFileWarningMessage]                     = useState(null);
  const [fileType, setFileType]                                         = useState(null);
  const [userInputTableName, setUserInputTableName]                     = useState(null);
  const [userInputTimezone, setUserInputTimezone]                       = useState(null);
  const [userInputTimeOffset, setUserInputTimeOffset]                   = useState(null);
  const [parsedFile, setParsedFile]                                     = useState(null);
  const [dropzoneIndicator, setDropzoneIndicator]                       = useState("bgcolor-transparent");
  const [timezoneInputIndicator, setTimezoneInputIndicator]             = useState("fgcolor-normal");
  const [timezoneOffsetInputIndicator, setTimezoneOffsetInputIndicator] = useState("fgcolor-normal");

  const { useAuthentication } = useContext(UseAuthenticationContext);
  const msalContext = useContext(MsalContext);

  function handleFileChange(file) {
    const filename = file.name;
    setSelectedFileName(filename);
    
    /* Check if filetype is supported and handle accordingly */
    const filetype = DetectFileType(filename);
    if (!acceptedFileTypes.includes(filetype)) {
      handleBadFile("warn_bad_filetype");
    } else {
      handleGoodFile();
      switch(filetype) {
        case ".csv":
            setFileType(".csv");
          break;
        case ".xlsx":
            setFileType(".xlsx");
          break;
        default:
          break;
      }
      const data = new FormData();
      data.append("files", file);
      setParsedFile(data);
    }
  }

  function handleBadUpload(message) {
    popNotification({
      type: "warning",
      text: message
    });
  }

  function handleGoodFile() {
    handleDropAccept();
    setFileWarningMessage(null);
  }

  function handleBadFile(message) {
    handleDropReject();
    setFileWarningMessage(message);
  }

  function handleDropAccept() {
    setDropzoneIndicator("bgcolor-success");
  }

  function handleDropReject() {
    setDropzoneIndicator("bgcolor-error");
  }

  function timezoneParse(input) {
    const timezone = ParseTimeZone(input);
    if (timezone !== "error" || input === "") {
      setTimezoneInputIndicator("fgcolor-normal");
      if (input === "") {
        setUserInputTimezone(null);
      } else {
        setUserInputTimezone(timezone);
      }
    } else {
      setTimezoneInputIndicator("fgcolor-error");
    }
  }

  function timeoffsetParse(input) {
    const time_offset = ParseTimeoffset(input);
    if (time_offset !== "error" || input === "") {
      setTimezoneOffsetInputIndicator("fgcolor-normal");
      if (input === "") {
        setUserInputTimeOffset(null);
      } else {
        setUserInputTimeOffset(time_offset);
      }
    } else {
      setTimezoneOffsetInputIndicator("fgcolor-error");
    }
  }

  function formFileTypeArray() {
    let ret = "";
    const typeCount = acceptedFileTypes.length;
    for (let i = 0; i < typeCount; ++i) {
      ret += `${acceptedFileTypes[i]}`;
      if (i < typeCount - 1) {
        ret += ", ";
      }
    }
    return ret;
  }

  async function handleUpload() {
    if (endpoint) {
      if (parsedFile) {
        try {
          fetchDataHelper({
            url: endpoint,
            headers: {
              accept: "application/json"
            },
            params: {
              tablename: userInputTableName,
              timezone: userInputTimezone,
              timeoffset: userInputTimeOffset
            },
            method: "POST",
            data: parsedFile,
            useAuthentication,
            authContext: msalContext,
            responseType: "multipart/form-data",
            onSuccess: handleUploadSuccess,
            onError: () => handleBadUpload("Service unavailable")
          });
        } catch(error) {
          logErrorIfDevelopmentMode(error);
          handleBadUpload("Service unavailable");
        }
      } else {
        handleBadUpload("something_wrong_with_file");
      }
    } else {
      handleBadUpload("no_dbapi_address");
    }
  }

  function handleUploadSuccess() {
    popNotification({
      type: "success",
      text: "Data upload successful"
    });
  }

  return(
    <div>
      <Dropzone
        onDragOver={() => {
          setDropzoneIndicator("bgcolor-success");
        }}
        onDragLeave={() => {
          setDropzoneIndicator("bgcolor-default");
        }}
        onDrop={async (acceptedFiles) => {
          acceptedFiles.forEach(file => {
            handleFileChange(file);
          });
        }}
        onDropRejected={(files, event) => handleDropReject()}
        multiple={false}
      >
        {
          ({ getRootProps, getInputProps }) => (
            <div className="dropzone-file-item">
              <div className="file-item">
                <div
                  {...getRootProps({
                    className:"dropzone d-flex align-items-center position-relative"
                  })}
                >
                  <input {...getInputProps()} />
                  <div
                    className={`w-100 position-absolute pointer ${dropzoneIndicator}`}
                    style={{ height: "100%" }}
                  >
                    <p className="w-100 dropzone-text">
                      {selectedFileName || translate("click_or_drop_files_here")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </Dropzone>
      
      {
        fileWarningMessage
        ? (
          <div>
            <p className="text-danger">
              {translate(fileWarningMessage)}
            </p>
          </div>
        )
        : null
      }

      <div>
        <p>
          {translate("accepted_file_types:")} <b>{formFileTypeArray()}</b>
        </p>
      </div>

      <hr/>

      <div>
        <h5>
          <p>
            {translate("additional_parameters:")}
          </p>
        </h5>
      </div>
      
      <div className="user_input_div">
        <p>
          {translate("provide_tablename")}
        </p>
        <Input
          onChange={(value) => {
            setUserInputTableName(value);
          }}
        />
      </div>

      <div className="user_input_div">
        <p>
          {translate("provide_timezone")}
        </p>
        <Input
          className={timezoneInputIndicator}
          placeholder="e.g. +2"
          onChange={(value) => timezoneParse(value)}
        />
      </div>

      <div className="user_input_div">
        <p>
          {translate("provide_timeoffset")}
        </p>
        <Input 
          className={timezoneOffsetInputIndicator}
          placeholder="HH:MM:SS"
          onChange={(value) => timeoffsetParse(value)}
        />
      </div>

      <div className="upload-button">
        {
          fileType
          && userInputTableName
          && !timezoneOffsetInputIndicator.includes("error")
          && !timezoneInputIndicator.includes("error")
          ? (
            <Button
              appearance="primary"
              onClick={handleUpload}
            >
              {translate("upload")}
            </Button>
          )
          : (
            <Button
              disabled
              style={{ backgroundColor: "#ddd" }}
            >
              {translate("upload")}
            </Button>
          )
        }
      </div>

    </div>
  );
}
