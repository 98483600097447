import React, { useState, useContext, useEffect, useCallback } from "react";

import {
  MsalContext,
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from "@azure/msal-react";
import axios from "axios";
import { Loader } from "rsuite";
import { isEqual } from "lodash";

import { Header, HeaderNav, SiteSelect } from "./header";
import { SiteSelectMap } from "./map";
import { LoginScreen } from "./login";
import {
  acquireToken,
  fetchDataHelper,
  logErrorIfDevelopmentMode,
  makeAuthUrlWithHeaders
} from "./utils";
import {
  UseAuthenticationContext,
  LanguageContext,
  NarrowScreenContext,
  CookieContext,
  SiteContext,
  HeaderProvider
} from "./context";
import { CookieBanner } from "./cookies";
import { LangProvider, locales, translate } from "./languages";

import { fetchCustomPanelTemplate } from "./customPanel/gridLogic/fetchCustomPanelTemplate";

export default function App() {
  const [selectedSite, setSelectedSite]               = useState(null);
  const [siteList, setSiteList]                       = useState([]);
  const [loading, setLoading]                         = useState(true);
  const [authIsAuthenticated, setAuthIsAuthenticated] = useState(false);
  const [authIsLoading, setAuthIsLoading]             = useState(false);
  const [availableSitesState, setAvailableSitesState] = useState({});
  const [customTemplates, setCustomTemplates]         = useState({});

  const msalContext                     = useContext(MsalContext);
  const { isNarrowScreen }              = useContext(NarrowScreenContext);
  const { useAuthentication }           = useContext(UseAuthenticationContext);
  const { site, setSite, setTimeZone }  = useContext(SiteContext);
  const {
    cookiesAccepted,
    setCookiesAccepted
  } = useContext(CookieContext);
  const {
    translations,
    setTranslations,
    selectedLanguage,
    availableLanguages,
    setAvailableLanguages
  } = useContext(LanguageContext);

  // Fetch custom panel templates
  useEffect(() => {
    const fetchData = async () => {
      if (site) {
        try {
          const data = await fetchCustomPanelTemplate(authIsAuthenticated, msalContext, site);
          setCustomTemplates(data);
        } catch (error) {
          console.error("Error fetching custom panel templates:", error);
        }
      }
    };
  
    if (site) { fetchData(); }
  }, [authIsAuthenticated, msalContext, site]);

  /**
   * Set available languages in effectful code
   * If a site is selected and available sites ahve been set in state.
   * Additionally check if available languages state differs from the
   * available languages in site info, if site info exists. In case it
   * differs, set the new available languages to state.
   */
  useEffect(() => {
    if (site && Object.keys(availableSitesState).length > 0) {
      const siteInfo = availableSitesState?.filter?.(item => item.api === site)?.[0];
      if (
        siteInfo?.languages
        && !isEqual(availableLanguages, siteInfo.languages)
      ) {
        setAvailableLanguages(siteInfo.languages);
      }
    }
  }, [
    site,
    availableSitesState,
    availableLanguages,
    setAvailableLanguages
  ]);

  /**
   * Select site if only one site available
   * @param {array} availableSites available sites, array of objects
   */
  function selectSiteIfOnlyOneAvailable(availableSites) {
    if (availableSites.length === 1) {
      setSelectedSite(availableSites[0].api);
      setSite(availableSites[0].api);
      setTimeZone(availableSites[0].timeZone);
      setAvailableLanguages(availableSites[0].languages);
    }
  }

  /**
   * Set selected site URL from window location origin to state
   * @param {array} availableSites available sites, array of objects
   */
  function setSelectedSiteUrl(availableSites) {
    if (window.location.href.split(process.env.REACT_APP_PREFIX + "/")[1]) {
      const path = window.location.href.split(process.env.REACT_APP_PREFIX + "/")[1];
      if (availableSites.some(site => site.api === path.split('/')[0])) {
        const siteCode = path.split('/')[0];
        setSelectedSite(siteCode);
        setSite(siteCode);
        setTimeZone(availableSites.find(site => site.api === siteCode).timeZone);
      }
    }
  }

  /**
   * Retrieve site data from the cloud.
   */
  const getSiteDataFromAPI = useCallback(() => {
    if (msalContext.accounts.length > 0) {
      fetchDataHelper({
        url: "/boa/api/sites",
        method: "GET",
        useAuthentication: useAuthentication,
        authContext: msalContext,
        onFetchStart: () => setLoading(true),
        onFetchEnd: () => setLoading(false),
        onSuccess: response => {
          const availableSites = response.data;
          setAvailableSitesState(availableSites);
          setSiteList(availableSites);
          selectSiteIfOnlyOneAvailable(availableSites);
          setSelectedSiteUrl(availableSites);
        },
        onError: (error) => {
          const keys = Object.keys(sessionStorage);
          for (let key of keys) { // remove old tokens
            if (key.includes("login.windows.net")) {
              sessionStorage.removeItem(key);
            }
          }
          location.reload(); // reload log in screen again
          logErrorIfDevelopmentMode(error);
        }
      });
    } else {
      setLoading(false);
    }
  }, [ // eslint-disable-line
    msalContext,
    useAuthentication
  ]);

  /**
   * Retrieve site data from directory.
   */
  const getSiteDataFromDir = useCallback(async () => {
    setLoading(true);
    const availableSites = await axios.get(process.env.PUBLIC_URL+ "/local/api.json").then(
      response => {setLoading(false); return response.data}
    );
    setAvailableSitesState(availableSites);
    setSiteList(availableSites);
    selectSiteIfOnlyOneAvailable(availableSites);
    setSelectedSiteUrl(availableSites);
  }, []); // eslint-disable-line

  /**
   * Get site data from correct storage. If authentication is used,
   * site data is retrieved from the cloud using REST API, otherwise
   * site data is retrieved from a directory.
   */
  const getSites = useCallback(() => {
    if(useAuthentication) {
      getSiteDataFromAPI();
    } else {
      getSiteDataFromDir();
    }
  }, [
    useAuthentication,
    getSiteDataFromAPI,
    getSiteDataFromDir
  ]);

  /**
   * Retrieve translations from the cloud or locally
   */
  const getTranslations = useCallback(async () => {
    const translations = Object.keys(locales);
    const languageNames = Object.values(locales)
    const allTranslations = [];
    for (let id in translations) {
      let url;
      let headers = {};
      const languageCode = translations[id];
      const languageName = languageNames[id];
      if (useAuthentication) { // authentication is used
        try { 
          if (msalContext.accounts[0]) { // user is logged in
            url = `/boa/api/v1/general/translations/${languageCode}`;
            [url, headers] = await makeAuthUrlWithHeaders(
              url,
              headers,
              acquireToken(msalContext)
            );
          } else { // user isn't logged in
            let boaurl = window.location.origin;
            if (
              window.location.hostname === "localhost"
              || window.location.hostname === "127.0.0.1"
            ) {
              boaurl = process.env.REACT_APP_DEV_URI;
            }
            url = `${boaurl}/boa/api/v1/public/${languageCode}.json`;
          }
        
          await axios({ method: "get", url, headers })
          .then(response => {
            const translationObject = {
              language: languageName,
              code: languageCode,
              translations: response.data
            };
            allTranslations.push(translationObject);
          });
        } catch (error) {
          logErrorIfDevelopmentMode(error);
        }
      } else { // no authentication, local BOA version
        await axios.get(process.env.PUBLIC_URL+ `/local/translations/${languageCode}.json`)
        .then(response => {
          const translationObject = {
            language: languageName,
            code: languageCode,
            translations: response.data
          };
          allTranslations.push(translationObject);
        })
      }
    }
    setTranslations(allTranslations);
  }, [
    msalContext,
    useAuthentication,
    setTranslations
  ]);

  // set token fetching interval on mount
  useEffect(() => {
    if (!useAuthentication) {
      getSites();
      getTranslations();
    }

    const tokenInterval = setInterval(() => {
      if (authIsAuthenticated) {
        acquireToken(msalContext, true);
      }
    }, 10*60*1000);

    return () => clearInterval(tokenInterval);
  }, [
    useAuthentication,
    getSites,
    getTranslations,
    authIsAuthenticated,
    msalContext
  ]);

  // get sites and translations from API
  useEffect(() => {
    if (msalContext.inProgress === "none") {
      getSites();
      getTranslations();
    }
  }, [msalContext, getSites, getTranslations]);

  // set authentication details
  useEffect(() => {
    if (useAuthentication) {
      setAuthIsLoading(msalContext.inProgress);
      setAuthIsAuthenticated(msalContext.accounts.length > 0);
    } else {
      setAuthIsLoading("none");
      setAuthIsAuthenticated(false);
    }
  }, [useAuthentication, msalContext]);

  /**
   * Set selected site
   * @param {string} selectedSite selected site to change to
   */
  function onSiteChange(selectedSite) {
    setSelectedSite(selectedSite);
    setSite(selectedSite);
    const siteInfo = availableSitesState.find(site => site.api === selectedSite);
    if (siteInfo) {
      setAvailableLanguages(siteInfo.languages);
      setTimeZone(siteInfo.timeZone);
    }
  }

  /**
   * Get name of authenticated user. If no user is
   * authenticated, return null.
   * @returns name of authenticated user or null
   */
  function getNameOfUser() {
    if (msalContext?.accounts?.[0]?.name) {
      return ` ${msalContext?.accounts?.[0]?.name}`;
    }
    return null;
  }

  /**
   * Create site selection element
   * @returns {JSX.Element} site selection element
   */
   function getSiteSelectContent() {
    if (siteList.length < 2) return null;
    return (
      <div className="siteSelectMobile">
        <SiteSelect
          id="sites-dropdown"
          className="form-control sites-dropdown"
          onSiteChange={onSiteChange}
          siteList={siteList}
        />
      </div>
    );
  }

  /**
   * Create map div content, which depends on component state
   * @returns {JSX.Element} map div
   */
  function getMapDivContent() {
    // no map on narrow screens
    if (isNarrowScreen) return getSiteSelectContent();

    // map if many sites
    if (siteList.length > 0) {
      return (
        <SiteSelectMap
          site={selectedSite}
          onSiteChange={onSiteChange}
          siteList={siteList}
          isAuthenticated={authIsAuthenticated}
        />
      );
    }

    // loader if component not ready
    if (loading) return <Loader size="lg" center />;

    // text if nothing else
    return <h2 className="mt-5 pt-4 center">{translate("No sites available")}</h2>
  }

  /**
   * Create body content for authentication-using users. Simultaneously
   * create authenticated and unauthenticated content, and render only
   * the one which MSAL deems correct.
   * @returns {JSX.Element} body content for authentication-using user
   */
  
  function getBodyContentAuth() {
    return (
      <>
        <AuthenticatedTemplate>
          <div className="siteSelectionMap">
            {getMapDivContent()}
            <h1 className={isNarrowScreen ? "welcomeTextMobile" : "welcomeText fadeOutElementShort"}>
              {translate("Welcome")}
              {getNameOfUser()}
            </h1>
          </div>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <LoginScreen />
        </UnauthenticatedTemplate>
      </>
    );
  }

  /**
   * Create site selection
   * @returns {JSX.Element} body content for non-authentication-using users
   */
  function getBodyContentNonAuth() {
    return (
      <>
        <div>
          <h2>{translate("select_open_site")}</h2>
        </div>
        <SiteSelect
          site={selectedSite}
          onSiteChange={onSiteChange}
          siteList={siteList}
          className="mt-4"
        />
      </>
    );
  }

  /**
   * Call one of two content creating functions depending
   * on whether authentication is in use or not.
   * @returns {JSX.Element} body content
   */
  function getBodyContent() {
    if (useAuthentication) return getBodyContentAuth();
    return getBodyContentNonAuth();
  }

  /* BEGIN RENDER SECTION */

  // component not yet loaded -> display loader
  if (loading || translations === null) {
    return <Loader size="lg" center />;
  }

  // component loaded and site is not selected
  if (!selectedSite && translations) {
    if (authIsLoading === "none") {
      return (
        <LangProvider
          locale={locales[selectedLanguage]}
          translations={translations}
        >

          <div className="App">

            <HeaderNav
              siteList={siteList}
              onSiteChange={onSiteChange}
              isAuthenticated={authIsAuthenticated}
            />

            {getBodyContent()}

          </div>

          <CookieBanner
            opacity={"opacity-100"}
            cookiesAccepted={cookiesAccepted}
            onAccept={() => setCookiesAccepted(true)}
          />

        </LangProvider>
      );
    }
    return <Loader size="lg" center />;
  }
  // component is loaded and site is selected
  if (siteList.length > 0 && translations) {
    return (
      <LangProvider
        locale={locales[selectedLanguage]}
        translations={translations}
      >
      <HeaderProvider>
        <Header
          onSiteChange={onSiteChange}
          siteList={siteList}
          isAuthenticated={authIsAuthenticated}
          customTemplates={customTemplates}
        />
      </HeaderProvider>

        <CookieBanner
          opacity={"opacity-75"}
          cookiesAccepted={cookiesAccepted}
          onAccept={() => setCookiesAccepted(true)}
        />
      </LangProvider>
    );
  }

  // fall back to loader
  return <Loader size="lg" center />;
}
