/* built-in packages */
import React from "react";
/* self-provided packages */
import {
  TimeserieChart,
  MultilineChart,
  ScatterChart,
  Histogram
} from "./charts";

export default function ChartController(props) {

  const {
    chartData,
    chartType,
    movingAverageData,
    tagsInfo,
    timeValue,
    settings,
    cuttingMode,
    cuttingRange,
    handleZoom,
    loading,
    trendsInCharts,
    setTrends,
    hiddenTags,
    selectedTags
  } = props;

  const getLineChart = () => {
    return (
      <div>
        <TimeserieChart
          chartData={chartData}
          loading={loading}
          cuttingMode={cuttingMode}
          cuttingRange={cuttingRange}
          handleZoom={handleZoom}
          hiddenSeries={hiddenTags}
          selectedTags={selectedTags}
        />
      </div>
    )
  }

  const getMultilineChart = () => {
    return (
      <MultilineChart
        chartData={chartData}
        tagsInfo={tagsInfo}
        movingAverageData={movingAverageData}
        trendsInCharts={trendsInCharts}
        setTrends={setTrends}
        handleZoom={handleZoom}
        loading={loading}
      />
    );
  }

  const getScatterchart = () => {
    return(
      <ScatterChart
        data={chartData}
        timeValue={timeValue}
        scatter_endpoint={settings.endpoints.scatter}
        default_queryparams={settings.default_queryparams}
      />
    );
  }

  const getHistogramChart = () => {
    return (
      <Histogram
        chartData={chartData}
        loading={loading}
        hiddenSeries={hiddenTags}
      />
    );
  }

  const getChartContent = (chartType) => {
    switch(chartType) {
      case "area":
        return getAreaChart();
      case "multiline":
        return getMultilineChart();
      case "scatter":
        return getScatterchart();
      case "histogram":
        return getHistogramChart();
      case "line":
      default:
        return getLineChart();
    }
  }

  return(
    <div key={chartData.length}>
      <div className="tagAnalyze">
        {getChartContent(chartType)}
      </div>
    </div>
  );
}
