import React, { useState, useCallback, useEffect, useRef } from "react";
import { CheckPicker, Button } from 'rsuite';
import CloseIcon from '@rsuite/icons/Close';
import { translate } from "../languages";

const TagLists = (props) => {
  let { defaultTags, onChange, tags, endpoint } = props;

  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [visibleFields, setVisibleFields] = useState(["id", "tagname", "description"]);
  const [selectedOnTop, toggleSelectedOnTop] = useState(false);
  const [selectedTags, setSelectedTags] = useState();
  
  const tagPickerRef = useRef(null);
  
  useEffect(() => {
    setSelectedTags(defaultTags);
  }, [defaultTags]);

  const handleChange = useCallback((value) => {
    setSelectedTags(value);
    onChange(value);
  }, [onChange]);

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const toggleFieldVisibility = (field) => {
    setVisibleFields((prevFields) =>
      prevFields.includes(field)
        ? prevFields.filter((f) => f !== field)
        : [...prevFields, field]
    );
  };

  const getSortIcon = (field) => {
    if (sortField === field) {
      return sortOrder === 'asc' ? 'fas fa-sort-amount-down-alt' : 'fas fa-sort-amount-up-alt';
    }
    return 'fas fa-sort gray';
  };

  const customTagPickerMenu = (item) => {
    let descriptionLength = 12;
    let tagnameLength = 12;
    let idLength = 1;
    if (visibleFields.length === 3) {
      descriptionLength = 6;
      tagnameLength = 5; // id col is always 1
    } else if (visibleFields.includes('tagname') && visibleFields.includes('description')) { // id is hidden
      descriptionLength = 6;
      tagnameLength = 6;
    } else if (visibleFields.length === 1) { // only 1 field is visible
      idLength = 12;
    } else { // id and either tagname or description is visible
      tagnameLength = 11; // id col is always 1
      descriptionLength = 11; // id col is always 1
    }

    return (
      <div className="row" style={{ display: "flex", alignItems: "center" }}>
        {visibleFields.includes('id') &&
          <div 
            className={`col-${idLength}`} 
            style={{ 
              overflow: "hidden", 
              textOverflow: "ellipsis", 
              whiteSpace: "nowrap", 
              fontSize: 14, 
              flexShrink: 0 
            }}
          >
            <span>{item.id}</span> 
          </div>
        }
        {visibleFields.includes('tagname') &&
          <div 
            className={`col-${tagnameLength}`} 
            style={{ 
              overflow: "hidden", 
              textOverflow: "ellipsis", 
              whiteSpace: "nowrap", 
              fontSize: 14, 
              flexShrink: 0 
            }}>
              <span>{item.tagname}</span>
          </div>
        }
        {visibleFields.includes('description') && 
          <div 
            className={`col-${descriptionLength}`} 
            style={{ 
              overflow: "hidden", 
              textOverflow: "ellipsis", 
              whiteSpace: "nowrap", 
              fontSize: 14, 
              flexGrow: 1 
            }}>
            <span>{item.description}</span>
          </div>
        }
      </div>
    );
  };

  const TagPickerExtraFooter = () => {
    const iconStyle = { fontSize: 16, marginRight: 10, cursor: "pointer" };
    const isDarkTheme = localStorage.getItem('theme') === 'dark';
    const textStyle = { color: isDarkTheme ? 'rgb(224, 224, 224)' : 'black' };
    const iconColor = isDarkTheme ? 'rgb(224, 224, 224)' : 'black';

    const handleOKClick = () => {
      if (tagPickerRef.current) {
        tagPickerRef.current.close();
      }
    };

    return (
      <div className="tagPickerExtraFooter" style={textStyle}>
        <div 
          style={{ 
            display: "flex", 
            justifyContent: "space-between", 
            alignItems: "center", 
            padding: "0px 15px",
            marginBottom: "15px" 
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
            <i 
              className={visibleFields.includes("id") ? "fas fa-eye" : "fas fa-eye-slash gray"} 
              style={{ ...iconStyle, color: iconColor }} 
              onClick={() => toggleFieldVisibility('id')} 
            />
            <i 
              className={getSortIcon('id')} 
              style={{ ...iconStyle, color: iconColor }} 
              onClick={() => handleSort('id')} 
            />
            <span>{translate("ID")}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', margin: 'auto' }}>
            <i 
              className={visibleFields.includes("tagname") ? "fas fa-eye" : "fas fa-eye-slash gray"} 
              style={{ ...iconStyle, color: iconColor }} 
              onClick={() => toggleFieldVisibility('tagname')} 
            />
            <i 
              className={getSortIcon("name")} 
              style={{ ...iconStyle, color: iconColor }} 
              onClick={() => handleSort('name')} 
            />
            <span>{translate("Tagname")}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}>
            <i 
              className={visibleFields.includes("description") ? "fas fa-eye" : "fas fa-eye-slash gray"} 
              style={{ ...iconStyle, color: iconColor }} 
              onClick={() => toggleFieldVisibility('description')}
            />
            <i 
              className={getSortIcon("description")} 
              style={{ ...iconStyle, color: iconColor }} 
              onClick={() => handleSort('description')} 
            />
            <span>{translate("Description")}</span>
          </div>
        </div>
        <div 
          style={{ 
            display: "flex", 
            justifyContent: "space-between", 
            alignItems: "center", 
            padding: "0px 15px"
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <i 
              className={selectedOnTop ? "fas fa-check-square" : "far fa-square" }
              style={{ ...iconStyle, color: iconColor }} 
              onClick={() => toggleSelectedOnTop(!selectedOnTop)} 
            />
            {translate("Selected on the top")}
          </div>
          <div style={{ display: 'flex', alignItems: 'center'}}>
            <Button 
              appearance="ghost" 
              style={{ float: "right", color: iconColor }}
              onClick={handleOKClick}
            >
              {translate("OK")}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const sortTags = (a, b) => {
    if (sortField === "name") { 
      if (sortOrder === "asc") { return a.tagname && b.tagname ? a.tagname.toLowerCase().localeCompare(b.tagname.toLowerCase()) : -1; } 
      else { return a.tagname && b.tagname ? b.tagname.toLowerCase().localeCompare(a.tagname.toLowerCase()) : -1; }
    } else if (sortField === "description") { 
      if (sortOrder === "asc") { return a.description && b.description ? a.description.toLowerCase().localeCompare(b.description.toLowerCase()) : -1; }
      else { return a.description && b.description ? b.description.toLowerCase().localeCompare(a.description.toLowerCase()) : -1; }
    } else { 
      if (sortOrder === "asc") { return a.id > b.id ? 1 : -1; } 
      else { return a.id > b.id ? -1 : 1; }
    }
  }

  if (!tags) {
    return null;
  }

  const getValues = () => {
    const tagsArray = Array.isArray(tags) ? tags : (tags[endpoint] || []);
    const combinedTags = tagsArray.map(tag => {
      const selectedTag = selectedTags.find(selectedTag => selectedTag[2] === (Array.isArray(tag) ? tag[0] : tag.id));
      return {
        id: Array.isArray(tag) ? tag[0] : tag.id,
        tagname: Array.isArray(tag) ? tag[1] : tag.tagname,
        description: Array.isArray(tag) ? tag[2] : tag.description,
        unit: Array.isArray(tag) ? tag[3] : tag.unit,
        color: "",
        yAxisHeight: undefined,
        combinedProperties: selectedTag ? selectedTag : (Array.isArray(tag) ? tag : [tag.tagname, tag.description, tag.id, tag.unit, tag.color, tag.yAxisHeight])
      };
    });
    const selectedTagIds = (selectedTags || []).map(tag => tag[2]);
    const sortedTags = combinedTags.sort((a, b) => sortTags(a, b));
    return selectedOnTop
      ? [
          ...sortedTags.filter(tag => selectedTagIds.includes(tag.id)),
          ...sortedTags.filter(tag => !selectedTagIds.includes(tag.id))
        ]
      : sortedTags;
  }

  return (
    <div className="flex-item tagPicker" style={{ marginBottom: 16 }}>
      <CheckPicker
        ref={tagPickerRef}
        virtualized={true}
        data={getValues()}
        placeholder={<div style={{ padding: "9px" }}>{translate(`Select tags: ${endpoint.split("/tags")[0].split("/").pop()}`)} </div>}
        searchable={true}
        style={{ minWidth: 300, flex: 1 }}
        value={selectedTags}
        onChange={(value) => handleChange(value)}
        renderExtraFooter={() => <TagPickerExtraFooter />}
        renderMenuItem={(label, item) => customTagPickerMenu(item)}
        renderValue={(value, items) => {
          // Sort items based on the order of the value array
          const sortedItems = items.sort((a, b) => value.indexOf(a.id) - value.indexOf(b.id));
        
          return (
            <div className="tagContainer">
              {sortedItems.map(item => (
                <div 
                  key={item.id} 
                  className="customTagBox"
                >
                  {item.id}
                  <CloseIcon
                    className="hoverRed"
                    style={{ 
                      marginLeft: '5px', 
                      cursor: 'pointer', 
                      zIndex: 200, 
                      fontSize: "12px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      const newSelectedTags = value.filter(tag => tag[2] !== item.id);
                      handleChange(newSelectedTags);
                    }}
                  />
                </div>
              ))}
            </div>
          );
        }}
        valueKey="combinedProperties"
        labelKey="id"
        searchBy={(keyword, label, item) => {
          if (Object.values(item).filter(removeNulls => removeNulls).some(value => value.toString().toLowerCase().includes(keyword.toLowerCase()))) {
            return item;
          }
        }}
      />
    </div>
  );
};

export default TagLists;