// import React, { Component } from "react";
import * as XLSX from "xlsx";
import { getJsDateFromExcel } from "excel-date-to-js";

export const DetectFileType = (filename) => {
  /* Function takes filename as argument and
   * splits it from each dot ('.'). The last
   * item in the split array is extracted and
   * returned with a preceding dot.
   * Example:
   * - input: example.filename.type
   * - output: .type */
  return `.${filename.split(/[.]+/).pop()}`;
};

const guess_CSV_delimiter = (text) => {
  /* Function takes an input text (.csv format) as parameter
   * and attempts to guess the used delimiter. This is done
   * by comparing the first and last rows of the csv text.
   * The rows are split using one of the possible delimiters
   * and if the lengths match, the delimiter is deemed as
   * valid. Among valid delimiters, the one which produces
   * the longest array of items after split is chosen. */
  const possible_delimiters = [",", ";"];
  const valid_delimiters = [];
  const csv_array = text.split("\n");
  const csv_array_length = csv_array.length;
  for (let i = 0; i < possible_delimiters.length; ++i) {
    if (csv_array[0].split(possible_delimiters[i]).length ===
      csv_array[csv_array_length - 2].split(possible_delimiters[i]).length) {
      valid_delimiters.push(possible_delimiters[i]);
    }
  }
  if (valid_delimiters.length === 1) {
    return valid_delimiters[0];
  } else if (valid_delimiters.length > 1) {
    let longest = 0;
    let delimiter;
    for (let i = 0; i < valid_delimiters.length; ++i) {
      const count = csv_array[0].split(valid_delimiters[i]).length;
      if (count > longest) {
        delimiter = valid_delimiters[i];
      }
    }
    return delimiter;
  } else return;
};

const CSV_text_to_arrays = (text, delimiter) => {
  /* Function creates an array of arrays from
   * text that is in .csv format. The text is
   * split into arrays, where one array contains
   * one line from the .csv file. Each line
   * (array item) is then split into an array
   * of .csv items and added to an array, which
   * is eventually returned. */
  const ret = [];
  const csv_array = text.split("\n");
  const csv_array_length = csv_array.length;
  for (let i = 0; i < csv_array_length; ++i) {
    ret.push(csv_array[i].split(delimiter));
  }
  return ret;
};

const str_values_to_num = (data_chunks) => {
  /* Function takes data chunks as parameter,
   * loops through each data chunk to manipulate
   * its data rows, then loops through each data
   * row to manipulate its data values transforming
   * all but the first (left-most) value, which
   * is assumed to be datetime value, to numerical
   * format. */
  const chunk_count = data_chunks.length;
  for (let i = 0; i < chunk_count; ++i) {
    const current_chunk = data_chunks[i];
    const current_chunk_length = current_chunk.length;
    for (let j = 0; j < current_chunk_length; ++j) {
      const current_row = current_chunk[j];
      const current_row_length = current_row.length;
      for (let k = 1; k < current_row_length; ++k) {
        const numeric_data = parseFloat(current_row[k]);
        data_chunks[i][j][k] = numeric_data;
      }
    }
  }
  return data_chunks;
};

export const ParseCSVFile = (text) => {
  /* Main exported .csv parsing function, 
   * which calls other helper functions. */
  const delimiter = guess_CSV_delimiter(text);
  if (delimiter) {
    const CSV_array_items = CSV_text_to_arrays(text, delimiter);
    const [ header_rows, header_row_count ] = extract_header_rows(CSV_array_items);
    const data_row_chunks = extract_data_rows(CSV_array_items, header_row_count);
    const numeric_data_row_chunks = str_values_to_num(data_row_chunks);
    const ret = {
      header: header_rows,
      data: numeric_data_row_chunks
    };
    return ret;
  }
};

const transform_xlsx_datetime = (datetime) => {
  /* .xlsx files have stupid datetime format, which
   * needs additional work to be understandable by
   * modern standards. */
  let str_datetime;
  let ret;
  if (typeof datetime === "number") {
    str_datetime = datetime.toString();
  } else if (typeof datetime === "string") {
    str_datetime = datetime;
  }
  if (str_datetime) {
    if (str_datetime.includes(".")) {
      if (str_datetime.split(".")[0].length === 5) {
        ret = getJsDateFromExcel(str_datetime);
      }
    } else {
      ret = datetime;
    }
  } else {
    console.info("Unknown datetime format, backend will handle it.");
  }
  return ret;
};

const extract_header_rows = (data) => {
  const header_row_count = detect_header_row_count(data);
  const header_rows = [];
  for (let i = 0; i < header_row_count; ++i) {
    const current_header_row = data[i];
    if (!current_header_row[0]) {
      current_header_row[0] = "datetime";
    }
    header_rows.push(current_header_row);
  }
  return [ header_rows, header_row_count ];
};

const extract_data_rows = (data, header_row_count, rows_per_chunk=500) => {
  const data_row_chunks = [];
  let temp_data_rows = [];
  let temp_data_row_count = 0;
  const last_data_row_number = data.length;
  for (let i = header_row_count; i < last_data_row_number; ++i) {
    let current_row = data[i];
    current_row[0] = transform_xlsx_datetime(current_row[0]);
    temp_data_rows.push(current_row);
    ++temp_data_row_count;
    if (temp_data_row_count === rows_per_chunk) {
      data_row_chunks.push(temp_data_rows);
      temp_data_rows = [];
      temp_data_row_count = 0;
    }
    if (i === last_data_row_number - 1) {
      data_row_chunks.push(temp_data_rows);
    }
  }
  return data_row_chunks;
};

const is_char_numnber = (char) => {
  if (isNaN(parseInt(char))) {
    return false;
  } else {
    return true;
  }
};

const is_datetime = (input) => {
  /* Function is really stupid. It checks
   * whether an input, which is converted
   * into a string, contains a significant
   * amount of numeric characters. If it
   * does, it is assumed to be a datetime
   * string, in which case function returns
   * true and otherwise false. */
  if (input) {
    let num_count = 0;
    let non_num_count = 0;
    const str_input = input.toString();
    const str_length = str_input.length;
    for (let i = 0; i < str_length; ++i) {
      const current_char = str_input[i];
      if (is_char_numnber(current_char)) {
        ++num_count;
      } else {
        ++non_num_count;
      }
    }
    if (num_count / non_num_count > 0.4) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const detect_header_row_count = (data) => {
  /* Function expects data as an array of
   * arrays and checks the amount of
   * 'header' or non-data rows. */
  if (Array.isArray(data)) {
    let current_row_number = 0;
    let latest_legit_header_row_number = 0;
    let consecutive_data_rows = 0;
    const consecutive_data_rows_for_decision = 5;
    let decision = false;
    while (!decision) {
      const current_row = data[current_row_number];
      if (Array.isArray(current_row)) {
        let legit_header_row = false;
        
        /* Check if the first (left-most) column
         * value in the current row is a datetime.
         * If it is, the row is assumed to not be
         * a header row. */
        if (!is_datetime(current_row[0])) {
          legit_header_row = true;
        }

        /* If the current row array is assumed to belong
         * to the header part, label its row number to
         * the latest legit header row number. Otherwise
         * add one to consecutive data (non-header) rows. */
        if (legit_header_row) {
          latest_legit_header_row_number = current_row_number;
          consecutive_data_rows = 0;
        } else {
          ++consecutive_data_rows;
        }

        /* If the amount of consecutive data rows
         * matches the threshold amount of consecutive
         * data rows required for making the decision.
         * Otherwise add to row number and resume
         * while-looping. */
        if (consecutive_data_rows === consecutive_data_rows_for_decision) {
          decision = true;
        } else {
          ++current_row_number;
        }
      }
    }
    return latest_legit_header_row_number + 1;
  } else {
    // TODO: error to user
    console.error("Error parsing given .xlsx file.");
  } 
};

export const ParseXLSXFile = (xlsx_file_content) => {
  /* Function takes filereader binary string as argument
   * and utilizes a 3rd-party xlsx-file reading library
   * for reading the file contents. */
  const xlsx = XLSX.read(xlsx_file_content, { type: "binary" });
  const data = XLSX.utils.sheet_to_json(
    xlsx.Sheets[xlsx.SheetNames[0]],
    { header: 1 }
  );

  /* Manipulate header rows into their own
   * array. The first array item i.e. the
   * left-most column is assumed to be
   * the datetime column. That is why the
   * corresponding label is added there
   * in case it does not exist. */
  const [ header_rows, header_row_count ] = extract_header_rows(data);

  /* The data rows are added as an array(2) of row
   * arrays(1) to a main array(3). Array(2) length
   * is limited to a feasible database query size.
   * Each datetime is checked and possibly transformed
   * .xlsx files have their own datetime format. */
  const data_row_chunks = extract_data_rows(data, header_row_count);

  const ret = {
    header: header_rows,
    data: data_row_chunks
  };
  return ret;
}

const determine_sign = (input) => {
  /* Function determines plus (+) or minus (-)
   * from the first character of input string. */
  let sign = null;
  if (input.split("+").length === 2) {
    sign = "+";
  } else if (input.split("-").length === 2) {
    sign = "-";
  } else if (!isNaN(parseFloat(input))) {
    sign = "+";
  }
  return sign;
}

const determine_tznum = (input, sign) => {
  let tznum = input;
  let split_tznum = null;
  let first_part = null;
  let last_part = null;
  let timezone = null;
  if (input.includes(sign) && input[0] === sign) {
    tznum = input.split(sign)[1];
  }
  /* See if non-integer timezone */
  if (tznum.includes(".")) {
    split_tznum = tznum.split(".");
  } else if (tznum.includes(",")) {
    split_tznum = tznum.split(",");
  }

  if (split_tznum) {
    if (split_tznum[0].length < 3 && !isNaN(parseInt(split_tznum[0]))) {
      first_part = split_tznum[0];
    }
    if (!isNaN(parseInt(split_tznum[1].substring(0, 2)))) {
      last_part = split_tznum[1].substring(0, 2);
    }
    if (first_part && last_part) {
      timezone = `${first_part}.${last_part}`;
    }
  } else if (tznum.length < 3 && !isNaN(parseInt(tznum))) {
    if ((sign === "-" && parseInt(tznum) <= 12) ||
      (sign === "+" && parseInt(tznum) <= 14)) {
        timezone = tznum;
    }   
  }
  return timezone;
};

export const ParseTimeZone = (input) => {
  /* determine plus or minus */
  let ret = "error";
  const sign = determine_sign(input);
  if (sign) {
    /* determine timezone numerical value */
    const tznum = determine_tznum(input, sign);
    if (tznum) {
      ret = `${sign}${tznum}`;
    }
  }
  return ret;
};

const determine_sign_general = (input) => {
  /* Function determines whether the first
   * character of a string is a plus (+) or
   * minus (-). If neither are found, a plus
   * is assumed. Returns the determined sign
   * and the input stripped from the possible
   * sign. */
  let sign = "+";
  let input_without_sign = input;
  if (input) {
    const first_char = input[0];
    if (first_char === "-" || first_char === "+") {
      if (first_char === "-") {
        sign = "-"
      }
      input_without_sign = input.substring(1, input.length);
    }
  }
  return [sign, input_without_sign];
};

export const ParseTimeoffset = (input) => {
  /* Function validates time offset in
   * HH:MM:SS format. First the semicolons
   * are split off and if the remaining
   * array is three items long, the values
   * are checked to be numeric and two
   * characters long. */
  let ret = "error";
  const [sign, timeoffset_without_sign] = determine_sign_general(input);
  const arr = [];
  const split_input = timeoffset_without_sign.split(":");
  if (split_input.length === 3) {
    for (let i = 0; i < 3; ++i) {
      if (split_input[i].length === 2 &&
        !isNaN(parseInt(split_input[i]))) {
        if (i > 0 && parseInt(split_input[i]) < 60) {
          arr.push(split_input[i]);
        } else if (i === 0) {
          arr.push(split_input[0]);
        }
      }
    }
    if (arr.length === 3) {
      ret = `${sign}${arr.join(":")}`;
    }
  }
  return ret;
};