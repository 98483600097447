import React, { useRef, useContext, useState } from "react";
import { MsalContext } from "@azure/msal-react";
import { Whisper, Popover } from "rsuite";
import { PanelAlertButton, PanelMoreButton } from ".";
import { SiteContext, NarrowScreenContext, PanelAlertContext, UseAuthenticationContext, HeaderContext } from "../context";
import { dataSetHasQuicklyExportableData, data2excel, makeAuthUrlWithHeaders, acquireToken } from "../utils";
import { FeedbackModal, InfoModal, ExcelExportModal } from "../modals";
import { useIsAuthenticated } from '../utils';

import axios from "axios";
import CustomPanelSettingsMenu from "../customPanel/customPanelSettingsMenu";

export default function GetMoreButtons(props) {
    const {
        panelName,
        panel,
        autoUpdate,
        toggleAutoUpdate,
        handlePanelRefresh,
        resizable,
        toggleResizeTool,
        handlePanelMinimize,
        excelExport,
        panelDataHasQuicklyExportableData,
        handleFavorites,
        queryParams,
        panelHasExcelExport,
        nextAutoUpdate,
        width,
        fetchedData,
        commentingMode,
        toggleCommentingMode,
        panelHasDataCommenting,
        activeApp,
        endpoints,
        site_info
    } = props;

    const [showFeedbackModal, setFeedbackModal] = useState(false);
    const [showExportModal, setExportModal]     = useState(false);
    const [infoModalConfig, setInfoModalConfig] = useState();
    const [isOpen, setIsOpen]                   = useState(false);

    const { site, timeZone }        = useContext(SiteContext);
    const { isNarrowScreen }        = useContext(NarrowScreenContext);
    const { panelAlerts }           = useContext(PanelAlertContext);
    const { useAuthentication }     = useContext(UseAuthenticationContext);
    const { header, updateHeader }  = useContext(HeaderContext);
    const msalContext               = useContext(MsalContext);

    const popoverRef = useRef();

    const isAuthenticated = useIsAuthenticated(); 

    const closeMoreButtons = () => {
        popoverRef.current.close();
    };

    const parseDataForQuickExport = () => {
        const parsedData = [];

        const dataLen = fetchedData?.length || 0;
        for (let i = 0; i < dataLen; ++i) {
            const currentDataSet = fetchedData[i];
            if (dataSetHasQuicklyExportableData(currentDataSet)) {
                parsedData.push(currentDataSet);
            }
        }
        return parsedData;
    };

    const autoUpdateButton = () => {
        const panelHasRefreshInterval = panel.refreshinterval > 0 ? true : false;
        return (
            <PanelMoreButton
                buttonTooltip={
                    panelHasRefreshInterval
                        ? "Toggle panel autoupdate"
                        : "Automatic updating is disabled for panel"
                }
                icon={autoUpdate && panelHasRefreshInterval ? "fa-sync fa-spin" : "fa-sync"}
                buttonText={
                    autoUpdate && panelHasRefreshInterval
                        ? "Automatic update is on"
                        : "Automatic update is off"
                }
                onClick={toggleAutoUpdate}
                panel={panelName}
                disabled={!panelHasRefreshInterval}
                extraTooltipInfo={autoUpdate && panelHasRefreshInterval && { text: "Next update:", value: nextAutoUpdate }
                }
            />
        );
    };

    const refreshPanelButton = () => {
        return (
            <PanelMoreButton
                buttonTooltip="Refresh the panel"
                icon="fa-redo-alt"
                buttonText="Refresh panel"
                onClick={() => {
                    handlePanelRefresh();
                    closeMoreButtons();
                }}
                panel={panelName}
            />
        );
    };

    const addToFavoritesButton = () => {

        // Disable favorites button for demo panels
        if (panel.isDemo) return null;

        const checkFavorites = () => {
            const favorites = localStorage.getItem(`${site}-favorites`);
            if (favorites) {
                const favoritesList = JSON.parse(favorites);
                return favoritesList.some(fav => fav === panelName);
            } else {
                return false;
            }
        };

        const panelIsFavorite = checkFavorites();

        return (
            <PanelMoreButton
                buttonTooltip={panelIsFavorite ? "Remove from favorites" : "Add to favorites"}
                icon="fa-star"
                iconStyle={panelIsFavorite ? "fas" : " far"}
                buttonText={panelIsFavorite ? "Remove from favorites" : "Add to favorites"}
                panel={panelName}
                onClick={() => {handleFavorites(panelName)}}
            />
        );
    };

    const resizeButton = () => {
        return (
            <PanelMoreButton
                buttonTooltip="Toggle resizing of panel"
                extraTooltipInfo={width && {text: "Current width:", value: `${width.toFixed(1)}px`}}
                icon="fa-arrows-alt-h"
                buttonText={resizable ? "Resizing is enabled" : "Resizing is disabled"}
                onClick={() => toggleResizeTool()}
                panel={panelName}
            />
        );
    };

    const feedbackButton = () => {
        return (
            <PanelMoreButton
                buttonTooltip="Give feedback"
                icon="fa-envelope"
                buttonText="Panel feedback"
                onClick={() => {
                    setFeedbackModal(true);
                    closeMoreButtons();
                }}
                panel={panelName}
            />
        );
    };

    const quickExportButton = () => {
        return (
            <PanelMoreButton
                buttonTooltip="Export currently visible data as Excel file"
                icon="fa-file-excel"
                buttonText="Quick export"
                onClick={() => data2excel(parseDataForQuickExport(), timeZone)}
                panel={panelName}
            />
        );
    };

    const excelExportButton = () => {
        return (
            <PanelMoreButton
                buttonTooltip="Export Excel"
                icon="fa-file-download"
                buttonText="Export Excel"
                onClick={() => {
                    setExportModal(true);
                    closeMoreButtons();
                }}
                panel={panelName}
            />
        );
    };

    const minimizeButton = () => {
        return (
            <PanelMoreButton
                buttonTooltip="Minimize panel"
                icon="fa-minus"
                buttonText="Minimize panel"
                onClick={() => handlePanelMinimize()}
                panel={panelName}
            />
        );
    };
    const infoButton = () => {

      // Disable info button for demo panels
      if (panel.isDemo) return null;

        return (
            <PanelMoreButton
                buttonTooltip="Open panel info"
                icon="fa-info"
                buttonText="Show panel info"
                onClick={() => {
                    setInfoModalConfig(panel.info);
                    closeMoreButtons();
                }}
                panel={panelName}
            />
        );
    };

    const commentingButton = () => {
        return (
            <PanelMoreButton
                buttonTooltip="Toggle panel commenting mode"
                icon="fa-pencil-alt"
                buttonText={commentingMode ? "Commenting mode is on" : "Commenting mode is off"}
                onClick={() => toggleCommentingMode()}
                panel={panelName}
            />
        );
    };

    const panelAlertButton = () => {
        return (
            <PanelAlertButton
                panelId={panelName}
                site={site}
                alertEnabled={panelAlerts[panelName]}
            />
        );
    };

    const editCustomPanelsButton = () => {

      // isPublic is unique to custom panels, so it can be used to determine if the panel is a custom panel. Demo panels should not be edited
      if (!('isPublic' in panel) || panel.isDemo) return null;

        return (
            <>
                <PanelMoreButton
                    buttonTooltip="Edit the selected panel"
                    icon="fa-edit"
                    buttonText="Edit"
                    panel={panelName}
                    onClick={async () => {
                        setIsOpen(true);
                        closeMoreButtons();
                    }}
                />
            </> 
        );
    }

    // fileName is the panel id
    const deletePanelInfo = async (fileName) => {
        if (!isAuthenticated) { return }
        try {
            let url = `/boa/api/v1/${site}/guides?id=${fileName + ".json"}`;
            let headers = {};
            if (useAuthentication) {
                [url, headers] = await makeAuthUrlWithHeaders(url, headers, acquireToken(msalContext));
            }
            await axios.delete(url, { headers });
        } catch (error) {
            console.error(error);
        }
    }
    
    const deleteCustomPanel = async (panelId, user) => {
        try {
            let url = `/boa/api/v1/${site}/custompanels/delete`;
            let headers = {};
            [url, headers] = await makeAuthUrlWithHeaders(url, headers, acquireToken(msalContext));
            await axios.post(url, { id: panelId, user }, { headers });
        } catch (error) {
            console.error(error);
        }
    }
    
    const removeCustomPanelsButton = () => {

        // isPublic is unique to custom panels, so it can be used to determine if the panel is a custom panel. Demo panels should not be deleted
        if (!('isPublic' in panel) || panel.isDemo) return null;
    
        const handleRemoveClick = async () => {
            // Define the confirmation message based on whether the panel is public or private
            const message = panel.isPublic 
                ? "Are you sure you want to delete this public panel?" 
                : "Are you sure you want to delete this private panel?";
    
            // Show the confirmation dialog
            const userConfirmed = window.confirm(message);
    
            if (userConfirmed) {
                // Proceed with deletion if user confirms
                if (isAuthenticated) {
                    await deleteCustomPanel(panel.id, msalContext.accounts[0].username);
                } else {
                    const customPanels = JSON.parse(localStorage.getItem(`${site}-customPanels`)) || [];
                    const updatedCustomPanels = customPanels.filter(customPanel => customPanel.id !== panel.id);
                    localStorage.setItem(`${site}-customPanels`, JSON.stringify(updatedCustomPanels));
                }
                await deletePanelInfo(panel.id);  // Remove panel info after custom panel deletion
                updateHeader(header + 1);
                closeMoreButtons();
            }
        };
    
        return (
            <PanelMoreButton
                buttonTooltip="Remove the selected custom panel"
                icon="fa-trash"
                buttonText="Remove"
                panel={panelName}
                onClick={handleRemoveClick} // Use the new handler with confirmation
            />
        );
    };
    
    return (
        <React.Fragment>
            <Whisper
                placement="autoHorizontalStart"
                ref={popoverRef}
                speaker={
                    <Popover>
                        {autoUpdateButton()}
                        {refreshPanelButton()}
                        {addToFavoritesButton()}
                        {panel.resizable !== false && !isNarrowScreen && resizeButton()}
                        {panelAlerts.hasOwnProperty(panelName) && panelAlertButton()}
                        {minimizeButton()}
                        {panel.info && infoButton()}
                        {panelHasDataCommenting && commentingButton()}
                        {useAuthentication && feedbackButton()}
                        {panelDataHasQuicklyExportableData && quickExportButton()}
                        {excelExport && queryParams.some((params) => params.tags) && excelExportButton()}
                        {editCustomPanelsButton()}
                        {removeCustomPanelsButton()}
                    </Popover>
                }
                trigger="click"
                preventOverflow
            >
                <div className="more-button-container pointer">
                    <em className="fas fa-ellipsis-v" />
                </div>
            </Whisper>
    
            {isOpen && (
                <CustomPanelSettingsMenu
                    isOpen={isOpen}
                    setIsOpen={() => setIsOpen(false)}
                    editMode={true}
                    panelID={panel.id}
                    // settings={[endpoints.find(endpoint => endpoint.endpoints.history === panel.sources.series[0].url)]}
                    settings={endpoints}
                    activeMenu={activeApp}
                />
            )}
    
            {panelHasExcelExport && (
                <ExcelExportModal
                    params={queryParams}
                    parseTags={true}
                    show={showExportModal}
                    excelExport={excelExport}
                    {...props}
                    onHide={() => setExportModal(false)}
                />
            )}
    
            {panel.info && infoModalConfig && (
                <InfoModal
                    show={infoModalConfig !== undefined}
                    title={panel.title}
                    info={infoModalConfig}
                    site={site}
                    onHide={() => setInfoModalConfig()}
                />
            )}
    
            <FeedbackModal
                show={showFeedbackModal}
                title={panel.title}
                panel={panelName}
                site={site}
                site_info={site_info}
                onHide={() => setFeedbackModal(false)}
            />
        </React.Fragment>
    );
}