import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";

highchartsMore(Highcharts);
solidGauge(Highcharts);

export default class Gauge extends Component {
  render() {
    const gauge = this.props.gauge;
    const dataValue = this.props.dataValue;

    let options = {
      chart: {
        type: "gauge",
        backgroundColor: "rgba(255, 255, 255, 0.0)",
        height:"200px"
      },
      title: {
        text: null,
      },
      yAxis: {},

      pane: {
        startAngle: -150,
        endAngle: 150,
        background: [
          {
            backgroundColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, "#FFF"],
                [1, "#333"],
              ],
            },
            borderWidth: 0,
            outerRadius: "109%",
          },
          {
            backgroundColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, "#333"],
                [1, "#FFF"],
              ],
            },
            borderWidth: 1,
            outerRadius: "107%",
          },
          {
            // default background
          },
          {
            backgroundColor: "#DDD",
            borderWidth: 0,
            outerRadius: "105%",
            innerRadius: "103%",
          },
        ],
      },

      plotOptions: {},
      series: [
        {
          name: null,
          data: [],
        },
      ],
    };

    options.yAxis = Object.assign(options.yAxis, gauge.yAxis);
    options.series[0].data.push(parseFloat(dataValue));

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
}
