import React, { useContext, useState } from "react";

import { Whisper, Tooltip } from "rsuite";
import { MsalContext } from "@azure/msal-react";
import { isFunction } from "lodash";

import { sharedSettings } from ".";
import { translate } from "../languages";
import {
  UseAuthenticationContext,
  TrackingContext,
  NarrowScreenContext
} from "../context";

export default function MenuToggleButton(props) {
  const {
    showMenu,
    onClick,
    site
  } = props;
  const useAuthenticationContext = useContext(UseAuthenticationContext);
  const authContext = useContext(MsalContext);
  const trackingContext = useContext(TrackingContext);
  const { isNarrowScreen } = useContext(NarrowScreenContext);

  const [closeMenuIcon, toggleMenuIcon] = useState(true);

  function getNewMenuState() {
    let newMenuState;
    if (showMenu) {
      newMenuState = "hide";
    } else {
      newMenuState = "show";
    }
    return newMenuState;
  }

  function handleClick() {
    if (isFunction(onClick)) {
      trackingContext.track(
        {
          type: "menu_toggle",
          action: "click",
          source: "menu_toggle_button",
          target: getNewMenuState(),
          site
        },
        useAuthenticationContext.useAuthentication,
        authContext
      );
      toggleMenuIcon(!closeMenuIcon);
      onClick();
    }
  }

  function getButtonClasses(mobile) {
    let classes = "";

    if (mobile) {
      classes = `${classes} menu-toggle-button-mobile`;
    } else {
      classes = `${classes} menu-toggle-button`;
    }

    return classes;
  }

  function getIconClasses(show, mobile) {
    let classes = "fas";
    let icon = closeMenuIcon ? "fa-outdent" : "fa-indent"; 

    if (show) {
      if (mobile) {
        classes = `${classes} fa-chevron-up`;
      } else {
        classes = `${classes} ${icon}`;
      }
    } else {
      if (mobile) {
        classes = `${classes} fa-chevron-down`;
      } else {
        classes = `${classes} ${icon}`;
      }
    }

    return classes;
  }

  function getHoverText() {
    let text;

    if (closeMenuIcon) {
      text = "Hide menu";
    } else {
      text = "Show menu";
    }

    return text;
  }

  function getPlacement(mobile) {
    let placement = "right";

    if (mobile) {
      placement = "left";
    }

    return placement;
  }

  return(
    <Whisper
      speaker={
        <Tooltip>
          {translate(getHoverText())}
        </Tooltip>
      }
      trigger={ isNarrowScreen ? "none" : "hover" }
      delayOpen={sharedSettings.delayShow}
      placement={getPlacement(isNarrowScreen)}
    >
      <button
        type="button"
        className={getButtonClasses(isNarrowScreen)}
        onClick={handleClick}
        data-testid="menu-toggle-button"
      >
        <i className={getIconClasses(showMenu, isNarrowScreen)} />
      </button>
    </Whisper>
  );
}
