import React from "react";

import { Whisper, Tooltip } from "rsuite";
import { isFunction } from "lodash";

import { sharedSettings } from ".";
import { translate } from "../languages";

export default function HeaderButton(props) {
  const { 
    tooltip,
    icon,
    onClick,
    customClass = null 
  } = props;

  const {
    trigger = "hover",
    placementInHeader = "auto",
    delayShow = 0,
    sharedClasses = ""
  } = sharedSettings;
  
  function onClickEvent() {
    if (isFunction(onClick)) {
      onClick();
    }
  }

  return (
    <Whisper
      speaker={
        <Tooltip>
          {translate(tooltip)}
        </Tooltip>
      }
      trigger={trigger}
      placement={placementInHeader}
      delayShow={delayShow}
    >
      <i
        className = {customClass ? `${customClass} ${icon}` : `${sharedClasses} ${icon}`}
        onClick = {onClickEvent}
        data-testid = "headerButton"
      />
    </Whisper>
  );
}
