import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { IconButton } from 'rsuite';
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine';
import ArrowLeftLineIcon from '@rsuite/icons/ArrowLeftLine';
import debounce from 'lodash.debounce';

const ArrowRightIcon = ({ size }) => <ArrowRightLineIcon style={{ fontSize: size }} />;
const ArrowLeftIcon = ({ size }) => <ArrowLeftLineIcon style={{ fontSize: size }} />;

const ColorPicker = (props) => {
    let { colors, defaultColor, onChange } = props;

    const sortedColors = useMemo(() => [defaultColor, ...colors.filter(color => color !== defaultColor)], [colors, defaultColor]);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedColor, setSelectedColor] = useState(sortedColors[0]);
    const [visibleColors, setVisibleColors] = useState([]);

    const colorPickerRef = useRef(null);
    const hasRun = useRef(false);

    const colorSquareSize = 18;
    const marginSize = 8;
    const totalSize = colorSquareSize + marginSize;
    const closedWidth = 3.6 * colorSquareSize;

    // Send default color to parent when component is created
    useEffect(() => {
        if (!hasRun.current) {
            onChange(defaultColor);
            hasRun.current = true;
        }
    }, [defaultColor, onChange]);

    const handleColorSelect = useCallback((color) => {
        setSelectedColor(color);
        onChange(color);
        setIsOpen(false);
    }, [onChange]);

    const calculateVisibleColors = useCallback(() => {
        if (colorPickerRef.current) {
            const width = colorPickerRef.current.clientWidth;
            const numVisibleColors = Math.floor(width / totalSize);

            if (width <= 100) {
                setVisibleColors([selectedColor]); // Show only one color if width is 100 or less
            } else {
                setVisibleColors(prev => prev.length !== numVisibleColors ? sortedColors.slice(0, numVisibleColors) : prev); // Show colors based on available width
            }
        }
    }, [sortedColors, selectedColor, totalSize]);

    const debouncedCalculateVisibleColors = useMemo(() => debounce(calculateVisibleColors, 100), [calculateVisibleColors]);

    // Resize observer to calculate visible colors based on width
    useEffect(() => {
        const currentRef = colorPickerRef.current;
        const resizeObserver = new ResizeObserver(debouncedCalculateVisibleColors);
        if (currentRef) {
            resizeObserver.observe(currentRef);
        }
        return () => {
            if (currentRef) {
                resizeObserver.unobserve(currentRef);
            }
            debouncedCalculateVisibleColors.cancel();
        };
    }, [debouncedCalculateVisibleColors]);

    useEffect(() => {
        if (isOpen) {
            setVisibleColors(sortedColors);
        } else {
            calculateVisibleColors();
        }
    }, [isOpen, sortedColors, calculateVisibleColors]);

    return (
        <div
            ref={colorPickerRef}
            className={`color-picker`}
            style={{ width: isOpen ? (totalSize * (colors.length + 1.5)): `${closedWidth}px`, maxWidth: "91vw" }} // Set width based on open state
        >
            {visibleColors.map((color) => (
                <div
                    key={color}
                    onClick={() => handleColorSelect(color)}
                    className="color-square"
                    style={{
                        backgroundColor: color,
                        width: colorSquareSize,
                        height: colorSquareSize,
                        margin: `${marginSize / 2}px`,
                        boxShadow: color === selectedColor ? `${color} 0 0 4px` : 'none'
                    }}
                />
            ))}
            <IconButton
                icon={isOpen ? <ArrowLeftIcon size={"2em"} /> : <ArrowRightIcon size={"2em"} />}
                onClick={() => setIsOpen(!isOpen)}
                appearance="subtle"
                className="no-padding no-hover-background"
                style={{ border: "none", height: colorSquareSize + marginSize }}
            />
        </div>
    );
};

export default ColorPicker;
