import React, { useState } from "react";
import { Item } from "../grid";
import { Tooltip, Whisper } from "rsuite";
import { translate } from "../languages";
import { InfoModal } from "../modals";

export default function OverviewItem({ item, color, value, layouts, imageUrl, toggles, socket_values, socket }) {
  
  const [infoModal, setInfoModal] = useState({ show: false, config: null });
  /* if item doesn't have title, background is none and circles are bigger */
  const background = item.title ? "rgba(255,255,255,0.75)" : "none";
  let circleSize = item.title ? "25px" : "50px";
  if (item.circleSize) { circleSize = item.circleSize; }
  const imageWidth = item.imageWidth || item?.image?.width || "50px";
  const imageHeight = item.imageHeight || item?.image?.height || "auto";
  const imageExt = item?.image?.ext || "png";
  const unit = item.unit ?? "";
  let arrowColor = color.name;
  if (color.name === "left") { arrowColor = "red" }
  if (color.name === "right") { arrowColor = "green"; }
  const css = {
    ...{
      display: "inline-block",
      alignItems: "center",
      padding: "1rem",
      background: background
    },
    ...item.css
  }

  const imageAltStyle = { color: color.code, fontSize: "20px", fontWeight: 600, textShadow: "2px 2px black" }
  const children = (
    <div style={css} >
      {(item.type === "trafficLight" || item.type === "toggle") && (
        <div
          style={{
            width: circleSize,
            height: circleSize,
            borderRadius: "90%",
            background: color.code,
            display: "inline-block",
            marginRight: "0.5rem",
          }}
        />
      )}

      {item.type === "trafficLightImg" && (
        <img
          src={`${imageUrl}/media/trafficlight/${color.name}.png `}
          alt={color.name}
          width={imageWidth}
          style={imageAltStyle}
        />
      )}
      {item.type === "arrowImg" && (
        <img
          src={`${imageUrl}/media/arrows/${arrowColor}.png `}
          alt={color.name}
          width={imageWidth}
          style={imageAltStyle}
        />
      )}
      {item.type === "lineImg" && (
        <img
          src={`${imageUrl}/media/arrows/${color.name}.png`}
          alt={color.name}
          width={imageWidth}
          style={imageAltStyle}
        />
      )}
      {item.type === "arrow" && (
        <img
          src={`${imageUrl}/media/arrows/green.png`}
          alt="arrow"
          width={imageWidth}
          style={imageAltStyle}
        />
      )}
      {item.type === "value" && (
        <span 
          className="overviewText"
          style={{
            fontSize: "12pt",
            backgroundColor: color.code,
            padding: "8px",
            border: "1px solid #61a9d6",
            borderRadius: "5px"
          }}
        >
          {`${value} ${unit}`}
        </span>
      )}
      {item.info &&
        <>
          <div style={{ position: "relative", padding: "10px" }}>
            <div style={{ cursor: "pointer" }}>
                <em
                  className="fas fa-info-circle text-info"
                  onClick={() => setInfoModal({ show: true, config: item.info}) }
                  style={{ float: "right", fontSize: "1.25rem" }}
                />
            </div>
          </div>
            {infoModal.config && infoModal.show &&
              <InfoModal
                show={infoModal.show}
                title="info"
                info={infoModal.config}
                onHide={() => setInfoModal({ show: false, config: null}) }
              />
            }
        </>
      }
      {item.image?.path && (
        <img
          src = {`${imageUrl}/media/${item.image?.path}.${imageExt}`}
          alt = {item.path}
          style = {{ width: imageWidth, height: imageHeight }}
        />
      )}
      {item.title &&
        <span className="overviewText" style={{ fontSize: "1rem", marginLeft: "8px" }}>
          {translate(item.title)}
        </span>
      }
      {item.panel && (
        <Item
          simplified={true}
          id={0}
          panel={layouts[item.panel]}
          code={"index"}
          panelName={item.panel}
          layouts={layouts}
          socket_values={socket_values}
          socket={socket}
        />
      )}
    </div>
  );

  let render = <div></div>;
  
  if (item.tooltip) {
    render = (
      <Whisper
        placement="top"
        trigger="hover"
        speaker={ 
          <Tooltip visible>
            {translate(item.tooltip)}
          </Tooltip>
        }
      >
        {children}
      </Whisper>
    );
  } else if (item.type === "text") {
    render = 
    <div 
      className="overviewText"
      style={css}
    >
      {translate(item.title)}
    </div>
  } else {
    render = children;
  }
  let hideCondition;
  value = parseFloat(value);
  if ( item?.hideCondition?.comparison === "=") {
    hideCondition = value === item.hideCondition.value;
  } else if (item?.hideCondition?.comparison === "<") {
    hideCondition = value < item.hideCondition.value;
  } else if (item?.hideCondition?.comparison === ">") {
    hideCondition = value > item.hideCondition.value;
  } else if (toggles && toggles[item.toggleGroup] !== "undefined" && toggles[item.toggleGroup] === false ) {
    hideCondition = true;
  } else {
    hideCondition = false;
  }
  if (hideCondition) { return null } 
  else { return render }
 
}
