import React, { PureComponent, createContext } from "react";

const NarrowScreenContext = createContext();

/**
 * Context provider class for narrow screen detection
 * and dynamic handling of different screen sizes.
 * Done using React.PureComponent to prevent unnecessary
 * state updates during window resize.
 * 
 * For testing purposes, prop **_testOverride** (preferably
 * boolean) can be used to set tested components to use
 * an override value for narrow or wide screen, and prop
 * **_testIsNarrowScreen** (preferably boolean) to set screen
 * width detection to either true (screen in narrow) or
 * false (screen is wide). 
 */
class NarrowScreenProvider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isNarrowScreen: false
    };
  }

  /** Add resize event listener on mount */
  componentDidMount() {
    this.handleScreenResize();
    window.addEventListener("resize", this.handleScreenResize);
  }

  /**
   * sets new state on screen resize
   * breakpoint is 768px which is the same value that Bootstrap md-class uses
   */
  handleScreenResize = () => {
    if (window.innerWidth < 768) {
      this.setState({ isNarrowScreen: true });
    } else {
      this.setState({ isNarrowScreen: false });
    }
  }

  render() {
    return (
      <NarrowScreenContext.Provider
        value={{
          isNarrowScreen: (
            this.props._testOverride
            ? this.props._testIsNarrowScreen
            : this.state.isNarrowScreen
          )
        }}
      >
        {this.props.children}
      </NarrowScreenContext.Provider>
    );
  }
}

export { NarrowScreenContext, NarrowScreenProvider };