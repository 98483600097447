import React, { useRef, useEffect, useContext, useState } from "react";
import { useIntl } from 'react-intl';
import ReactQuill from "react-quill-new";
import {
  Modal, 
  Loader,
  Notification,
  Whisper,
  Tooltip,
  toaster,
  Button
} from "rsuite";
import axios from "axios";
import { isFunction } from "lodash";

import { getQuillToolbar } from ".";
import {
  fetchDataHelper,
  isUserFromAndritz,
  makeAuthUrlWithHeaders,
  checkHttpStatusCode,
  acquireToken
} from "../utils";
import { translate } from "../languages";
import {
  UseAuthenticationContext,
  NarrowScreenContext,
  SiteContext
} from "../context";
import { MsalContext } from "@azure/msal-react";

export default function InfoModal(props) {
  const { 
    info, //guide object
    onHide, //closes modal
    show, //visibility of modal
    title = null
  } = props;

  const { site }                                    = useContext(SiteContext);
  const { useAuthentication }                       = useContext(UseAuthenticationContext);
  const { isNarrowScreen }                          = useContext(NarrowScreenContext);
  const msalContext                                 = useContext(MsalContext);

  const [editingMode, setEditingMode]               = useState(false);
  const [content, setContent]                       = useState(false);
  const [savingInProgress, setSavingInProgress]     = useState(false);
  const [resetEditor, setResetEditor]               = useState(new Date());

  const intl = useIntl();
  const quillRef = useRef();

  useEffect( () => {
    const guidesId = typeof(info) === "string" ? info : info.id;
    const url = info.url ? info.url : `/boa/api/v1/${site}/guides?id=`;
    if (guidesId) {
      fetchDataHelper({
        url: `${url}${guidesId}`,
        method: "GET",
        useAuthentication: useAuthentication,
        authContext: msalContext,
        onSuccess: setInfoModalContent
      });
    }
  },[info, msalContext, site, useAuthentication]);

  useEffect( () => {
    const editor = quillRef?.current?.getEditor();
    quillRef?.current?.setEditorContents(editor, content);
  }, [content, resetEditor]);

  const setInfoModalContent = (content) => {
    setContent(content.data)
  }

  const handleClose = () => {
    if (isFunction(onHide)) { onHide(); }
    setEditingMode(false);
  }

  const getModalTitle = () => {
    let modalTitle;

    if (title) { modalTitle = title;} 
    else { modalTitle = `${intl.formatMessage({ id: "Panel info" })}`; }

    return <h3>{modalTitle}</h3>;
  }

  const getQuillContent = () => {
    try {
      const editor = quillRef.current.getEditor();
      const delta = editor.getContents();
      return delta;
    } catch {
      return null;
    }
  }

  const getToolbar = () => {
    return(
      <div
        id="toolbar"
        style={editingMode ? { } : { display: "none" } }
        className="ql-toolbar ql-snow"
      >
        {getQuillToolbar()}
      </div>
    );
  }

  const getModules = () => {
    return { toolbar: { container: "#toolbar" }};
  }

  const saveEdits = async () => {
    /**
     * Function gets the current content of the quill editor and
     * posts its content to API for saving. Also calls different
     * class methods to indicate information to the user.
     * 
     * For some reason the usage of dataFetchHelper function's POST
     * method never sent the request, so the fetching was written
     * in this function of the component. The 'would-have-been' function
     * call is commented out.
     */

    const delta = getQuillContent();
    
    try {
      if (delta) {
        const guidesId = typeof(info) === "string" ? info : info.id;
        let url = info.url ? info.url : `/boa/api/v1/${site}/guides?id=`;
        url = `${url}${guidesId}`;
        let headers = {};
        if (useAuthentication) {
          [url, headers] = await makeAuthUrlWithHeaders(url, headers, acquireToken(msalContext));
        }

        setSavingInProgress(true);
        setContent(delta);
        const response = await axios.post(url, delta, { headers });
        setSavingInProgress(false);

        if (checkHttpStatusCode(response.status)) {
          saveSuccess();
        } else {
          saveError();
        }
      }
    } catch (error){ 
      setSavingInProgress(false);
      saveError();
    }
  }

  const saveSuccess = () => {
    notifySaveSuccess();
    setEditingMode(false);
  }

  const saveError = () => {
    notifySaveError();
  }

  const notifySaveSuccess = () => {
    toaster.push(
      <Notification
        type="success"
        header={intl.formatMessage({ id: "Info content saved successfully" })}
      />,
      { placement: "topEnd" }
    );
  }

  const notifySaveError = () => {
    toaster.push(
      <Notification
        type="error"
        header={intl.formatMessage({ id: "Something went wrong" })}
      />,
      { placement: "topEnd" }
    );
  }

  const discardEdits = () => {
    setEditingMode(false);
    setResetEditor(new Date());
  }

  const getEditButton = () => {
    let editButtons = null;

    if (isUserFromAndritz(msalContext.accounts?.[0]?.username) || !useAuthentication) {
      if (editingMode) {
        editButtons = (
          <>
            <Button 
                onClick={() => saveEdits()}
                appearance="primary" 
                color="green"
              > {translate("Save edits")}
            </Button>

            <Button 
                onClick={() => discardEdits()}
                appearance="primary" 
                color="orange"
              > {translate("Discard edits")}
            </Button>
          </>
        );
      } else {
        editButtons = (
          <Whisper
            speaker={<Tooltip>{translate("edit_info_button_text")}</Tooltip>}
            trigger="hover"
            placement="top"
            delayShow={1000}
          >
            <Button 
                onClick={() => setEditingMode(true)}
                appearance="primary" 
                color="green"
              > {translate("Edit")}
            </Button>

          </Whisper>
        );
      }
    }

    return editButtons;
  }

  const getCloseButton = () => {
    return(

      <Button 
        onClick={ ()=> handleClose() }
        appearance="primary" 
        color="red"
        data-testid="info-modal-close-button"
        > {translate("Close")}
      </Button>
    );
  }

  const getLoader = () => {
    return(
      <Loader
        size="lg"
        center
      />
    );
  }

  return(
    <Modal
      open={show}
      size={isNarrowScreen ? "xs" : "md"}
      onClose={()=> handleClose()}
      backdrop={editingMode ? "static" : true}
      data-testid="info-modal"
    >

      {savingInProgress && getLoader()}

      <Modal.Header>
        {getModalTitle()}
      </Modal.Header>

      <Modal.Body>
        {getToolbar()}
        <ReactQuill
          modules={getModules()}
          theme="snow"
          readOnly={!editingMode}
          ref={quillRef}
        />
      </Modal.Body>

      <Modal.Footer>
        {info.editable && getEditButton()}
        {getCloseButton()}
      </Modal.Footer>

    </Modal>
  );
}
