import React from "react";
import { Dropdown, Badge } from "rsuite";
import { translate } from "../languages";

export default function HeaderDropdownItem(props) {
  const { 
    onClick,
    icon,
    iconStyle = "fas",
    text, 
    showBadge = false,
    testId = null
  } = props

  const getIcon = () => {
    return(
      <i className={`${iconStyle} ${icon}`} />
    );
  }

  return(
    <Dropdown.Item>
      <div
        className="header-dropdown-item"
        onClick={() => onClick()}
        data-testid={testId || "header-dropdown-item"}
      >
        <div className="header-dropdown-item-icon">
          {showBadge ? ( 
              <Badge>
                {getIcon()}
              </Badge>
            ) : (
              getIcon()
            )
          }
        </div>

        <div className="header-dropdown-item-description">
          {translate(text)}
        </div>

      </div>
    </Dropdown.Item>
  );
}
