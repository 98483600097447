import React, { Component, createContext } from "react";

import {
  isDeviceMobile,
  encrypt,
  hash,
  fetchDataHelper,
  isUserFromAndritz
} from "../utils";

const TrackingContext = createContext();

class TrackingProvider extends Component {
  constructor(props) {
    super(props);
    this.tracking = [];
    this.authContext = null;
    this.sendTimer = null;
  }

  /**
   * Function sets up an interval with 15 seconds
   * frequency of sending data to server.
   */
  componentDidMount() {
    this.sendTimer = setInterval(this.sendToServer, 15000);
  }

  /**
   * Function clears interval of sending tracking
   * data to server.
   */
  componentWillUnmount() {
    clearInterval(this.sendTimer);
  }

  /**
   * Function sets authentication context
   * to component's attributes if not set
   * already.
   * @param {Object} authContext authentication context
   */
  initAuthContext(authContext) {
    if (
      authContext  
      && this.authContext === null
    ) {
      this.authContext = authContext;
    }
  }

  /**
   * Function attempts to extract site name
   * from tracking Array by looping it and
   * returning the first non-null site name.
   * @return {string | null} site name if exists, otherwise null
   */
  extractSite() {
    let site = null;
    if (this.tracking.length > 0) {
      for (let i = 0; i < this.tracking.length; ++i) {
        if (this.tracking[i].site) {
          site = this.tracking[i].site;
          break;
        }
      }
    }
    return site;
  }

  /**
   * Function sends gathered tracking information to
   * server if authentication context has been set to
   * component and tracking array has some content.
   */
  sendToServer = () => {
    if (
      this.authContext !== null
      && this.tracking?.length > 0  
    ) {
      const encryptedMessage = encrypt(this.tracking);
      const site = this.extractSite();
      fetchDataHelper({
        url: `/boa/api/v1/${site}/trc`,
        method: "POST",
        data: { data: encryptedMessage },
        useAuthentication: true,
        authContext: this.authContext
      });

      this.tracking = [];
    }
  }

  /** Function receives an Object as parameter
   * and attempts to extract different information
   * from it by destructuring.
   * Information description:
   * type: type of event, e.g. 'page_change'
   * action: action taken by user, e.g. 'click'
   * source: the origin 'element' of the event
   * target: target of action if applicable, e.g. name of layout view to move into
   * site: name of current site
   * @param {Object} event Object containing tracking information
   */
  track = (event, useAuthentication, authContext) => {
     const {
      type    = null,
      action  = null,
      source  = null,
      target  = null,
      site    = null
    } = event;
     if (
       (type || target || action)
       && useAuthentication
       && process.env.NODE_ENV !== "development"
    ) {
      this.initAuthContext(authContext);
    
      const trackingObject = {
        datetime: new Date().toISOString(),
        mobile: isDeviceMobile(),
        andritzUser: isUserFromAndritz(this.authContext?.accounts?.[0]?.username),
        userHash: hash(this.authContext?.accounts?.[0]?.username),
        type,
        action,
        source,
        target,
        site
        /** The below notation adds attribute
         * to object conditionally, in this
         * case only if attribute exists. This
         * is an alternative to including them
         * all always, even if null.
         */
        // ...(type    && { type }   ),
        // ...(action  && { action } ),
        // ...(source  && { source } ),
        // ...(target  && { target } ),
        // ...(site    && { site }   )
      };
      
      this.tracking.push(trackingObject);
    }
  }

  render() {
    return(
      <TrackingContext.Provider
        value={{
          track: this.track,
          sendToServerAsBeacon: this.sendToServerAsBeacon
        }}
      >
        {this.props.children}
      </TrackingContext.Provider>
    );
  }
}

export { TrackingContext, TrackingProvider };
