import React, { useState, useEffect } from "react";
import { Whisper, Tooltip } from "rsuite";

export default function LocalClock(props) {
  const { timeZone } = props;

  /**
   * Creates a date string in the local time of
   * the site if time zone is provided, otherwise
   * creates a date string at the client's current
   * time.
   * @returns {string} date string
   */
  function getTime() {
    if (timeZone) {
      return new Date().toLocaleTimeString(navigator.language, { timeZone });
    } else {
      return new Date().toLocaleTimeString(navigator.language);
    }
  }

  // init clock time
  const [time, setTime] = useState(getTime());

  // Set an interval of one second for updating the clock
  useEffect(
    () => {
      const timeUpdateInterval = setInterval(() => {
        setTime(getTime());
      }, 1000);

      return () => { clearInterval(timeUpdateInterval)}
    },
    [time] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function getTimezoneName(type = "long") {
    const today = new Date();
    const short = today.toLocaleDateString(undefined);
    const full = today.toLocaleDateString(undefined, { timeZone, timeZoneName: type });
  
    // Trying to remove date from the string in a locale-agnostic way
    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
      const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
      
      // by this time `trimmed` should be the timezone's name with some punctuation -
      // trim it from both sides
      return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
  
    } else {
      // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
      return full;
    }
  }

  return (
    <Whisper
      speaker={<Tooltip> <span>{`${timeZone}:`}<br/>{ `${getTimezoneName("long")} (${getTimezoneName("short")})`}</span> </Tooltip>}
      trigger="hover"
      placement="top"
    >
      <p>
        <i className="far fa-clock me-2"/>
        {time}
      </p>
    </Whisper>
  );
}
