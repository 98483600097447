import React, { Component } from "react";

import Select from "react-select";

import { withAllContext } from "../context";
import { translate } from "../languages";

class SiteSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panels: {},
      tags: [],
      site: this.props.site
    };
  }

  siteChange = (e) => {
    //setState only if the new value is different than the previous one
    if (this.props.site === e.value) {
      return null;
    } else {
      if (this.props.siteList.some(site => site.api === e.value)) {
        window.history.pushState("", "", `${process.env.REACT_APP_PREFIX}/${e.value}`);
        this.setState({ site: e.value });
        this.props.setSite(e.value);
        return this.props.onSiteChange(e.value);
      }
    }
  }

  filterDefault(site_code, options) {
    /** This function helps in filtering the
     * default selected site to insert 
     * into the selection field.
     * Also removes BOA/FOA etc. prefix from label
     */
    for (let i = 0; i < options.length; ++i) {
      if (options[i].value === site_code) {
        const defaultSite = {
          ...options[i], 
          label: options[i].label.split(": ")[1]
        }
        return defaultSite;
      }
    }
  }

  parseOptions(sites) {
    const arr = [];
    for (let i = 0; i < sites.length; ++i) {
      const item = { value: sites[i].api, label: `${sites[i].product}:  ${sites[i].text}` };
      arr.push(item);
    }
    return arr;
  }

  render() {
    const options = this.parseOptions(this.props.siteList);
    let defaultOption = null;
    if (this.state.site) {
      defaultOption = this.filterDefault(this.state.site, options);
    }
    return (
      <div data-testid="site-select">
        <Select 
          classNamePrefix="react-select"
          placeholder={translate("select_site")}
          defaultValue={defaultOption}
          options={options}
          onChange={(e) => this.siteChange(e)}
          isSearchable={!this.props.isNarrowScreen}
        />
      </div>
    );
  }
}

export default withAllContext(SiteSelect);
