/* built-in packages */
import React, { Component } from "react";

/* 3rd-party packages */
import { Modal } from "rsuite"; 

/* self-provided packages */
import { translate } from "../languages";

export default class SimpleInfoButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_modal: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = prevState;

    let title = null;
    if ("title" in nextProps) title = nextProps.title;
    state.title = title;

    let content = "No content";
    if ("content" in nextProps) content = nextProps.content;
    state.content = content;

    return state;
  }

  handleOpening = () => {
    this.setState({ show_modal: true });
  }

  handleClosing = () => {
    this.setState({ show_modal: false });
  }

  getModalHeader(title) {
    return (
      title
      ? (
        <Modal.Header>
          <Modal.Title>
            {translate(title)}
          </Modal.Title>
        </Modal.Header>
      )
      : null
    );
  }

  render() {
    const modalHeader = this.getModalHeader(this.state.title);

    return (
      <>
        {this.props.useText ? (
          <div style={{cursor:"help"}} onClick={this.handleOpening}>
            {translate(this.props.useText)}
          </div>
        ) : (
          <em
            className="fas fa-info-circle hvr-grow"
            onClick={this.handleOpening}
          />
        )}

        <Modal
          open={this.state.show_modal}
          onClose={this.handleClosing}
          backdrop={true}
          size="md"
        >

          {modalHeader}
  
          <Modal.Body>
            {translate(this.state.content)}
          </Modal.Body>

        </Modal>
      </>
    );
  }
}