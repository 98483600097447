import React, { useState, useEffect, useContext } from "react";
import { Whisper, Tooltip } from "rsuite";
import axios from "axios";
import { capitalize } from "lodash";
import {
  localTime,
  LocalClock,
  acquireToken
} from "../utils";
import { translate } from "../languages";
import { MsalContext } from "@azure/msal-react";
import { 
  LanguageContext, 
  NarrowScreenContext, 
  SiteContext, 
  UseAuthenticationContext
} from "../context";

export default function BottomBar(props) {
  const {
    site_info,
    extraFeatures
  } = props;

  const [showBottomBar, setShowBottomBar] = useState(true);
  const [weatherData, setWeatherData] = useState(null);
  const [lastUpdated, setLastUpdated] = useState("-");
  const [siteHealth, setSiteHealth] = useState();

  const msalContext = useContext(MsalContext);
  const { useAuthentication } = useContext(UseAuthenticationContext);
  const { isNarrowScreen } = useContext(NarrowScreenContext);
  const { site } = useContext(SiteContext);
  const languageContext = useContext(LanguageContext);
  
  /* fetch weather data from openweathermap */
  useEffect(() => {
    async function getWeatherData() {
      if (site_info.city) {
        try {
          const response = await axios.get(`https://api.openweathermap.org/data/2.5/weather?q=${site_info.city}&appid=d18c0b0e9c555eafe3c8585f515ce44a&lang=${languageContext.selectedLanguage}`);
          setWeatherData(response.data);
          setLastUpdated(localTime());
        } catch (error) { console.log(error.message); }
      }
    }

    if (useAuthentication) { getWeatherData(); }

    // set a weather fetching interval to 5 minutes
    const weatherFetchInterval = useAuthentication ? setInterval(getWeatherData, 300000) : null;

    return function() { // clear interval when component is closed
      clearInterval(weatherFetchInterval);
    }

  },[site_info, languageContext.selectedLanguage, useAuthentication]);

  /* set site health status light */
  useEffect(() => {
    let mounted = true;
    async function getSiteHealth() {
      try {
        let headers = {};
        if (useAuthentication && msalContext.accounts[0]) {
          const accessToken = await acquireToken(msalContext);
          headers = { authorization: `Bearer ${accessToken.idToken}` };
        }
        const urlArray = [];
        if (Array.isArray(extraFeatures.siteHealthTag)) { // multiple status lights
          for (let healthTag of extraFeatures.siteHealthTag) {
            let endpoint = healthTag.endpoint ? healthTag.endpoint : `/boa/api/v1/${site}/history`;
            let queryparams = healthTag.queryparams ? healthTag.queryparams : {tags: healthTag.tag}
            endpoint = `${endpoint}?${Object.keys(queryparams).map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(queryparams[k])).join("&")}`;
            urlArray.push(endpoint);
          }
        } else if (typeof(extraFeatures.siteHealthTag) === "number" || typeof(extraFeatures.siteHealthTag) === "string") { // 1 tag as a number or string
          urlArray.push(`/boa/api/v1/${site}/history?func=last&tags=${extraFeatures.siteHealthTag}`);
        } else { // 1 tag but as an object
          let endpoint = extraFeatures.siteHealthTag?.endpoint ? extraFeatures.siteHealthTag?.endpoint : `/boa/api/v1/${site}/history`;
          let queryparams = extraFeatures.siteHealthTag?.queryparams ? extraFeatures.siteHealthTag?.queryparams : {tags: extraFeatures.siteHealthTag?.tag}
          endpoint = `${endpoint}?${Object.keys(queryparams).map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(queryparams[k])).join("&")}`;
          urlArray.push(endpoint);
        }

        const currentTime = new Date().getTime();
        const minTime = currentTime - (60 * 1000 * 10);

        axios.all(urlArray.map(url => axios.get(url, {headers, timeout: 10000 } )))
        .then(axios.spread((...response) => {
          const healthStatusArray = [];
          for (let index in response) {
            if (
              Array.isArray(response[index].data) &&
              Array.isArray(response[index].data[0].data) && 
              Array.isArray(response[index].data[0].data[0]) && 
              typeof(response[index].data[0].data[0][0]) === "number"
            ) {
              const infoText = Array.isArray(extraFeatures.siteHealthTag) ? extraFeatures.siteHealthTag[index].info : "";
              const statusCode = response[index].data[0].data[0][0] > minTime ? "Online" : "Offline";
              healthStatusArray.push({ statusCode: statusCode, info: infoText})
            } else { healthStatusArray.push({ statusCode: "Offline", info: "Error" }); }
          }
          if (mounted) { setSiteHealth(healthStatusArray); }
        }))
        .catch(() => setSiteHealth( [{ statusCode: "Offline", info: "Error" }] ));
      } catch (error) { console.log(error.message); }
    }

    if (extraFeatures?.siteHealthTag) { getSiteHealth() };

    const siteHealthCheckInterval = extraFeatures?.siteHealthTag ? setInterval(getSiteHealth, 1000 * 60) : null;

    return () => {
      mounted = false;
      clearInterval(siteHealthCheckInterval);
    }

  },[useAuthentication, site, msalContext, extraFeatures])

  /**
   * Retrieves the weather description from component state.
   * If the description does not exist, returns '-'.
   * @returns weather description
   */
  function getWeatherDescription() {
    const description = weatherData?.weather?.[0]?.description;
    const conditionCode = weatherData?.weather?.[0]?.main;
    let icon = null;
    switch (conditionCode) {
      case "Thunderstorm":
        icon = <i className="fas fa-bolt me-2"/>
        break;
      case "Drizzle":
        icon = <i className="fas fa-cloud-rain me-2"/>
        break;
      case "Rain":
        icon = <i className="fas fa-cloud-showers-heavy me-2"/>
        break;
      case "Snow":
        icon = <i className="fas fa-snowflake me-2"/>
        break;
      case "Clear":
        icon = <i className="fas fa-sun me-2"/>
        break;
      case "Clouds":
        icon = <i className="fas fa-cloud me-2"/>
        break;
      default:
        icon = <i className="fas fa-smog me-2"/>
    }

    if (description) { return (<p>{icon}{capitalize(description)}</p>); } 
    else { return "-"; }
  }

  /**
   * Retrieves temperature from component's state data. The temperature
   * is retrieved in Kelvins, so proper calculations are made to display
   * the result in correct units. '-' is returned if weather data does
   * not exist in component state
   * @returns {string} temperature or '-'
   */
  function getTemperature() {
    const kelvin = weatherData?.main?.temp;
    if (kelvin) {
      if (site_info.units === "imperial") {
        return `${((weatherData.main.temp * 9 / 5) - 459.67).toFixed(2)} °F`;
      } else {
        return `${(weatherData.main.temp - 273.15).toFixed(2)} °C`;
      }
    } else {
      return "-";
    }
  }


  function getUser() {
    return (
      <>
        <div className="metadata-item">
          <p>
          <i className="fas fa-user me-2"/>
            {msalContext?.accounts?.[0]?.name}
          </p>
        </div>

        <div className="metadata-item">
          |
        </div>
      </>
    );
  }

  function getMaintenanceInfo() {
    const text = extraFeatures.maintenance.text ?? "This site is currently down for maintenance";
    return (
      <>
        <div className="metadata-item">
          |
        </div>
        <div className="metadata-item" style={{ color: extraFeatures.maintenance.color ?? "red" }}>
          <p>
            <i className="fas fa-exclamation-triangle me-2"/>
            {text.toUpperCase()}
          </p>
        </div>
      </>
    );
  }

  const getSiteHealth = () => {
    return (
      <div className="metadata-item">
        {siteHealth.map((status, index) => {
          const color = status.statusCode === "Online" ? "#00c851" : "#ff4444";
          let tooltipText = status.statusCode;
          if (status.info) { tooltipText += ` - ${status.info}`; }
          return(
            <div key={`siteHealth${index}`} className="d-inline-block ms-3">
              <Whisper placement="topStart" trigger="hover" speaker={ <Tooltip><p>{tooltipText}</p></Tooltip> } >
                <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: color }}>
                  <div className="sonar-wave" style={{ width: "100%", height: "100%", borderRadius: "50%", backgroundColor: color}}></div>
                </div>
              </Whisper>
            </div>
          );
        })}
      </div>
    );
  }


  function getBottomBar() {
    if (showBottomBar && !isNarrowScreen) {
      return(
        <div className="metadata-container" data-testid="metadata-container">

          {siteHealth && getSiteHealth()}

          {msalContext?.accounts?.[0]?.name && getUser()}
        
          {site_info.city &&
            <div className="metadata-item">
              <i className="fas fa-map-marker-alt me-2" />
              <b>
                {capitalize(site_info.city)}
              </b>
            </div>
          }

          <div className="metadata-item metadata-item-fixed-length-container">
            <LocalClock timeZone={site_info.timeZone} />
          </div>

          {site_info.city && weatherData &&
            <>
              <div className="metadata-item">
                <i className="fas fa-thermometer-half me-2"/>
                {getTemperature()}
              </div>

              <div className="metadata-item">
                {getWeatherDescription()}
              </div>
          
              <div className="metadata-item">
                <Whisper
                  speaker={<Tooltip>{translate("Last updated at (your local time)")}</Tooltip>}
                  // delayOpen={sharedSettings.delayShow}
                  trigger="hover"
                  placement="top"
                >
                  <span>
                    <i className="fas fa-history me-1" />
                    {lastUpdated}
                  </span>
                </Whisper>
              </div>
            </>
          }

          {extraFeatures?.maintenance?.active && getMaintenanceInfo()}

          <div
            className="metadata-hide-btn pointer"
            onClick={()=> setShowBottomBar(false)}
            data-testid="metadata-hide-button-in-element"
          >
            <i className="fas fa-chevron-down" />
          </div>

        </div>
      );
    }
  }

  function getBottomBarToggleButton() {
    if (!isNarrowScreen && !showBottomBar) {
      return(
        <div
          className="metadata-hide-btn pointer"
          onClick={()=> setShowBottomBar(true)}
          data-testid="metadata-show-button"
        >
          <i className="fas fa-chevron-up" />
        </div>
      );
    }
  }

  return (
    <>
      {getBottomBar()}
      {getBottomBarToggleButton()}
    </>
  );
}
