import React, { useRef, useState, useEffect, useContext, useCallback } from "react";
import { isFunction } from "lodash";
import {
  ChartItem,
  ReportItem,
  TrafficLight,
  PlotsData,
  Footer,
  ResizePanel
} from ".";
import { Overview } from "../overview";
import { translate } from "../languages";
import { dataSetHasQuicklyExportableData, getTimeparams } from "../utils";
import { calculateTimeWindow, calculateExtraTimeAndSkipCount } from "../timebuttons";
import { PanelMoreButtons } from "../moreButtons";
import { SiteContext, NarrowScreenContext, UseAuthenticationContext } from "../context";
import { MsalContext } from "@azure/msal-react";
import { InfoModal } from "../modals";
import { Whisper, Tooltip } from 'rsuite';
import GlobalIcon from '@rsuite/icons/Global';

export default function Item(props) {

  const {
    panelName, 
    panel,
    code,
    simplified,
    activeApp,
    minimizePanel,
    socket_values,
    socket,
    excelExport,
    visibilitySettings,
    muuriRef,
    layouts,
    module,
    collapsedMenu,
    ruuriId,
    globalTime,
    endpoints,
    site_info,
    globalResolutionFilterValue
  } = props;

  const [fetchedData, setFetchedData]                 = useState(); //1
  const [queryParams, setQueryParams]                 = useState([]); //2
  const [fetching, setFetching]                       = useState(false); //3
  const [dates, setDates]                             = useState(); //4
  const [selectedTimeWindow, setSelectedTimeWindow]   = useState(); //5
  const [resetZoomValues, setResetZoomValues]         = useState(); //6
  const [width, setWidth]                             = useState(); //7
  const [defaultWidth, setDefaultWidth]               = useState(); //8
  const [autoUpdate, setAutoUpdate]                   = useState(true); //9
  const [nextAutoUpdate, setNextAutoUpdate]           = useState(); //10
  const [cuttingMode, setCuttingMode]                 = useState("off"); //11
  const [stateOfInputs, setStateOfInputs]             = useState({}); //12
  const [resizable, setResizable]                     = useState(false); //13
  const [commentingMode, setCommentingMode]           = useState(false); //14
  const [toggleOptions, setToggleOptions]             = useState(); //15
  const [panelTab, setPanelTab]                       = useState(); //16
  const [resolution, setResolution]                   = useState(globalResolutionFilterValue); //17
  const [infoModalConfig, setInfoModalConfig]         = useState(); //18
  const [panelTimeValues, setPanelTimeValues]         = useState(); //19
  const [pickerType, setPickerType]                   = useState(); //20
  const [forceUpdate, setForceUpdate]                 = useState(false); //21

  const { useAuthentication }     = useContext(UseAuthenticationContext);
  const msalContext               = useContext(MsalContext);
  const { site }                  = useContext(SiteContext);
  const { isNarrowScreen }        = useContext(NarrowScreenContext);

  const itemRef = useRef();
  const itemTitleRef = useRef();
  const resizeObserver = useRef(null);

  // Set default values if exists in panel
  if (panel.sources.series[0].queryParams?.resolution) { setResolution(panel.sources.series[0].queryParams.resolution); }
  if (panel.sources.series[0].queryParams?.dates) { setDates(panel.sources.series[0].queryParams.dates); }


  /* set dates when datepicker is used 
   * also set auto update to false when datepicker is used
   */
  const passDate = useCallback(({ sdt, edt, timeWindow, extraTime, skipTimeCounter }) => {
    setDates({ sdt: sdt, edt: edt });
    setSelectedTimeWindow(timeWindow);
    setPanelTimeValues({ sdt, edt, timeWindow, extraTime, skipTimeCounter });
    setAutoUpdate(false);
    setResetZoomValues();
  },[]);

  /* set the timeWindow when time button is used 
   * clear dates and set auto update back to true
   */
  const handleTimeButtonClick = (timeWindow) => {
    setDates();
    setSelectedTimeWindow(timeWindow); 
    setAutoUpdate(true);
    setResetZoomValues();
    const newDates = getTimeparams(timeWindow);
    setPanelTimeValues({ sdt: newDates.sdt, edt: newDates.edt, timeWindow: timeWindow, extraTime: 0, skipTimeCounter: 0 })
  };


  const zoomRange = ({sdt, edt, zoomUsed}) => {
    if (zoomUsed) {
      const timeWindow = calculateTimeWindow(sdt, edt);
      const [newSkipTimeCount, newExtraTime ] = calculateExtraTimeAndSkipCount(timeWindow, edt);
      const defaultDates = dates ? { sdt: dates.sdt, edt: dates.edt } : getTimeparams(selectedTimeWindow);
      setDates({ sdt: sdt, edt: edt });
      setAutoUpdate(false);
      if (!resetZoomValues) { setResetZoomValues({ timeWindow: selectedTimeWindow, sdt: defaultDates.sdt, edt: defaultDates.edt }); } // only when zoom is used the first time
      setSelectedTimeWindow(timeWindow / 1000);
      setPanelTimeValues({ sdt: sdt, edt: edt, timeWindow: timeWindow / 1000, extraTime: newExtraTime, skipTimeCounter: newSkipTimeCount })
    } else if (resetZoomValues) {
      const [newSkipTimeCount, newExtraTime ] = calculateExtraTimeAndSkipCount(resetZoomValues.timeWindow * 1000, edt);
      setSelectedTimeWindow(resetZoomValues.timeWindow);
      setPanelTimeValues({ sdt: resetZoomValues.sdt, edt: resetZoomValues.edt, timeWindow: resetZoomValues.timeWindow, extraTime: newExtraTime, skipTimeCounter: newSkipTimeCount })
      setDates({ sdt: resetZoomValues.sdt, edt: resetZoomValues.edt });
      setAutoUpdate(true);
      setResetZoomValues();
    }
  };

  /**
   * Fetches data from the server based on the provided parameters.
   *
   * @param {Object} [params=stateOfInputs] - The parameters to be sent with the request.
   * @param {string} [type="get"] - The type of request to be made (e.g., "get", "post").
   * @returns {Promise<void>} - A promise that resolves when the data is fetched successfully.
   */
  const fetchData = useCallback(async (params = stateOfInputs) => {
    const series = panel.sources?.series;
    if (series && pickerType) {
      setFetching(true);
      const [fetchedData, queryParams] = await PlotsData({
        series,
        msal: msalContext,
        state: {dates, resolution, selectedTimeWindow},
        useAuthentication: useAuthentication,
        params: params,
        site: site
      });
      setFetchedData(fetchedData);
      setQueryParams(queryParams);
      setFetching(false);
    }
  }, [msalContext, selectedTimeWindow, panel, resolution, site, stateOfInputs, dates, useAuthentication, pickerType]);

  const postData = async(inputs) => {
    const series = panel.sources?.series;
    if (series) {
      const postSeries = series.filter(serie => serie.method.toUpperCase() === "POST");
      const [fetchedData] = await PlotsData({
        series: postSeries,
        msal: msalContext,
        state: {dates, resolution, selectedTimeWindow},
        useAuthentication: useAuthentication,
        params: inputs,
        site: site
      });
      if (fetchedData) { setForceUpdate(!forceUpdate) }
    }
  }

  /* Fetch data when panel is loaded or when force update is needed */
  useEffect(() => { fetchData(); },[fetchData, forceUpdate])

  /* set the auto update function when auto update is enabled and refresh interval is set 
   * also calculate next update time when auto update is enabled and when interval is run
   * next update time is visible in tooltip in the panel footer
   */
  useEffect(() => {
    const refresh =  panel.refreshinterval || 0;
    let intervalSeries;
    if (refresh) { setNextAutoUpdate(new Date(new Date().getTime() + panel.refreshinterval * 1000).toLocaleTimeString()); }
    if (refresh > 0 && autoUpdate) { intervalSeries = setInterval(
      () => {
        fetchData();
        setNextAutoUpdate(new Date(new Date().getTime() + panel.refreshinterval * 1000).toLocaleTimeString());
      }, 1000 * parseInt(refresh)
      ); }
    return () => { clearTimeout(intervalSeries); };
  }, [fetchData, panel, autoUpdate]);


  /* set panel specific states such as
   * - toggleOptions that are added to panel footer if panel has toggleOptions
   * - default time window that panel is using to fetch data
   * - resolution that is used when data is fetched (if exists in panel)
   * - panelTab that is used to select the tab that is shown in the panel header if panel has tabs
   * - default width of the panel
   */
  useEffect(() => {
    if (panel.toggleOptions) { setToggleOptions(panel.toggleOptions); }
    if (panel.tabs) { setPanelTab(Object.keys(panel.tabs)[0]); }
    if (panel.sources) {
      if (panel.resolutions) { setResolution(panel.resolutions.find(value => typeof value === "string") || globalResolutionFilterValue); }
      const queryWindow = panel.sources.series.some(series => series?.queryparams?.window) ? panel.sources.series.find(series => series.queryparams.window).queryparams.window : null;
      if (panel.timeButtons?.length > 0 && isNaN(panel.timeButtons.find(value => typeof value === "string"))) { // if timebuttons dont have a number value, queryparams.window is the default value  
        setPickerType(panel.timeButtons.find(value => typeof value === "string"))
        setSelectedTimeWindow(queryWindow);
      } else if (panel.timeButtons?.length > 0) { // defaultValue is the first string number in timeButtons
        setPickerType("range");
        setSelectedTimeWindow(parseInt(panel.timeButtons.find(value => typeof value === "string")));
      } else { //defaultValue is defined in queryparams, each query can have a different timewindow
        //setPickerType("queryparams");
        setPickerType("none");
        setSelectedTimeWindow(null);
      }
    }
  
  }, [panel, globalResolutionFilterValue]);

  useEffect(() => {
    if (globalTime) {
      setDates({ sdt: globalTime.sdt, edt: globalTime.edt });
      setSelectedTimeWindow(globalTime.timeWindow);
      setAutoUpdate(globalTime.autoUpdate);
      setResetZoomValues();
      setPanelTimeValues({ 
        sdt: globalTime.sdt, 
        edt: globalTime.edt, 
        timeWindow: globalTime.timeWindow, 
        extraTime: globalTime.extraTime, 
        skipTimeCounter: globalTime.skipTimeCounter
      })
    }
  }, [globalTime])

  /* Calculate the default width of the panel based on the screen size and panel settings
   * collapsed sidebar width 75px, expanded width is 270px
   * muuri has margin-left 10px 
   * rigth side of panel should also have margin 10px
   * scrollbar + extra width to keep 2 panels at the same line when their width factor is 0.5 = 30px
   * These are subtracted from the window width to get the default width of the content area
   */
  useEffect(() => {
    if (isNarrowScreen) { setDefaultWidth(window.innerWidth * 0.93); }
    else if (!isNarrowScreen && panel?.css?.width) { setDefaultWidth(panel.css.width); }
    else { 
      const contentWidth = collapsedMenu ? window.innerWidth - 125 : window.innerWidth - 320;
      const calculatedWidth = contentWidth * panel.widthFactor;
      if (panel?.css?.minWidth) {
        if (calculatedWidth < panel.css.minWidth) { setDefaultWidth(panel.css.minWidth); }
        else { setDefaultWidth(calculatedWidth); }
      } else { setDefaultWidth(contentWidth * panel.widthFactor); }
    }
  }, [panel, collapsedMenu, isNarrowScreen]);

  /* Handles the panel sizes */
  useEffect(() => {   

    const setPanelDimensions = () => {
      const app = activeApp;
      const panelTitle = panel.title;
      const itemStorageCode = `${site}-${app}-${panelTitle}`;
      const storedItem = localStorage.getItem(itemStorageCode);
      if (storedItem) {
        setWidth(JSON.parse(storedItem).width);
        if (itemRef.current) { itemRef.current.style.overflow = "hidden"; }
      } else { // if not set -> calculate width
        setWidth(defaultWidth);
      }
    }

    setPanelDimensions();

    if (itemRef.current) { //simplified item doesnt have this
      resizeObserver.current = new ResizeObserver(() => {
        if (muuriRef?.current?.grid) {
          muuriRef.current.grid.refreshItems();
          muuriRef.current.grid.layout();
        }
      });
      resizeObserver.current.observe(itemRef.current);
    }

    window.addEventListener("resize", setPanelDimensions);
  }, [panel, activeApp, site, isNarrowScreen, collapsedMenu, muuriRef, defaultWidth]);


  const showFooter = () => {
    if (panel.timeButtons) { return true; } 
    else if (panel.toggleOptions) { return true; } 
    else { return false; }
  }

  const handlePanelMinimize = () => {
    if (isFunction(minimizePanel)) {
      const app = activeApp;
      const panelTitle = panel.title;
      minimizePanel({
        layout: app,
        panel: panelTitle,
        id: panel.id || panelTitle
      });
    }
  }

  const handlePanelRefresh = () => {
    const app = activeApp;
    const panelTitle = panel.title;
    const itemStorageCode = `${site}-${app}-${panelTitle}`;
    localStorage.removeItem(itemStorageCode);

    setWidth(defaultWidth);
    setResizable(false);
    setAutoUpdate(true);
    if (selectedTimeWindow) {
      const newDates = getTimeparams(selectedTimeWindow);
      setDates({ sdt: newDates.sdt, edt: newDates.edt });
    }
  }

  const activateCuttingTool = (mode) => {
    setCuttingMode(mode);
    setAutoUpdate(false);
  }

  const getPanelContent = () => {
    const panels = [];
    /* Normal panel has rows */
    let panelRows = panel.rows;
    /* if panel tabs is used panelRows are the items of currently selected tab */
    if (panel.tabs) { panelRows = panel.tabs[panelTab]}

    const title = panel.title;

    for (let row in panelRows) {
      const panelCode = `item${row}_panel_${code}`;
      let panelContent = panelRows[row];
        /* Chart and Report info (every chart can have info button)  */
        if (row.includes("info")) {
          panels.push(
            <div style={{ position: "relative", padding: "10px" }}>
              <div style={{ cursor: "pointer" }}>
                <em
                    className="fas fa-info-circle text-info"
                    onClick={()=> { setInfoModalConfig(panelContent) }}
                    style={{ float: "right", fontSize: "1.25rem" }}
                  />
              </div>
            </div>
          );
        }
        /* REPORT */
        if (row.includes("table")) {
          let showReport = true;
          if (panelContent.metakeys) { // if row has metakeys check visibility
            for (let key of panelContent.metakeys) {
              if (visibilitySettings && visibilitySettings.get(key) === false) {
                showReport = false;
              }
            }
          }
          if (showReport) {
            panels.push(
              <div>
                <ReportItem
                  key={`report${row}`}
                  fetchedData={fetchedData}
                  queryParams={queryParams}
                  table={panelContent}
                  code={panelCode}
                  time={selectedTimeWindow}
                  setStateOfInputs={ (inputs) => setStateOfInputs(inputs) }
                  submitForm={ (inputs) => postData(inputs) }
                  socket_values={socket_values}
                  socket={socket}
                />
              </div>
            );
          }
        }
        /* CHART */
        if (row.includes("chart")) {
          let showChart = true;
          if (panelContent.metakeys) { // if row has metakeys check visibility
            for (let key of panelContent.metakeys) {
              if (visibilitySettings && visibilitySettings.get(key) === false) {
                showChart = false;
              }
            }
          }

          const cuttingToolsEnabled = panel.cutting || false;
          const zoomingEnabled = panel.zoomData || false;
          if (showChart) {
            panels.push(
              <div>
                <ChartItem
                  key={`$chart__${row}`}
                  chartObjName={row}
                  panelName={panelName}
                  title={title}
                  module={module}
                  updateData={() => setForceUpdate(!forceUpdate)}
                  time={selectedTimeWindow}
                  chart={panelContent}
                  fetchedData={fetchedData}
                  socket_values={socket_values}
                  socket={socket}
                  cuttingRange={(cut) => setFetchedData(cut)}
                  cuttingMode={cuttingMode}
                  cuttingToolsEnabled={cuttingToolsEnabled}
                  zoomRange={zoomRange}
                  zoomingEnabled={zoomingEnabled}
                  width={width}
                  commentingMode={commentingMode}
                  setAutoUpdate={(value) => { setAutoUpdate(value) }}
                  nonSeriesData={panel.sources.others}
                  queryParams={queryParams}
                  resetZoomValues={resetZoomValues}
                />
              </div>
            );
          }
        } 
        /* OVERVIEW */
        if (row.includes("overview")) {
          panels.push(
            <div className="flex-grow-1">
              <Overview
                key={`overview${row}`}
                overview={panelContent}
                sources={layouts[panelName].sources}
                layouts={layouts}
                toggleOptions={toggleOptions}
                fetchedData={fetchedData}
                queryParams={queryParams}
                socket_values={socket_values}
                socket={socket}
                time={selectedTimeWindow}
              />
            </div>
          );
        }
      
      
        if (row.includes("trafficLight")) {
          panels.push(
            <TrafficLight
              key={`trafficlight${row}`}
              options={panelContent}
            />
          );
        }
    }
    return (
      <>
        {fetchedData ? (
            panels.map((item, index) => ( 
              <React.Fragment key={`card-item-body__${index}`}>
                {item}
              </React.Fragment>
            ))
          ) : (
            <div className="object_loading">
              <em
                className="fas fa-spinner"
                id="loadingIcon"
              >
              </em>
              {translate("object_loading")}
            </div>
          )
        }
      </>
    );
  }

  let itemStyle = { width: `${width}px` };
  if (panel.css) {//itemStyle should overwrite css width and height since those can be changed with resize tool
    itemStyle = Object.assign({}, panel.css, itemStyle); 
  }

  if (simplified) { return getPanelContent(); }
  else {
    return (
      <div
        ref={itemRef}
        className="card item d-flex"
        style={itemStyle}
      >
        <div
          className="card-title draggable"
          ref={itemTitleRef}
          style={{display: "flex", marginTop: "-4px"}}
        >
          {panelTab ? (
            Object.keys(panel.tabs).map((settings, index) => {
              return(
                <div 
                  key={`panelTab${index}`}
                  className={panelTab === settings ? "boa-card-header panelTab selectedPanelTab" : "boa-card-header panelTab"}
                  onClick={ () => setPanelTab(settings) }
                >
                  {translate(settings)}
                  
                </div>
              );
            })
          ):(
            <div className="boa-card-header">
              {panel.title}
              {panel.isPublic &&
                <Whisper
                  trigger="hover"
                  placement="right"
                  speaker={<Tooltip>{"Last modified by: " + panel.creator || "Unknown"}</Tooltip>}
                >
                  <GlobalIcon style={{ fontSize: "17px", marginLeft: "5px" }} />
                </Whisper>
              } 
            </div>
          )}

          <PanelMoreButtons 
            autoUpdate={autoUpdate}
            toggleAutoUpdate={() => setAutoUpdate(!autoUpdate) }
            handlePanelRefresh={() => handlePanelRefresh()}
            toggleResizeTool={() => setResizable(!resizable)}
            handlePanelMinimize={() => handlePanelMinimize()}
            panelDataHasQuicklyExportableData={ fetchedData?.some(item => dataSetHasQuicklyExportableData(item))}
            queryParams={queryParams}
            panelHasExcelExport={fetchedData && excelExport}
            resizable={resizable}
            nextAutoUpdate={nextAutoUpdate}
            width={width}
            fetchedData={fetchedData}
            commentingMode={commentingMode}
            toggleCommentingMode={() => setCommentingMode(!commentingMode)}
            panelHasDataCommenting={panel.dataCommenting}
            panelName={panelName}
            activeApp={activeApp}
            endpoints={endpoints}
            site_info={site_info}
            {...props}
          />
        </div>

        <div className="item-content d-flex flex-column">
          {getPanelContent()}
        </div>
          
        {showFooter() &&
          <Footer 
            toggleOptions={toggleOptions}
            handleToggle={(e) => setToggleOptions({...toggleOptions, ...{ [e.target.value] : !toggleOptions[e.target.value]} })}
            layoutPanel={panel}
            handleTimeButtonClick={handleTimeButtonClick}
            activateCuttingTool={(mode) => activateCuttingTool(mode)}
            cuttingMode={cuttingMode}
            handleResolution={(res) => setResolution(res)}
            resolution={resolution}
            loading={fetching}
            autoUpdate={autoUpdate}
            width={width}
            passDate={passDate}
            selectedTimeWindow={selectedTimeWindow}
            panelTimeValues={panelTimeValues}
            pickerType={pickerType}
            nextAutoUpdate={nextAutoUpdate}
            panel={panel}
          />
        }

        {resizable && 
          <ResizePanel 
            panelWidth={width}
            updateWidth={(newWidth) => setWidth(newWidth)}
            panelRef={itemRef}
            resizeMinWidth={defaultWidth}
            itemStorageCode={`${site}-${activeApp}-${panel.title}`}
            ruuriId={ruuriId}
          />
        }
        {infoModalConfig &&
          <InfoModal
            show={infoModalConfig !== undefined}
            title={panel.title}
            info={infoModalConfig}
            site={site}
            onHide={() => setInfoModalConfig()}
          />
        }
      </div>
    );
  }
}

export { Item };