import React, { useState, useRef, useContext, useEffect } from "react";
import axios from "axios";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { TimeButtons } from "../timebuttons";
import {
  getColors,
  makeAuthUrlWithHeaders,
  acquireToken
} from "../utils";
import {
  UseAuthenticationContext,
  SiteContext
} from "../context";
import { MsalContext } from "@azure/msal-react";

export default function CustomReport(props) {
  const { module } = props;

  const { theme }                           = useContext(SiteContext);
  const { useAuthentication }               = useContext(UseAuthenticationContext);
  const msalContext                         = useContext(MsalContext);

  const [timeValue, setTimeValue] = useState(0);

  const chartRef = useRef();

  useEffect(() => {
    const getData = async() => {
      try {
        let url = `${module.endpoints?.history}?function=${module.function}&resolution=${new Date(timeValue.value.edt)/1000 - new Date(timeValue.value.sdt)/1000}&tags=${module.endpoints.tags.map(tag => tag)}&sdt=${timeValue.value.sdt}&edt=${timeValue.value.edt}`;
        let headers = {};
        if (useAuthentication) {
          [url, headers] = await makeAuthUrlWithHeaders(
            url, 
            headers, 
            acquireToken(msalContext)
          )
        }
        await axios({
          method: "get",
          url: url,
          headers: headers,
        }).then(response => {
          let seriesLength = response.data.length;
          let colorIndex = chartRef.current.chart.series.length / seriesLength;
          for (let i = 0; i < seriesLength; i++) {
            response.data[i].name = `${timeValue.value.sdt}-${timeValue.value.edt}`;
            if (module.dropFirstValue) {
              response.data[i].data.shift(); // drop first index due bug in api. 26112020
            }
            response.data[i].data[0][0] = module.endpoints?.mapNames[i];
            response.data[i].showInLegend = i > 0 ? false : true;
            const index = (
              (
                colorIndex === 0
                || colorIndex % 10 === 0
              )
              ? 0
              : parseInt((colorIndex * 0.1).toString().split(".")[1].charAt(0))
            );
            console.log(index)
            response.data[i].color = getColors(theme)[index];
            
            chartRef.current.chart.addSeries( response.data[i]);
            chartRef.current.chart.setTitle({ text: "" });
          }
        });
      } catch (e) {
        console.log(e.message);
      }
    }
    if (timeValue) { getData() }
  },[timeValue, msalContext, theme, useAuthentication, module]);

  useEffect(() => {
    chartRef.current.chart.setTitle({text: "Select time range in below"});
    chartRef.current.chart.reflow();
  },[chartRef]);


  const clearChart = () => {
    let series = chartRef.current.chart.series.length;
    for (let i = 0; i < series; i++){
      chartRef.current.chart.series[0].remove(false)
    }
    chartRef.current.chart.setTitle({text: "Select time range in below"});
  }

  return (
    <div
      key={module}
      className="card item"
      style={{width:"600px"}}
    >
      <div className="card-title">
        <div className="boa-card-header">
          <span>
            {module.title}
          </span>
        </div>
      </div>

      <div
        className="item-content card-body w-100"
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={module.chartConfig}
          ref={chartRef}
        />
      </div>
      <div className="card-footer">
        <TimeButtons
          item="default"
          id="0"
          panel="0"
          timeValue={timeValue}
          onTimeChange={(value) => { console.log(value); setTimeValue(value)}}
          layouts={{
            title: "default",
            timeButtons: [
              "custom"
            ] 
          }}    
          datepickerPlacement="top-start"
        />
        <button 
          className = "btn btn-custom-secondary" 
          onClick={()=> clearChart()}
        >
          Clear Chart
        </button>
      </div>
    </div>
  );
}
