import React, { Component, createContext } from "react";

const UseAuthenticationContext = createContext();

class UseAuthenticationProvider extends Component {

  getUseAuthentication() {
    if (process.env.REACT_APP_USE_AUTHENTICATION === "true") {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return(
      <UseAuthenticationContext.Provider
        value={{
          useAuthentication: this.props._testOverride ? this.props._testUseAuthentication : this.getUseAuthentication()
        }}
      >
        {this.props.children}
      </UseAuthenticationContext.Provider>
    );
  }
}

export { UseAuthenticationContext, UseAuthenticationProvider };
