import React, {useEffect, useContext, useState } from "react";
import { Panel, PanelGroup, Radio, RadioGroup, Whisper, Tooltip } from "rsuite";
import { translate } from "../languages";
import { NarrowScreenContext } from "../context";
import { SkipTime } from "../timebuttons";
import { secondsToString } from "../utils";
import { FilterButtons } from "../timebuttons";

export default function Footer(props) {
  const {
    toggleOptions,
    handleToggle,
    layoutPanel,
    handleTimeButtonClick,
    activateCuttingTool,
    cuttingMode,
    handleResolution,
    resolution,
    loading,
    autoUpdate,
    width,
    passDate,
    selectedTimeWindow,
    panelTimeValues,
    pickerType,
    nextAutoUpdate
  } = props;

  const { isNarrowScreen } = useContext(NarrowScreenContext);

  /* smallPanel defines buttons width 
   * when panel width is less than 450px buttons are displayed in block and one button fills the whole panel width
   * when panel width is more than 450px buttons are displayed in flex and they are all in the same row
   */
  const [smallPanel, setSmallPanel] = useState();

  useEffect(() => { setSmallPanel(width < 450); },[width]);

  const displayStyle = smallPanel ? "block" : "flex";
  const textAlign = smallPanel ? "text-center" : "text-left";

  /* button syles are used in radioGroup buttons */
  const buttonStyle = {
    padding: '8px 10px 8px 10px',
    display: 'inline-block',
    verticalAlign: 'middle',
    width: "70px"
  };

  const getCuttingTools = () => {
    return (
      <div className="mb-3">
        <RadioGroup 
          name = "radioList" 
          inline 
          appearance = "picker" 
          className = "footerBtnGroup row"
          style={{ display: displayStyle }}
          defaultValue = { cuttingMode }
          onChange={ (value) => activateCuttingTool(value) }
        >
          <span 
            className={`${textAlign} col`}
            style={buttonStyle}
          >
            <i className="fas fa-cut me-2"></i>{translate("CUT")}
          </span>
          <Radio className="col" style={{textAlign: "center"}} value="off" >{translate("Off")}</Radio>
          <Radio className="col" style={{textAlign: "center"}} value="xAxis">{translate("X-Axis")}</Radio>
          <Radio className="col" style={{textAlign: "center"}} value="yAxis">{translate("Y-Axis")}</Radio>
        </RadioGroup>
      </div>
    );
  }

  const getToggleButtons = () => {
    return(
      <div className="mb-3">
        <RadioGroup 
          name = "radioList" 
          inline 
          appearance = "picker" 
          className = "footerBtnGroup row"
          style={{display: displayStyle}}
        >
          <span 
            className={`${textAlign} col`}
            style={buttonStyle}
          >
            <i className="fas fa-list me-2"></i>{translate("Elements")}
          </span>
          {Object.keys(toggleOptions).map(option =>
            <button 
              className={toggleOptions[option] ? "col selectedElement" : "col unselectedElement" } 
              style={{ lineHeight: "20px", padding: "7px 0" }}
              key={option} 
              value={option} 
              onClick={(e) => handleToggle(e)}
            >
              {translate(option)}
            </button>
          )}
        </RadioGroup>
      </div>
    );
  }

  const getTimeText = () => {
    if (selectedTimeWindow === "month") { 
      if (panelTimeValues?.sdt) {
        return `${new Date(panelTimeValues.sdt).getMonth()+1} / ${new Date(panelTimeValues.sdt).getFullYear()}`
      } else {
        return `${new Date().getMonth()+1} / ${new Date().getFullYear()}`
      }    
    } else if (typeof(selectedTimeWindow) === "number"){ return secondsToString(selectedTimeWindow) }
  }

  const hasTimeButtons = layoutPanel.timeButtons && layoutPanel.timeButtons.length > 0;
  const isRangePicker = pickerType === "range";

  return(
    <PanelGroup className="noPadding">
    <Panel
      header={
        <div style={{color: "#444"}}>
          {layoutPanel.timeButtons && selectedTimeWindow &&
            <div className="d-inline-block me-4">
              <Whisper
                speaker={
                  <Tooltip>
                    {autoUpdate && nextAutoUpdate
                      ? <span>{translate("Next update:")} {nextAutoUpdate}</span> 
                      : translate("Automatic update is off")
                    }
                  </Tooltip>
                }
                trigger = "hover"
                placement = "auto"
              > 
                <i className={autoUpdate && nextAutoUpdate ? "fas fa-clock me-2" : "far fa-clock me-2"} />
              </Whisper>
              {getTimeText()}
            </div>
          }

          {layoutPanel.resolutions && resolution &&
            <div className="d-inline-block me-4">
              <i className="fas fa-wave-square me-2"/> 
              {resolution.toString() === "-1" ? "auto" : secondsToString(resolution)}
            </div>
          }

          {layoutPanel.cutting && !isNarrowScreen &&
            <div className="d-inline-block me-4">
              <i className="fas fa-cut me-2"/> 
              {cuttingMode}
            </div>
          }

          {toggleOptions &&
            <div className="d-inline-block me-4">
              <i className="fas fa-list me-2"/> 
              {`${Object.values(toggleOptions).filter(option => option).length}/${Object.values(toggleOptions).length}`}
            </div>
          }

          {loading &&
            <div className="d-inline-block me-2">
              <i className="fas fa-circle-notch fa-spin"></i>
            </div>
          }
        </div>
      }
      className={selectedTimeWindow || toggleOptions ? "card-footer" : "card-footer pe-none"}
      collapsible
    >
      <div className="mt-3" key={module + "" + selectedTimeWindow + "" + resolution}>

        {layoutPanel.timeButtons && 
          <SkipTime 
            passDate={passDate} 
            smallPanel={smallPanel} 
            loading={loading} 
            panelTimeValues={panelTimeValues}
            defaultValue={selectedTimeWindow}
            pickerType={pickerType}
          />
        }

        {hasTimeButtons && isRangePicker && (
          <FilterButtons
            buttons={layoutPanel.timeButtons} 
            handleChange={handleTimeButtonClick} 
            defaultSelected={selectedTimeWindow || 86400} 
            icon="far fa-clock me-1" 
            placeholderText="Time"
          />
        )}

        {layoutPanel.resolutions && (
          <FilterButtons
            buttons={layoutPanel.resolutions} 
            handleChange={handleResolution} 
            icon="fas fa-wave-square" 
            placeholderText="Resolution"
            defaultSelected={resolution || -1}
          />
        )}        

        {layoutPanel.cutting && !isNarrowScreen && getCuttingTools()}
        
        {toggleOptions && getToggleButtons()}
         
      </div>
    </Panel>
    </PanelGroup>
  );
}
