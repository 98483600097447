import React, { useContext } from "react";
import { Whisper, Tooltip } from "rsuite";
import { isFunction } from "lodash";
import { sharedSettings } from ".";
import { translate } from "../languages";
import { MsalContext } from "@azure/msal-react";
import { 
  SiteContext,  
  UseAuthenticationContext,
  TrackingContext
} from "../context";


export default function IconButton(props) {
  const { 
    onClick,
    buttonTooltip,
    icon,
    iconStyle = "fas",
    buttonText,
    panel,
    disabled = false,
    extraTooltipInfo = false
  } = props;

  const {
    trigger,
    placementInMoreIcon,
    delayShow,
  } = sharedSettings;

  const { site } =                  useContext(SiteContext);
  const useAuthenticationContext =  useContext(UseAuthenticationContext);
  const authContext =               useContext(MsalContext);
  const trackingContext =           useContext(TrackingContext);

  function handleClick() {
    if (isFunction(onClick) && !disabled) {
      trackingContext.track(
        {
          type: buttonText,
          action: "click",
          source: panel,
          target: null,
          site
        },
        useAuthenticationContext.useAuthentication,
        authContext
      );
      onClick();
    }
  }

  function getClickableDivClasses() {
    let classes = "more-item-container";
    if (disabled) {
      classes += " greyed-out";
    } else {
      classes += " pointer";
    } 
    return classes;
  }

  return(
    <Whisper
      speaker={
        <Tooltip>
          <div>
            <div>{translate(buttonTooltip)}</div>
            {extraTooltipInfo && <div>{translate(extraTooltipInfo.text)} {extraTooltipInfo.value}</div>}
          </div>
        </Tooltip>
      }
      trigger = {trigger}
      placement = {placementInMoreIcon}
      delayShow = {delayShow}
    > 
      <div
        className = {getClickableDivClasses()}
        onClick = {(e) => handleClick(e)}
        data-testid = "panel-button"
      >

        <div
          className = "more-item-icon-container"
        >
         <i className = {`${iconStyle} ${icon}`} />
        </div>

        <div className = "more-item-text-container">
          <p className = "more-text">{translate(buttonText)}</p>
        </div>

      </div>
    </Whisper>
  );
}
