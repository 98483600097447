import React, { useState } from 'react';
import { InputNumber, IconButton } from 'rsuite';

import ColorPicker from './colorPicker';
import TrashIcon from '@rsuite/icons/Trash';

const ChartIcon = ({ size }) => <TrashIcon style={{ fontSize: size }} />;

const colors = [
"#55bf3b",
"#dddf0d",
"orange",
"#de5253" ,
"#a64ca6",
"#4c8cd4",
"#a0522d",
];

const GaugeRangeSelector = (props) => {
    const { defaultValues, onDelete, selectorIndex, screenSize, rangeCount } = props;
    const [range, setRange] = useState(defaultValues);

    const handleChange = (field, value) => {
        setRange((prevRange) => {
            const updatedRange = { ...prevRange, [field]: value };
            props.onChange(updatedRange);
            return updatedRange;
        });
    };

    return (
        <div className="flex-container">
            <InputNumber
                prefix="From"
                defaultValue={range.min}
                onChange={(value) => handleChange("min", Number(value))}
                step={0.5}
            />

            <InputNumber
                prefix="To"
                defaultValue={range.max}
                onChange={(value) => handleChange("max", Number(value))}
                step={0.5}
            />

            {screenSize < 460 ? (
            <>
                <ColorPicker
                    colors={colors}
                    defaultColor={range.color || colors[selectorIndex]}
                    onChange={(value) => handleChange("color", value)}
                />

                <IconButton
                    icon={<TrashIcon />}
                    onClick={onDelete}
                    disabled={rangeCount <= 1}
                    appearance="primary"
                    color="red"
                    style={{ width: 38, height: "auto", marginLeft: "auto" }}
                />
            </>
            ) : (
                <div className="flex-item">
                    <ColorPicker
                        colors={colors}
                        defaultColor={range.color || colors[selectorIndex]}
                        onChange={(value) => handleChange("color", value)}
                    />

                    <IconButton
                        icon={<ChartIcon size="14px" />}
                        onClick={onDelete}
                        disabled={rangeCount <= 1}
                        appearance="primary"
                        color="red"
                        style={{ marginLeft: "auto" }}
                    />
                </div>
            )}

        </div>
    );
};

export default GaugeRangeSelector;
