import React, {useEffect, useContext, useState} from "react";
import axios from "axios";
import { Modal } from "rsuite"
import { isFunction } from "lodash";
import { translate } from "../languages";
import { UseAuthenticationContext } from "../context";
import { MsalContext } from "@azure/msal-react";
import {
  logErrorIfDevelopmentMode,
  makeAuthUrlWithHeaders,
  acquireToken
} from "../utils";

export default function SystemInfoModal(props) {
  const {
    onClose,
    systemInfo
  } = props;

  const { useAuthentication } = useContext(UseAuthenticationContext);
  const msalContext           = useContext(MsalContext);

  const [fetchedInfo, setFetchedInfo] = useState(null);

  function handleClose() {
    if (isFunction(onClose)) {
      onClose();
    }
  }

  useEffect(() => {
    async function fetchInfo() {
    let url = `/boa/api/v1/${systemInfo}/info`;
    let headers = {};
    if (useAuthentication) {
      [url, headers] = await makeAuthUrlWithHeaders(
        url, 
        headers, 
        acquireToken(msalContext)
      )
    } else {
      url = systemInfo;
    }
      try {
        await axios.get(url, { headers }).then(response => setFetchedInfo(response.data))
      } catch(e) {
        logErrorIfDevelopmentMode(e);
      }
    }

    fetchInfo();

  },[msalContext, systemInfo, useAuthentication])

  return(
    <React.Fragment>
      <Modal.Header>
        <h3>
          {translate("System Status")}
        </h3>
      </Modal.Header>

      <Modal.Body data-testid="about-modal-body">
        {fetchedInfo ? (
          <React.Fragment>
            <div className="mb-2 d-block">
              <div className="me-2 d-inline-block">
                {translate(" Platform started")}:
              </div>
              <div className="d-inline-block">
                {`${new Date(fetchedInfo?.processStarted).toLocaleString()} (${fetchedInfo?.processUpHours} hours)`}
              </div>
            </div>
            
            <div className="mb-2 d-block">
              <div className="me-2 d-inline-block">
                {translate("System started")}: 
              </div>
              <div className="d-inline-block">
                {`${new Date(fetchedInfo?.systemStarted).toLocaleString()} (${fetchedInfo?.systemUpHours} hours)`}
              </div>
            </div>

            <div className="mb-2 d-block">
              <div className="me-2 d-inline-block">
                {translate("RAM")}:
              </div>
              <div className="d-inline-block">
                {((fetchedInfo?.totalMem - fetchedInfo?.freeMem) / fetchedInfo?.totalMem * 100).toFixed(1)}%
              </div>
            </div>
          </React.Fragment>
        ) : (
          translate("Loading system status")
        )}
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          className="btn btn-danger"
          onClick={handleClose}
          data-testid="about-close-button"
        >
          {translate("Close")}
        </button>
      </Modal.Footer>
    </React.Fragment>
  );
}
