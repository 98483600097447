export function calculateTimeWindow(sdt, edt, calculateExtraHour = true) {
  let selectedSdtTime = new Date(sdt).getTime();
  let selectedEdtTime = new Date(edt).getTime();
  /* 
  * daylight saving time / standard time converter
  * sdt: "2023-02-28T22:00:52.036Z" - Date Wed Mar 01 2023 00:00:52 GMT+0200 (Eastern European Standard Time)
  * getTimezoneOffset() -120
  * edt: "2023-03-30T21:00:52.036Z" - Date Fri Mar 31 2023 00:00:52 GMT+0300 (Eastern European Summer Time) 
  * getTimezoneOffset() -180
  * 
  * when local time is daylight saving time (GMT +3 instead of GMT +2)
  * new Date().getTimezoneOffset is -180
  * selected time's timezoneOffset needs to be compared to that
  * if selected time has bigger value than new date() e.g. -120 > -180, 1 hour needs to be removed from that date 
  * New York: standard: -5, DST: -4. offsets: 300 > 240
  */
  if (calculateExtraHour) {
    /* sdt */
    if (new Date(sdt).getTimezoneOffset() > new Date().getTimezoneOffset()) { // daylight saving time
      selectedSdtTime = selectedSdtTime - 60 * 60 * 1000; // -1h
    } else if (new Date(sdt).getTimezoneOffset() < new Date().getTimezoneOffset()) { // Standard
      selectedSdtTime = selectedSdtTime + 60 * 60 * 1000; // +1h
    }
    /* edt */
    if (new Date(edt).getTimezoneOffset() > new Date().getTimezoneOffset()) { // daylight saving time
      selectedEdtTime = selectedEdtTime - 60 * 60 * 1000; // -1h
    } else if (new Date(edt).getTimezoneOffset() < new Date().getTimezoneOffset()) {// Standard
      selectedEdtTime = selectedEdtTime + 60 * 60 * 1000; // +1h
    }
  }
  return selectedEdtTime - selectedSdtTime;
}

export function calculateExtraTimeAndSkipCount(timeWindow, edt) {
    const currentTime = new Date().getTime();
    const selectedEdtTime = new Date(edt).getTime();
    if (currentTime - selectedEdtTime > 1000) {
      const newSkipTimeCount = parseInt((currentTime - selectedEdtTime) / timeWindow);
      const ExtraTime = (currentTime - selectedEdtTime) - timeWindow * newSkipTimeCount;
      return [ newSkipTimeCount, ExtraTime ];
    } else {
      return [ 0, 0 ];
    }
  }
